import { RegistrationActions } from 'actions'
import { combineReducers } from 'redux'
const INITIAL_STATE = {}

const handlers = {
  DEFAULT: state => state,
  [RegistrationActions.SET_REGISTRATION_FLOW]: (state, { payload }) => ({
    ...state,
    registerFlow: payload,
  }),
  [RegistrationActions.SET_REGISTRATION_DATA]: (state, { payload }) => ({
    ...state,
    regData: { ...state.regData, ...payload },
  }),
  [RegistrationActions.SET_TEMPORARY_AUTH_DATA]: (state, { payload }) => ({
    ...state,
    tempAuthData: payload,
  }),
  [RegistrationActions.SET_CUSTOMER_CATEGORIES]: (state, { payload }) => ({
    ...state,
    customerCategories: payload,
  }),
  [RegistrationActions.SET_CURRENT_CUSTOMER_CATEGORY]: (state, { payload }) => ({
    ...state,
    currentCustomerCategory: payload,
  }),
  [RegistrationActions.SET_LOADING]: (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }),
  [RegistrationActions.SET_AFFILIATE_TOKEN]: (state, { payload }) => ({
    ...state,
    affiliateToken: payload,
  }),
}

export const registrationReducer = (state = INITIAL_STATE, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}

const RegistrationReducer = combineReducers({
  data: registrationReducer,
})

export default RegistrationReducer
