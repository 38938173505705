const SET_CURRENT_CUSTOMER_CATEGORY = 'SET_CURRENT_CUSTOMER_CATEGORY'
const SET_REGISTRATION_FLOW = 'SET_REGISTRATION_FLOW'
const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA'
const SET_TEMPORARY_AUTH_DATA = 'SET_TEMPORARY_AUTH_DATA'
const SET_CUSTOMER_CATEGORIES = 'SET_CUSTOMER_CATEGORIES'
const SET_LOADING = 'SET_LOADING'
const SET_AFFILIATE_TOKEN = 'SET_AFFILIATE_TOKEN'

export const setAffiliateToken = payload => ({ payload, type: SET_AFFILIATE_TOKEN })

export {
  SET_AFFILIATE_TOKEN,
  SET_CURRENT_CUSTOMER_CATEGORY,
  SET_REGISTRATION_FLOW,
  SET_REGISTRATION_DATA,
  SET_TEMPORARY_AUTH_DATA,
  SET_CUSTOMER_CATEGORIES,
  SET_LOADING,
}
