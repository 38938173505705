/* eslint-disable indent */
/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from './use-field'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
/* Assets */
// import validCheckmarkSS from 'assets/svg/checkmark.svg'

/** Helpers */
import PasswordInput from 'components/helpers/form_fields/text_input'
import renderErrors from 'components/helpers/render_errors'
import Searchbox from 'components/helpers/form_fields/searchbox'
import Checkbox from 'components/helpers/form_fields/checkbox'
import CustomDropdownIndicator from 'components/helpers/form_fields/select/CustomDropdownIndicator'
/* Styles */

export const TextField = ({ label, required, type, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, { isSubmitting, setValue }] = useField(props)

  return (
    <div className="form-group mb-0">
      {label && (
        <label className={'d-flex control-label field-label text-nowrap'} htmlFor={props.id || props.name}>
          {label}
          <label className={`my-0 control-label${required ? ' required' : ''}`}></label>
        </label>
      )}
      {type === 'textarea' ? (
        <textarea className="form-control" {...field} {...props} />
      ) : (
        <input
          className="text-input form-control w-input"
          {...field}
          {...props}
          disabled={isSubmitting || props.isDisabled}
          onChange={e => {
            props.onChange ? props.onChange(e) : setValue(e.target.value)
          }}
        />
      )}
      {meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
    </div>
  )
}

export const SelectField = forwardRef(
  ({ label, required, errorAppearPosition, testID, type, ...props }, selectRef, asyncRef) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta, { isSubmitting, setValue }] = useField(props)
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const toggleMenu = () => setMenuIsOpen(prevState => !prevState)

    const customStyles = value => ({
      control: (styles, state) => ({
        ...styles,
        '&:before': props.suffix && {
          color: '#000',
          content: `'${props.suffix}'`,
          fontWeight: '600',
          position: 'absolute',
          right: '4rem',
        },
        // removing checkmark based on feedback from Simon
        // backgroundImage: state.hasValue && !state.isDisabled && `url(${validCheckmarkSS})`,
        backgroundPosition: 'right 2rem center !important',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1.2rem 1.2rem !important',
        border:
          state.hasValue && !state.isDisabled
            ? '1px solid green'
            : state.isFocused
            ? '1px solid #227643'
            : '1px solid #B3B3B3',
        borderRadius: 0,
        boxShadow: 'none',
        height: 45,
        minHeight: 45,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (styles, state) => ({
        ...styles,
        '&:hover': { backgroundColor: '#e6e6e6' },
        backgroundColor: state.isSelected ? '#e6e6e6' : null,
        borderBottom: '1px solid #ccc',
        color: '#333',
        padding: '1.2rem 1.6rem',
      }),
    })

    const CustomStylesSuperSwift = value => ({
      control: (styles, state) => ({
        ...styles,
        '&:before': props.suffix && {
          color: '#000',
          content: `'${props.suffix}'`,
          fontWeight: '600',
          position: 'absolute',
          right: '4rem',
        },
        // removing checkmark based on feedback from Simon
        // backgroundImage: state.hasValue && !state.isDisabled && `url(${validCheckmarkSS})`,
        backgroundPosition: 'right 2rem center !important',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1rem 1rem !important',
        border:
          state.hasValue && !state.isDisabled
            ? '1px solid #0A9BA9'
            : state.isFocused
            ? '1px solid #666664'
            : '1px solid #B3B3B3',
        borderRadius: '10px',
        boxShadow: 'none',
        height: 50,
        minHeight: 50,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (styles, state) => ({
        ...styles,
        '&:hover': { backgroundColor: '#e6e6e6' },
        backgroundColor: state.isSelected ? '#e6e6e6' : null,
        borderBottom: '1px solid #ccc',
        color: '#333',
        padding: '1.2rem 1.6rem',
      }),
    })

    return (
      <div className={`form-group mb-0 ${props.lowerIndent ?? ''} `}>
        {errorAppearPosition === 'top' && meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
        {label && (
          <label className="d-flex control-label align-items-start field-label" htmlFor={props.id || props.name}>
            {label}
            <label className={`my-0 control-label${required ? ' required' : ''}`}></label>
          </label>
        )}
        {type === 'AsyncSelect' ? (
          <AsyncSelect
            isDisabled={isSubmitting || props.isDisabled}
            ref={asyncRef}
            {...field}
            {...props}
            components={{
              DropdownIndicator: props => (
                <CustomDropdownIndicator {...props} testID={testID} menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
              ),
            }}
            styles={props.theme === 'superSwift' ? CustomStylesSuperSwift(field.value) : customStyles(field.value)}
            classNamePrefix="select"
            onChange={option => {
              props.onChange ? props.onChange(option, setValue) : setValue(option.value)
            }}
            value={
              props.value
                ? props.value
                : props.options?.find(option => option.value === field.value) || props.defaultValue || null
            }
          />
        ) : (
          <Select
            isDisabled={isSubmitting || props.isDisabled}
            ref={selectRef}
            {...field}
            {...props}
            components={{
              DropdownIndicator: props => (
                <CustomDropdownIndicator {...props} testID={testID} menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
              ),
            }}
            styles={props.theme === 'superSwift' ? CustomStylesSuperSwift(field.value) : customStyles(field.value)}
            classNamePrefix="select"
            onChange={option => {
              props.onChange ? props.onChange(option, setValue) : setValue(option.value)
            }}
            value={
              props.value
                ? props.value
                : props.options?.find(option => option.value === field.value) || props.defaultValue || null
            }
          />
        )}
        {!errorAppearPosition && meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
      </div>
    )
  },
)

export const CalendarField = ({ label, required, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, { isSubmitting, setValue }] = useField(props)
  return (
    <div className="form-group">
      <label className="d-flex control-label align-items-start field-label" htmlFor={props.id || props.name}>
        {label}
        <label className={`my-0 control-label${required ? ' required' : ''}`}></label>
      </label>
      <Searchbox
        disabled={isSubmitting || props.isDisabled}
        {...field}
        {...props}
        type="date"
        onChange={e => {
          if (props.onChange) {
            props.onChange(e)
          } else {
            setValue(e.target.value) && field.onBlur(e)
          }
        }}
      />
      {meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
    </div>
  )
}

export const CheckboxField = ({ required, controlled, ...props }) => {
  const [field, meta, { isSubmitting, setValue }] = useField({ ...props, type: 'checkbox' })
  const onChangeHandler = () => {
    controlled && props.onChange(!field.checked)
    setValue(!field.checked)
  }
  return (
    <div className="form-group">
      <Checkbox
        disabled={isSubmitting || props.isDisabled}
        {...props}
        onChange={onChangeHandler}
        checked={field.checked}
        bold={props.bold || false}
      />
      {meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
    </div>
  )
}

export const PasswordField = ({ label, required, ...props }) => {
  const [field, meta, { isSubmitting, setValue }] = useField(props)
  return (
    <div className={`form-group ${props.lowerIndent ? 'mb-0' : ''}`}>
      <label className="d-flex control-label" htmlFor={props.id || props.name}>
        {label}
        <label className={`d-flex my-0 control-label${required ? ' required' : ''}`} />
      </label>
      <PasswordInput
        disabled={isSubmitting}
        {...field}
        {...props}
        type="password"
        onChange={e => setValue(e.target.value)}
      />
      {meta.touched && (renderErrors(meta.error) || renderErrors(meta.apiError))}
    </div>
  )
}

PasswordField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  lowerIndent: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}
CheckboxField.propTypes = {
  /** Should message be bold? */
  bold: PropTypes.bool,

  controlled: PropTypes.bool,

  isDisabled: PropTypes.bool,

  message: PropTypes.string,

  name: PropTypes.string,

  onChange: PropTypes.func,

  required: PropTypes.bool,

  /** Distance from top */
  top: PropTypes.string,

  value: PropTypes.bool,
}
CalendarField.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}
SelectField.propTypes = {
  className: PropTypes.string,
  currencySelect: PropTypes.bool,
  defaultValue: PropTypes.object,
  errorAppearPosition: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  lowerIndent: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  suffix: PropTypes.any,
  testID: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.any,
  }),
}
TextField.propTypes = {
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
}
