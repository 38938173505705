import React from 'react'
import PropTypes from 'prop-types'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import amountToPayFromAvailableFund from 'components/utils/amount_to_pay_from_available_funds'

const AmountDeducted = ({ currencyCode, prefs }) => {
  const amountToPayFromAf = amountToPayFromAvailableFund(prefs)
  if (amountToPayFromAf > 0) {
    return (
      <div>
        Amount
        <span className="font-bold">
          {' '}
          {currencyCode} {formatCurrencyAmount(amountToPayFromAf)}{' '}
        </span>
        will be deducted from your funds.
      </div>
    )
  }

  return null
}

AmountDeducted.propTypes = {
  currencyCode: PropTypes.string,
  prefs: PropTypes.shape({
    from_deposited_funds: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
    from_held_funds: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
}

export default AmountDeducted
