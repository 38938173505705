import fetchApi from 'components/utils/fetch_api'

const getBeneficiaryCashDetails = (beneficiaryId, signal) => {
  return fetchApi(`recipients/cw/cash_beneficiaries/${beneficiaryId}`, { signal })
}

const updateBeneficiaryCashDetails = (beneficiaryId, data) => {
  return fetchApi(`recipients/cw/cash_beneficiaries/${beneficiaryId}`, { data, method: 'PUT' })
}

const getPayoutPartners = params => fetchApi('/recipients/cw/cash_payout_partners', { params })

const getPayoutPartnersAlt = params => fetchApi('/recipients/cw/cash_payout_partners_alt', { params })

export { getBeneficiaryCashDetails, updateBeneficiaryCashDetails, getPayoutPartners, getPayoutPartnersAlt }
