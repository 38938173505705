import React from 'react'
import PropTypes from 'prop-types'
import store from 'config/create_store'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signOut } from 'actions/users'
import { eraseForcedProfileReviewProp } from 'actions/clients'
import saveDataSession from 'components/utils/save_data_session'
import { userLocalStorage } from 'config/local_storage'
/**
 * HOC(Higher Order Components) that passes down function to save/delete session data
 */
const WithDataSessionStore = Component => {
  const HOC = class extends React.Component {
    /** Saves data session using the redux store */
    saveDataSession = data => {
      userLocalStorage.set('data', data)
      saveDataSession(data, store)
    }

    /** Removes data session using the redux store */
    removeDataSession = () => {
      this.props.eraseForcedProfileReviewProp(false)
      if (userLocalStorage.get('isAccountConfirmed')) {
        userLocalStorage.set('isAccountConfirmed', false)
      }
      this.props.signOut()
    }

    render() {
      return (
        <Component {...this.props} saveDataSession={this.saveDataSession} removeDataSession={this.removeDataSession} />
      )
    }
  }

  HOC.displayName = 'WithDataSessionStore'

  HOC.propTypes = {
    eraseForcedProfileReviewProp: PropTypes.func.isRequired,
    /** Delete all data from redux store */
    signOut: PropTypes.func.isRequired,
  }

  return connect(null, mapDispatchToProps)(HOC)
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        eraseForcedProfileReviewProp,
        signOut,
      },
      dispatch,
    ),
  }
}

export default WithDataSessionStore
