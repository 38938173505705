import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import env from '.env'
import { toast } from 'react-toastify'

/* Helpers */
import PaginationTable from 'components/helpers/pagination_table'

/* API */
import { payments as getPayments } from 'api/trading/cw/beneficiary_transactions'

const ViewHistory = ({ show, onHide, recipientId }) => {
  const containerClass = location.pathname.includes('retail') ? 'retail' : 'wholesale'
  const [data, setData] = useState([])
  const columns = [
    {
      header: 'Date',
      sort: true,
      sort_field: 'value_date',
    },
    {
      header: 'Currency',
      sort: true,
      sort_field: 'counter_currency_code',
    },
    {
      header: 'Amount',
      sort: true,
      sort_field: 'amcounter_currency_amountount',
    },
    {
      header: 'Status',
      sort: true,
      sort_field: 'status',
    },
  ]
  const renderItem = tx => {
    const newClassName = `${tx?.payment_status?.toLowerCase?.() || 'default'}-status`

    return (
      <div key={tx?.id} columns={1} data-testid="incoming-payments-item-row" className="rows history">
        <div className="item-container text">
          <div className="placeholder">Date</div>
          <div>
            {new Date(tx.value_date).toLocaleDateString('en-us', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Currency</div>
          <div>{tx?.currency_code}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Amount</div>
          <div>{tx?.amount}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Status</div>
          <div>
            <span className={newClassName}>{tx?.payment_status}</span>
          </div>
        </div>
      </div>
    )
  }

  const fetchData = async params => {
    try {
      const res = await getPayments({
        q: {
          beneficiary_id_eq: recipientId,
          s: 'spot_transaction_value_date DESC',
        },
        ...params,
      })
      res && setData(res?.data)
    } catch (error) {
      toast.error(error?.response?.data?.description || error?.response?.data?.error)
    }
  }

  return (
    <div className="modal-wrapper">
      <Modal show={show} onHide={onHide} className={containerClass + ' bene-modal'} contentClassName={env.retail.theme}>
        <PaginationTable
          onHide={onHide}
          columns={columns}
          data={data}
          getData={fetchData}
          perPage={5}
          renderItem={renderItem}
          testID="payment-history"
          tableTop={false}
          tableClassName="history"
        />
      </Modal>
    </div>
  )
}

ViewHistory.propTypes = {
  onHide: PropTypes.func,
  recipientId: PropTypes.number,
  show: PropTypes.bool,
}

export default ViewHistory
