import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
/* Base */
import Spinner from 'components/helpers/spinner'
/* Helpers */
import { TextField, SelectField, PasswordField, CheckboxField } from 'components/helpers/formik_fields'
/* Validations */
import { passwordSchema } from 'validations'
import './styles.scss'

const disabledFields = ['secret_question_1', 'secret_question_2', 'secret_answer_1', 'secret_answer_2']

export const Fields = ({ inputs, additionalInputs, tncInputs, onFormSubmit }) => {
  // eslint-disable-next-line no-unused-vars
  const [dropdowns, setDropdowns] = useState()

  const initialFields = {
    admin_user: { email: '', password: '', password_confirmation: '' },
    customer_address: { mobile: '', mobile_isd_code: '+27' },
    secret_answer_1: '',
    secret_answer_2: '',
    secret_question_1: '',
    secret_question_2: '',
    tnc_accepted: false,
    tnc_read: false,
  }

  const onMobileCodeChange = (e, setFieldValue) => {
    if (e.target.value.match(/^(\+?\d{0,3})$/)) {
      setFieldValue(e.target.name, e.target.value)
    }
  }

  return (
    <React.Fragment>
      <span className="d-flex align-items-start new-header mb-4">Create account</span>

      <Formik
        initialValues={initialFields}
        validationSchema={Yup.object().shape({
          admin_user: Yup.object().shape({
            email: Yup.string().email('Invalid email address'),
            password: passwordSchema,
            password_confirmation: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required("Can't be blank"),
          }),
          customer_address: Yup.object().shape({
            mobile: Yup.string()
              .matches(/^[0-9\b]+$/, 'Phone number is invalid')
              .min(10, 'Must be 10 digits')
              .max(10, 'Must be 10 digits'),
            mobile_isd_code: Yup.string().matches(/^(\+\d{1,3})$/, 'Not valid'),
          }),
          tnc_accepted: Yup.boolean()
            .required("Can't be blank")
            .oneOf([true], 'You must accept the terms and conditions.'),
        })}
        onSubmit={(values, actions) => {
          onFormSubmit(values, actions, initialFields).then(() => actions.setSubmitting(false))
        }}>
        {formik => (
          <Form>
            {additionalInputs?.mobile_number?.enabled && (
              <div className="form-control-container">
                <div className="row">
                  <div className="col col-3 pr-0">
                    <TextField
                      label={additionalInputs?.mobile_number?.name}
                      required={additionalInputs?.mobile_number?.required}
                      name="customer_address.mobile_isd_code"
                      placeholder="+27"
                      isDisabled
                      onChange={e => onMobileCodeChange(e, formik.setFieldValue)}
                    />
                  </div>
                  <div className="col col-9 pl-2 mt-4">
                    <TextField
                      name="customer_address.mobile"
                      label=" "
                      placeholder={additionalInputs?.mobile_number?.placeholder || '0123456789'}
                    />
                  </div>
                </div>
              </div>
            )}
            {inputs?.email?.enabled && (
              <TextField
                label={inputs?.email?.name}
                name="admin_user.email"
                type={inputs?.email?.type}
                placeholder={inputs?.email?.placeholder}
                required={inputs?.email?.required}
              />
            )}
            {inputs?.password?.enabled && (
              <PasswordField
                label={inputs?.password?.name}
                name="admin_user.password"
                type={inputs?.password?.type}
                placeholder={inputs?.password?.placeholder}
                required={inputs?.password?.required}
                lowerIndent="mb-0"
              />
            )}
            <div style={{ fontSize: '9px' }} className="my-2 ml-1">
              Password must be a minimum of 8 characters long, include at least one capital letter, one lowercase
              letter, one number, and one special character
            </div>
            {inputs?.confirm_password?.enabled && (
              <PasswordField
                label={inputs?.confirm_password?.name}
                name="admin_user.password_confirmation"
                type={inputs?.confirm_password?.type}
                placeholder={inputs?.confirm_password?.placeholder}
                required={inputs?.confirm_password?.required}
              />
            )}
            {!disabledFields.includes('secret_question_1') && (
              <SelectField
                label={inputs?.secret_question_1?.name}
                name="secret_question_1"
                id="secret_question_1-select"
                options={dropdowns?.secret_question_1}
                required={inputs.secret_question_1.required}
                errorAppearPosition="top"
              />
            )}
            {!disabledFields.includes('secret_answer_1') && (
              <TextField
                label={inputs?.secret_answer_1?.name}
                name="secret_answer_1"
                type={inputs.secret_answer_1?.type}
                placeholder={inputs?.secret_answer_1.placeholder}
                required={inputs.secret_answer_1.required}
              />
            )}
            {!disabledFields.includes('secret_question_2') && (
              <SelectField
                label={inputs.secret_question_2.name}
                name="secret_question_2"
                id="secret_question_2-select"
                options={dropdowns?.secret_question_2}
                required={inputs.secret_question_2.required}
              />
            )}
            {!disabledFields.includes('secret_answer_2') && (
              <TextField
                label={inputs?.secret_answer_2?.name}
                name="secret_answer_2"
                type={inputs?.secret_answer_2?.type}
                placeholder={inputs?.secret_answer_2?.placeholder}
                required={inputs?.secret_answer_2?.required}
              />
            )}
            {tncInputs?.tnc_accepted?.enabled && (
              <CheckboxField
                controlled
                name="tnc_accepted"
                onChange={() => {
                  const values = formik.values
                  formik.setValues({ ...values, tnc_read: !values?.tnc_accepted })
                }}>
                <div className="my-4 ml-1 register-tnc">
                  By clicking in Create Account, you agree to the ShopriteSend&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.termsconditions.co.za/content/dam/ShopriteGroup/Terms/PDFS/Shoprite-Send.pdf">
                    terms and conditions.
                  </a>
                </div>
              </CheckboxField>
            )}
            <div className="form-group mt-4">
              <button
                disabled={formik.isSubmitting || !formik.values.tnc_accepted}
                type="submit"
                className="ss-btn red w-100 popins-16">
                {formik.isSubmitting ? <Spinner spinner={false} /> : 'Create account'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

Fields.propTypes = {
  additionalInputs: PropTypes.object,
  dropdownFetcher: PropTypes.func,
  inputs: PropTypes.shape({
    confirm_password: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
    email: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
    password: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
    secret_answer_1: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
    secret_answer_2: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    }),
    secret_question_1: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      required: PropTypes.bool,
    }),
    secret_question_2: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      required: PropTypes.bool,
    }),
    user_name: PropTypes.shape({
      name: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  onFormSubmit: PropTypes.func,
  serverErrors: PropTypes.object,
  tncInputs: PropTypes.object,
}
