import React, { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
/** Actions */
import { setAffiliateToken } from 'actions/registration'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import { CheckboxField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'
/* Utils */
import { steps, SOUTH_AFRICAN } from '../register.constants'
/* Paths */
import { STEP_TWO, STEP_THREE } from 'routes/paths/public/register'
import { REGISTER_PATH } from 'routes/paths/root'

/**
 * Component for the first step of registration process
 */
const StepOne = () => {
  const history = useHistory()
  const { isLoading, setCurrentCustomerCategory, setRegistrationFlow, setCustomerCategories } = useContext(
    RegistrationContext,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    setCustomerCategories()
  }, [])
  /**
   * Gets called when the user selects the account type by pressing the Select button
   *
   * @param {String} path destination of redirection after the button click
   */

  const handleToggle = (fieldName, { values, setFieldValue }) => {
    values[fieldName] && setFieldValue(fieldName, false)
  }

  // store affiliate_token search param if set into redux store to use when creating user
  try {
    const searchParams = new URLSearchParams(document.location.search)
    const affiliateToken = searchParams.get('affiliate_token')
    if (affiliateToken) {
      dispatch(setAffiliateToken(affiliateToken))
    }
  } catch (error) {
    console.log(error)
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[0].text} />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <Formik
                initialValues={{ foreign_national: false, sa_native: false }}
                onSubmit={async (values, actions) => {
                  if (values.foreign_national) {
                    return history.push(`${REGISTER_PATH}/${STEP_TWO}`)
                  }
                  setCurrentCustomerCategory(SOUTH_AFRICAN)
                  setRegistrationFlow('Individual', 'Individual Account Registration', SOUTH_AFRICAN)
                  actions.setSubmitting(false)
                  history.push(`${REGISTER_PATH}/${STEP_THREE}`)
                }}>
                {formik => (
                  <Form>
                    <CheckboxField
                      className="ss-theme-checkbox"
                      controlled
                      name="sa_native"
                      message="South African ID Holders"
                      onChange={() => handleToggle('foreign_national', formik)}
                    />

                    <div className="popins-16 dim-grey mb-3">
                      You will need a copy of your SA Green bar coded ID book or SA Smart card ID.
                    </div>

                    <hr className="p-1 my-4" />

                    <CheckboxField
                      className="ss-theme-checkbox"
                      controlled
                      name="foreign_national"
                      message="Foreign National, residing in South Africa"
                      onChange={() => handleToggle('sa_native', formik)}
                    />

                    <div className="popins-16 dim-grey mb-3">
                      You will need a copy of your passport or temporary resident permit.
                    </div>
                    <button
                      data-testid="selectBtn-customerCategoryName"
                      disabled={isLoading || (!formik.values.foreign_national && !formik.values.sa_native)}
                      type="submit"
                      className="ss-btn red w-100 popins-16">
                      {isLoading ? <Spinner spinner={false} /> : 'Select'}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}

StepOne.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object,
  /** Function to get registration flow from API */
  setRegistrationFlow: PropTypes.func,
}

export default StepOne
