import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import DateInput from 'components/helpers/form_fields/date_input'
import renderErrors from 'components/helpers/render_errors'
import 'react-datepicker/dist/react-datepicker.css'

const radioValues = {
  company: 'Company',
  individual: 'Individual',
}

const OrderingCustomerDetails = props => {
  const { beneficiary, errors, onBeneficiaryOrderingCustomerChange, recipientSettings, countriesList } = props

  // eslint-disable-next-line camelcase
  const { birth_date_year, birth_date_month, birth_date_day } = beneficiary?.ordering_customer || {}
  const [customerBirthday, setCustomerBirthday] = useState(
    // eslint-disable-next-line camelcase
    birth_date_year && birth_date_month && birth_date_day
      ? new Date(birth_date_year, birth_date_month, birth_date_day)
      : new Date().setFullYear(new Date().getFullYear() - 18),
  )
  const [maxDate] = useState(new Date().setFullYear(new Date().getFullYear() - 18))

  const onDateChange = date => {
    onBeneficiaryOrderingCustomerChange('birth_date_day')
    onBeneficiaryOrderingCustomerChange('birth_date_month')
    onBeneficiaryOrderingCustomerChange('birth_date_year')
    setCustomerBirthday(date)
  }

  if (!recipientSettings?.currency?.ordering_customer) {
    return null
  }

  return (
    <React.Fragment>
      <div className="form-group row mb-4">
        <div className="col-12">
          <div className="well-text p-2">
            <label className="text-right-label font-bold">Ordering Customer Details</label>
          </div>
        </div>
      </div>
      <div className="form-group row mb-4">
        <div className="col-12">
          <div className="well-text p-2">
            <label className="text-right-label font-bold">Select The Type Of Ordering Customer</label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-4">
          <Form.Check
            type="radio"
            checked={beneficiary.ordering_customer.account_type_name === radioValues.individual}
            onChange={() => onBeneficiaryOrderingCustomerChange('account_type_name', radioValues.individual)}
            label={radioValues.individual}
          />
        </div>
        <div className="col-4">
          <Form.Check
            type="radio"
            checked={beneficiary.ordering_customer.account_type_name === radioValues.company}
            onChange={() => onBeneficiaryOrderingCustomerChange('account_type_name', radioValues.company)}
            disabled={beneficiary.ordering_customer.beneficiary_id}
            label={radioValues.company}
          />
        </div>
      </div>
      {beneficiary.ordering_customer.account_type_name === radioValues.individual ? (
        <React.Fragment>
          <div className="form-group required row">
            <div className="col-sm-6">
              <label className="control-label">First Name</label>
            </div>
            <div className="col-sm-6">
              <input
                name="first_name"
                className="form-control"
                value={beneficiary.ordering_customer.first_name}
                onChange={event => onBeneficiaryOrderingCustomerChange('first_name', event.target.value)}
              />
              {renderErrors(errors['ordering_customer.first_name'])}
            </div>
          </div>
          <div className="form-group required row">
            <div className="col-sm-6 space-10">
              <label className="control-label">Last Name</label>
            </div>
            <div className="col-sm-6">
              <input
                name="last_name"
                className="form-control"
                value={beneficiary.ordering_customer.last_name}
                onChange={event => onBeneficiaryOrderingCustomerChange('last_name', event.target.value)}
              />
              {renderErrors(errors['ordering_customer.last_name'])}
            </div>
          </div>
          <div className="form-group required row">
            <div className="col-sm-6 space-10">
              <label className="control-label">Date of Birth</label>
            </div>
            <div className="col-sm-6">
              <div className="input-group date">
                <DateInput selected={customerBirthday} onChange={date => onDateChange(date)} maxDate={maxDate} />
              </div>
              {renderErrors(errors['ordering_customer.birth_date_day'])}
              {renderErrors(errors['ordering_customer.birth_date'])}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="form-group required row">
            <div className="col-sm-6 space-10">
              <label className="control-label">Company Name</label>
            </div>
            <div className="col-sm-6">
              <input
                className="form-control"
                value={beneficiary.ordering_customer.company_name}
                onChange={event => onBeneficiaryOrderingCustomerChange('company_name', event.target.value)}
              />
              {renderErrors(errors['ordering_customer.company_name'])}
            </div>
          </div>
          <div className="form-group required row">
            <div className="col-sm-6 space-10">
              <label className="control-label">Company Number</label>
            </div>
            <div className="col-sm-6">
              <input
                className="form-control"
                value={beneficiary.ordering_customer.company_number}
                onChange={event => onBeneficiaryOrderingCustomerChange('company_number', event.target.value)}
              />
              {renderErrors(errors['ordering_customer.company_number'])}
            </div>
          </div>
        </React.Fragment>
      )}
      <div>
        <div className="form-group required row">
          <div className="col-sm-6 space-10">
            <label className="control-label">Address Line 1</label>
          </div>
          <div className="col-sm-6">
            <input
              className="form-control"
              value={beneficiary.ordering_customer.address_line_1}
              onChange={event => onBeneficiaryOrderingCustomerChange('address_line_1', event.target.value)}
            />
            {renderErrors(errors['ordering_customer.address_line_1'])}
          </div>
        </div>
        <div className="form-group required row">
          <div className="col-sm-6 space-10">
            <label className="control-label">Address Line 2</label>
          </div>
          <div className="col-sm-6">
            <input
              className="form-control"
              value={beneficiary.ordering_customer.address_line_2}
              onChange={event => onBeneficiaryOrderingCustomerChange('address_line_2', event.target.value)}
            />
            {renderErrors(errors['ordering_customer.address_line_2'])}
          </div>
        </div>
        <div className="form-group required row">
          <div className="col-sm-6 space-10">
            <label className="control-label">City</label>
          </div>
          <div className="col-sm-6">
            <input
              className="form-control"
              value={beneficiary.ordering_customer.city}
              onChange={event => onBeneficiaryOrderingCustomerChange('city', event.target.value)}
            />
            {renderErrors(errors['ordering_customer.city'])}
          </div>
        </div>
        <div className="form-group required row">
          <div className="col-sm-6 space-10">
            <label className="control-label">State</label>
          </div>
          <div className="col-sm-6">
            <input
              className="form-control"
              value={beneficiary.ordering_customer.province}
              onChange={event => onBeneficiaryOrderingCustomerChange('province', event.target.value)}
            />
            {renderErrors(errors['ordering_customer.province'])}
          </div>
        </div>
        <div className="form-group required row">
          <div className="col-sm-6 space-10">
            <label className="control-label">Post Code</label>
          </div>
          <div className="col-sm-6">
            <input
              className="form-control"
              value={beneficiary.ordering_customer.post_code}
              onChange={event => onBeneficiaryOrderingCustomerChange('post_code', event.target.value)}
            />
            {renderErrors(errors['ordering_customer.post_code'])}
          </div>
        </div>
        <div className="form-group required row">
          <div className="col-sm-6">
            <label className="control-label">Country</label>
          </div>
          <div className="col-sm-6">
            <Select
              placeholder="Select"
              value={countriesList.find(option => option.value === beneficiary.ordering_customer.country_code)}
              onChange={countryOption => onBeneficiaryOrderingCustomerChange('country_code', countryOption.value)}
              options={countriesList}
            />
            {renderErrors(errors['ordering_customer.country_code'])}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

OrderingCustomerDetails.propTypes = {
  beneficiary: PropTypes.shape({
    ordering_customer: PropTypes.shape({
      account_type_name: PropTypes.any,
      address_line_1: PropTypes.any,
      address_line_2: PropTypes.any,
      beneficiary_id: PropTypes.any,
      birth_date_day: PropTypes.any,
      birth_date_month: PropTypes.any,
      birth_date_year: PropTypes.any,
      city: PropTypes.any,
      company_name: PropTypes.any,
      company_number: PropTypes.any,
      country_code: PropTypes.any,
      first_name: PropTypes.any,
      last_name: PropTypes.any,
      post_code: PropTypes.any,
      province: PropTypes.any,
    }),
  }),
  countriesList: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.shape({
    'ordering_customer.address_line_1': PropTypes.any,
    'ordering_customer.address_line_2': PropTypes.any,
    'ordering_customer.birth_date': PropTypes.any,
    'ordering_customer.birth_date_day': PropTypes.any,
    'ordering_customer.city': PropTypes.any,
    'ordering_customer.company_name': PropTypes.any,
    'ordering_customer.company_number': PropTypes.any,
    'ordering_customer.country_code': PropTypes.any,
    'ordering_customer.first_name': PropTypes.any,
    'ordering_customer.last_name': PropTypes.any,
    'ordering_customer.post_code': PropTypes.any,
    'ordering_customer.province': PropTypes.any,
  }),
  onBeneficiaryOrderingCustomerChange: PropTypes.func,
  recipientSettings: PropTypes.shape({
    currency: PropTypes.shape({
      ordering_customer: PropTypes.any,
    }),
  }),
}

export default OrderingCustomerDetails
