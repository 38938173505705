const queryUrl = {
  /**
   * Creates a URLSearchParams to be able to retrieve the params from the url
   * @param {string} url Full url or search part of the url to extrac the query
   * @returns {URLSearchParams object}
   */
  extract: url => {
    let params = url
    try {
      params = new URL(url).search
    } catch (error) {
      // console.error(error)
    }

    return new URLSearchParams(params)
  },
  /**
   * Generates a new url with a query from an object
   * @param {string} url Full url to add the params object
   * @param {object} params Contains key and value to add the URL passed
   * @returns {string} URL with a query
   */
  include: (url, params) => {
    var validUrl
    try {
      validUrl = new URL(url)
      for (const [key, value] of Object.entries(params)) {
        validUrl.searchParams.append(key, value)
      }
      return validUrl.href
    } catch (error) {
      // console.error(error)
    }

    return url
  },
}

export default queryUrl
