import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch } from 'react-router'
import SignIn from 'components/pages/users/sign_in'
import PasswordReset from 'components/pages/users/password/reset'
import OtpValidation from 'components/pages/users/password/otp-validation'
import PasswordNew from 'components/pages/users/password/new'
import TwoFactorNewFormFields from 'components/pages/users/two-factor-authentication/new-form-fields'
import TwoFactorConfirmFormFields from 'components/pages/users/two-factor-authentication/confirm-form-fields'
import {
  SIGN_IN_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_EDIT_PATH,
  TWO_FACTOR_CONFIRM_PATH,
  TWO_FACTOR_NEW_PATH,
  SELECT_ACCOUNT_PATH,
  OTP_VALIDATION_PATH_FULL_PATH,
  CONFIRM_CUSTOMER_DETAILS,
} from 'routes/paths/public/users'
import NotFound from 'routes/shared/not_found'
import Route from 'routes/shared/auth_route'
import SwitchAccount from 'components/pages/users/select-account'
import ConfirmCustomerDetails from 'components/pages/users/confirm-customer-details'

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/${SIGN_IN_PATH}`} component={SignIn} />
    <Route exact isPrivate path={`${match.path}/${SELECT_ACCOUNT_PATH}`} component={SwitchAccount} />
    <Route exact path={`${match.path}/${CONFIRM_CUSTOMER_DETAILS}`} component={ConfirmCustomerDetails} />
    <Route exact path={`${match.path}/${PASSWORD_EDIT_PATH}`} component={PasswordNew} />
    <Route exact path={`${match.path}/${PASSWORD_RESET_PATH}`} component={PasswordReset} />
    <Route exact path={`${match.path}/${OTP_VALIDATION_PATH_FULL_PATH}`} component={OtpValidation} />
    <Route exact path={`${match.path}/${TWO_FACTOR_NEW_PATH}`} component={TwoFactorNewFormFields} />
    <Route exact path={`${match.path}/${TWO_FACTOR_CONFIRM_PATH}`} component={TwoFactorConfirmFormFields} />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
