import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
/* Components */
import FundingSourceClient from '../funding_source/client'
import FundingSourceBank from '../funding_source/bank'
import RemainingTransferLimit from '../shared/remaining_transfer_limit'
import { NavLink } from 'react-router-dom'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'
import ExchangeRate from '../exchange_rate'

const InstantQuote = props => {
  const {
    isApplicationBank,
    oneToOneDealAllowed,
    setCustomerBankAccount,
    toggleFetching,
    setSettlementPrefs,
    onSellCurrencyChange,
    onBuyCurrencyChange,
    onCurrencyAmountChange,
    onSourceOfFundsChange,
    showExchangeRate,
    proceedWithTransfer,
    displayPage,
  } = props
  return (
    <div className={`calculator-container ${displayPage === 2 ? 'full' : ''}`}>
      <div className={`calculator ${displayPage === 2 ? 'full' : ''}`}>
        <div className={`calculator-grid ${displayPage === 2 ? 'resize' : ''}`}>
          <div className={`w-layout-grid calculator-rows ${displayPage === 2 ? 'columns' : ''}`}>
            <h2 className={`heading quote-header ${displayPage === 2 ? 'hidden' : ''}`}>Instant quote</h2>
            {isApplicationBank ? (
              <FundingSourceBank
                {...props}
                oneToOneDealAllowed={oneToOneDealAllowed}
                setCustomerBankAccount={setCustomerBankAccount}
              />
            ) : (
              <FundingSourceClient
                {...props}
                toggleFetching={toggleFetching}
                setSettlementPrefs={setSettlementPrefs}
                oneToOneDealAllowed={oneToOneDealAllowed}
                onSellCurrencyChange={onSellCurrencyChange}
                onBuyCurrencyChange={onBuyCurrencyChange}
                onCurrencyAmountChange={onCurrencyAmountChange}
                onSourceOfFundsChange={onSourceOfFundsChange}
              />
            )}
          </div>
          <div className={`w-layout-grid calculator-rows ${displayPage === 2 ? 'column' : ''}`}>
            <RemainingTransferLimit displayPage={displayPage} />
            <ExchangeRate {...props} showExchangeRate={showExchangeRate} className="hidden" />
            <div className={`w-layout-grid economics buttons ${displayPage === 2 ? 'hidden' : ''}`}>
              <NavLink to={TRANSACTION_NEW_FULL_PATH + '-'} className="cta-button secondary grey w-button mt-0">
                Cancel transfer
              </NavLink>
              <a onClick={proceedWithTransfer} className="cta-button calc w-button mt-0">
                Proceed with transfer
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

InstantQuote.propTypes = {
  displayPage: PropTypes.number,
  isApplicationBank: PropTypes.bool,
  onBuyCurrencyChange: PropTypes.func.isRequired,
  onCurrencyAmountChange: PropTypes.func.isRequired,
  onSellCurrencyChange: PropTypes.func.isRequired,
  onSourceOfFundsChange: PropTypes.func.isRequired,
  oneToOneDealAllowed: PropTypes.func.isRequired,
  proceedWithTransfer: PropTypes.func.isRequired,
  setCustomerBankAccount: PropTypes.func.isRequired,
  setSettlementPrefs: PropTypes.func.isRequired,
  showExchangeRate: PropTypes.bool,
  toggleFetching: PropTypes.func.isRequired,
}

export default InstantQuote
