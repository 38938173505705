import env from '.env'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { userLocalStorage } from 'config/local_storage'
import { toast } from 'react-toastify'
/* API */
import { sendResetPasswordInstructions } from 'api/accounts/cw/users'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import AuthHeader from 'components/base/layouts/auth_header'

/* Helpers */
import Spinner from 'components/helpers/spinner'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import FlagImage from 'components/helpers/flag_image'

/* Paths */
import { PASSWORD_EDIT_FULL_PATH } from 'routes/paths/public/users'

/**
 * Component which renders form to trigger the reset password instructions
 */

class SendResetPasswordInstructions extends Component {
  /**
   * @namespace
   * @property {boolean} state.isFetching  - Indicates the component loading status
   * @property {string} state.error  - Message displayed when an error occurs
   * @property {string} state.login  - Value typed by user
   */
  state = {
    authStrategy: window.Cypress ? 'email' : env.authenticationStrategy || 'email',
    country: 'ZA',
    error: '',
    isFetching: false,
    login: '',
    showCountryName: false,
    showCountryPhoneCodeSelector: false,
  }

  onChangeHandler = value => {
    const regexSpecialCharacters = /[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,'#]/
    const regexNumbers = /^[0-9\b]+$/
    this.setState({
      error: regexSpecialCharacters.test(value) ? 'Special characters are not allowed' : '',
      login: value,
      showCountryPhoneCodeSelector: !!regexNumbers.test(value),
    })
  }

  renderCountryPhoneCodeSelector() {
    const { showCountryName } = this.state
    return (
      <React.Fragment>
        <FlagImage countryCode={this.state.country} />
        <select
          className="width-100"
          value={this.state.country}
          onMouseLeave={() => this.setState({ showCountryName: false })}
          onMouseUp={() => this.setState({ showCountryName: true })}
          onFocus={() => this.setState({ showCountryName: true })}
          onBlur={() => this.setState({ showCountryName: false })}
          onChange={e => this.setState({ country: e.target.value, showCountryName: false })}>
          {getCountries().map(country => (
            <option key={country} value={country}>
              +{getCountryCallingCode(country)} {showCountryName && en[country]}
            </option>
          ))}
        </select>
      </React.Fragment>
    )
  }

  isLoginValid = () => {
    const { showCountryPhoneCodeSelector, login, authStrategy } = this.state
    const emailPattern = /\S+@\S+\.\S+/
    const regexMemberId = /^[a-zA-z]{3}[0-9]{8}$/g
    const payload = () => {
      switch (true) {
        case authStrategy === 'email' && !emailPattern.test(login):
          return 'Please type a valid email'
        case authStrategy === 'mobile_phone_or_member_id' && showCountryPhoneCodeSelector && login.length !== 10:
          return 'Phone number is invalid'
        case authStrategy === 'mobile_phone_or_member_id' &&
          !showCountryPhoneCodeSelector &&
          !regexMemberId.test(login):
          return 'Member ID is invalid'
        default:
          return ''
      }
    }
    const error = payload()
    this.setState({ error })

    return !error.length
  }

  submitForm = async event => {
    event.preventDefault()
    if (this.isLoginValid()) {
      this.setState({ isFetching: true })
      const { login } = this.state
      try {
        const response = await sendResetPasswordInstructions(login)
        if (response?.data?.success) {
          userLocalStorage.set('restorationLogin', login)
          this.props.history.push(PASSWORD_EDIT_FULL_PATH)
        } else {
          this.setState({ error: response?.data?.description })
        }
      } catch (error) {
        toast.error(error?.response?.data?.description || error?.message)
      }
      this.setState({ isFetching: false })
    }
  }

  render() {
    const { showCountryPhoneCodeSelector, error } = this.state
    return (
      <RegistrationHeader classContainer="col-8">
        <AuthHeader title="Forgot password?">
          <form onSubmit={this.submitForm}>
            <div className="form-group mb-5">
              <span className="d-flex align-items-start new-color mb-2 popins-14">Mobile number or Member ID</span>
              <div className="d-flex">
                {showCountryPhoneCodeSelector && this.renderCountryPhoneCodeSelector()}
                <input
                  type="text"
                  name="login"
                  className="form-control"
                  placeholder="User ID"
                  onChange={e => this.onChangeHandler(e.target.value)}
                  required
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
            </div>
            <div className="form-group mt-4">
              <button type="submit" disabled={this.state.isFetching} className="ss-btn red w-100 popins-16">
                {this.state.isFetching ? <Spinner spinner={false} /> : 'Proceed'}
              </button>
            </div>
          </form>
        </AuthHeader>
      </RegistrationHeader>
    )
  }
}

SendResetPasswordInstructions.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
}

export default withRouter(SendResetPasswordInstructions)
