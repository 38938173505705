/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
/* API */
import fetchApi from 'components/utils/fetch_api'
import { update as updateSettlementPrefs } from 'api/trading/cw/transaction_settlement_prefs'
import { index as renumerationPreferencesIndex } from 'api/recipients/cw/renumeration_preferences'

/* Helpers */
import { Skeleton } from 'components/helpers/skeleton'
import Retail from './retail'
import Wholesale from './wholesale'
// import DebitCardsSelectionContent from 'components/pages/shared/debit_cards/modal/selection'
// import DebitCardsFormContent from 'components/pages/shared/debit_cards/modal/form'
/* Actions */
import { setCurrentPaymentMode, setCurrentReceiptAccounts } from 'actions/trading'

class TransactionSettlementPrefs extends Component {
  state = {
    customerBankAccount: {},
    errors: {},
    fetching: {
      initialLoad: true,
      options: false,
      receipt: false,
      resourceGet: false,
      resourcePut: false,
      tdd: false,
    },
    modal: {
      debitCardForm: false,
      debitCardSelection: false,
    },
    prefs: {},
    receiptAccount: {},
    receiptAccountList: [],
    receiptPaymentModeList: [],
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerBankAccount, receiptAccount } = this.state
    const { currentPaymentMode, resource, receiptAmount, currencyCode, fetchingCBAList } = this.props

    if ((!prevProps.resource?.id && resource?.id) || prevProps.receiptAmount !== receiptAmount) {
      currentPaymentMode?.paymentMode ? this.updateResource() : this.requestPrefs()
    }

    if (prevState.customerBankAccount.id !== customerBankAccount.id) {
      this.transactionsDirectDebitsCreateOrUpdate()
    }

    if (
      receiptAccount?.receipt_account_id &&
      receiptAmount &&
      resource?.id &&
      prevState?.currentPaymentMode?.paymentMode !== currentPaymentMode?.paymentMode
    ) {
      this.updateResource()
    }

    if (prevProps.currencyCode !== currencyCode) {
      this.requestPaymentModes()
      this.counterPartyReceiptAccountsIndex()
    }

    if (prevProps.fetchingCBAList !== fetchingCBAList && !fetchingCBAList) {
      this.updateCurrentCustomerBankAccount()
    }
  }

  fetchingData = fetchingName => this.setState({ fetching: { ...this.state.fetching, [fetchingName]: true } })

  dataFetched = fetchingName => this.setState({ fetching: { ...this.state.fetching, [fetchingName]: false } })

  updateCurrentCustomerBankAccount = () => {
    const { prefs } = this.state
    const { customerBankAccountList } = this.props
    let { customerBankAccount } = this.state
    if (prefs?.from_customer_bank_account?.enabled && prefs.from_customer_bank_account?.customer_bank_account_id) {
      customerBankAccount =
        customerBankAccountList.find(cba => prefs.from_customer_bank_account.customer_bank_account_id === cba.id) ||
        customerBankAccountList[0]
    } else if (customerBankAccountList.length === 1) {
      customerBankAccount = customerBankAccountList[0]
    }
    this.setState({
      customerBankAccount,
    })
  }

  toggleDebitCardContent = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        debitCardForm: !this.state.modal.debitCardForm,
        debitCardSelection: !this.state.modal.debitCardSelection,
      },
    })
  }

  displayFundingAccountForTransactionOrMo = () => {
    return this.props.displayFundingAccountSection && (this.resourceIsTransaction() || this.resourceIsMarketOrder())
  }

  resourceIsTransaction = () => {
    return this.props.resource.type === 'Transaction'
  }

  resourceIsMarketOrder = () => {
    return this.props.resource.type === 'MarketOrder'
  }

  resourceIsForwardTransaction = () => {
    return this.props.resource.type === 'ForwardTransaction'
  }

  electronicFundTransfer = () => {
    return this.state.paymentMode?.value === 'transfer_in_settlement_account'
  }

  forwardDepositReceiptPrefsSet = () => {
    const { prefs } = this.state
    return prefs.transfer_in_settlement_account?.deposit_receipt_transfer?.receipt_account_id
  }

  isReceiptAccountSelected = () => {
    const { prefs } = this.state
    return prefs.transfer_in_settlement_account?.enabled && prefs.transfer_in_settlement_account?.receipt_account_id
  }

  setApplicablePrefs = () => {
    const { prefs } = this.state

    if (prefs.transfer_in_settlement_account?.enabled) {
      this.setState({
        balanceAmountToPayIntoReceiptAcc: prefs.transfer_in_settlement_account.balance_receipt_transfer?.amount_to_pay,
        depositAmountToPayIntoReceiptAcc: prefs.transfer_in_settlement_account.deposit_receipt_transfer?.amount_to_pay,
      })
    }
  }

  onCustomerBankAccountChange = customerBankAccountId => {
    const { customerBankAccountList } = this.props
    const customerBankAccount = customerBankAccountList.find(cba => cba.id === customerBankAccountId)
    this.setState({ customerBankAccount })
  }

  onReceiptAccountChange = receiptAccountId => {
    const { receiptAccountList } = this.state
    this.setState({
      receiptAccount: receiptAccountList.find(receiptAccount => receiptAccount.receipt_account_id === receiptAccountId),
    })
  }

  transactionsDirectDebitsCreateOrUpdate = () => {
    const { tddUrl } = this.props
    const { customerBankAccount } = this.state
    const data = {
      direct_debit: {
        amount: null,
        customer_bank_account_id: customerBankAccount.id,
      },
    }
    fetchApi(tddUrl, { data, method: 'POST' })
      .then(() => this.requestPrefs())
      .catch(error => this.setState({ errors: error?.response?.data?.errors }))
  }

  requestPrefs = () => {
    const { resource, serviceUrl } = this.props
    if (resource.id) {
      fetchApi(`${serviceUrl}/${resource.id}`).then(response => {
        this.setState({ prefs: response?.data })
        this.props.setSettlementPrefs(response?.data)
      })
    }
  }

  requestPaymentModes = () => {
    const { countryCode, currencyCode } = this.props
    const { fetching } = this.state
    if (currencyCode && countryCode) {
      this.fetchingData('options')
      this.props.setCurrentPaymentMode() // clear any previously selected value
      renumerationPreferencesIndex({
        q: {
          country_code_eq: countryCode,
          currency_code_eq: currencyCode,
        },
      })
        .then(response => {
          const receiptPaymentModeList = response?.data.reduce((arr, value) => {
            Object.entries(value.renumeration_options).forEach(([key, value]) => {
              if (value.enabled) {
                arr.push({
                  id: value?.renumeration_type_id,
                  label: value?.form_preferences?.name,
                  value: key,
                })
              }
            })
            return arr
          }, [])
          this.setState({ fetching: { ...fetching, options: false }, receiptPaymentModeList })
        })
        .catch(error => this.props.setCurrentPaymentMode(undefined, error?.response?.data?.description))
    }
  }

  counterPartyReceiptAccountsIndex = () => {
    const toggleReceiptAccountSelectList = () => {
      const { fetching, receiptAccountList, prefs } = this.state
      let receiptAccount
      if (this.isReceiptAccountSelected() && receiptAccountList?.length) {
        receiptAccount = receiptAccountList?.find(
          ra => ra?.receipt_account_id === prefs?.transfer_in_settlement_account?.receipt_account_id,
        )
      } else if (receiptAccountList?.length > 1) {
        receiptAccount = receiptAccountList?.find(ra => ra.default_receipt_provider)
      }
      this.setState(
        {
          fetching: { ...fetching, initialLoad: false },
          receiptAccount: receiptAccount || receiptAccountList?.[0],
        },
        () => fetching.initialLoad && this.props.onLoadInitialState?.(),
      )
    }

    const { currencyCode } = this.props
    const params = { q: { counter_party_account_currency_code_eq: currencyCode } }
    this.props
      .setCurrentReceiptAccounts(params)
      .then(response => this.setState({ receiptAccountList: response?.data }, toggleReceiptAccountSelectList))
  }

  updateResource = () => {
    const { receiptAccount } = this.state
    const receiptParams = {
      transaction_settlement_prefs: this.electronicFundTransfer()
        ? {
            transfer_in_settlement_account: { receipt_account_id: receiptAccount?.receipt_account_id },
          }
        : { pay_by_payment_card: { gateway_reference_id: '' } },
    }

    const { resource } = this.props
    // do not update Transaction Settlement Prefs if PaymentMode equals = 'pay_by_payment_card' because API returns error
    this.electronicFundTransfer() &&
      updateSettlementPrefs(resource?.id, receiptParams)
        .then(response => {
          this.setState({ prefs: response?.data })
          this.props.setSettlementPrefs(response?.data)
        })
        .catch(error => toast.error(error?.response?.data?.description))
  }

  render() {
    const { location } = this.props
    const {
      fetching: { receipt, resourceGet, resourcePut },
    } = this.state

    if (receipt || resourceGet || resourcePut) {
      return <Skeleton />
    }

    return (
      <React.Fragment>
        <div data-testid="settlement-pref-container">
          {location.pathname.includes('retail') ? (
            <Retail {...this.state} {...this.props} />
          ) : (
            <Wholesale
              {...this.state}
              {...this.props}
              displayFundingAccountForTransactionOrMo={this.displayFundingAccountForTransactionOrMo}
              electronicFundTransfer={this.electronicFundTransfer}
              onCustomerBankAccountChange={this.onCustomerBankAccountChange}
              onReceiptAccountChange={this.onReceiptAccountChange}
              resourceIsForwardTransaction={this.resourceIsForwardTransaction}
              resourceIsMarketOrder={this.resourceIsMarketOrder}
            />
          )}
        </div>
      </React.Fragment>
    )
  }
}

TransactionSettlementPrefs.propTypes = {
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  currentPaymentMode: PropTypes.object,
  customerBankAccountList: PropTypes.array,
  displayFundingAccountSection: PropTypes.any,
  fetchingCBAList: PropTypes.bool,
  /** Provides several attributes to get the current url {@link withRouter} */
  location: PropTypes.object.isRequired,
  onLoadInitialState: PropTypes.func.isRequired,
  receiptAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resource: PropTypes.object.isRequired,
  serviceUrl: PropTypes.string,
  setCurrentPaymentMode: PropTypes.func,
  setCurrentReceiptAccounts: PropTypes.func,
  setSettlementPrefs: PropTypes.func,
  tddUrl: PropTypes.string,
}

const mapStateToProps = state => {
  const { currentPaymentMode } = state.trading.data
  return { currentPaymentMode }
}

export default connect(mapStateToProps, { setCurrentPaymentMode, setCurrentReceiptAccounts })(
  withRouter(TransactionSettlementPrefs),
)
