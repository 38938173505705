import fetchApi from 'components/utils/fetch_api'

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 180,
}

const sell = (params = DEFAULT_QUERY_PARAMS) => {
  return fetchApi('trading/cw/currencies/sell', { params })
}

const buy = (params = DEFAULT_QUERY_PARAMS) => {
  return fetchApi('trading/cw/currencies/buy', { params })
}

export { sell, buy }
