import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/**
 * Component with log in, register link and a form to sign in
 */
const AuthHeader = ({ tenantName, logo, ...props }) => {
  return (
    <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
      <div className="reg-frame">
        <h3 className="auth-header mb-5">{props.title || 'Welcome back'}</h3>
        {props.children}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { tenant } = state.client.data
  const { logo, name } = tenant || {}
  return { logo, tenantName: name }
}

AuthHeader.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.shape({
    small_thumbnail_url: PropTypes.string,
  }),
  tenantName: PropTypes.string,
  title: PropTypes.string,
}

export default connect(mapStateToProps, null)(AuthHeader)
