import { combineReducers } from 'redux'
import ServerErrorReducers from './server_errors'
import UserReducers from './users'
import RegistrationReducer from './registration'
import TradingReducers from './trading'
import ClientReducers from './clients'
import { RESET_STORE } from 'actions'
import { userLocalStorage } from 'config/local_storage'

const reducers = combineReducers({
  client: ClientReducers,
  registration: RegistrationReducer,
  server: ServerErrorReducers,
  trading: TradingReducers,
  user: UserReducers,
})

export default (state, action) => {
  if (action.type === RESET_STORE) {
    userLocalStorage.clearAll()
    state = undefined
  }

  return reducers(state, action)
}
