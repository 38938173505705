import fetchApi from 'components/utils/fetch_api'

const sessions = ({ ssoToken }) => {
  return fetchApi('boq/sessions', { data: { SsoToken: ssoToken }, method: 'POST' })
}

const sessionsDelete = ({ springbackReason }) => {
  return fetchApi('boq/sessions', { data: { springback_reason: springbackReason }, method: 'DELETE' })
}

export { sessions, sessionsDelete }
