import PropTypes from 'prop-types'
import React from 'react'

/**
 * Component for custom DropdownIndicator
 */
const CustomDropdownIndicator = props => (
  <div onClick={props.toggleMenu} data-testid={`${props.testID}-indicator`}>
    {props.isMulti ? (
      <div className="custom-select_multi-indicator" />
    ) : (
      <i className={`fa fa-caret-${props.selectProps.menuIsOpen ? 'up' : 'down'} p-3`} />
    )}
  </div>
)

CustomDropdownIndicator.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
  testID: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
}

export default CustomDropdownIndicator
