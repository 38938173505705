import fetchApi from 'components/utils/fetch_api'
import { userLocalStorage } from 'config/local_storage'

const signIn = (login, password) => {
  if (userLocalStorage.get('registrationToken')?.token) {
    userLocalStorage.set('registrationToken', null)
  }
  return fetchApi('accounts/cw/users/sign_in', { data: { user: { ...login, password } }, method: 'POST' })
}

const signOut = () => {
  return fetchApi('accounts/cw/users/sign_out', { method: 'DELETE' })
}

const authorizations = userId => {
  return fetchApi(`accounts/cw/users/${userId}/authorizations`)
}

const show = userId => {
  return fetchApi(`accounts/cw/users/${userId}`, { method: 'GET' })
}

const updateUser = (userId, data, contentType) => {
  return fetchApi(`accounts/cw/users/${userId}`, {
    contentType: contentType || 'application/x-www-form-urlencoded',
    data,
    method: 'PUT',
  })
}

const accountSettings = userId => {
  return fetchApi(`accounts/cw/users/${userId}/account_settings`)
}

const sessionRefresh = ({ token, refreshToken }) => {
  return fetchApi('accounts/cw/users/sessions/refresh', {
    data: { refresh_token: refreshToken, token },
    method: 'POST',
  })
}

const sessionSwitch = customerId => {
  return fetchApi('accounts/cw/users/sessions/switch', { data: { customer_id: customerId }, method: 'POST' })
}

const addresses = userId => {
  return fetchApi(`accounts/cw/users/${userId}/addresses/${userId}`)
}

const sendResetPasswordInstructions = uid => {
  return fetchApi('accounts/cw/users/password', { data: { user: { uid } }, method: 'POST' })
}

const updatePasswordWithToken = data => {
  return fetchApi('accounts/cw/users/password', { data: { user: data }, method: 'PUT' })
}

const notificationSettings = userId => {
  return fetchApi(`notifications/cw/users/${userId}/notification_preferences`)
}

const updatePassword = (userId, password) => {
  return fetchApi(`accounts/cw/users/${userId}/update_with_password`, { data: { user: password }, method: 'PUT' })
}

const notificationSettingsUpdate = (userId, data) => {
  return fetchApi(`notifications/cw/users/${userId}/notification_preferences`, { data, method: 'POST' })
}

const securityAnswers = userId => {
  return fetchApi(`accounts/cw/users/${userId}/secret_answers`)
}

const securityAnswersUpdate = (userId, data) => {
  return fetchApi(`accounts/cw/users/${userId}/secret_answers/cud`, { data, method: 'POST' })
}

export {
  show,
  signIn,
  signOut,
  addresses,
  updateUser,
  authorizations,
  sessionSwitch,
  accountSettings,
  sessionRefresh,
  sendResetPasswordInstructions,
  notificationSettings,
  updatePassword,
  notificationSettingsUpdate,
  securityAnswers,
  securityAnswersUpdate,
  updatePasswordWithToken,
}
