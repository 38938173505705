import { useState, useEffect } from 'react'

/** Returns window height and width  */
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    height,
    width,
  }
}

/** Hook to provide widow dimension on resize */
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
