import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import CurrencyInput from 'components/helpers/form_fields/currency'
import FlagImage from 'components/helpers/flag_image'
import { Formik } from 'formik'
/* Shared */
import TransactionSettlementPrefs from 'components/pages/shared/transaction_settlement_prefs'

/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'
/** Actions */
import { setRiskAssesmentInformation } from 'actions/users'

const FundingSourceClient = props => {
  const {
    counterCurrencyCountryCode,
    customerBankAccountList,
    transaction,
    tradingCurrencies,
    oneToOneDealAllowed,
    toggleFetching,
    transactionAmountCalculationBasedOn,
    onCurrencyAmountChange,
    onSellCurrencyChange,
    onBuyCurrencyChange,
    errors,
    fetching,
    onSourceOfFundsChange,
    paymentMode,
    sourcesOfFunds,
    userId,
    riskAssesmentInfo,
    setRiskAssesmentInformation,
    setSettlementPrefs,
    displayPage,
  } = props

  useEffect(() => {
    if (isEmpty(riskAssesmentInfo)) {
      return setRiskAssesmentInformation(userId)
    }
    if (!transaction?.source_of_funds) {
      const sof = sourcesOfFunds.find(source => source.label === riskAssesmentInfo?.sources_of_funds?.[0])?.value
      onSourceOfFundsChange?.(sof)
    }
    if (!transaction?.base_currency_code && tradingCurrencies?.sell[0]?.currency_code) {
      onSellCurrencyChange?.(tradingCurrencies?.sell[0]?.currency_code)
    }
  }, [
    userId,
    sourcesOfFunds,
    transaction,
    onSellCurrencyChange,
    onSourceOfFundsChange,
    setRiskAssesmentInformation,
    riskAssesmentInfo,
    tradingCurrencies,
  ])

  const currentLocale = () =>
    tradingCurrencies.sell.find(ccy => ccy.currency_code === transaction?.base_currency_code)?.country_code

  const baseCurrencyCodeOptions = useMemo(() => {
    const disableSellCurrencyOptions = currencyCode =>
      oneToOneDealAllowed() ? false : currencyCode === transaction?.counter_currency_code
    return tradingCurrencies.sell.map(ccy => ({
      isDisabled: disableSellCurrencyOptions(ccy.currency_code),
      label: (
        <div>
          <FlagImage countryCode={ccy.country_code} />
          {ccy.currency_code}
        </div>
      ),
      value: ccy.currency_code,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingCurrencies.sell, transaction?.counter_currency_code])

  const quoteCurrencyCodeOptions = useMemo(() => {
    const disableSellCurrencyOptions = currencyCode =>
      oneToOneDealAllowed() ? false : currencyCode === transaction?.counter_currency_code

    return (
      tradingCurrencies?.buy &&
      tradingCurrencies.buy.map(ccy => ({
        country_code: ccy.country_code,
        currency_code: ccy.currency_code,
        isDisabled: disableSellCurrencyOptions(ccy.currency_code),
        label: (
          <div className="counter-currency-select-list-item">
            <div>
              <FlagImage countryCode={ccy.country_code} />
              {ccy.currency_code}
            </div>
            <div className="counter-currency-select-list-item-currency">{ccy.country_name}</div>
          </div>
        ),
        value: ccy.country_name,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingCurrencies?.buy, transaction?.counter_currency_code])

  const paymentModeSelected = Boolean(paymentMode)

  return (
    <React.Fragment>
      <div className="calculator-form">
        <label className="calculator-label">You pay</label>
        <CurrencyInput
          locale={currentLocale()}
          data-testid="base_currency_amount-currency-input"
          value={transaction?.base_currency_amount}
          onChange={onCurrencyAmountChange('base_currency_amount')}
          disabled={paymentModeSelected || transactionAmountCalculationBasedOn === 'counter_currency_amount'}
          className="form-control w-input"
          currencyClassName="zar"
          placeholder="0.00"
          currencyFlagAndName={
            <div>
              <Formik>
                <SelectField
                  placeholder="Select"
                  className={baseCurrencyCodeOptions.length <= 1 ? 'select-disabled' : ''}
                  isDisabled={baseCurrencyCodeOptions.length <= 1}
                  id="base_currency_code-select"
                  name="base_currency_code"
                  isLoading={fetching?.initialLoad && !tradingCurrencies?.sell[0]?.currency_code}
                  value={baseCurrencyCodeOptions.find(option => option.value === transaction?.base_currency_code)}
                  onChange={ccyOption => onSellCurrencyChange(ccyOption.value)}
                  options={baseCurrencyCodeOptions}
                />
              </Formik>
            </div>
          }
        />
        {renderErrors(errors?.base_currency_amount || errors?.base_currency_code)}
      </div>
      <div className="calculator-form recipient-currency">
        <label className="calculator-label">Recipient gets</label>
        <CurrencyInput
          locale={currentLocale()}
          data-testid="counter_currency_amount-currency-input"
          value={transaction?.counter_currency_amount}
          onChange={onCurrencyAmountChange('counter_currency_amount')}
          disabled={paymentModeSelected || transactionAmountCalculationBasedOn === 'base_currency_amount'}
          className="form-control w-input"
          currencyClassName="zar"
          placeholder="0.00"
          currencyFlagAndName={
            <div>
              <Formik>
                <SelectField
                  classNamePrefix="select"
                  placeholder="Select"
                  className={quoteCurrencyCodeOptions && quoteCurrencyCodeOptions?.length > 1 ? 'select-active' : ''}
                  isDisabled={(quoteCurrencyCodeOptions && quoteCurrencyCodeOptions.length <= 1) || displayPage > 1}
                  id="counter_currency_code-select"
                  name="counter_currency_code"
                  isLoading={fetching?.initialLoad && !tradingCurrencies?.sell[0]?.currency_code}
                  value={
                    quoteCurrencyCodeOptions &&
                    quoteCurrencyCodeOptions.find(option => option.country_code === counterCurrencyCountryCode)
                  }
                  onChange={ccyOption => onBuyCurrencyChange(ccyOption)}
                  options={quoteCurrencyCodeOptions}
                />
              </Formik>
            </div>
          }
        />
        {renderErrors(errors?.counter_currency_amount)}
      </div>
      <div className={`calculator-form ${displayPage === 2 ? 'hidden' : ''}`}>
        <TransactionSettlementPrefs
          resource={{ id: transaction?.transaction_id, type: 'Transaction' }}
          customerBankAccountList={customerBankAccountList}
          setSettlementPrefs={setSettlementPrefs}
          fetchingCBAList={fetching?.CBAList}
          countryCode={transaction?.counter_currency_country_code}
          currencyCode={transaction?.counter_currency_code}
          receiptAmount={transaction?.receipt_amount}
          displayFundingAccountSection={transaction?.transaction_id}
          serviceUrl="trading/cw/transaction_settlement_prefs"
          tddUrl={`trading/cw/transactions/${transaction?.transaction_id}/direct_debits`}
          onLoadInitialState={() => toggleFetching('settlementPreferences')}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { currencies, currentCounterCurrency } = state.trading.data
  return {
    counterCurrencyCountryCode: currentCounterCurrency?.counterCurrencyCountryCode,
    paymentMode: state?.trading?.data?.currentPaymentMode?.paymentMode,
    riskAssesmentInfo: state?.user?.data?.riskAssesmentInformation,
    sourcesOfFunds: state.client?.data?.dropdownSOF || [],
    tradingCurrencies: currencies,
    userId: state.user?.data?.userId || null,
  }
}

FundingSourceClient.propTypes = {
  beneficiary: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  counterCurrencyCountryCode: PropTypes.string,
  customerBankAccountList: PropTypes.arrayOf(PropTypes.object),
  displayPage: PropTypes.number,
  errors: PropTypes.shape({
    base_currency_amount: PropTypes.any,
    base_currency_code: PropTypes.any,
    counter_currency_amount: PropTypes.any,
  }),
  fetching: PropTypes.shape({
    CBAList: PropTypes.bool,
    initialLoad: PropTypes.bool,
  }).isRequired,
  onBuyCurrencyChange: PropTypes.func.isRequired,
  onCurrencyAmountChange: PropTypes.func.isRequired,
  onSellCurrencyChange: PropTypes.func.isRequired,
  onSourceOfFundsChange: PropTypes.func,
  oneToOneDealAllowed: PropTypes.func.isRequired,
  paymentMode: PropTypes.string,
  riskAssesmentInfo: PropTypes.object,
  setRiskAssesmentInformation: PropTypes.func,
  setSettlementPrefs: PropTypes.func,
  sourcesOfFunds: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  toggleFetching: PropTypes.func.isRequired,
  tradingCurrencies: PropTypes.exact({
    buy: PropTypes.arrayOf(PropTypes.object),
    sell: PropTypes.arrayOf(
      PropTypes.shape({
        country_code: PropTypes.string,
        currency_code: PropTypes.string,
        currency_name: PropTypes.string,
      }),
    ),
  }),
  transaction: PropTypes.shape({
    base_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    base_currency_code: PropTypes.string,
    counter_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter_currency_code: PropTypes.string,
    counter_currency_country_code: PropTypes.string,
    receipt_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    source_of_funds: PropTypes.number,
    transaction_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  transactionAmountCalculationBasedOn: PropTypes.string,
  userId: PropTypes.number,
}

export default connect(mapStateToProps, { setRiskAssesmentInformation })(FundingSourceClient)
