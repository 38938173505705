/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
import { addressInfo } from 'api/dropdowns'
import { toast } from 'react-toastify'

export const fetchInitialProvince = async (setDropdowns, setIsLoading) => {
  const response = await addressInfo('province', { country_code: 'za' }).catch(error =>
    toast.error(error.response?.data.description),
  )
  if (response) {
    setDropdowns(prevState => ({
      ...prevState,
      address: { ...prevState.address, province: response },
      postal_address: { ...prevState.postal_address, province: response },
    }))
    setIsLoading(state => ({
      ...state,
      address: { ...state.address, province: false },
      postal_address: { ...state.postal_address, province: false },
    }))
  }
}

export const fetchInitialCascade = async (
  addressAttributes,
  setDropdowns,
  setIsLoading,
  initialValues,
  dropdownSection,
) => {
  const params = fieldName => {
    switch (fieldName) {
      case 'province':
        return { country_code: 'za' }
      case 'city':
        return { country_code: 'za', province: initialValues.province }
      case 'suburb':
        return {
          city: initialValues.city,
          country_code: 'za',
          province: initialValues.province,
        }
      case 'zip':
        return {
          city: initialValues.city,
          country_code: 'za',
          province: initialValues.province,
          suburb: initialValues.suburb,
        }
      default:
        return null
    }
  }

  await Promise.all(
    Object.keys(addressAttributes).map(async attribute => {
      try {
        setIsLoading(state => ((state[dropdownSection][attribute] = true), state))

        const response = await addressInfo(attribute, params(attribute))

        if (response) {
          setDropdowns(state => ({
            ...state,
            [dropdownSection]: Object.assign({}, state[dropdownSection], { [attribute]: response }),
          }))
          setIsLoading(state => ((state[dropdownSection][attribute] = false), state))
        }
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }),
  )
}
