import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import HistoryTransfers from 'components/pages/retail/history_transfers'
import IncomingTransfers from 'components/pages/retail/incoming_transfers'

import Route from 'routes/shared/auth_route'
import { INCOMING_PAYMENTS, HISTORY_PAYMENTS } from 'routes/paths/private/payments'

const Routing = ({ match }) => (
  <React.Fragment>
    <Route isPrivate path={`${match.path}/${HISTORY_PAYMENTS}`} component={HistoryTransfers} />
    <Route isPrivate path={`${match.path}/${INCOMING_PAYMENTS}`} component={IncomingTransfers} />
  </React.Fragment>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
