import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** Actions */
import { setIncomingTransfers } from 'actions/trading'
/** Helpers */
import PaginationTable from 'components/helpers/pagination_table'

/** Incoming Transfers component */
export const IncomingTransfers = ({ data, getData }) => {
  const renderItem = ({
    customer_name: customerName,
    id,
    reference_number: referenceNumber,
    quote,
    value_date: valueDate,
  }) => (
    <tr key={id} columns={1} data-testid="incoming-payments-item-row">
      <td>{valueDate}</td>
      <td>
        <a>{referenceNumber}</a>
      </td>
      <td>{customerName}</td>
      <td />
      <td>
        {quote.base_currency_code} {quote.base_currency_amount}
      </td>
    </tr>
  )

  const defaultParams = {
    'q[home_currency_trading_ability_eq]': 'buy',
    'q[qustomer_bank_account_debited_eq]': true,
    'q[s]': 'confirmed_at DESC',
  }

  const columns = [
    { header: 'Transfer date', search_type: 'eq', sort: true, sort_field: 'value_date', type: 'date' },
    { header: 'Reference', search_type: 'eq', sort: true, sort_field: 'reference_number', type: 'text' },
    { header: 'Received from' },
    { header: 'Paid to' },
    { header: 'Amount received', search_type: 'eq', sort: true, sort_field: 'counter_currency_amount', type: 'text' },
  ]

  return (
    <PaginationTable
      columns={columns}
      data={data}
      defaultParams={defaultParams}
      getData={getData}
      perPage={5}
      renderItem={renderItem}
      testID="incoming-payments"
      title="Incoming Payments"
    />
  )
}

IncomingTransfers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  getData: PropTypes.func,
}

const mapStateToProps = ({ trading }) => ({
  data: trading.data.incomingTransfers,
})

const mapDispatchToProps = dispatch => ({ ...bindActionCreators({ getData: setIncomingTransfers }, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(IncomingTransfers)
