import fetchApi from 'components/utils/fetch_api'

const index = currencyCode => {
  const params = {
    q: {
      balance_gteq: 0.1,
      currency_code_eq: currencyCode,
      direct_debit_enabled_eq: true,
      status_eq: 'active',
      use_as_funding_source_access_enabled_eq: true,
    },
  }
  return fetchApi('bank_accounts/cw/cbas', { params })
}

export { index }
