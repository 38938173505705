import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch } from 'react-router'
import Route from 'routes/shared/auth_route'
import NotFound from 'routes/shared/not_found'
import Recipients from 'components/pages/retail/recipients'
import { NEW_BENEFICIARY } from 'routes/paths/private/recipients'
import recipientForm from 'components/pages/shared/modals/beneficiary_form'

const Routing = ({ match }) => (
  <Switch>
    <Route exact isPrivate path={`${match.path}/${NEW_BENEFICIARY}`} component={recipientForm} />
    <Route exact isPrivate path={`${match.path}`} component={Recipients} />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
