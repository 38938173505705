import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { search as searchBankAccountSetting } from 'api/recipients/cw/bank_account_settings'

const BeneficiarySettings = ({ beneficiary }) => {
  const [beneficiarySettings, setBeneficiarySettings] = useState({})
  const { buy: buyCurrencies } = useSelector(({ trading }) => trading?.data?.currencies) || []

  const params = useMemo(
    () => ({
      countryCode: beneficiary?.account?.address?.country_code,
      currencyCode: beneficiary?.currency_code,
      paymentMode: beneficiary?.account?.payment_mode,
    }),
    [beneficiary],
  )

  const beneficiaryBankAccountDetails = beneficiary?.account || {}

  useEffect(() => {
    if (beneficiary?.id && beneficiary?.renumeration_type_name === 'SWIFT') {
      searchBankAccountSetting(params)
        .then(response => setBeneficiarySettings(response?.data))
        .catch(error => toast.error(error?.response?.data?.description))
    }

    if (beneficiary?.renumeration_type_name === 'Local') {
      setBeneficiarySettings(beneficiary)
    }
  }, [beneficiary?.id])

  if (isEmpty(beneficiarySettings)) {
    return null
  }

  return (
    <React.Fragment>
      {beneficiaryBankAccountDetails?.account_number && (
        <div className="my-2">
          <label className="font-13">{beneficiarySettings?.account_number?.label || 'Account Number'}:&nbsp;</label>
          <span className="font-bold" data-testid="beneficiary-account-number">
            {beneficiaryBankAccountDetails?.account_number}
          </span>
        </div>
      )}
      {beneficiaryBankAccountDetails?.bic && (
        <div className="my-2">
          <label className="font-13">{beneficiarySettings?.bic?.label || 'Swift Code'}:&nbsp;</label>
          <span className="font-bold" data-testid="beneficiary-bic">
            {beneficiaryBankAccountDetails?.bic}
          </span>
        </div>
      )}
      {beneficiary?.currency_code && (
        <div className="my-2">
          <label className="font-13">Currency:&nbsp;</label>
          <span className="font-bold">
            {buyCurrencies.find(currency => currency?.currency_code === beneficiary?.currency_code).currency_name}
          </span>
          <span className="font-bold">&nbsp;({beneficiary?.currency_code})</span>
        </div>
      )}
    </React.Fragment>
  )
}

BeneficiarySettings.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      account_number: PropTypes.string,
      address: PropTypes.shape({
        country_code: PropTypes.string,
      }),
      bic: PropTypes.string,
      bic_label: PropTypes.string,
      blc: PropTypes.string,
      blc_label: PropTypes.string,
      payment_mode: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renumeration_type_name: PropTypes.string,
  }),
}

export default BeneficiarySettings
