import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
/* Helpers */
import { TextField, SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'

const BeneficiaryNameEmail = forwardRef(
  (
    {
      errors,
      beneficiary,
      recipientEmail,
      recipientNames,
      onBeneficiaryAddressChange,
      countriesList,
      onCountryChange,
      currentCurrenciesList,
      onCurrencyChange,
      onRenumerationTypeNameChange,
      paymentMethods,
      isLoading,
      ...props
    },
    beneficiaryNameEmailFormRef,
  ) => {
    const requiredObjects = { ...recipientEmail, ...recipientNames }
    const requiredFields = Object.keys(requiredObjects).reduce(
      (obj, field) => ({
        ...obj,
        [`is${field.charAt(0).toUpperCase() + field.slice(1)}Required`]: requiredObjects?.[field].required,
      }),
      {},
    )

    const initialValues = {
      address: {
        country_code: '',
        ...requiredFields,
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      },
      currency_code: '',
      renumeration_type_name: '',
    }

    const handleBeneficiaryAddressChange = (e, { setFieldValue }) => {
      const { name, value } = e.target
      onBeneficiaryAddressChange(name.split('.')[1], value)
      setFieldValue(name, value)
    }

    return (
      <React.Fragment>
        <div className="form-group-1">
          <Formik
            enableReinitialize
            innerRef={beneficiaryNameEmailFormRef}
            initialValues={initialValues}
            initialErrors={initialValues}
            validationSchema={Yup.object().shape({
              address: Yup.object().shape({
                country_code: Yup.string().required("Can't be blank").nullable(),
                email: Yup.string().when('isEmailRequired', {
                  is: true,
                  then: Yup.string().required("Can't be blank").nullable(),
                }),
                first_name: Yup.string()
                  .min(1, 'Must be 1 letter or more')
                  .max(35, 'Must be 35 letters or less')
                  .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed')
                  .when('isFirst_nameRequired', {
                    is: true,
                    then: Yup.string().required("Can't be blank").nullable(),
                  }),
                isEmailRequired: Yup.boolean(),
                isFirst_nameRequired: Yup.boolean(),
                isLast_nameRequired: Yup.boolean(),
                last_name: Yup.string()
                  .min(1, 'Must be 1 letter or more')
                  .max(35, 'Must be 35 letters or less')
                  .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed')
                  .when('isLast_nameRequired', {
                    is: true,
                    then: Yup.string().required("Can't be blank").nullable(),
                  }),
              }),
              currency_code: Yup.string().required("Can't be blank").nullable(),
              renumeration_type_name: Yup.string().required("Can't be blank").nullable(),
            })}
            onSubmit={(values, actions) => {}}>
            {formik => (
              <Form>
                {Object.keys(recipientNames).map(
                  inputName =>
                    recipientNames?.[inputName]?.enabled && (
                      <div key={`address.${inputName}`} className="form-group mb-0">
                        <TextField
                          onChange={e => handleBeneficiaryAddressChange(e, formik)}
                          value={beneficiary?.address?.[inputName]}
                          label={recipientNames?.[inputName]?.name}
                          name={`address.${inputName}`}
                          placeholder={recipientNames[inputName]?.placeholder}
                          required={recipientNames[inputName]?.required}
                        />
                        {renderErrors(errors[`address.${inputName}`])}
                      </div>
                    ),
                )}
                {/* field is disabled until API fix */}
                {Object.keys(recipientEmail).map(
                  inputName =>
                    !recipientEmail?.[inputName]?.enabled && (
                      <div key={`address.${inputName}`} className="form-group mb-0">
                        <TextField
                          onChange={e => handleBeneficiaryAddressChange(e, formik)}
                          value={beneficiary?.address?.[inputName]}
                          label={recipientEmail?.[inputName]?.name}
                          name={`address.${inputName}`}
                          placeholder={recipientEmail[inputName]?.placeholder}
                          required={recipientEmail[inputName]?.required}
                        />
                        {renderErrors(errors[`address.${inputName}`])}
                      </div>
                    ),
                )}
                <SelectField
                  testID="bene-step1-country"
                  theme="superSwift"
                  name="address.country_code"
                  label="Country"
                  required
                  onChange={countryOption => {
                    formik.setFieldValue('address.country_code', countryOption.countryCode)
                    onCountryChange(countryOption.countryCode, formik.setFieldValue)
                  }}
                  options={countriesList}
                  isLoading={isEmpty(countriesList)}
                  value={countriesList?.find(country => beneficiary?.address?.country_code === country.countryCode)}
                  placeholder="Select"
                />
                <SelectField
                  name="currency_code"
                  testID="bene-step1-currency"
                  theme="superSwift"
                  label="Currency"
                  required
                  value={currentCurrenciesList.find(ccy => beneficiary.currency_code === ccy.value)}
                  onChange={currencyOption => {
                    formik.setFieldValue('currency_code', currencyOption.value)
                    onCurrencyChange(currencyOption.value)
                  }}
                  options={currentCurrenciesList}
                  isLoading={beneficiary?.address?.country_code && isEmpty(currentCurrenciesList)}
                  placeholder="Select"
                />
                <SelectField
                  name="renumeration_type_name"
                  testID="bene-step1-payment-method"
                  theme="superSwift"
                  label="Payment method"
                  required
                  onChange={option => {
                    formik.setFieldValue('renumeration_type_name', option?.value.renumeration_type)
                    onRenumerationTypeNameChange(option?.value)
                  }}
                  value={paymentMethods.find(
                    paymt => paymt?.value?.renumeration_type === beneficiary?.renumeration_type_name,
                  )}
                  options={paymentMethods}
                  placeholder="Select"
                  isLoading={isLoading.renumerationPreference}
                />
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    )
  },
)

BeneficiaryNameEmail.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      address: PropTypes.shape({
        country_code: PropTypes.string,
      }),
    }),
    address: PropTypes.shape({
      country_code: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    renumeration_type_name: PropTypes.string,
    short_name: PropTypes.string,
  }),
  countriesList: PropTypes.arrayOf(PropTypes.object),
  currentCurrenciesList: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.shape({
    'address.email': PropTypes.any,
    'address.first_name': PropTypes.any,
    'address.full_name': PropTypes.any,
    'address.last_name': PropTypes.any,
    short_name: PropTypes.any,
  }),
  isLoading: PropTypes.shape({
    renumerationPreference: PropTypes.bool,
  }),
  onBeneficiaryAddressChange: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
  onRenumerationTypeNameChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  recipientEmail: PropTypes.shape({
    email: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
  recipientNames: PropTypes.shape({
    first_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    last_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
}

export default BeneficiaryNameEmail
