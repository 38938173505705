import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import Select from 'react-select'
import { Skeleton } from 'components/helpers/skeleton'
import ValueDatePreferences from 'components/pages/shared/transaction_settlement_prefs/value_date'
import renderErrors from 'components/helpers/render_errors'
import FlagImage from 'components/helpers/flag_image'
import CustomerBankAccountSettings from 'components/pages/retail/transactions/new/new_payment/shared/customer_bank_account_settings'

const FundingSourceBank = props => {
  const { customerBankAccountList } = props
  const onCustomerBankAccountChange = cbaId => {
    const { setCustomerBankAccount } = props
    setCustomerBankAccount(
      customerBankAccountList.find(account => {
        return String(account.id) === String(cbaId)
      }),
    )
  }

  const disableCustomerBankAccountOptions = cbaId => {
    const { beneficiary, transaction, oneToOneDealAllowed } = props
    if (oneToOneDealAllowed()) {
      if (beneficiary.linked_to_cba) {
        return cbaId === String(beneficiary.linked_cba_id)
      }

      return false
    }
    return cbaId === transaction.counter_currency_code
  }

  const cbaOptions = useMemo(() => {
    if (!customerBankAccountList) {
      return []
    }
    return customerBankAccountList.map(cba => {
      let label = cba.account_name
      if (cba.use_as_beneficiary && cba.currency_code && cba.bank_account_detail.account_number) {
        label = `${cba.currency_code} - ${cba.bank_account_detail.account_number}`
      }
      return {
        isDisabled: disableCustomerBankAccountOptions(cba.id),
        label: (
          <div>
            <FlagImage countryCode={cba.country_code} />
            {label}
          </div>
        ),
        value: cba.id,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerBankAccountList])

  const { customerBankAccount, errors, transaction, fetching } = props

  if (fetching?.CBAList || !customerBankAccount || !transaction) {
    return <Skeleton />
  }

  return (
    <React.Fragment>
      <div>
        <label>Fund payment from</label>
        <Select
          placeholder="Select"
          name="customer_bank_account_id"
          value={cbaOptions.find(option => option.value === customerBankAccount.id)}
          onChange={cbaOption => onCustomerBankAccountChange(cbaOption.value)}
          options={cbaOptions}
        />
        {renderErrors(errors?.customer_id)}
        {renderErrors(errors?.['direct_debit.amount'])}
      </div>
      <CustomerBankAccountSettings customerBankAccount={customerBankAccount} />
      {customerBankAccount.balance > 0 && (
        <div className="form-group">
          <label>Available funds:</label>
          <span className="font-bold">{formatCurrencyAmount(customerBankAccount.balance)}</span>
        </div>
      )}
      {/* TODO: This is not specified when it will be used */}
      <ValueDatePreferences transactionId={transaction.transaction_id} />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { settings } = state.trading.data
  return {
    tradingSettings: settings,
  }
}

FundingSourceBank.propTypes = {
  beneficiary: PropTypes.shape({
    linked_cba_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    linked_to_cba: PropTypes.bool,
  }),
  customerBankAccount: PropTypes.shape({
    balance: PropTypes.number,
    id: PropTypes.number,
  }),
  customerBankAccountList: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  fetching: PropTypes.object,
  oneToOneDealAllowed: PropTypes.func.isRequired,
  setCustomerBankAccount: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    counter_currency_code: PropTypes.string,
    transaction_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

export default connect(mapStateToProps, null)(FundingSourceBank)
