import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { KIWIBANK } from 'config/banks'
import { SIGN_OUT_FULL_PATH } from 'routes/paths/public/sso'
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'

/** Component to render error page only for Kiwibank */
const SsoError = props => {
  if (env.tenant.uid !== KIWIBANK) {
    props.history.push(SIGN_IN_FULL_PATH)
    return null
  }

  return (
    <div className="row space-40">
      <div className="text-center col-md-8 col-md-offset-2 col-sm-6 col-xs-12">
        <div className="row space-40">International Services is currently unavailable. Please try again later.</div>

        <div className="row space-40">
          <Link className="mr-1" to={SIGN_OUT_FULL_PATH}>
            Click here
          </Link>{' '}
          to return to your NZ Dollar accounts.
        </div>
      </div>
    </div>
  )
}

SsoError.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
}

export default withRouter(SsoError)
