export const NEW_PASSWORDS_DOES_NOT_MATCH_CONFIRM_PASSWORD = 'New password and confirm new password do not match'
export const FILL_OUT_ALL_FIELDS_TO_CHANGE_PASSWORD = 'Fill out all fields in order to change password'
export const UNEXPECTED_ERROR = 'An unexpected error has ocurred'
export const NO_CHANGES_MADE_ERROR = 'No changes made'
export const FILL_OUT_ALL_FIELDS = 'You have to fill out all fields'
export const UPDATE_CONTACT_INFORMATION_SUCCESSFUL = 'Successfully updated contact information'
export const INVALID_PASSWORD = 'Invalid Password'

export default {
  FILL_OUT_ALL_FIELDS,
  FILL_OUT_ALL_FIELDS_TO_CHANGE_PASSWORD,
  NEW_PASSWORDS_DOES_NOT_MATCH_CONFIRM_PASSWORD,
  NO_CHANGES_MADE_ERROR,
  UNEXPECTED_ERROR,
  UPDATE_CONTACT_INFORMATION_SUCCESSFUL,
}
