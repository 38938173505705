import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CurrencyInput from 'react-currency-input-field'
import noDecimalPlacesCurrencyCode from './config/no_decimal_places'
import threeDecimalPlacesCurrencyCode from './config/three_decimal_places'
import './styles.scss'

const CurrencyInputField = props => {
  const { locale, value, currencyClassName, currencyFlagAndName, label, onChange, ...restProps } = props
  const [precision, setPrecision] = useState(2)

  useEffect(() => {
    let precision = 2
    if (noDecimalPlacesCurrencyCode.includes(locale)) {
      precision = 0
    } else if (threeDecimalPlacesCurrencyCode.includes(locale)) {
      precision = 3
    }

    setPrecision(precision)
  }, [locale])

  return (
    <div className="form-group mb-0">
      {label && <label className="d-flex control-label">{label}</label>}
      <div className="input-group">
        <CurrencyInput
          {...restProps}
          value={value}
          onValueChange={onChange}
          allowNegativeValue={false}
          decimalScale={precision}
        />
        <div className={`input-group-append form-field currency-calculator ${currencyClassName || ''}`}>
          {currencyFlagAndName}
        </div>
      </div>
    </div>
  )
}

CurrencyInputField.propTypes = {
  currencyClassName: PropTypes.string,
  currencyFlagAndName: PropTypes.object,
  label: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CurrencyInputField
