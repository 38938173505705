import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { RECIPENTS_FULL_PATH } from 'routes/paths/private/recipients'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'
import { HISTORY_PAYMENTS, HISTORY_PAYMENTS_FULL_PATH } from 'routes/paths/private/payments'
import { RECIPIENTS_PATH } from 'routes/paths/root'
import Dropdown from 'assets/svg/dropdown.svg'
import Avatar from 'assets/svg/user_avatar.svg'

export const Menu = ({ itemClass, show, setSubNavbarOpen }) => {
  const user = useSelector(({ user }) => user.data.userDetails)

  return (
    <React.Fragment>
      <li className={`nav-2-link w-nav-link ${useLocation().pathname.includes(RECIPIENTS_PATH) ? 'w--current' : ''}`}>
        <NavLink to={`${RECIPENTS_FULL_PATH}`}>Beneficiaries</NavLink>
      </li>
      <li className={`nav-2-link w-nav-link ${useLocation().pathname.includes(HISTORY_PAYMENTS) ? 'w--current' : ''}`}>
        <NavLink to={`${HISTORY_PAYMENTS_FULL_PATH}`}>Transactions</NavLink>
      </li>
      <li className={`nav-2-link ${itemClass ? 'mobile' : ''} w-nav-link`}>
        <NavLink to="/retail/users/profile-information">My profile</NavLink>
      </li>
      <li className={`nav-2-link ${itemClass ? 'mobile' : ''} w-nav-link`}>
        <NavLink to="/retail/users/profile-information">Log out</NavLink>
      </li>
      <li className="cta-button nav-2 w-inline-block align-self-start">
        {/* adding '-' to route to make route change to take effect when already on route  */}
        <NavLink style={{ color: '#fff' }} to={TRANSACTION_NEW_FULL_PATH + '-'}>
          Send Money
        </NavLink>
      </li>

      <div className="dropdown-trigger" onClick={() => setSubNavbarOpen(!show)}>
        <img alt={Avatar} className="icon-left" src={Avatar} width="30" height="30" />
        <span className="user-name">
          {user?.address?.first_name} {user?.address?.last_name}
        </span>
        <img alt={Dropdown} className={`icon ${show ? 'transform-svg' : ''}`} src={Dropdown} />
      </div>
    </React.Fragment>
  )
}

Menu.propTypes = {
  itemClass: PropTypes.string,
  setSubNavbarOpen: PropTypes.func,
  show: PropTypes.bool,
}
