import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
/* API */
import { getLocalProviders } from 'api/dropdowns'
/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'
import Spinner from 'components/helpers/spinner'

function LocalPayoutProvider({ localPayoutProvider, onLocalPayoutProviderChange, errors, beneficiary }) {
  const [localPayoutProviders, setLocalPayoutProviders] = useState([])
  const { currentBeneficiary } = useSelector(({ trading }) => trading.data) || {}
  const { name: localPayoutProviderName } =
    useSelector(({ trading }) => trading?.data?.currentBeneficiary?.local_payout_provider) || {}

  useEffect(() => {
    async function fetchLocalPayoutProviders() {
      try {
        await getLocalProviders({
          per_page: 100,
          q: { country_code_eq: beneficiary?.address?.country_code, currency_code_eq: beneficiary?.currency_code },
        }).then(res => {
          setLocalPayoutProviders(res?.data.map(item => ({ label: item.name, value: item.name })))
        })

        !isEmpty(currentBeneficiary) &&
          onLocalPayoutProviderChange('local_payout_provider', currentBeneficiary?.local_payout_provider?.name)
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }
    beneficiary?.address?.country_code && beneficiary?.currency_code && fetchLocalPayoutProviders()
  }, [beneficiary?.address?.country_code, beneficiary?.currency_code, beneficiary?.local_payout_provider?.name])

  useEffect(
    () => localPayoutProviderName && onLocalPayoutProviderChange('local_payout_provider', localPayoutProviderName),
    [localPayoutProviders?.length, localPayoutProviderName],
  )
  if (localPayoutProvider?.local_payout_provider?.enabled) {
    return (
      <div className="px-0">
        {localPayoutProviders?.length ? (
          Object.keys(localPayoutProvider).map(input => (
            <div key={input} className="form-group row">
              <div className="col-12">
                <Formik>
                  <div>
                    <SelectField
                      label={localPayoutProvider[input]?.name}
                      name="local_provider"
                      theme="superSwift"
                      /* To change once the API is confirmed */
                      options={localPayoutProviders}
                      isLoading={!localPayoutProviders?.length}
                      onChange={option => onLocalPayoutProviderChange('local_payout_provider', option.value)}
                      /* To change once the API is confirmed */
                      value={localPayoutProviders?.find(
                        option =>
                          option.value === beneficiary?.local_provider?.local_payout_provider ||
                          option.value === beneficiary?.local_payout_provider?.name,
                      )}
                      required
                    />
                    {renderErrors(errors['provider.name'])}
                  </div>
                </Formik>
              </div>
            </div>
          ))
        ) : (
          <Spinner text={false} />
        )}
      </div>
    )
  } else {
    return null
  }
}

LocalPayoutProvider.propTypes = {
  beneficiary: PropTypes.shape({
    address: PropTypes.shape({
      country_code: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    local_payout_provider: PropTypes.shape({
      name: PropTypes.string,
    }),
    local_provider: PropTypes.shape({
      local_payout_provider: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    'local_provider.local_payout_provider': PropTypes.any,
    'provider.name': PropTypes.any,
  }),
  localPayoutProvider: PropTypes.shape({
    locale: PropTypes.string,
    name: PropTypes.string,
  }),
  localPayoutProviders: PropTypes.arrayOf(PropTypes.object),
  onLocalPayoutProviderChange: PropTypes.func,
}

export default LocalPayoutProvider
