/* eslint-disable indent */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ClearIndicator from './CustomClearIndicator'
import CustomDropdownIndicator from './CustomDropdownIndicator'
import CustomValueContainer from './CustomValueContainer'
import CustomMenu from './CustomMenu'
import { HISTORY_PAYMENTS } from 'routes/paths/private/payments'
import { RECIPENTS_FULL_PATH } from 'routes/paths/private/recipients'

export const BACKSPACE_KEY = 8
export const DOWN_KEY = 40
export const ENTER_KEY = 8
export const SPACE_KEY = 32
export const UP_KEY = 38

/**
 * Component for Select - dropdown
 */
export const SelectCustom = ({
  className,
  classNameContainer,
  clearable,
  defaultValue,
  disabled,
  error,
  helpBlock,
  id,
  isRtl,
  label,
  layout,
  multiple,
  name,
  options,
  onChange,
  required,
  searchable,
  testID,
  value,
  theme,
  useDefaultMenu = false,
  useDefaultValueContainer = false,
  isLoading,
  placeholder,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const activePath = [`${HISTORY_PAYMENTS}`, `${RECIPENTS_FULL_PATH}`].some(path => location.pathname.includes(path))

  /**
   * Toggle visibility of dropdown menu
   */
  const toggleMenu = () => {
    setMenuIsOpen(prevState => !prevState)
  }

  /**
   * Disables press on backspace, space and enter
   * as react-select has its own tempering with backspace click, we disable it in the root of select component
   */
  const handleKeyDown = event => {
    const forbidden = [BACKSPACE_KEY, ENTER_KEY, SPACE_KEY]
    if (forbidden.includes(event.which)) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  /**
   * Renders select component
   */
  const renderSelect = () => (
    <Select
      id={id}
      onKeyDown={handleKeyDown}
      className={`custom-form-select ${theme || ''}${error?.error ? ' well-danger' : ''} ${
        activePath ? 'w-select' : ''
      }`}
      classNamePrefix="select"
      defaultValue={defaultValue}
      isDisabled={disabled}
      isClearable={clearable}
      isMulti={multiple}
      closeMenuOnSelect={!multiple}
      isRtl={isRtl}
      isSearchable={searchable}
      menuIsOpen={useDefaultValueContainer ? undefined : menuIsOpen}
      onChange={onChange}
      name={name}
      testID={testID}
      toggleMenu={toggleMenu}
      options={options}
      value={value}
      setValue={null}
      isLoading={isLoading || undefined}
      placeholder={placeholder}
      components={Object.assign(
        {
          ClearIndicator,
          DropdownIndicator: props => (
            <CustomDropdownIndicator {...props} testID={testID} menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
          ),
        },
        useDefaultMenu ? {} : { Menu: CustomMenu },
        useDefaultValueContainer
          ? {}
          : {
              ValueContainer: props => (
                <CustomValueContainer {...props} testID={testID} menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
              ),
            },
      )}
    />
  )

  /**
   * Renders select with label
   */
  if (layout === 'horizontal') {
    const selectClassName = label ? 'col-sm-7' : 'col-sm-12'
    return (
      <div className={`d-flex form-group ${className || ''} ${activePath ? '' : 'align-items-center'}`}>
        {label && (
          <div className="col-sm-5">
            <label className={`control-label${required ? ' required' : ''}`}>{label}</label>
          </div>
        )}
        <div className={classNameContainer || `${activePath ? 'w-100' : `${selectClassName}`}`}>
          {renderSelect()}
          {helpBlock && <div className="help-block">{helpBlock}</div>}
        </div>
      </div>
    )
  }
  return (
    <div className={`form-group ${className || ''}`}>
      {label && (
        <label
          className={`control-label${required ? ' required' : ''} ${activePath ? 'field-label' : ''}`}
          htmlFor={name}>
          {label}
        </label>
      )}
      <div>
        <div className="form-control-container">{renderSelect()}</div>
        {helpBlock && <div className="help-block">{helpBlock}</div>}
      </div>
    </div>
  )
}
SelectCustom.propTypes = {
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  helpBlock: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  label: PropTypes.string,
  layout: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOf(PropTypes.string, PropTypes.number),
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  testID: PropTypes.string,
  theme: PropTypes.string,
  useDefaultMenu: PropTypes.bool,
  useDefaultValueContainer: PropTypes.bool,
  value: PropTypes.any,
}

SelectCustom.defaultProps = {
  className: '',
  classNameContainer: '',
  clearable: false,
  defaultValue: '',
  disabled: false,
  isRtl: false,
  layout: 'vertical',
  multiple: false,
  options: [],
  placeholder: 'Select',
  required: false,
  searchable: false,
  testID: '',
  type: 'text',
}

export default SelectCustom
