import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch } from 'react-router'
import Registration from 'components/pages/users/register'
import NotFound from 'routes/shared/not_found'
import Route from 'routes/shared/auth_route'

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/:step`} component={Registration} />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
