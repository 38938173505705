import env from '.env'
import React from 'react'
import Spinner from 'components/helpers/spinner'

const SsoSessionTimeout = () => {
  const title = env.springBack.message.title
  const content = env.springBack.message.content
  return (
    <div className="modal-body space-20 text-center">
      <Spinner>
        <div>
          <h4>Session Timeout</h4>
          <h4>{title}</h4>
          <h5 className="space-20">
            <p>
              <span>{content}</span>
            </p>
          </h5>
        </div>
      </Spinner>
    </div>
  )
}

export default SsoSessionTimeout
