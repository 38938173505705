import { ClientActions } from 'actions'
import { combineReducers } from 'redux'
import { clientApplicationLocalStorage } from 'config/local_storage'

const INITIAL_DATA_STATE = {
  dropdownCountries: [],
  dropdownSOF: [],
  isReviewRequired: false,
  payOutLocationOptions: [],
}

function data(state = INITIAL_DATA_STATE, action) {
  switch (action.type) {
    case ClientActions.SET_CURRENT_CLIENT_DATA:
      clientApplicationLocalStorage.set('data', action.payload)
      return Object.assign({}, state, {
        ...action.payload,
      })
    case ClientActions.SET_DROPDOWN_COUNTRIES:
      return {
        ...state,
        dropdownCountries: action.payload,
      }
    case ClientActions.SET_DROPDOWN_SOF:
      return {
        ...state,
        dropdownSOF: action.payload,
      }
    case ClientActions.SET_FORCED_PROFILE_REVIEW:
      return Object.assign({}, state, {
        isReviewRequired: action.payload,
      })
    case ClientActions.SET_PAYOUT_LOCATION_OPTIONS:
      return Object.assign({}, state, {
        payOutLocationOptions: action.payload,
      })
    default:
      return state
  }
}

const ClientReducers = combineReducers({
  data,
})

export default ClientReducers
