export function googleTagManagerPush(data) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export function googleTagManagerEvent({ beneficiary, currentPaymentMode, transactionDetails, userDetails }) {
  const gender = userDetails?.personal_info?.gender == null ? null : userDetails?.personal_info?.gender ? 'm' : 'f' // 1/true = male, 0/false = female

  const birthdate =
    String(userDetails?.birth_date_year) +
    String(userDetails?.birth_date_month)?.padStart(2, '0') +
    String(userDetails?.birth_date_day)?.padStart(2, '0')

  const event = {
    event: 'TransferComplete',
    personal_info: {
      birthdate,
      city: (userDetails?.address?.city || '').toLocaleLowerCase(),
      email: userDetails?.email,
      // external_id: '', // TODO: Not easy to get the swift card number
      first_name: (userDetails?.address?.first_name || '').toLocaleLowerCase(),
      gender,
      last_name: (userDetails?.address?.last_name || '').toLocaleLowerCase(),
      mobile: userDetails?.address?.mobile_isd_code + userDetails?.address?.mobile,
    },
    source_of_funds: transactionDetails?.funding_source?.label || transactionDetails?.quote?.funding_source?.label,
    transfer: {
      collection_method: beneficiary?.renumeration_type_name,
      destination_currency: transactionDetails?.counter_currency_code,
      fees: transactionDetails?.fee,
      payment_method: currentPaymentMode,
      source_currency: transactionDetails?.base_currency_code,
      transaction_value: transactionDetails?.base_currency_amount,
    },
  }

  googleTagManagerPush(event)
}
