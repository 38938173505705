import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import WithCurrentUser from 'components/HOC/with_current_user'
import AuthorizationRedirectionPath from 'components/utils/authorization_redirection_path'
import switchAccount from 'components/utils/switch_account'

/**
 * Renders Modal to show switch account form
 */
const SwitchAccountModal = ({ show, currentUser, closeModal }) => {
  const history = useHistory()

  const handleAccountSwitch = async event => {
    await switchAccount(event.target.value)
    history.push(AuthorizationRedirectionPath())
  }

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header className="px-5 pt-5 border-0">
        <Modal.Title as={'h3'} data-testid="modal-title">
          Switch Account
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-5 pb-5">
        <p>
          Your login is enabled to manage multiple accounts. You are currently logged in as{' '}
          {currentUser.address.first_name} {currentUser.address.last_name}, Please select the account below to switch
          the account you are currently representing.
        </p>
        <select
          data-testid="select"
          className="form-control"
          name="selectValueDate"
          onChange={handleAccountSwitch}
          defaultValue={currentUser.customer_id}>
          {currentUser.linkedAccounts.map((account, index) => (
            <option key={account.account_number} value={account.account_number} data-testid={`option-${index}`}>
              {account.account_name}
            </option>
          ))}
        </select>
      </Modal.Body>
    </Modal>
  )
}

SwitchAccountModal.propTypes = {
  /** function that should close the modal */
  closeModal: PropTypes.func.isRequired,
  /** Current authenticated user data {@link withCurrentUser} */
  currentUser: PropTypes.shape({
    /** Name object of the user */
    address: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    /** Account's ID associated to the user */
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Account associated to the user */
    linkedAccounts: PropTypes.array,
  }),
  /** Attribute to make modal visible */
  show: PropTypes.bool.isRequired,
}

export default WithCurrentUser(SwitchAccountModal)
