import fetchApi from 'components/utils/fetch_api'

const index = currencyCode => {
  return fetchApi('recipients/cw/settings', {
    params: {
      currency_code: currencyCode,
    },
  })
}

export { index }
