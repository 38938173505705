import { SSO_PATH } from '../root'

const SIGN_IN_PATH = 'sign-in'
const SIGN_IN_FULL_PATH = `${SSO_PATH}/${SIGN_IN_PATH}`

const SIGN_OUT_PATH = 'sign-out'
const SIGN_OUT_FULL_PATH = `${SSO_PATH}/${SIGN_OUT_PATH}`

const ERROR_PATH = 'error'
const ERROR_FULL_PATH = `${SSO_PATH}/${ERROR_PATH}`

const SESSION_TIMEOUT_PATH = 'session-timeout'
const SESSION_TIMEOUT_FULL_PATH = `${SSO_PATH}/${SESSION_TIMEOUT_PATH}`

export {
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  ERROR_PATH,
  SESSION_TIMEOUT_PATH,
  SIGN_IN_FULL_PATH,
  ERROR_FULL_PATH,
  SIGN_OUT_FULL_PATH,
  SESSION_TIMEOUT_FULL_PATH,
}
