export default {
  account: {
    account_number: '',
    address: {
      address_line_1: null,
      address_line_2: null,
      city: null,
      country_code: null,
      province: null,
      zip: null,
    },
    bank_name: null,
    bic: '',
    branch_name: null,
    name: null,
    payment_mode: 'Priority',
    reason_for_payment: null,
  },
  account_type_name: 'Individual',
  address: {
    address_line_1: null,
    address_line_2: null,
    city: null,
    country_code: null,
    country_name: null,
    email: null,
    first_name: '',
    last_name: '',
    middle_name: null,
    mobile: null,
    mobile_isd_code: null,
    name: null,
    phone: null,
    phone_isd_code: null,
    post_code: null,
    province: null,
    user_id: null,
    zip: null,
  },
  currency_code: null,
  id: null,
  initializer: true,
  intermediary_routing_bank_account: {
    _destroy: false,
    account_number: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      country_code: '',
      country_name: '',
      province: '',
      zip: '',
    },
    bank_name: '',
    bic: '',
    branch_name: '',
    payment_mode: 'Priority',
    reason_for_payment: '',
    standard_intermediary_routing_account: '',
  },
  is_default: false,
  ordering_customer: {
    account_type_name: 'Individual',
    address_line_1: null,
    address_line_2: null,
    beneficiary_id: null,
    birth_date_day: null,
    birth_date_month: null,
    birth_date_year: null,
    city: null,
    company_name: null,
    company_number: null,
    country_code: null,
    first_name: null,
    last_name: null,
    province: null,
  },
  renumeration_type_name: null,
  short_name: null,
  status: null,
}
