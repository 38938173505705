import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'
import { SSO_PATH, USERS_PATH, RETAIL_PATH, TRANSACTIONS_PATH, REGISTER_PATH, RECIPIENTS_PATH } from './paths/root'
import Transactions from 'routes/components/transactions'
import Users from 'routes/components/users'
import Registration from 'routes/components/registration'
import Sso from 'routes/components/sso'
import NotFound from 'routes/shared/not_found'
import AccountDetails from 'routes/components/account_details'
import Payments from 'routes/components/payments'
import Recipients from 'routes/components/recipients'

class AllRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path={REGISTER_PATH} component={Registration} />
        <Route path={USERS_PATH} component={Users} />
        <Route path={SSO_PATH} component={Sso} />
        <Route
          path={RETAIL_PATH}
          render={({ match }) => (
            <React.Fragment>
              <Route path={`${match.path}${TRANSACTIONS_PATH}`} component={Transactions} />
              <Route path={`${match.path}${USERS_PATH}`} component={AccountDetails} />
              <Route path={`${match.path}`} component={Payments} />
              <Route path={`${match.path}${RECIPIENTS_PATH}`} component={Recipients} />
              <Route exact path={match.path} render={() => <Redirect to={TRANSACTION_NEW_FULL_PATH} />} />
            </React.Fragment>
          )}
        />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

export default AllRoutes
