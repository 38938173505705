/* eslint-disable indent */
import { useState, useCallback, useEffect, useRef } from 'react'
import { addressInfo } from 'api/dropdowns'
import { toast } from 'react-toastify'

export const useFetchAddressOptions = addressAttributes => {
  const [dropdowns, setDropdowns] = useState({ address: {}, postal_address: {} })
  const [isLoading, setIsLoading] = useState({ address: {}, postal_address: {} })
  const [cache, setCache] = useState({})
  const addressAttributesRef = useRef(null)

  const setZipFieldValue = useCallback(
    dropdownSection => {
      if (addressAttributesRef.current && dropdowns?.[dropdownSection]?.zip?.length) {
        const { initialValues, setFieldValue } = addressAttributesRef.current
        const fieldName = initialValues.hasOwnProperty(`${dropdownSection}`)
          ? `${dropdownSection}.zip`
          : dropdownSection === 'address'
          ? 'zip'
          : 'address.postal_address.zip'
        setFieldValue(fieldName, dropdowns?.[dropdownSection]?.zip?.[0].value)
      }
    },
    [dropdowns?.address?.zip, dropdowns?.postal_address?.zip],
  )

  useEffect(() => setZipFieldValue('address'), [dropdowns?.address?.zip])

  useEffect(() => setZipFieldValue('postal_address'), [dropdowns?.postal_address?.zip])

  const fetchDropdownOptions = useCallback(
    (fieldName, fieldValue, formikValues, dropdownSection) => {
      setCache(prevState => {
        if (
          prevState.fieldValue === fieldValue &&
          prevState.dropdownSection === dropdownSection &&
          prevState.fieldName === fieldName
        ) {
          return { ...cache, dropdownSection, fieldValue }
        }
        const handleSetState = async () => {
          try {
            const indexOfAttribute = Object.keys(addressAttributes).indexOf(fieldName)
            const attribute = Object.keys(addressAttributes)[indexOfAttribute + 1]
            setIsLoading(state => ({ ...state, [dropdownSection]: { ...state[dropdownSection], [attribute]: true } }))

            const params = fieldName => {
              switch (fieldName) {
                case 'province':
                  return { country_code: 'za', province: fieldValue }
                case 'city':
                  return { city: fieldValue, country_code: 'za', province: formikValues.province }
                case 'suburb':
                  return {
                    city: formikValues.city,
                    country_code: 'za',
                    province: formikValues.province,
                    suburb: fieldValue,
                  }
                default:
                  return null
              }
            }

            const response = await addressInfo(attribute, params(fieldName))
            const resetObj = attribute === 'city' ? { suburb: [], zip: [] } : attribute === 'suburb' ? { zip: [] } : {}
            if (response) {
              setDropdowns(state => ({
                ...state,
                [dropdownSection]: { ...state[dropdownSection], [attribute]: response, ...resetObj },
              }))
              setIsLoading(state => ({
                ...state,
                [dropdownSection]: { ...state[dropdownSection], [attribute]: false },
              }))
            }
          } catch (error) {
            toast.error(error?.response?.data?.description)
          }
        }
        handleSetState()
        return { ...cache, dropdownSection, fieldValue }
      })
    },
    [addressAttributes, cache],
  )

  const newFormikStateObj = (fieldName, value) => {
    switch (fieldName) {
      case 'province':
        return { city: '', province: value, suburb: '', zip: '' }
      case 'city':
        return { city: value, suburb: '', zip: '' }
      case 'suburb':
        return { suburb: value, zip: '' }
      case 'zip':
        return { zip: value }
      default:
        return null
    }
  }

  return [
    dropdowns,
    setDropdowns,
    isLoading,
    setIsLoading,
    fetchDropdownOptions,
    addressAttributesRef,
    newFormikStateObj,
  ]
}
