/* eslint-disable eqeqeq */
import { isNumber, toNumber, isBoolean } from 'lodash'

const transform = str => {
  const parts = str.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  parts[1] = parts?.[1]?.padEnd(2, '0') || '00'
  return parts.join('.')
}

const numberWithCommas = value => {
  const numberValue = toNumber(value)
  if (!isNumber(numberValue) || isBoolean(value)) {
    return '0.00'
  }

  if (numberValue) {
    return transform(numberValue)
  }
  return '0.00'
}

export default numberWithCommas
