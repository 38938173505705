import { TradingActions, UserActions } from 'actions'

import { combineReducers } from 'redux'

const INITIAL_STATE = {
  currencies: {
    buy: [],
    sell: [],
  },
  currentBeneficiary: {},
  currentCounterCurrency: {},
  currentPaymentMode: {},
  currentReceiptAccounts: [],
  currentReceiptPaymentMethod: {},
  currentTradingLimit: {},
  incomingTransfers: [],
  outgoingTransfers: [],
  settings: {},
}

function data(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TradingActions.SET_SETTINGS:
      return Object.assign({}, state, {
        settings: action.payload,
      })
    case TradingActions.SET_CURRENCIES:
      return Object.assign({}, state, {
        currencies: action.payload,
      })
    case TradingActions.SET_OUTGOING_TRANSFERS:
      return Object.assign({}, state, {
        outgoingTransfers: action.payload,
      })
    case TradingActions.SET_INCOMING_TRANSFERS:
      return Object.assign({}, state, {
        incomingTransfers: action.payload,
      })
    case TradingActions.SET_CURRENT_TRADING_LIMIT:
      return Object.assign({}, state, {
        currentTradingLimit: action.payload,
      })
    case TradingActions.SET_CURRENT_RECEIPT_ACCOUNTS:
      return Object.assign({}, state, {
        currentReceiptAccounts: action.payload,
      })
    case TradingActions.SET_CURRENT_PAYMENT_MODE:
      return Object.assign({}, state, {
        currentPaymentMode: action.payload,
      })
    case TradingActions.SET_CURRENT_BENEFICIARY:
      return Object.assign({}, state, {
        currentBeneficiary: action.payload,
      })
    case TradingActions.SET_CURRENT_RECEIPT_PAYMENT_METHOD:
      return Object.assign({}, state, {
        currentReceiptPaymentMethod: action.payload,
      })
    case TradingActions.SET_CURRENT_COUNTER_CURRENCY:
      return Object.assign({}, state, {
        currentCounterCurrency: action.payload,
      })
    case UserActions.SIGN_OUT:
      return INITIAL_STATE
    default:
      return state
  }
}

const TradingReducers = combineReducers({
  data,
})

export default TradingReducers
