import { isEmpty, get, filter } from 'lodash'

/*
         Payload example:
          {
            permissions: {
              approve_cud_suspended_users: true
              manage_invoices: true
              create_ft_quote: false
              ....
            },
            interfacePermissions: {
              account:  {
                name: "Account",
                sub_interface: {
                  account_information: {
                    name: 'Account information',
                    enabled: true
                  }
                  ...
                }
              }
              ...
            }
          }
      */
class PermissionsGenerator {
  constructor(permissions) {
    this.authorizationPermissions = permissions.authorizationPermissions
    this.interfacePermissions = permissions.interfacePermissions
    this.tradingPermissions = permissions.tradingPermissions
    this.tradingForwardsPermissions = permissions.tradingForwardsPermissions
    this.transactionPermissions = permissions.transactionPermissions
    this.allPermissions = []
  }

  generateValidPermissions() {
    if (isEmpty(this.authorizationPermissions) || isEmpty(this.interfacePermissions)) {
      return []
    }

    this.allPermissions = [].concat(
      this.extractAuthorizationPermissions(),
      this.extractInterfacePermissions(),
      this.extractTradingPermission(),
      this.extractTransactionPermission(),
      this.extractTradingForwardsPermission(),
    )
    return this.allPermissions.concat(this.extractCustomPermissions())
  }

  extractAuthorizationPermissions() {
    return filter(Object.keys(this.authorizationPermissions), permission =>
      get(this.authorizationPermissions, permission),
    )
  }

  extractInterfacePermissions() {
    const permissions = []
    Object.entries(this.interfacePermissions).forEach(([keyInterface, userInterface]) => {
      Object.entries(userInterface.sub_interfaces).forEach(([keySubInterface, subInterface]) => {
        if (subInterface.enabled) {
          permissions.push(`${keyInterface}_${keySubInterface}`)
        }
      })
    })
    return permissions
  }

  extractTradingPermission() {
    const permissions = []
    if (this.tradingPermissions.transaction.restricted) {
      permissions.push('transaction_allowed')
    }
    if (this.tradingPermissions.transaction.split_transaction_quote_and_payments) {
      permissions.push('spfx_enabled')
    }
    return permissions
  }

  extractTransactionPermission() {
    const permissions = []
    if (this.transactionPermissions.incoming_payments_exists) {
      return permissions.push('incoming_payments_exists')
    }
    return permissions
  }

  extractTradingForwardsPermission() {
    const permissions = []
    if (this.tradingForwardsPermissions.forward_enabled) {
      permissions.push('forward_enabled')
    }
    return permissions
  }

  extractCustomPermissions() {
    const permissions = []

    if (this.canManageSpotTransactions()) {
      permissions.push('manage_spot_transactions')
    }

    if (this.canSpecifyPayments()) {
      permissions.push('specify_payments')
      permissions.push('lo_specify_payments')
    }

    if (this.canManageLimitOrders()) {
      permissions.push('manage_limit_orders')
    }

    if (this.canManageFc()) {
      permissions.push('manage_fc')
    }

    if (this.canFcSpecifyPayments()) {
      permissions.push('fc_specify_payments')
    }

    return permissions
  }

  /**
    Conditions
   */

  canManageSpotTransactions() {
    /**
      transaction_allowed &&
      forward_enabled || create_transaction_quote || create_transaction_quote_and_pending_beneficiary_transactions &&
      transfer_currency_transfer_currency
      */
    return this.isTransactionAllowed() && this.canUserManageTransactionOrQuote() && this.isTransactionInterfaceEnabled()
  }

  canSpecifyPayments() {
    /**
      transaction_allowed &&
      spfx_enabled &&
      manage_transactions || create_beneficiary_transactions || create_pending_beneficiary_transactions || create_transaction_quote_and_pending_beneficiary_transactions
    */
    return this.isTransactionAllowed() && this.isSpFxEnabled() && this.canUserCreateBeneficiaryTransactionsOrPendBt()
  }

  canManageLimitOrders() {
    /**
      transaction_allowed &&
      forward_enabled || create_transaction_quote || create_transaction_quote_and_pending_beneficiary_transactions &&
      limit_orders_new_order
    */
    return (
      this.isTransactionAllowed() && this.canUserManageTransactionOrQuote() && this.isNewMarketOrderInterfaceEnabled()
    )
  }

  canManageFc() {
    /**
      transaction_allowed &&
      forward_enabled &&
      forward_enabled || create_transaction_quote || create_transaction_quote_and_pending_beneficiary_transactions &&
      forward_contracts_forward_contracts
     */
    return (
      this.isTransactionAllowed() &&
      this.isForwardEnabled() &&
      this.canUserManageTransactionOrQuote() &&
      this.isForwardInterfaceEnabled()
    )
  }

  canFcSpecifyPayments() {
    /**
      transaction_allowed &&
      spfx_enabled &&
      forward_enabled &&
      manage_transactions || create_beneficiary_transactions || create_pending_beneficiary_transactions || create_transaction_quote_and_pending_beneficiary_transactions
     */
    return (
      this.isTransactionAllowed() &&
      this.isSpFxEnabled() &&
      this.isForwardEnabled() &&
      this.canUserCreateBeneficiaryTransactionsOrPendBt()
    )
  }

  canUserManageTransactionOrQuote() {
    /**
      forward_enabled || create_transaction_quote || create_transaction_quote_and_pending_beneficiary_transactions
     */
    return (
      this.canUserManageTransactions() ||
      this.canUserCreateTransactionQuote() ||
      this.canUserCreateTransactionQuoteAndPendingBeneficiaryTransactions()
    )
  }

  canUserCreateBeneficiaryTransactionsOrPendBt() {
    /**
      manage_transactions ||
      create_beneficiary_transactions ||
      create_pending_beneficiary_transactions ||
      create_transaction_quote_and_pending_beneficiary_transactions
     */
    return (
      this.canUserManageTransactions() ||
      this.canUserCreateBeneficiaryTransactions() ||
      this.canUserCreatePendingBeneficiaryTransactions() ||
      this.canUserCreateTransactionQuoteAndPendingBeneficiaryTransactions()
    )
  }

  isTransactionAllowed() {
    return this.allPermissions.includes('transaction_allowed')
  }

  canUserCreateTransactionQuote() {
    return this.allPermissions.includes('create_transaction_quote')
  }

  canUserCreateTransactionQuoteAndPendingBeneficiaryTransactions() {
    return this.allPermissions.includes('create_transaction_quote_and_pending_beneficiary_transactions')
  }

  canUserManageTransactions() {
    return this.allPermissions.includes('manage_transactions')
  }

  isTransactionInterfaceEnabled() {
    return this.allPermissions.includes('transfer_currency_transfer_currency')
  }

  isSpFxEnabled() {
    return this.allPermissions.includes('spfx_enabled')
  }

  canUserCreatePendingBeneficiaryTransactions() {
    return this.allPermissions.includes('create_pending_beneficiary_transactions')
  }

  canUserCreateBeneficiaryTransactions() {
    return this.allPermissions.includes('create_beneficiary_transactions')
  }

  isNewMarketOrderInterfaceEnabled() {
    return this.allPermissions.includes('limit_orders_new_order')
  }

  isForwardEnabled() {
    return this.allPermissions.includes('forward_enabled')
  }

  isForwardInterfaceEnabled() {
    return this.allPermissions.includes('forward_contracts_forward_contracts')
  }
}

export default PermissionsGenerator
