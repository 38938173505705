/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'

/* Validations */
import { ContactInfoValidation } from 'validations'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import { TextField, SelectField, CheckboxField } from 'components/helpers/formik_fields'
import { useFetchAddressOptions } from 'components/hooks/useFetchAddressOptions'
import { fetchInitialCascade } from 'components/utils/fetch_initial_address_options'
/* API */
import { getRegistrationEnabledCountries } from 'api/dropdowns'
/* Actions */
import { updateUserDetails } from 'actions/users'

/* Styles */
import styles from './styles.module.scss'

const addressAttributes = { province: 'Province', city: 'City', suburb: 'Suburb', zip: 'Zip' }
/**
 * Component for Contact Information
 */
export const ContactInformation = ({ user, userId, updateUserDetails }) => {
  const [countries, setCountries] = useState()

  const [
    dropdowns,
    setDropdowns,
    isLoading,
    setIsLoading,
    fetchDropdownOptions,
    addressAttributesRef,
    newFormikStateObj,
  ] = useFetchAddressOptions(addressAttributes)

  useEffect(() => {
    async function fetchRegistrationEnabledCountries() {
      try {
        const res = await getRegistrationEnabledCountries()
        res &&
          setCountries(
            res.map(country => ({
              id: country.id,
              label: country.name,
              value: country.code,
            })),
          )
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }
    if (user.address?.province) {
      const pickedInitialValues = (({ province, city, suburb, zip }) => ({ city, province, suburb, zip }))(
        user?.address,
      )
      fetchInitialCascade(addressAttributes, setDropdowns, setIsLoading, pickedInitialValues, 'address')
      fetchRegistrationEnabledCountries()
    }
  }, [user.address?.province])

  /** Loads spinner while waiting for content */
  if (isEmpty(user) || !countries) {
    return <Spinner />
  }

  /**
   * Handle the submit event after clicking the 'Update changes' button
   * @param {object} values - values of the fields from Formik
   * @param {API} - can pass field API as props (See the Formik document)
   * @see {Document} - https://formik.org/docs/api/formik
   */
  const handleFormSubmission = async (values, { setSubmitting, setStatus }) => {
    const { address, email } = values
    const { postal_address: postalAddress, postal, mobile, mobile_isd_code: mobileIsdCode, ...restValues } = address

    const updateAdressesObj = {
      user: {
        address_attributes: {
          ...restValues,
          first_name: user.address.first_name,
          last_name: user.address.last_name,
          middle_name: user.address.middle_name,
          mobile,
          mobile_isd_code: mobileIsdCode.replace(/\+/g, ''),
          postal,
          postal_address_attributes: postal ? restValues : postalAddress,
        },
        email,
      },
    }

    const response = await updateUserDetails(userId, updateAdressesObj, 'application/json').catch(error => {
      toast.error(error.message)
      if (error?.response?.status === 422) {
        setStatus(error?.response?.data?.errors)
      }
    })
    response && setSubmitting(false)
    response?.status === 200 && toast.success('Successfully updated contact information')
  }

  const onProvinceChange = async (option, dropdownSection, fieldName, { setValues, values }) => {
    const assigningMethod = dropdownSection === 'address'
    const { address } = values
    const { postal_address: postalAddress } = address
    const formikValues = assigningMethod ? address : postalAddress

    assigningMethod
      ? setValues({
          ...values,
          [dropdownSection]: {
            ...values[dropdownSection],
            ...newFormikStateObj(fieldName, option.value),
          },
        })
      : setValues({
          ...values,
          address: {
            ...values.address,
            [dropdownSection]: {
              ...values.address[dropdownSection],
              ...newFormikStateObj(fieldName, option.value),
            },
          },
        })
    fieldName !== 'zip' && fetchDropdownOptions(fieldName, option.value, formikValues, dropdownSection)
  }

  const onMobileCodeChange = (e, setFieldValue) => {
    if (e.target.value.match(/^\+(.*)/)) {
      setFieldValue(e.target.name, e.target.value)
    }
  }

  return (
    <div className="accounts-card col-xl-9">
      <div className="well-dark">
        <div className="bg-white w-100">
          <div className="m-1 tdb-card_title-body">
            <h2>Contact information</h2>
            <div className="col pr-0 pl-0">
              <Formik
                innerRef={addressAttributesRef}
                /** Initial values of each input field */
                initialValues={{
                  address: {
                    address_line_1: user.address?.address_line_1 || '',
                    city: user.address?.city || '',
                    country_code: countries?.find(c => c.label === user.address?.country_name)?.value || '',
                    mobile: user.address?.mobile || '',
                    mobile_isd_code: '+' + user.address?.mobile_isd_code || '',
                    postal: true,
                    postal_address: {
                      address_line_1: user.address?.postal_address?.address_line_1 || '',
                      city: user.address?.postal_address?.city || '',
                      country_code: countries?.find(c => c.label === user.address?.country_name)?.value || '',
                      province: user.address?.postal_address?.province || '',
                      suburb: user.address?.postal_address?.suburb || '',
                      zip: user.address?.postal_address?.zip || '',
                    },
                    province: user.address?.province || '',
                    suburb: user.address?.suburb || '',
                    zip: user.address?.zip || '',
                  },
                  email: user.email || '',
                }}
                validationSchema={ContactInfoValidation}
                onSubmit={handleFormSubmission}>
                {({ values, setFieldValue, setValues, isSubmitting }) => (
                  <Form>
                    <TextField name="email" label="Email address" placeholder="Email address" />

                    <div className="form-control-container">
                      <div className="row mx-0">
                        <div className="col col-3 pr-0 pl-0">
                          <TextField
                            label="Mobile number"
                            name="address.mobile_isd_code"
                            onChange={e => onMobileCodeChange(e, setFieldValue)}
                            isDisabled={true}
                          />
                        </div>
                        <div className="col col-9 pl-2 mt-auto pr-0">
                          <TextField name="address.mobile" label=" " isDisabled={true} placeholder="Mobile phone" />
                        </div>
                      </div>
                    </div>
                    <TextField name="address.address_line_1" label="Street address" placeholder="Street address" />

                    {Object.keys(addressAttributes).map(inputName => (
                      <SelectField
                        theme="superSwift"
                        key={`address.${inputName}`}
                        label={addressAttributes[inputName]}
                        name={`address.${inputName}`}
                        options={dropdowns?.address?.[inputName]}
                        onChange={option => onProvinceChange(option, 'address', inputName, { setValues, values })}
                        isLoading={isLoading?.address?.[inputName] || isLoading?.address?.[inputName] === undefined}
                      />
                    ))}
                    <div className="mt-4">
                      {values.address.postal ? null : <div className={styles.label}>Postal address</div>}
                      <CheckboxField
                        controlled
                        name="address.postal"
                        onChange={() => {
                          if (values.address.postal) {
                            const { postal_address: postalAddress } = user?.address
                            fetchInitialCascade(
                              addressAttributes,
                              setDropdowns,
                              setIsLoading,
                              postalAddress,
                              'postal_address',
                            )
                          }
                        }}
                        message="Use the address above as my postal address"
                      />
                    </div>
                    {!values.address.postal && (
                      <div className="pb-3 mb-4">
                        <TextField
                          name="address.postal_address.address_line_1"
                          label="Street address"
                          placeholder="Street address"
                        />

                        {Object.keys(addressAttributes).map(inputName => (
                          <SelectField
                            theme="superSwift"
                            key={`address.postal_address.${inputName}`}
                            label={addressAttributes[inputName]}
                            name={`address.postal_address.${inputName}`}
                            options={dropdowns?.postal_address?.[inputName]}
                            isLoading={
                              isLoading?.postal_address?.[inputName] ||
                              isLoading?.postal_address?.[inputName] === undefined
                            }
                            onChange={option =>
                              onProvinceChange(option, 'postal_address', inputName, { setValues, values })
                            }
                          />
                        ))}
                      </div>
                    )}
                    <hr className="mt-4" />
                    <SelectField
                      theme="superSwift"
                      label="Country of residence"
                      name={'address.country_code'}
                      options={countries}
                      isLoading={!countries}
                      onChange={option => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            country_code: option.value,
                            postal_address: { ...values.address.postal_address, country_code: option.value },
                          },
                        })
                      }}
                    />
                    <Button
                      className={`btn btn-sm btn-success btn-width-200 space-10 mt-4 w-inline-block-button ${
                        isSubmitting ? 'is-submitting' : 'not-submitting'
                      }`}
                      type="submit"
                      disabled={isSubmitting}>
                      {isSubmitting ? <Spinner spinner={false} /> : 'Update changes'}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactInformation.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  updateUserDetails: PropTypes.func,
  user: PropTypes.shape({
    address: PropTypes.shape({
      address_line_1: PropTypes.string,
      city: PropTypes.string,
      country_code: PropTypes.string,
      country_name: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
      mobile: PropTypes.string,
      mobile_isd_code: PropTypes.string,
      postal_address: PropTypes.shape({
        address_line_1: PropTypes.string,
        city: PropTypes.string,
        country_code: PropTypes.string,
        country_name: PropTypes.string,
        province: PropTypes.string,
        suburb: PropTypes.string,
        zip: PropTypes.string,
      }),
      province: PropTypes.string,
      suburb: PropTypes.string,
      zip: PropTypes.string,
    }),
    email: PropTypes.string,
  }),
  userId: PropTypes.number,
}

const mapStateToProps = ({ user }) => ({
  user: user.data?.userDetails,
  userId: user.data?.userId,
})

export default connect(mapStateToProps, { updateUserDetails })(ContactInformation)
