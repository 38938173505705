import React from 'react'
import PropTypes from 'prop-types'

/**
 * renders HTML image tag associated to a SVG flag
 * @param {{countryCode: string, width: number|string}} object
 */
const FlagImage = ({ countryCode, width }) => {
  if (!countryCode) {
    return null
  }
  return (
    <img
      width={width || 30}
      src={`/assets/images/flags/${countryCode?.toUpperCase?.()}.svg`}
      className="country_flag"
      alt=""
    />
  )
}

FlagImage.propTypes = {
  /** Code of the country that will be render */
  countryCode: PropTypes.string,
  /** Width of the HTML image tag */
  width: PropTypes.number,
}

export default FlagImage
