import store from 'config/create_store'
import { userLocalStorage } from 'config/local_storage'

/**
 * Returns homepage path for a user already logged in
 * @returns {String}
 */
const AuthorizationRedirectionPath = () => {
  try {
    const { linkedAccounts, accountSettings } = store?.getState().user?.data

    switch (true) {
      case linkedAccounts.length > 1 && !userLocalStorage.get('isAccountSwitched'):
        return 'users/select-account'

      case accountSettings:
        return 'retail'
    }
  } catch (error) {
    console.error(error.message)
  }

  return 'retail'
}

export default AuthorizationRedirectionPath
