import React from 'react'
import PropTypes from 'prop-types'

import BankDetails from '../modules/bank_details'
import AccountDetails from '../modules/account_details'
import IntermediaryBankDetails from '../modules/intermediary_bank_details'
import OrderingCustomerDetails from '../modules/ordering_customer_details'

const ThirdPage = props => {
  const { isNewBeneficiary } = props
  return (
    <React.Fragment>
      <BankDetails {...props} />
      <AccountDetails {...props} />
      <IntermediaryBankDetails {...props} />
      {isNewBeneficiary && <OrderingCustomerDetails {...props} />}
    </React.Fragment>
  )
}

ThirdPage.propTypes = {
  beneficiary: PropTypes.shape({
    is_default: PropTypes.bool,
    renumeration_type_name: PropTypes.string,
  }),
  errors: PropTypes.shape({
    is_default: PropTypes.any,
  }),

  isNewBeneficiary: PropTypes.bool,
  isRenumerationMobile: PropTypes.func,
  onBeneficiaryIsDefaultChange: PropTypes.func,
}

export default ThirdPage
