import React from 'react'
import PropTypes from 'prop-types'
/* Helpers */
import TextInput from 'components/helpers/form_fields/text_input'

import { useLoginDetails } from './login_details.hook'

import 'components/base/layouts/main/container/index.scss'

/**
 * Component for Login Details - form for updating user account password
 */
export const LoginDetails = ({ userId, memberID }) => {
  const { isSavingPassword, onPasswordChange, onPasswordSubmit, password } = useLoginDetails({ userId })

  /**
   * Renders component Login Details
   */
  return (
    <React.Fragment>
      <div className="tdb-card_title-body">
        <h2>Password</h2>
        <div className="tdb-card_divider" />
      </div>
      <div className="tdb-card_content-body d-flex flex-column justify-content-between align-items-start pt-0">
        <div className="w-100">
          <div className="form-group">
            <label className="control-label required" htmlFor="account_number">
              Member ID
            </label>
            <input
              className="form-control input-sm"
              data-testid="account_number-input"
              disabled
              name="account_number"
              type="text"
              onChange={onPasswordChange}
              value={memberID}
            />
          </div>
          <TextInput
            label="Current password"
            name="current_password"
            required
            testID="current-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.current_password}
          />
          <TextInput
            label="New password"
            name="password"
            required
            testID="new-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.password}
          />
          <TextInput
            label="Confirm new password"
            name="password_confirmation"
            required
            testID="confirmation-new-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.password_confirmation}
          />
        </div>
        <input
          className={`btn btn-sm btn-success btn-width-200 space-10 mt-4 w-inline-block-button ${
            isSavingPassword ? 'is-submitting' : 'not-submitting'
          }`}
          data-testid="update-changes-password"
          name="update_password"
          type="button"
          value={isSavingPassword ? 'Saving...' : 'Change password'}
          onClick={onPasswordSubmit}
        />
      </div>
    </React.Fragment>
  )
}

LoginDetails.propTypes = {
  /** Provides current memeberID */
  memberID: PropTypes.string,
  /** Provides current userId */
  userId: PropTypes.number,
}

export default LoginDetails
