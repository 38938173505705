import * as Yup from 'yup'
import { passwordSchema } from './password'

const NewPasswordValidation = Yup.object().shape({
  password: passwordSchema,
  password_confirmation: Yup.string()
    .required("Can't be blank")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export { NewPasswordValidation }
