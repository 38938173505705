/* eslint camelcase: "off" */

import * as ServerErrorActions from './server_errors'
import * as TradingActions from './trading'
import * as UserActions from './users'
import * as ClientActions from './clients'
import * as RegistrationActions from './registration'

const initializers = {
  actions: [],
  types: {},
}
;[
  'INIT_SET_CURRENT_CLIENT',
  'INIT_SET_CURRENT_USER_DATA',
  'INIT_SET_DROPDOWN_COUNTRIES',
  'INIT_SET_DROPDOWN_SOF',
  'INIT_SET_USER_RISK_ASSESMENT_INFO',
].forEach(name => {
  initializers.types[name.toUpperCase()] = `APP_${name}`
  initializers.actions.push({
    type: initializers.types[name.toUpperCase()],
  })
})

const RESET_STORE = 'RESET_STORE'

const resetStore = { type: RESET_STORE }

export {
  RegistrationActions,
  ServerErrorActions,
  UserActions,
  ClientActions,
  TradingActions,
  RESET_STORE,
  resetStore,
  initializers,
}
