import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PersonalDetailsConfirm from './personal_details_confirm'
import { toast } from 'react-toastify'
import { userLocalStorage } from 'config/local_storage'
import { ReactComponent as Warning } from 'assets/svg/warning.svg'
import './styles.scss'

/* Api */
import { turnOffReviewCustomerDetails } from 'api/accounts/cw/customers'

export const ConfirmCustomerDetails = ({ userId }) => {
  const [buttonStatus, setButtonStatus] = useState()

  const onSubmit = ({ setSubmitting }) => {
    turnOffReviewCustomerDetails(userId)
      .then(res => toast.success(res?.data?.message || res?.data?.description))
      .catch(error => toast.error(error?.response?.data?.description || error?.message))
    userLocalStorage.set('isAccountConfirmed', true)
    setSubmitting(false)
  }

  const renderCustomHeading = () => {
    return (
      <div className="px-4 pt-4">
        <h5 className="font-bold mb-4">Personal details</h5>
        <hr className="m-0" />
      </div>
    )
  }

  return (
    <div className="container confirm-details-container justify-content-center">
      <div className="details-container d-flex flex-column col-8 align-items-center">
        <nav className="navbar navbar-expand-lg confirm-details justify-content-between mt-5 col-lg-9">
          <h5 className="auth-header h5 font-bold">Confirm your details</h5>
          <button
            form="PersonalDetailsConfirm"
            type="submit"
            disabled={buttonStatus}
            style={{ height: '3rem', width: 'fit-content' }}
            className="btn btn-tbd-red btn-primary btn-block">
            {buttonStatus ? 'Please wait...' : 'Confirm & continue'}
          </button>
        </nav>
        <div className="confirm-details-alert">
          <div className="col-lg-9  alert alert-warning d-flex align-items-center">
            <Warning style={{ height: '1.5rem', marginRight: '1rem', width: '1.5rem' }} />
            Please confirm that your current details are still valid.
          </div>
        </div>
        <PersonalDetailsConfirm
          btnText="Confirm & continue"
          setButtonStatus={setButtonStatus}
          onSubmit={onSubmit}
          customHead={renderCustomHeading()}
        />
      </div>
    </div>
  )
}

ConfirmCustomerDetails.propTypes = {
  /** Current authenticated user data {@link withCurrentUser} */
  currentUser: PropTypes.shape({
    /** Account associated to the user */
    linkedAccounts: PropTypes.array,
    /** Name object of the user */
    userDetails: PropTypes.shape({
      address: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
    }),
  }),
  userId: PropTypes.string,
}

const mapStateToProps = ({ user }) => ({
  user: user.data?.userDetails,
  userId: user.data?.userId,
})

export default connect(mapStateToProps, null)(ConfirmCustomerDetails)
