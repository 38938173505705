/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Success } from 'assets/svg/valid.svg'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'

/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { NEW_FULL_PATH } from 'routes/paths/private/transactions'

/**
 * Component for the SUCCESS page of registration process
 */
const StepSixSuccess = () => {
  const history = useHistory()

  return (
    <RegistrationHeader title={steps[2].text} classContainer="col-lg-4">
      <div className="row justify-content-center">
        <div className="col-lg-4 p-0">
          <div className="alert alert-success d-flex">
            <Success className="mr-3 w-25" />
            <span className="font-bold">
              Uploaded documents were submitted successfully. You will shortly receive an email stating confirmation of
              your email address but account access will be granted after approval of compliance team.
            </span>
          </div>
          <button onClick={() => history.push(NEW_FULL_PATH)} className="btn btn-flat btn-block mt-6">
            Continue
          </button>
        </div>
      </div>
    </RegistrationHeader>
  )
}

StepSixSuccess.propTypes = {
  /** Provides several attributes/functions for managing session history */
  history: PropTypes.object,
}

export default StepSixSuccess
