import env from '.env'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { TRANSACTIONS_PATH, RETAIL_PATH } from 'routes/paths/root'
import { Modal } from 'react-bootstrap'
import { Ipay } from './script/ipay-jsintegration-1.0.js'
import style from './style.module.scss'

const Ozow = props => {
  const { show, onHide, ozowDetails } = props
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const ozow = new Ipay()
    const containerElement = document.getElementById('paymentContainer')
    const paymentUrl = env?.ozow?.url
    const postData = {
      Amount: ozowDetails.amount,
      BankReference: ozowDetails.bank_reference,
      CancelUrl: ozowDetails.cancel_url,
      CountryCode: ozowDetails.country_code,
      CurrencyCode: ozowDetails.currency_code,
      Customer: ozowDetails.customer,
      ErrorUrl: ozowDetails.error_url,
      HashCheck: ozowDetails.hash_check,
      IsTest: ozowDetails.is_test,
      NotifyUrl: ozowDetails.notify_url,
      Optional1: ozowDetails.member_id,
      Optional2: ozowDetails.beneficiary_id,
      Optional3: ozowDetails.beneficiary_type,
      Optional4: ozowDetails.counter_currency_amount,
      Optional5: ozowDetails.value_datetime,
      SiteCode: ozowDetails.site_code,
      SuccessUrl: ozowDetails.success_url,
      TransactionReference: ozowDetails.transaction_reference,
    }
    ozow.createPaymentFrame(containerElement, paymentUrl, postData)
  }, [])

  useEffect(() => {
    location.hash && history.push(`${RETAIL_PATH}${TRANSACTIONS_PATH}/${location.hash.substring(1)}`)
  }, [location.hash])

  const containerClass = location.pathname.includes('retail') ? 'retail' : 'wholesale'

  return (
    <Modal contentClassName={`well-dark ${env.retail.theme}`} className={containerClass} show={show} size={'xl'}>
      <React.Fragment>
        <Modal.Header className="p-3 m-2 bg-white">
          <Modal.Title as={'h4'}>
            Transaction Payment
            <button onClick={onHide} className="border-0 checkLocal">
              ×
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white px-4 pt-4 pb-0 mx-2 mb-5">
          <div id="paymentContainer" className={style.ozow}></div>
        </Modal.Body>
      </React.Fragment>
    </Modal>
  )
}

Ozow.propTypes = {
  onHide: PropTypes.func.isRequired,
  ozowDetails: PropTypes.shape({
    amount: PropTypes.string,
    bank_reference: PropTypes.string,
    beneficiary_id: PropTypes.string,
    beneficiary_type: PropTypes.string,
    cancel_url: PropTypes.string,
    counter_currency_amount: PropTypes.string,
    country_code: PropTypes.string,
    currency_code: PropTypes.string,
    customer: PropTypes.string,
    error_url: PropTypes.string,
    hash_check: PropTypes.string,
    is_test: PropTypes.string,
    member_id: PropTypes.string,
    notify_url: PropTypes.string,
    site_code: PropTypes.string,
    success_url: PropTypes.string,
    transaction_reference: PropTypes.number,
    value_datetime: PropTypes.string,
  }),
  show: PropTypes.bool,
}

export default Ozow
