import React from 'react'
import PropTypes from 'prop-types'
import AlertErrors from './alert_errors'
import { withRouter } from 'react-router-dom'
import { clearErrors } from 'actions/server_errors'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { userLocalStorage } from 'config/local_storage'

class ErrorBoundary extends React.Component {
  state = {
    appErrors: {
      message: '',
      response: {},
    },
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.dispatch(clearErrors)
      this.setState({
        appErrors: {
          message: '',
          response: {},
        },
      })
    }
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtras(info)
      Sentry.captureException(error)
      this.setState({
        appErrors: {
          message: error.message,
          response: info,
        },
      })
    })
    if (userLocalStorage.get('tokenInformation')) {
      this.props.history.push('/')
    }
  }

  render() {
    const { serverError } = this.props
    const { appErrors } = this.state

    if (serverError) {
      const { url } = serverError.config
      const { response } = serverError
      return (
        <AlertErrors response={serverError} message={`Request to ${url} returned ${response.status} status code`} />
      )
    }

    if (appErrors.message) {
      return <AlertErrors {...appErrors} />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  serverError: PropTypes.bool,
}

export default connect()(withRouter(ErrorBoundary))
