import React from 'react'
import PropTypes from 'prop-types'
/* Modules */
import FundingOption from './shared/funding_option'

const Retail = props => {
  const { fetching, receiptPaymentModeList, errors } = props

  return (
    <div>
      <FundingOption errors={errors} fetching={fetching} receiptPaymentModeList={receiptPaymentModeList} />
    </div>
  )
}

Retail.propTypes = {
  displayFundingAccountForTransactionOrMo: PropTypes.func,
  errors: PropTypes.object,
  fetching: PropTypes.object,
  receiptPaymentModeList: PropTypes.arrayOf(PropTypes.object),
}

export default Retail
