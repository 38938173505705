import { USERS_PATH, RETAIL_PATH } from '../root'

const SIGN_IN_PATH = 'sign_in'
const SIGN_IN_FULL_PATH = `${USERS_PATH}/${SIGN_IN_PATH}`

const SELECT_ACCOUNT_PATH = 'select-account'
const SELECT_ACCOUNT_FULL_PATH = `${USERS_PATH}/${SELECT_ACCOUNT_PATH}`

const PASSWORD_RESET_PATH = 'password/reset'
const PASSWORD_RESET_FULL_PATH = `${USERS_PATH}/${PASSWORD_RESET_PATH}`
const OTP_VALIDATION_PATH = 'otp-validation'
const OTP_VALIDATION_PATH_FULL_PATH = 'password/otp-validation'
const PASSWORD_EDIT_PATH = 'password/restore'
const PASSWORD_EDIT_FULL_PATH = `${USERS_PATH}/${PASSWORD_EDIT_PATH}`

const TWO_FACTOR_NEW_PATH = 'two-factor-authentication-new-form-fields'
const TWO_FACTOR_NEW_FULL_PATH = `${USERS_PATH}/${TWO_FACTOR_NEW_PATH}`
const TWO_FACTOR_CONFIRM_PATH = 'two-factor-authentication-confirm-form-fields'
const TWO_FACTOR_CONFIRM_FULL_PATH = `${USERS_PATH}/${TWO_FACTOR_CONFIRM_PATH}`

const CONFIRM_CUSTOMER_DETAILS = 'confirm-customer-details'
const CONFIRM_CUSTOMER_DETAILS_PARTIAL_PATH = `${USERS_PATH}/${CONFIRM_CUSTOMER_DETAILS}`
const CONFIRM_CUSTOMER_DETAILS_FULL_PATH = `${RETAIL_PATH}/${USERS_PATH}/${CONFIRM_CUSTOMER_DETAILS}`

export {
  SIGN_IN_PATH,
  SIGN_IN_FULL_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_RESET_FULL_PATH,
  PASSWORD_EDIT_PATH,
  PASSWORD_EDIT_FULL_PATH,
  TWO_FACTOR_NEW_PATH,
  TWO_FACTOR_NEW_FULL_PATH,
  TWO_FACTOR_CONFIRM_PATH,
  TWO_FACTOR_CONFIRM_FULL_PATH,
  SELECT_ACCOUNT_PATH,
  SELECT_ACCOUNT_FULL_PATH,
  CONFIRM_CUSTOMER_DETAILS,
  CONFIRM_CUSTOMER_DETAILS_FULL_PATH,
  OTP_VALIDATION_PATH,
  OTP_VALIDATION_PATH_FULL_PATH,
  CONFIRM_CUSTOMER_DETAILS_PARTIAL_PATH,
}
