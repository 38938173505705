import React from 'react'
import PropTypes from 'prop-types'
import TextInputMask from '../input_mask'

/**
 * Component for phone text input
 */
export const TextInputPhone = ({
  className,
  disabled,
  helpBlock,
  keys,
  label,
  layout,
  mask,
  name,
  onChange,
  required,
  testID,
  type: typeProp,
  value: valueProp,
  placeholder,
}) => {
  const renderInput = () => {
    const data = {
      className: 'form-control input-sm',
      'data-testid': `${testID}-number`,
      disabled,
      name: keys[1],
      onChange: onChange(keys[1]),
      placeholder,
      value: valueProp[keys[1]],
    }
    if (mask) {
      return <TextInputMask {...data} testID={`${testID}-number`} />
    }
    return <input {...data} />
  }

  /**
   * Renders phone text input with horizontal layout
   */
  if (layout === 'horizontal') {
    const inputWidth = label ? 7 : 12
    return (
      <div className={`d-flex form-group ${className || ''}`}>
        {label && (
          <div className="col-sm-5">
            <label className={`control-label${required ? ' required' : ''}`} htmlFor={name}>
              {label}
            </label>
          </div>
        )}
        <div className={`col-sm-${inputWidth}`}>
          <div className="row">
            <div className="col col-3">
              <input
                data-testid={`${testID}-area`}
                type="text"
                name={keys[0]}
                className="form-control input-sm"
                onChange={onChange(keys[0])}
                value={valueProp[keys[0]]}
              />
            </div>
            <div className="col col-9">{renderInput()}</div>
          </div>
        </div>
      </div>
    )
  }
  /**
   * Renders phone text input with vertical layout
   */
  return (
    <div className="form-group">
      <label className={`control-label${required ? ' required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div>
        <div className="form-control-container">
          <div className="row">
            <div className="col col-3 pr-0">
              <input
                type="text"
                name={keys[0]}
                className="form-control input-sm"
                onChange={onChange(keys[0])}
                value={valueProp[keys[0]]}
              />
            </div>
            <div className="col col-9 pl-3">
              <div className="d-flex">{renderInput()}</div>
            </div>
          </div>
        </div>
        {helpBlock && <div className="help-block">{helpBlock}</div>}
      </div>
    </div>
  )
}

TextInputPhone.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * help text that describes form input
   */
  helpBlock: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  /**
   * defines if layout should be horizontal/vertical
   */
  layout: PropTypes.string,
  /**
   * defines if phone input should be masked
   */
  mask: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  testID: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

TextInputPhone.defaultProps = {
  className: '',
  disabled: false,
  layout: 'vertical',
  mask: false,
  required: false,
  testID: 'input-phone',
  type: 'text',
}

export default TextInputPhone
