import React from 'react'
import SGC from 'assets/images/superswift/shoprite-group_1shoprite-group.png'
import Phone from 'assets/images/superswift/phone.svg'
import Mail from 'assets/images/superswift/email.svg'
import './index.scss'

const GroupDetails = () => {
  return (
    <div className="group w-nav w-container bg-white">
      <div className="group-ctn container-1200">
        <div className="group-ctn-logo d-flex">
          <div className="group-ctn-img">
            <img src={SGC} alt="shoprite-group-logo" />
          </div>
          <div className="by-sgc">by Shoprite Group of Companies</div>
        </div>
        <div className="group-ctn-logo-2">
          <div className="group-links group-links-right">
            <a href="tel:+27112141743">
              <img src={Phone} alt="phone-image" />
              <span>+27 11 214 1743</span>
            </a>
          </div>
          <div className="group-links down">
            <a href="mailto:help@shopritesend.co.za">
              <img src={Mail} alt="letter-image" />
              <span>help@shopritesend.co.za</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupDetails
