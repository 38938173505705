import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import { CheckboxField } from 'components/helpers/formik_fields'
/* Utils */
import { steps, FOREIGN_NATIONAL_416, FOREIGN_NATIONAL_417 } from '../register.constants'
/* Paths */
import { STEP_THREE } from 'routes/paths/public/register'
import { REGISTER_PATH } from 'routes/paths/root'
/**
 * Component for the Second step of registration process
 */
const StepTwo = () => {
  const history = useHistory()
  const { setCurrentCustomerCategory, setRegistrationFlow } = useContext(RegistrationContext)

  /**
   * Gets called when the user selects the transfer limit by pressing the Select button
   *
   * @param {String} path destination of redirection after the button click
   * @param {String} label name of customer_category
   */

  const handleToggle = (fieldName, { values, setFieldValue }) => {
    values[fieldName] && setFieldValue(fieldName, false)
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[1].text} />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <Formik
                initialValues={{ foreign_416: false, foreign_417: false }}
                onSubmit={async (values, actions) => {
                  const currentCustomerCategory = values.foreign_416 ? FOREIGN_NATIONAL_416 : FOREIGN_NATIONAL_417
                  setCurrentCustomerCategory(currentCustomerCategory)
                  setRegistrationFlow('Individual', 'Individual Account Registration', currentCustomerCategory)
                  actions.setSubmitting(false)
                  history.push(`${REGISTER_PATH}/${STEP_THREE}`)
                }}>
                {formik => (
                  <Form>
                    <CheckboxField
                      className="ss-theme-checkbox"
                      controlled
                      name="foreign_416"
                      message="Standard"
                      onChange={() => handleToggle('foreign_417', formik)}
                    />

                    <div className="popins-16 dim-grey mb-3">Up to ZAR 5,000 per day / ZAR 15,000 per month.</div>

                    <hr className="p-1 my-4" />

                    <CheckboxField
                      className="ss-theme-checkbox"
                      controlled
                      name="foreign_417"
                      message="Boost"
                      onChange={() => handleToggle('foreign_416', formik)}
                    />

                    <div className="popins-16 dim-grey mb-3">Up to ZAR 5,000 per day / ZAR 25,000 per month.</div>

                    <li className="mb-4">Proof of income is required</li>

                    <button
                      data-testid="selectBtn-customerCategoryName"
                      disabled={!formik.values.foreign_416 && !formik.values.foreign_417}
                      type="submit"
                      className="ss-btn red w-100 popins-16">
                      Select
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}

StepTwo.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object,
}

export default StepTwo
