import React from 'react'
import PropTypes from 'prop-types'

import Tabs from 'components/helpers/tabs'

import { tabs } from './account.constants'
import './styles.scss'

/**
 * Component for My Account
 */
export const MyAccount = ({ match }) => {
  const { tab } = match.params

  return (
    <div className="content-container">
      <h1 className="page-title white">
        <span className="bar-1">My Account</span>
      </h1>
      <Tabs currentTab={tab} tabs={tabs} />
    </div>
  )
}

MyAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
}

export default MyAccount
