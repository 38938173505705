import React from 'react'
import { Link } from 'react-router-dom'

/**
  Renders component when a route does not exist, also known as 404 page
 */
const NotFound = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-6 space-40 text-center well">
        <h4 className="text-danger">The page you were looking for does not exist.</h4>
        <h5>You may have mistyped the address or the page may have moved.</h5>
        <Link className="btn btn-sm btn-warning space-20" to="/">
          Home
        </Link>
      </div>
    </div>
  )
}

export default NotFound
