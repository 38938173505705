import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import { SkeletonPanel } from 'components/helpers/skeleton'
import AmountToPay from './shared/amount_to_pay'
import FundingAccount from './shared/funding_account'
import FundingMethod from './shared/funding_method'
import FundingOption from './shared/funding_option'
import AmountDeducted from './shared/amount_deducted'
import AmountDirectDebited from './shared/amount_direct_debited'

const Wholesale = props => {
  const {
    balanceAmountToPayIntoReceiptAcc,
    currencyCode,
    customerBankAccount,
    customerBankAccountList,
    depositAmount,
    depositAmountDueDate,
    displayFundingAccountForTransactionOrMo,
    electronicFundTransfer,
    onCustomerBankAccountChange,
    onReceiptAccountChange,
    prefs,
    receiptAccount,
    receiptAccountList,
    receiptDate,
    receiptPaymentModeList,
    resourceIsForwardTransaction,
    resourceIsMarketOrder,
    errors,
  } = props

  if (displayFundingAccountForTransactionOrMo()) {
    if (isEmpty(prefs)) {
      return <SkeletonPanel text={'Funding account'} />
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h5 className="font-bold">Funding account</h5>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="transaction-content">
                <FundingOption prefs={prefs} errors={errors} receiptPaymentModeList={receiptPaymentModeList} />
                {(resourceIsMarketOrder() || !prefs.by_debit_card.enabled || electronicFundTransfer()) && (
                  <div>
                    <AmountDeducted prefs={prefs} currencyCode={currencyCode} />
                    <div className="col-sm-12">
                      <div className="form-group">
                        <FundingAccount
                          prefs={prefs}
                          customerBankAccount={customerBankAccount}
                          customerBankAccountList={customerBankAccountList}
                          errors={errors}
                          onCustomerBankAccountChange={onCustomerBankAccountChange}
                        />
                        <AmountDirectDebited
                          prefs={prefs}
                          customerBankAccount={customerBankAccount}
                          currencyCode={currencyCode}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <FundingMethod
                          prefs={prefs}
                          errors={errors}
                          receiptAccountList={receiptAccountList}
                          receiptAccount={receiptAccount}
                          onReceiptAccountChange={onReceiptAccountChange}
                        />
                        <AmountToPay prefs={prefs} receiptAccount={receiptAccount} currencyCode={currencyCode} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {resourceIsForwardTransaction() && (
        <div className="panel panel-default">
          <div className="panel-heading"></div>
          <div className="panel-body">
            <div className="row">
              <div className="transaction-content">
                {depositAmount > 0 && (
                  <div className="form-group">
                    <div className="col-sm-12">
                      A Deposit of
                      <span className="text-success">
                        {currencyCode} {formatCurrencyAmount(depositAmount)}
                      </span>
                      is required {depositAmountDueDate}.
                    </div>
                    <AmountDeducted prefs={prefs} currencyCode={currencyCode} />
                    <div className="col-sm-12">
                      <div className="form-group">
                        <FundingAccount
                          prefs={prefs}
                          customerBankAccount={customerBankAccount}
                          customerBankAccountList={customerBankAccountList}
                          errors={errors}
                          onCustomerBankAccountChange={onCustomerBankAccountChange}
                        />
                        <AmountDirectDebited
                          prefs={prefs}
                          customerBankAccount={customerBankAccount}
                          currencyCode={currencyCode}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <FundingMethod
                          prefs={prefs}
                          errors={errors}
                          receiptAccountList={receiptAccountList}
                          receiptAccount={receiptAccount}
                          onReceiptAccountChange={onReceiptAccountChange}
                        />
                        <AmountToPay prefs={prefs} receiptAccount={receiptAccount} currencyCode={currencyCode} />
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div className="col-sm-12">
                    The Final balance of
                    <span className="text-success">
                      {currencyCode} {formatCurrencyAmount(balanceAmountToPayIntoReceiptAcc)}
                    </span>
                    is due on {{ receiptDate }}.
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <FundingMethod
                        prefs={prefs}
                        errors={errors}
                        receiptAccountList={receiptAccountList}
                        receiptAccount={receiptAccount}
                        onReceiptAccountChange={onReceiptAccountChange}
                      />
                      <AmountToPay prefs={prefs} receiptAccount={receiptAccount} currencyCode={currencyCode} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Wholesale.propTypes = {
  balanceAmountToPayIntoReceiptAcc: PropTypes.number,
  currencyCode: PropTypes.string,
  customerBankAccount: PropTypes.object,
  customerBankAccountList: PropTypes.arrayOf(PropTypes.object),
  depositAmount: PropTypes.number,
  depositAmountDueDate: PropTypes.string,
  displayFundingAccountForTransactionOrMo: PropTypes.func.isRequired,
  electronicFundTransfer: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onCustomerBankAccountChange: PropTypes.func.isRequired,
  onReceiptAccountChange: PropTypes.func.isRequired,
  prefs: PropTypes.shape({
    by_debit_card: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
  receiptAccount: PropTypes.object,
  receiptAccountList: PropTypes.arrayOf(PropTypes.object),
  receiptDate: PropTypes.string,
  receiptPaymentModeList: PropTypes.arrayOf(PropTypes.object),
  resourceIsForwardTransaction: PropTypes.func.isRequired,
  resourceIsMarketOrder: PropTypes.func.isRequired,
}

export default Wholesale
