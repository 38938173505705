import env from '.env'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, useLocation, Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { signIn } from 'api/accounts/cw/users'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import FlagImage from 'components/helpers/flag_image'
import TextInput from 'components/helpers/form_fields/text_input'
/* Utils */
import WithDataSessionStore from 'components/HOC/with_data_session_store'
/* Paths */
import { PASSWORD_RESET_FULL_PATH } from 'routes/paths/public/users'
/* Actions */
import { refreshSession } from 'actions/users'

export const sessionExpiredContext = React.createContext(false)

const authStrategyLabels = {
  email: 'Email',
  mobile_phone_or_member_id: 'Mobile number or Member ID',
}

const authStrategy = window.Cypress ? 'email' : env.authenticationStrategy || 'email'

const SessionExpiredModal = props => {
  const { show, saveDataSession } = props
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [country, setCountry] = useState('ZA')
  const [showCountryPhoneCodeSelector, setShowCountryPhoneCodeSelector] = useState(false)

  useEffect(() => {
    // redirect the user back to sign_in if session has expired
    if (show) {
      // remove the users session data to force redirect back to sign_in
      props.removeDataSession()
    }
  }, [show])

  const submitForm = event => {
    event.preventDefault()
    /* if any of the environmental strategy is allowing user to SignIn then we
     * sending his credentials to BE
     */
    if (isLoginValid()) {
      setIsFetching(true)
      signIn(createAuthObj(), password)
        .then(res => {
          saveDataSession(res.data)
          props.refreshSession()
        })
        .catch(error => {
          const status = error.request ? error.request.status : null
          const errorMessage = !status
            ? 'Could not connect to the server now. Please try again later.'
            : error.response.data.error
          setErrorMessage(errorMessage)
        })
        .finally(() => {
          setIsFetching(false)
        })
    }
  }

  const createAuthObj = () => {
    if (authStrategy === 'email') {
      return { email: login }
    }
    if (authStrategy === 'mobile_phone_or_member_id') {
      if (showCountryPhoneCodeSelector) {
        // eslint-disable-next-line camelcase
        const mobile_number = login
        // eslint-disable-next-line camelcase
        const isd_code = getCountryCallingCode(country)
        return { isd_code, mobile_number }
      }
      return { external_uid: login }
    }
  }

  const isLoginValid = () => {
    switch (authStrategy) {
      case 'email':
        return isEmailValid()
      case 'mobile_phone_or_member_id':
        return isPhoneNumberOrMemberIdValid()
      default:
        return null
    }
  }

  const isPhoneNumberOrMemberIdValid = () => {
    const regexMemberId = /^[a-zA-z]{3}[0-9]{8}$/g
    if (showCountryPhoneCodeSelector && (login.length < 9 || login.length > 10)) {
      setErrorMessage('Phone number is invalid')
      return false
    } else if (!showCountryPhoneCodeSelector && !regexMemberId.test(login)) {
      setErrorMessage('Member ID is invalid')
      return false
    }
    setErrorMessage('')
    return true
  }

  const identifyLoginType = input => {
    const regexNumbers = /^[0-9\b]+$/
    if (regexNumbers.test(input)) {
      setShowCountryPhoneCodeSelector(true)
    } else {
      setShowCountryPhoneCodeSelector(false)
    }
  }

  const onChangeHandler = value => {
    setLogin(value)
    const regexSpecialCharacters = /[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,'#]/
    if (regexSpecialCharacters.test(value)) {
      setErrorMessage('Special characters are not allowed')
    } else {
      setErrorMessage('')
      identifyLoginType(value)
    }
  }

  const isEmailValid = () => {
    const emailPattern = /\S+@\S+\.\S+/
    if (!emailPattern.test(login)) {
      setErrorMessage('Please type a valid email')
      return false
    }
    setErrorMessage('')
    return true
  }

  const renderCountryPhoneCodeSelector = () => {
    return (
      <React.Fragment>
        <FlagImage countryCode={country} />
        <select className="width-100" value={country} onChange={e => setCountry(e.target.value)}>
          {getCountries().map(country => (
            <option key={country} value={country}>
              {en[country]} +{getCountryCallingCode(country)}
            </option>
          ))}
        </select>
      </React.Fragment>
    )
  }

  return (
    <Modal contentClassName={env.retail.theme} className={containerClass} show={show} backdrop="static">
      <Modal.Header className="px-5 pt-5 border-0 d-flex justify-content-center">
        <Modal.Title className="text-center" as="h3">
          Sign In
          <h5 className="text-danger">Your session has expired. Please login again</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 pb-5 pt-0">
        <form onSubmit={submitForm} className="space-40">
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <div className="form-group">
            <span className="d-flex align-items-start">{authStrategyLabels[authStrategy]}</span>
            <div className="d-flex">
              {showCountryPhoneCodeSelector && renderCountryPhoneCodeSelector()}
              <input
                type="text"
                name="login"
                className="form-control"
                onChange={e => onChangeHandler(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <TextInput
              label="Your password"
              placeholder="Password"
              name="password"
              type="password"
              disabled={isFetching}
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-sm btn-danger btn-block" disabled={isFetching}>
              {isFetching ? <Spinner spinner={false} /> : 'Login'}
            </button>
          </div>
          <div className="form-group text-center">
            <Link to={PASSWORD_RESET_FULL_PATH}>
              <i className="text-muted">Forgot Password?</i>
            </Link>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

SessionExpiredModal.propTypes = {
  errors: PropTypes.object,
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,

  refreshSession: PropTypes.func,
  /** Allows to store data session after sign in {@link WithDataSessionStore} */
  removeDataSession: PropTypes.func,
  saveDataSession: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  const { sessionExpired } = state.user?.auth
  return { show: sessionExpired }
}

export default connect(mapStateToProps, { refreshSession })(WithDataSessionStore(withRouter(SessionExpiredModal)))
