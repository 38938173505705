import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
/** API */
import { getCurrentTradingLimits } from 'api/trading/cw/trading_limits'
import { sell as sellCurrencies } from 'api/trading/cw/currencies'
import { index as countryIndex } from 'api/recipients/cw/countries'

import { accepted } from 'api/trading/cw/customers/transactions'
import { settings as tradingSettings } from 'api/trading/cw/settings'
import { index as getReceiptAccounts } from 'api/trading/cw/receipt_accounts'

/** Actions */
const SET_SETTINGS = 'SET_SETTINGS'
const SET_CURRENCIES = 'SET_CURRENCIES'
const SET_OUTGOING_TRANSFERS = 'SET_OUTGOING_TRANSFERS'
const SET_INCOMING_TRANSFERS = 'SET_INCOMING_TRANSFERS'
const SET_CURRENT_TRADING_LIMIT = 'SET_CURRENT_TRADING_LIMIT'
const SET_CURRENT_RECEIPT_ACCOUNTS = 'SET_CURRENT_RECEIPT_ACCOUNTS'
const SET_CURRENT_PAYMENT_MODE = 'SET_CURRENT_PAYMENT_MODE'
const SET_CURRENT_BENEFICIARY = 'SET_CURRENT_BENEFICIARY'
const SET_CURRENT_RECEIPT_PAYMENT_METHOD = 'SET_CURRENT_RECEIPT_PAYMENT_METHOD'
const SET_CURRENT_COUNTER_CURRENCY = 'SET_CURRENT_COUNTER_CURRENCY'

const setSettings = params => dispatch =>
  tradingSettings(params).then(response => {
    if (response) {
      dispatch({
        payload: response.data,
        type: SET_SETTINGS,
      })
    }
  })

const setCurrencies = () => dispatch =>
  Promise.all([sellCurrencies(), countryIndex({ q: { allow_beneficiaries_eq: true } })]).then(([sell, buy]) => {
    if (sell && buy) {
      dispatch({
        payload: {
          buy: buy.data,
          sell: sell.data,
        },
        type: SET_CURRENCIES,
      })
    }
  })

const setIncomingTransfers = params => async (dispatch, getState) => {
  const old = getState().trading.data.incomingTransfers || []
  if (params.page === 1 && !isEmpty(old)) {
    dispatch({
      payload: [],
      type: SET_INCOMING_TRANSFERS,
    })
  }
  return accepted(params).then(response => {
    if (response) {
      dispatch({
        payload: params.page === 1 ? response.data : [...old, ...response.data],
        type: SET_INCOMING_TRANSFERS,
      })
    }
  })
}

const setOutgoingTransfers = params => async (dispatch, getState) => {
  const old = getState().trading.data.outgoingTransfers || []
  if (params.page === 1 && !isEmpty(old)) {
    dispatch({
      payload: [],
      type: SET_OUTGOING_TRANSFERS,
    })
  }
  return accepted(params).then(response => {
    if (response) {
      dispatch({
        payload: params.page === 1 ? response.data : [...old, ...response.data],
        type: SET_OUTGOING_TRANSFERS,
      })
    }
  })
}

const setCurrentTradingLimit = currencyCode => async dispatch => {
  const response = await getCurrentTradingLimits(currencyCode).catch(error =>
    toast.error(error?.response?.data?.description),
  )
  response && dispatch({ payload: response, type: SET_CURRENT_TRADING_LIMIT })
}

const setCurrentReceiptAccounts = params => async dispatch => {
  const response = await getReceiptAccounts(params).catch(error => toast.error(error?.response?.data?.description))
  response && dispatch({ payload: response?.data?.[0], type: SET_CURRENT_RECEIPT_ACCOUNTS })
  return response
}

const setCurrentPaymentMode = (paymentMode = '', error = '') => dispatch => {
  const payload = error ? { error } : { paymentMode }
  dispatch({ payload, type: SET_CURRENT_PAYMENT_MODE })
}

const setCurrentReceiptPaymentMethod = paymentMethod => dispatch => {
  dispatch({ payload: paymentMethod, type: SET_CURRENT_RECEIPT_PAYMENT_METHOD })
}

const setCurrentCounterCurrency = counterCurrency => dispatch => {
  dispatch({ payload: counterCurrency, type: SET_CURRENT_COUNTER_CURRENCY })
}

const setCurrentBeneficiary = beneficiary => dispatch =>
  dispatch({ payload: beneficiary, type: SET_CURRENT_BENEFICIARY })

export {
  SET_SETTINGS,
  SET_CURRENCIES,
  SET_INCOMING_TRANSFERS,
  SET_OUTGOING_TRANSFERS,
  SET_CURRENT_TRADING_LIMIT,
  SET_CURRENT_RECEIPT_ACCOUNTS,
  SET_CURRENT_PAYMENT_MODE,
  SET_CURRENT_BENEFICIARY,
  SET_CURRENT_RECEIPT_PAYMENT_METHOD,
  SET_CURRENT_COUNTER_CURRENCY,
  setSettings,
  setCurrencies,
  setIncomingTransfers,
  setOutgoingTransfers,
  setCurrentTradingLimit,
  setCurrentReceiptAccounts,
  setCurrentPaymentMode,
  setCurrentBeneficiary,
  setCurrentReceiptPaymentMethod,
  setCurrentCounterCurrency,
}
