import React from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router'
import NotFound from 'routes/shared/not_found'
import New from 'components/pages/retail/transactions/new'
import DebitTransactionStatus from 'components/pages/retail/transactions/new/debit_transaction_status'
import { NEW_PATH, DEBIT_CARD_PATH } from 'routes/paths/private/transactions'
import Route from 'routes/shared/auth_route'

const Routing = ({ match }) => (
  <Switch>
    <Route exact isPrivate path={`${match.path}/${NEW_PATH}`} component={New} />
    <Route exact isPrivate path={`${match.path}/${DEBIT_CARD_PATH}`} component={DebitTransactionStatus} />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
