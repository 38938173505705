import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

const SameCurrencyModal = props => {
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'
  const { onAccept, onDecline, onHide, show } = props
  return (
    <Modal contentClassName={env.retail.theme} className={containerClass} show={show} onHide={onHide}>
      <Modal.Body className="px-5 pb-5">
        <h3>Confirm Action</h3>
        <h5 className="space-20">Would you like to make a same currency payment?</h5>
        <div className="space-40">
          <button className="btn btn-sm btn-primary" onClick={() => onAccept()}>
            Yes
          </button>
          <button className="btn btn-sm btn-default cancel" onClick={() => onDecline()}>
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

SameCurrencyModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default SameCurrencyModal
