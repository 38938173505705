import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'
import { isEmpty } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import renderErrors from 'components/helpers/render_errors'

const ERRORS_KEYS = [
  'description',
  'dealer_rate',
  'exchange_rate',
  'amount',
  'base_currency_amount',
  'counter_currency_amount',
  'status',
  'value_datetime',
  'base',
]
const QuoteFailed = props => {
  const { onHide, show, errors, beneficiary, transaction, customerBankAccount } = props
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'
  return (
    <Modal
      contentClassName={env.retail.theme}
      className={containerClass}
      show={show}
      onHide={onHide}
      data-testid="modal-quote-expired">
      <Modal.Header className="px-5 pt-5 border-0">
        <Modal.Title as={'h3'}>Quote Failed</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 pb-5">
        <div className="row">
          <div className="well well-bordered col-12">
            <div className="form-group">
              <div className="row no-margin">
                {!isEmpty(errors) && (
                  <div>
                    We found the following errors
                    {ERRORS_KEYS.map(key => {
                      if (!errors[key]) {
                        return null
                      }
                      return (
                        <div className="row" key={key}>
                          <div className="alert">{renderErrors(errors[key])}</div>
                        </div>
                      )
                    })}
                    <div className="row">
                      {errors?.balance_before_debit && (
                        <div className="alert">Balance before debit {renderErrors(errors?.balance_before_debit)}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="row no-margin">
                <span className="col-sm-7">Funding Account:</span>
                <span className="col-sm-5 font-bold">
                  {customerBankAccount.bank_account_detail.bank_name || customerBankAccount.account_name}
                </span>
              </div>
            </div>
            <div className="form-group">
              <div className="row no-margin">
                <span className="col-sm-7">Recipient:</span>
                <span className="col-sm-5 font-bold">{beneficiary.full_name}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="row no-margin">
                <span className="col-sm-7">Amount I want to send:</span>
                <span className="col-sm-5 font-bold text-danger">
                  {transaction.base_currency_code} {formatCurrencyAmount(transaction.base_currency_amount)}
                </span>
              </div>
            </div>
            <div className="form-group space-20">
              <div className="row no-margin">
                <span className="col-sm-7">Amount I want recipient to get:</span>
                <span className="col-sm-5 font-bold text-danger">
                  {transaction.counter_currency_code} {formatCurrencyAmount(transaction.counter_currency_amount)}
                </span>
              </div>
            </div>
            <div className="form-group space-20">
              <div className="row no-margin">
                <span className="col-sm-7">Exchange rate:</span>
                <span className="col-sm-5 font-bold text-danger">{transaction.customer_exchange_rate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Link className="btn btn-lg btn-primary" to={TRANSACTION_NEW_FULL_PATH + '-'}>
            Go Back
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

QuoteFailed.propTypes = {
  beneficiary: PropTypes.shape({
    full_name: PropTypes.string,
  }),
  confirmTransaction: PropTypes.func.isRequired,
  customerBankAccount: PropTypes.shape({
    account_name: PropTypes.string,
    bank_account_detail: PropTypes.shape({
      bank_name: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    amount: PropTypes.any,
    balance_before_debit: PropTypes.any,
    base: PropTypes.any,
    base_currency_amount: PropTypes.any,
    counter_currency_amount: PropTypes.any,
    dealer_rate: PropTypes.any,
    description: PropTypes.any,
    exchange_rate: PropTypes.any,
    status: PropTypes.any,
    value_datetime: PropTypes.any,
  }),
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  transaction: PropTypes.shape({
    base_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    base_currency_code: PropTypes.string,
    counter_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter_currency_code: PropTypes.string,
    customer_exchange_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

export default QuoteFailed
