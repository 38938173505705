/* eslint-disable indent */
import React, { useState, useContext, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
import * as Yup from 'yup'
import verifyNationalIdNumber, { isValidDate } from 'south-africa-national-id-validation'
import { userLocalStorage } from 'config/local_storage'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { toast } from 'react-toastify'
/** Actions */
import { updateRiskAssesmentInfo, updateUserDetails, updateSourceOfFunds, updateOccupation } from 'actions/users'
/* API */
import { updateIdentificationInformation } from 'api/accounts/cw/customers'
import { getDocumentTypes } from 'api/dropdowns'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import { TextField, SelectField, CalendarField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { REGISTER_PATH } from 'routes/paths/root'
import { STEP_FOUR_PHYSICAL_ADDRESS } from 'routes/paths/public/register'

// Step 4 section PersonalInfo
export const StepFourPersonalInfo = () => {
  const history = useHistory()
  const [dropdowns, setDropdowns] = useState()
  const dispatch = useDispatch()
  const [serverValidationErrors, setServerValidationErrors] = useState({})

  const { currentCustomerCategory, registerFlow, DropdownFetcher, tempAuthData, getDate } = useContext(
    RegistrationContext,
  )

  const registrationFlow = isEmpty(registerFlow) ? userLocalStorage.get('registerFlow') : registerFlow
  const isNative = currentCustomerCategory?.isNative || userLocalStorage.get('currentCustomerCategory')?.isNative
  const temporaryAuthData = isEmpty(registerFlow) ? userLocalStorage.get('tempAuthData') : tempAuthData

  const inputs = registrationFlow?.step_2?.customer_information.sections.personal_information.inputs
  inputs && DropdownFetcher(inputs, setDropdowns, ['title', 'gender'])

  const riskAssesmentInputs = registrationFlow?.step_3?.account_information.sections.account_information.inputs
  riskAssesmentInputs && DropdownFetcher(riskAssesmentInputs, setDropdowns, ['source_of_funds'])
  riskAssesmentInputs && DropdownFetcher(riskAssesmentInputs, setDropdowns, ['occupation'])

  const fetchDocumentTypes = useCallback(async () => {
    const response = await getDocumentTypes().catch(error => toast.error(error.response?.data.description))
    const documentTypes = response?.find(docType => docType.label === 'South African ID')
    documentTypes && setDropdowns(prevState => ({ ...prevState, documentTypes }))
  }, [])

  useEffect(() => {
    isNative && fetchDocumentTypes()
  }, [fetchDocumentTypes, isNative])

  const validationShape = {
    address: Yup.object().shape({
      first_name: Yup.string()
        .min(1, 'Must be 1 letter or more')
        .max(35, 'Must be 35 letters or less')
        .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed'),
      last_name: Yup.string()
        .min(1, 'Must be 1 letter or more')
        .max(35, 'Must be 35 letters or less')
        .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed'),
      middle_name: Yup.string().matches(/^[a-zA-Z ]+$/, 'Special characters not allowed'),
    }),
  }
  if (isNative) {
    validationShape.id_card = Yup.object().shape({
      number: Yup.string().test('ID Test', 'ID is invalid', value => verifyNationalIdNumber({ number: value || ' ' })),
    })
  }
  const validationSchema = Yup.object().shape(validationShape)

  const handleSAIdChange = (e, { setFieldValue }) => {
    const { value, name } = e.target
    setFieldValue(name, value)
    if (verifyNationalIdNumber({ number: value || ' ' })) {
      const currentYear = new Date().getFullYear().toString()
      // Dob year is only YY, so we need to guess whether the user is born in 2000s or 1900s
      const century = value.substring(0, 2) < currentYear.substring(2, 4) ? '20' : '19'
      const year = `${century}${value.substring(0, 2)}`
      const month = value.substring(2, 4)
      const day = value.substring(4, 6)
      // Check if valid date
      if (isValidDate(`${year}/${month}/${day}`)) {
        setFieldValue('birth_date_year', `${year}-${month}-${day}`)
      }
      const genderParam = parseInt(value.substring(6, 10))
      switch (true) {
        case genderParam >= 0 && genderParam <= 4999:
          return setFieldValue(
            'personal_info.gender',
            dropdowns?.gender.find(gender => gender.label === 'Female')?.value,
          )
        case genderParam >= 5000 && genderParam <= 9999:
          return setFieldValue('personal_info.gender', dropdowns?.gender.find(gender => gender.label === 'Male')?.value)
        default:
          return null
      }
    }
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[2].text} activeStep="Personal information" />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <span className="d-flex align-items-start new-header mb-4">Personal information </span>
              <Formik
                initialValues={{
                  address: {
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                  },
                  birth_date_year: '',

                  id_card: {
                    number: '',
                  },
                  personal_info: {
                    gender: '',
                    title: '',
                  },
                  risk_assesment_information: {
                    sources_of_funds: '',
                  },
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                  const updateIdentificationObj = {
                    id_information: {
                      country_code: 'ZA',
                      id_card_attributes: {
                        identity_type_id: dropdowns?.documentTypes?.value,
                        number: values?.id_card?.number,
                      },
                    },
                  }
                  const dateOfBirth = values.birth_date_year ? getDate(values.birth_date_year) : getDate('--')
                  const updateUserObj = {
                    user: {
                      ...dateOfBirth,
                      address_attributes: values.address,
                      personal_info_attributes: values.personal_info,
                    },
                  }
                  const formData = new FormData()
                  formData.append(
                    'risk_assesment_information[occupation_type]',
                    values?.risk_assesment_information?.occupation || '',
                  )
                  formData.append(
                    'risk_assesment_information[sources_of_funds][]',
                    values?.risk_assesment_information?.sources_of_funds,
                  )
                  // TO DO: This needs to be added in order for the API to work properly. To remove later
                  formData.append('risk_assesment_information[annual_income_range]', '')

                  const updateUserDetailsPromise = dispatch(
                    updateUserDetails(temporaryAuthData?.admin_user_id, updateUserObj, 'application/json'),
                  ).catch(error => {
                    error?.response?.status === 422
                      ? setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                      : toast.error(error?.response?.data?.description)
                  })
                  await updateUserDetailsPromise

                  const updateRiskAssessmentPromise = dispatch(
                    updateRiskAssesmentInfo(
                      temporaryAuthData?.account_number,
                      temporaryAuthData?.admin_user_id,
                      formData,
                    ),
                  ).catch(error => {
                    error?.response?.status === 422
                      ? setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                      : toast.error(error?.response?.data?.description)
                  })
                  await updateRiskAssessmentPromise

                  const updateIdentificationPromise = isNative
                    ? updateIdentificationInformation(
                        temporaryAuthData?.admin_user_id,
                        updateIdentificationObj,
                      ).catch(error =>
                        error.response.status === 422
                          ? setServerValidationErrors(
                              Object.assign(serverValidationErrors, error?.response?.data?.errors),
                            )
                          : toast.error(error?.response?.data?.description),
                      )
                    : Promise.resolve([])
                  await updateIdentificationPromise

                  const result = await Promise.allSettled([
                    updateUserDetailsPromise,
                    updateRiskAssessmentPromise,
                    updateIdentificationPromise,
                  ])
                  !isEmpty(serverValidationErrors) && setStatus(serverValidationErrors)
                  setSubmitting(false)
                  setServerValidationErrors({})
                  result.every(promise => promise.value) &&
                    history.push(`${REGISTER_PATH}/${STEP_FOUR_PHYSICAL_ADDRESS}`)
                }}>
                {formik => (
                  <Form>
                    {inputs?.title?.enabled && (
                      <SelectField
                        label={inputs?.title?.name}
                        theme="superSwift"
                        name={'personal_info.title'}
                        options={dropdowns?.title}
                        required={inputs?.title?.required}
                      />
                    )}
                    {inputs?.first_name?.enabled && (
                      <TextField
                        label={inputs?.first_name?.name}
                        name={'address.first_name'}
                        type={inputs?.first_name?.type}
                        placeholder={inputs?.first_name?.placeholder}
                        required={inputs?.first_name?.required}
                      />
                    )}
                    {inputs?.middle_name?.enabled && (
                      <TextField
                        label={inputs?.middle_name?.name}
                        name={'address.middle_name'}
                        type={inputs?.middle_name?.type}
                        placeholder={inputs?.middle_name?.placeholder}
                        required={inputs?.middle_name?.required}
                      />
                    )}
                    {inputs?.last_name?.enabled && (
                      <TextField
                        label={inputs?.last_name?.name}
                        name={'address.last_name'}
                        type={inputs?.last_name?.type}
                        placeholder={inputs?.last_name?.placeholder}
                        required={inputs?.last_name?.required}
                      />
                    )}
                    {isNative && (
                      <TextField
                        label="South African ID"
                        name={'id_card.number'}
                        onChange={e => handleSAIdChange(e, formik)}
                        required
                      />
                    )}
                    {inputs?.gender?.enabled && (
                      <SelectField
                        label={inputs?.gender?.name}
                        theme="superSwift"
                        name="personal_info.gender"
                        options={dropdowns?.gender}
                        required={inputs?.gender?.required}
                      />
                    )}
                    {inputs?.date_of_birth?.enabled && (
                      <CalendarField
                        label={inputs?.date_of_birth?.name}
                        name="birth_date_year"
                        calendarActiveStartDate={moment(new Date()).subtract(19, 'year').toDate()}
                        calendarMaxDate={moment(new Date()).subtract(18, 'year').toDate()}
                        required={inputs?.date_of_birth?.required}
                      />
                    )}
                    {riskAssesmentInputs?.source_of_funds?.enabled && (
                      <SelectField
                        label={riskAssesmentInputs?.source_of_funds?.name}
                        theme="superSwift"
                        name="risk_assesment_information.sources_of_funds"
                        options={dropdowns?.source_of_funds}
                        required={riskAssesmentInputs?.source_of_funds?.required}
                        onChange={(option, setValue) => {
                          dispatch(updateSourceOfFunds(option))
                          setValue(option.value)
                        }}
                      />
                    )}
                    {riskAssesmentInputs?.occupation?.enabled && (
                      <SelectField
                        label={riskAssesmentInputs?.occupation?.name}
                        theme="superSwift"
                        name="risk_assesment_information.occupation"
                        options={dropdowns?.occupation}
                        required={riskAssesmentInputs?.occupation?.required}
                        onChange={(option, setValue) => {
                          dispatch(updateOccupation(option))
                          setValue(option.value)
                        }}
                      />
                    )}
                    <div className="form-group mt-4">
                      <button disabled={formik.isSubmitting} type="submit" className="ss-btn red w-100 popins-16">
                        {formik.isSubmitting ? <Spinner spinner={false} /> : 'Next'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}
