import { useForm as form } from './useForm'

export const useForm = form

/**
 *
 * @param {Object} fields - fields object with all objects that need to be rendered
 * @param {Object} values - initial values
 * @param {Func} children
 * @param {Func} fieldContainer - function to provide field container
 * Provides option to use hook useForm for class base components
 */
const Form = ({ identificator, initialFields, children, layout = 'horizontal', fieldContainer }) =>
  children(useForm({ fieldContainer, identificator, initialFields, layout }))

export default Form
