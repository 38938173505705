import { ServerErrorActions } from 'actions'
import { combineReducers } from 'redux'

const error = (state = { critical: false, payload: null }, action) => {
  switch (action.type) {
    case ServerErrorActions.CRITICAL:
      return Object.assign({}, state, {
        critical: true,
        payload: action.payload,
      })
    case ServerErrorActions.NOT_FOUND:
      return Object.assign({}, state, {
        critical: false,
        payload: action.payload,
      })
    case ServerErrorActions.INTERNAL_SERVER_ERROR:
      return Object.assign({}, state, {
        critical: false,
        payload: action.payload,
      })
    case ServerErrorActions.CLEAR_ERRORS:
      return Object.assign({}, state, {
        critical: false,
        payload: null,
      })
    default:
      return state
  }
}

const ServerErrorReducers = combineReducers({
  error,
})

export default ServerErrorReducers
