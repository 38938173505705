import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import refreshToken from 'components/utils/refresh_token'
import AllRoutes from 'routes/all_routes'
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import { withRouter } from 'react-router-dom'
import SessionExpiredModal from 'components/base/modals/session_expired'

/**
 * Main Component of the application
 */
class App extends React.Component {
  /**
   * @namespace
   * @property {setTimeout}  state.timeScheduleTimeout  - Timer to refresh token
   */
  state = {
    timeScheduleTimeout: null,
  }

  /**
   * Redirects the user and schedule action to refresh token
   * @see {@link redirectToSignInPage}
   * @see {@link scheduleRefreshToken}
   */
  componentDidMount() {
    const { tokenExpiresAt, token } = this.props
    this.redirectToSignInPage()
    if (tokenExpiresAt && token) {
      this.scheduleRefreshToken(Math.max(0, tokenExpiresAt - Date.now()))
    }
  }

  /**
   * Redirects the user, cancel/schedule action to refresh token
   * @see {@link redirectToSignInPage}
   * @see {@link scheduleRefreshToken}
   */
  componentDidUpdate(prevProps) {
    const { tokenExpiresAt, token } = this.props
    this.redirectToSignInPage()

    if (prevProps.tokenExpiresAt !== tokenExpiresAt && token) {
      this.scheduleRefreshToken(Math.max(0, tokenExpiresAt - Date.now()))
    }

    if (this.state.timeScheduleTimeout && !token) {
      clearTimeout(this.state.timeScheduleTimeout)
    }
  }

  /**
   * schedules a timer to refresh user token
   *
   * @param {number} miliseconds time to be passed to the setTimeout function
   * @see scheduleRefreshToken
   */
  scheduleRefreshToken(miliseconds) {
    clearTimeout(this.state.timeScheduleTimeout)
    this.setState({
      timeScheduleTimeout: setTimeout(() => {
        refreshToken().then(() => {
          this.scheduleRefreshToken(Math.max(0, this.props.tokenExpiresAt - Date.now()))
        })
      }, miliseconds),
    })
  }

  /**
   * Redirects user to sign in page if user visits root path
   */
  redirectToSignInPage = () => {
    const { location, history } = this.props
    if (location.pathname === '/') {
      history.push(SIGN_IN_FULL_PATH)
    }
  }

  /**
   * Generate application routes and verifies if a server error occurred
   */
  render() {
    const { serverError, serverErrorCritical } = this.props
    if (serverError && serverErrorCritical) {
      throw new Error(serverError.message)
    }

    return (
      <React.Fragment>
        <AllRoutes />
        <SessionExpiredModal />
        <ToastContainer />
      </React.Fragment>
    )
  }
}

App.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  /** Provides several attributes to get the current url {@link withRouter} */
  location: PropTypes.object.isRequired,
  /** Server status response (origin: store) */
  serverError: PropTypes.object,
  /** Indicates if the server status response was critical (origin: store) */
  serverErrorCritical: PropTypes.bool,
  /** Token to authenticate user on server side (origin: store) */
  token: PropTypes.string,
  /** Amount of time for the token to expires (origin: store) */
  tokenExpiresAt: PropTypes.number,
}

const mapStateToProps = state => {
  const { server, user } = state
  const { error = {} } = server
  const { tokenInformation } = user.auth
  return {
    serverError: error.payload,
    serverErrorCritical: error.critical,
    token: tokenInformation.token,
    tokenExpiresAt: tokenInformation.expiresIn,
  }
}

export default connect(mapStateToProps, null)(withRouter(App))
