import { toast } from 'react-toastify'
import saveDataSession from 'components/utils/save_data_session'
import store from 'config/create_store'
import { sessionSwitch } from 'api/accounts/cw/users'
import { userLocalStorage } from 'config/local_storage'

const FAIL_MESSAGE = 'Unable to change your account'
const SUCCESS_MESSAGE = 'Account Changed'

const switchAccount = async customerId => {
  try {
    const response = await sessionSwitch(customerId)
    saveDataSession(response.data, store)
    userLocalStorage.set('isAccountSwitched', true)
    toast.success(SUCCESS_MESSAGE)
  } catch (error) {
    toast.error(FAIL_MESSAGE)
    console.error(error)
  }
}

export default switchAccount
