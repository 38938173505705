/* eslint-disable no-unused-vars */
import React from 'react'

import AccountSettings from './account_settings'
import ProfileInformation from './profile_information'
import Limits from './limits'
import ContactInformation from './contact_information'
import IdentificationInformation from './identification'
import Promotions from './promotions'

export const tabs = [
  {
    component: <ProfileInformation />,
    tab: 'profile-information',
    text: 'Profile information',
    to: '/retail/users/profile-information',
  },
  {
    component: <ContactInformation />,
    tab: 'contact-information',
    text: 'Contact Information',
    to: '/retail/users/contact-information',
  },
  // TODO: uncomment after redesign
  /* {
    component: <IdentificationInformation />,
    tab: 'identification-information',
    text: 'Identification',
    to: '/retail/users/identification-information',
  }, */
  {
    component: <AccountSettings />,
    tab: 'security',
    text: 'Security',
    to: '/retail/users/security',
  },
  {
    component: <Limits />,
    tab: 'limits',
    text: 'Limits',
    to: '/retail/users/limits',
  },
  {
    component: <Promotions />,
    tab: 'promotions',
    text: 'Promotions',
    to: '/retail/users/promotions',
  },
]
