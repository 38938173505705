import React from 'react'
import PropTypes from 'prop-types'
import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

export const SimplePopover = withRouter(props => {
  const { children, trigger = ['hover', 'focus'], text, location, placement = 'bottom' } = props
  const containerClass = location.pathname.includes('retail') ? 'retail' : 'wholesale'
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={
        <BootstrapPopover className={containerClass}>
          <BootstrapPopover.Content>{text}</BootstrapPopover.Content>
        </BootstrapPopover>
      }>
      {children}
    </OverlayTrigger>
  )
})

SimplePopover.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string,
  trigger: PropTypes.string,
}
