import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
/* API */
import { getSwiftProviders } from 'api/dropdowns'
/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'
import Spinner from 'components/helpers/spinner'

function SwiftPayoutProvider({ swiftPayoutProvider, onSwiftPayoutProviderChange, errors, beneficiary }) {
  const [swiftPayoutProviders, setSwiftPayoutProviders] = useState([])
  const { currentBeneficiary } = useSelector(({ trading }) => trading.data) || {}
  const { name: swiftPayoutProviderName } =
    useSelector(({ trading }) => trading?.data?.currentBeneficiary?.swift_payout_provider) || {}

  useEffect(() => {
    async function fetchSwiftPayoutProviders() {
      try {
        await getSwiftProviders({
          per_page: 100,
          q: { country_code_eq: beneficiary?.address?.country_code, currency_code_eq: beneficiary?.currency_code },
        }).then(res => {
          setSwiftPayoutProviders(res?.data.map(item => ({ label: item.name, value: item.name })))
        })

        !isEmpty(currentBeneficiary) &&
          onSwiftPayoutProviderChange('swift_payout_provider', currentBeneficiary?.swift_payout_provider?.name)
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }
    beneficiary?.address?.country_code && beneficiary?.currency_code && fetchSwiftPayoutProviders()
  }, [beneficiary?.address?.country_code, beneficiary?.currency_code, beneficiary?.swift_payout_provider?.name])

  useEffect(
    () => swiftPayoutProviderName && onSwiftPayoutProviderChange('swift_payout_provider', swiftPayoutProviderName),
    [swiftPayoutProviders?.length, swiftPayoutProviderName],
  )

  if (swiftPayoutProvider?.swift_payout_provider?.enabled) {
    return (
      <div className="px-0">
        {swiftPayoutProviders?.length ? (
          Object.keys(swiftPayoutProvider).map(input => (
            <div key={input} className="form-group row">
              <div className="col-12">
                <Formik>
                  <div>
                    <SelectField
                      label={swiftPayoutProvider[input]?.name}
                      name="swift_provider"
                      theme="superSwift"
                      /* To change once the API is confirmed */
                      options={swiftPayoutProviders}
                      isLoading={!swiftPayoutProviders?.length}
                      onChange={option => onSwiftPayoutProviderChange('swift_payout_provider', option.value)}
                      /* To change once the API is confirmed */
                      value={swiftPayoutProviders?.find(
                        option =>
                          option.value === beneficiary?.swift_provider?.swift_payout_provider ||
                          option.value === beneficiary?.swift_payout_provider?.name,
                      )}
                      required
                    />
                    {renderErrors(errors['provider.name'])}
                  </div>
                </Formik>
              </div>
            </div>
          ))
        ) : (
          <Spinner text={false} />
        )}
      </div>
    )
  } else {
    return null
  }
}

SwiftPayoutProvider.propTypes = {
  beneficiary: PropTypes.shape({
    address: PropTypes.shape({
      country_code: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    swift_payout_provider: PropTypes.shape({
      name: PropTypes.string,
    }),
    swift_provider: PropTypes.shape({
      swift_payout_provider: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    'provider.name': PropTypes.any,
    'swift_provider.swift_payout_provider': PropTypes.any,
  }),
  onSwiftPayoutProviderChange: PropTypes.func,
  swiftPayoutProvider: PropTypes.shape({
    locale: PropTypes.string,
    name: PropTypes.string,
  }),
  swiftPayoutProviders: PropTypes.arrayOf(PropTypes.object),
}

export default SwiftPayoutProvider
