import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import env from '.env'
/* Base */
import AuthHeader from 'components/base/layouts/auth_header'
/* Utils */
import 'components/base/layouts/main/container/index.scss'

/**
 * Component for two factor authentication to send PIN
 */
const TwoFactorConfirmFormFields = () => (
  <React.Fragment>
    <AuthHeader />
    <div className="content">
      <div className={env.retail?.theme || 'shoprite'}>
        <div className="page-header" />
        <form className="col-md-10 offset-md-1 form-horizontal">
          <div className="page-header">
            <h3>Your phone has been verified!</h3>
          </div>
          <div>
            <span className="font-bold">Enjoy using our service</span>
            <p className="space-10">
              <span>
                Now that your phone has been verified, you're ready to start using the application.Try adding a
                recipient and a funding
              </span>
            </p>
            <span>source before you initiate a transaction, or check our rates on the dashboard.</span>
          </div>
          <div className="space-40">
            <Link to="/users/two-factor-authentication-new-form-fields">
              <input type="submit" value="Go to Dashboard" name="commit" className="btn btn-sm btn-success" />
            </Link>
          </div>
        </form>
      </div>
    </div>
  </React.Fragment>
)

TwoFactorConfirmFormFields.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
}

export default connect()(withRouter(TwoFactorConfirmFormFields))
