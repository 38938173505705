import { useEffect } from 'react'

/** Hook to provide option to know when user clicked outside of element */
export const useOnClickOutside = (refs, callback) => {
  function handleClickOutside(event) {
    refs = Array.isArray(refs) ? refs : [refs]
    const mountedRefs = refs.every(ref => !!ref.current)

    if (mountedRefs) {
      const isSomeRef = refs.every(ref => !ref.current.contains(event.target))

      if (isSomeRef && callback) {
        callback()
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  })
}
