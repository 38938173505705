/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { ReactComponent as CheckMark } from 'assets/svg/valid.svg'
/** API */
import { show as getTransactionDetails } from 'api/trading/cw/transactions'
/** Helpers */
import { transactionMessage } from 'components/helpers/transaction/confirmed_message.js'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import './style.scss'

const ViewTransactionDetails = ({ show, onHide, transaction, currentReceiptAccounts }) => {
  const [{ transactionDetails, ...state }, setState] = useState({
    email: '',
    errors: '',
    loading: false,
    transactionDetails: {},
  })

  useEffect(() => {
    transaction.id
      ? setState({ ...state, transactionDetails: transaction })
      : getTransactionDetails(transaction.id)
          .then(res => setState({ ...state, transactionDetails: res?.data }))
          .catch(() => toast.error('Unable to fetch transaction details'))
  }, [])

  const paymentCost = `${transactionDetails?.base_currency_code} ${formatCurrencyAmount(
    transactionDetails?.base_currency_amount,
  )}`

  const receiptPaymentModeId = transactionDetails?.receipt_payment_mode_id
  const transactionStatus = transactionDetails?.status_detail?.status
  const paymentMethodLabel = transactionMessage(receiptPaymentModeId, transactionStatus)

  return (
    <React.Fragment>
      {!isEmpty(transactionDetails) && (
        <Modal show={show} size={'lg'} onHide={onHide}>
          <Modal.Header className="details-modal-header">
            <div className="details-modal-header-content">
              <CheckMark className="svg-tick" />
              Transaction was successful. Details have been sent to your contact information.
            </div>
          </Modal.Header>
          <Modal.Body className="p-2 font-pop modal-radius">
            <h4 className="details-header">Payment: {transactionDetails.reference_number}</h4>
            <div className="grid-container details-container pt-4 pb-5 px-5">
              <div className="grid-areas">
                <div>You have purchased</div>
                <div className="green">
                  {transactionDetails?.counter_currency_code}{' '}
                  {formatCurrencyAmount(transactionDetails.counter_currency_amount)}
                </div>
                <div>The cost of payment</div>
                <div className="red">{paymentCost}</div>
              </div>
              <div className="mt-3">{paymentMethodLabel}</div>

              {/* <hr className="hr-spacer" />
              <div>
                <p>
                  Please pay <strong>{paymentCost}</strong> to our bank as follows:
                </p>
                <p>{currentReceiptAccounts?.receipt_text}</p>
                <p>
                  <strong>
                    It is very important to include your number {transactionDetails.reference_number} as reference for
                    the payment.
                  </strong>
                </p>
              </div> */}
              <hr className="hr-spacer" />
              <div>
                <p>
                  Once we receive your payment we will release this payment to{' '}
                  <strong>{transactionDetails.beneficiary_names || transactionDetails.beneficiary_names?.[0]}</strong>:
                </p>
                <p>
                  The expected receiving date is {transactionDetails.value_date} providing we have received your{' '}
                  {paymentCost}
                </p>
              </div>
              <hr className="hr-spacer" />
              <ol className="list">
                <li>
                  By confirming the payment you are entering into a contract with Shoprite to sell{' '}
                  {transactionDetails.base_currency_code} and buy {transactionDetails?.counter_currency_code}. Once we
                  receive your {transactionDetails.base_currency_code} as cleared funds the{' '}
                  {transactionDetails?.counter_currency_code} purchased will be released to the beneficiary.
                </li>
                <li>
                  Shoprite’s commission is the total cost charged once your payment request is confirmed. Receiving
                  banks other than Shoprite may impose additional charges.
                </li>
              </ol>

              <button className="btn cta-button" onClick={onHide}>
                Done
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = ({ trading }) => ({
  currentReceiptAccounts: trading?.data?.currentReceiptAccounts,
})

ViewTransactionDetails.propTypes = {
  currentReceiptAccounts: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  transaction: PropTypes.shape({
    base_currency_code: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default connect(mapStateToProps, null)(ViewTransactionDetails)
