import fetchApi from 'components/utils/fetch_api'

const getOzowDetails = (transactionId, data) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/ozow_payments`, { data, method: 'POST' })
}

const getOzowStatus = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}/ozow_payments`)
}

export { getOzowDetails, getOzowStatus }
