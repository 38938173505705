import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useOnClickOutside } from 'components/hooks/useOnClickOutside'
/* Helpers */
import GroupDetails from 'components/base/layouts/auth_header/group-details'
import Footer from 'components/base/layouts/new_footer'
/* Paths */
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import { HOW_IT_WORKS, NEW_FULL_PATH as REGISTER_NEW_FULL_PATH } from 'routes/paths/public/register'
import Logo from 'assets/images/shopritesend/logo.jpg'

/**
 * Header for Registaration Process
 */
const Header = ({ tenantName, logo, title, classContainer, ...props }) => {
  const history = useHistory()
  const [open, setOpen] = useState()
  const menuRef = useRef()
  const buttonRef = useRef()

  useOnClickOutside([buttonRef, menuRef], () => {
    setOpen(false)
  })

  const renderNav = () => (
    <React.Fragment>
      <ul>
        <li id="ss-margin-left">
          <a href={HOW_IT_WORKS}>How it works</a>
        </li>
        <li>
          <Link to={SIGN_IN_FULL_PATH}>Log in</Link>
        </li>
      </ul>
      <button type="button" className="ss-btn red" onClick={() => history.push(REGISTER_NEW_FULL_PATH)}>
        Register
      </button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <GroupDetails />
      <nav className="w-nav w-container bg-white">
        <div className="container-1200 ss-ctn flex-row-middle">
          <div className="ss-logo">
            <a href="https://www.shopritesend.co.za/" title={tenantName}>
              <img src={Logo} alt="app-logo" />
            </a>
          </div>
          <div className="topbar ss-links">{renderNav()}</div>
          <div ref={buttonRef} className={open ? 'ss-burger ss-dark' : 'ss-burger'} onClick={() => setOpen(!open)}>
            <i className="fas fa-bars"></i>
          </div>
        </div>
      </nav>
      <div className={`nav-overlay ${open ? 'show' : ''}`}>
        <div ref={menuRef} className={`nav register-transform popins-16 ss-links ${open ? 'show' : ''}`}>
          {renderNav()}
        </div>
      </div>
      {props.children}
      <Footer />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { tenant } = state.client.data
  const { logo, name } = tenant || {}
  return { logo, tenantName: name }
}

Header.propTypes = {
  children: PropTypes.element,
  classContainer: PropTypes.string,
  logo: PropTypes.shape({
    small_thumbnail_url: PropTypes.string,
  }),
  tenantName: PropTypes.string,
  title: PropTypes.string,
}

export default connect(mapStateToProps, null)(Header)
