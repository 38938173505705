import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import env from '.env'
/* Base */
import AuthHeader from 'components/base/layouts/auth_header'
/* Utils */
import 'components/base/layouts/main/container/index.scss'

/**
 * Component for two factor authentication to send PIN
 */
const TwoFactorNewFormFields = () => {
  /**
   * Gets called when the user submit the form
   *
   * @param {Object} event object returned by form
   */
  const sendPIN = event => {
    event.preventDefault()
  }

  return (
    <React.Fragment>
      <AuthHeader />
      <div className="content">
        <div className={env.retail?.theme || 'shoprite'}>
          <div className="page-header" />
          <form className="col-sm-10 offset-sm-1 form-horizontal" onSubmit={sendPIN}>
            <div className="form-group">
              <div className="page-header">
                <h3>Verification</h3>
              </div>
            </div>
            <div className="form-group">
              <div className="well well-success col-sm-10">
                <span className="font-bold">Your account registration has been received and currently pending.</span>
                <p className="space-10">
                  <span>
                    You can complete the OTP and email verification process now and login and view the status of your
                    account
                  </span>
                </p>
                <p>
                  <span>on the dashboard.</span>
                </p>
              </div>
            </div>
            <div className="form-group space-40">
              <span className="font-bold">Thanks for submitting your application!</span>
              <p className="space-10">
                <span>
                  We'll send you a confirmation email as soon as we're done setting up your account. As an additional
                  measure of
                </span>
              </p>
              <p className="space-10">
                <span>
                  security, we also need to verify the cell phone number you provided us during registration as well as
                  your email
                </span>
              </p>
              <p className="space-10">
                <span>
                  address. You can either do that now by clicking the buttons below, or at your earliest convenience by
                  simply logging in.
                </span>
              </p>
            </div>
            <div className="form-group">
              <span className="font-bold">Verify your phone(note for demo also sent your email)</span>
              <p className="space-10">
                <span>
                  We'll send you a text at the phone number below with a four digit PIN.Confirming that PIN will let us
                  know you are in.
                </span>
              </p>
              <p className="space-10">
                <span>Control of your account and help maintain a high level of security</span>
              </p>
            </div>
            <div className="form-group space-40">
              <Link to="#">
                <input type="submit" value="Send PIN" name="commit" className="btn btn-sm btn-success" />
              </Link>
              <Link className="btn btn-sm no-background" to="#">
                <div className="text-muted">Skip for now</div>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

TwoFactorNewFormFields.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
}

export default connect()(withRouter(TwoFactorNewFormFields))
