import React from 'react'
import PropTypes from 'prop-types'

/**
 * Component for custom Dropdown Option
 */
const CustomOption = props => {
  const { hovered, innerProps, isMulti, data, selectProps, testID, toggleMenu } = props
  const { onChange } = selectProps

  /**
   * Gets called when user click on option from dropdown
   */
  const onOptionClick = data => {
    onChange(data)
    if (!isMulti) {
      toggleMenu()
    }
  }

  /**
   * Renders Option for multiple value dropdown - with checkboxes
   */
  if (isMulti) {
    const values = props.getValue()
    return (
      <div
        {...innerProps}
        className={`select-option_checkbox ${hovered ? ' select-option--hovered' : ''}`}
        onClick={() => onOptionClick(data.value)}
        data-testid={`${testID}-option`}>
        <div className="checkbox checkbox-success">
          <input type="checkbox" name={data.name} checked={values.includes(data.value)} />
          <label htmlFor={data.name}>{data.label}</label>
        </div>
      </div>
    )
  }
  /**
   * Renders Option for single value dropdown
   */
  return (
    <div
      {...innerProps}
      className={`select-option${hovered ? ' select-option--hovered' : ''}`}
      data-testid={`${testID}-option`}
      onClick={() => onOptionClick(data.value)}>
      {data.label}
    </div>
  )
}

CustomOption.propTypes = {
  data: PropTypes.object,
  getValue: PropTypes.func,
  hovered: PropTypes.bool,
  innerProps: PropTypes.any,
  isMulti: PropTypes.bool,
  menuPlacement: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectProps: PropTypes.any,
  setValue: PropTypes.func,
  testID: PropTypes.string,
  toggleMenu: PropTypes.func,
  value: PropTypes.any,
}

export default CustomOption
