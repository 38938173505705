import store from 'config/create_store'
import { setTokenInformation, signOut, sessionExpired } from 'actions/users'
import { isEmpty } from 'lodash'
import history from 'config/create_history'
import { userLocalStorage } from 'config/local_storage'
import { sessionRefresh } from 'api/accounts/cw/users'
import queryUrl from 'components/utils/query_url'
import { SIGN_OUT_FULL_PATH as SSO_SIGN_OUT_FULL_PATH } from 'routes/paths/public/sso'

const NOT_YET_EXPIRED_CODE = 'FRF01003'
const REFRESH_TOKEN_NOT_FOUND = 'FRF01001'
/**
 * Makes a request to refresh the current user's token
 * @returns {Promise}
 */
const refreshToken = () => {
  const token = store.getState().user.auth.tokenInformation.token
  const refreshToken = store.getState().user.auth.tokenInformation.refreshToken
  return new Promise((resolve, reject) => {
    sessionRefresh({ refreshToken, token })
      .then(res => {
        if (res?.data?.token) {
          const response = res.data
          store.dispatch(
            setTokenInformation({
              expiresIn: (response.expires_in + 30) * 1000 + Date.now(),
              refreshToken: response.refresh_token,
              token: response.token,
            }),
          )
          resolve(response.token)
        } else if (NOT_YET_EXPIRED_CODE !== res?.data?.code) {
          signPopUpOnExpiration()
        }
        reject(NOT_YET_EXPIRED_CODE)
      })
      .catch(error => {
        console.error(error)
        const code = error?.response?.data?.code
        if (code !== REFRESH_TOKEN_NOT_FOUND) {
          signPopUpOnExpiration()
        } else if (!isEmpty(store.getState().user.auth.tokenInformation)) {
          store.dispatch(signOut())
        }
        reject(error)
      })
  })
}

const signPopUpOnExpiration = () => {
  const sso = store.getState().user.auth.sso
  if (sso) {
    history.push(
      queryUrl.include(SSO_SIGN_OUT_FULL_PATH, {
        reason: 'timeout',
      }),
    )
  } else {
    userLocalStorage.clearAll()
    store.dispatch(sessionExpired())
  }
}

export default refreshToken
