import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import renderErrors from 'components/helpers/render_errors'

const FundingAccount = ({
  customerBankAccountList = [],
  errors,
  onCustomerBankAccountChange,
  customerBankAccount,
  prefs,
}) => {
  if (prefs?.from_customer_bank_account?.enabled && customerBankAccountList.length > 1) {
    const customerBankAccountOptions = customerBankAccountList.map(cba => ({
      label: cba.bank_account_detail.bank_name || cba.account_name,
      value: cba.id,
    }))

    return (
      <div>
        <div>Select funding account</div>
        <div>
          <Select
            placeholder="Select"
            name="customerBankAccountId"
            options={customerBankAccountOptions}
            value={customerBankAccountOptions.find(cbaOption => cbaOption.value === customerBankAccount.id)}
            onChange={cbaOption => onCustomerBankAccountChange(cbaOption.value)}
          />
          {renderErrors(errors?.amount)}
        </div>
        {renderErrors(errors?.customer_bank_account_id)}
      </div>
    )
  }

  return null
}

FundingAccount.propTypes = {
  customerBankAccount: PropTypes.object,
  customerBankAccountList: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  onCustomerBankAccountChange: PropTypes.func.isRequired,
  prefs: PropTypes.shape({
    from_customer_bank_account: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
}

export default FundingAccount
