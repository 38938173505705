/* eslint-disable handle-callback-err */
import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Checkmark } from 'assets/svg/checkmark.svg'
import './input_file.style.scss'

/**
 * Component for FileInput
 */
export const FileInput = ({ error, className, isValid, bottomText, fileName, ...props }) => {
  return (
    <React.Fragment>
      <div className="fileinput">
        <input
          type="file"
          className={`${isValid ? 'uploaded' : ''} ss-custom-input pr-4 ${className || ''}`}
          {...props}
        />
        <span className={`popins-16 placeholder ${isValid ? 'is-valid' : ''}`}>{fileName || 'No file selected'}</span>
        {bottomText && <div className="mt-1 bottom-file-input-text">{bottomText}</div>}
      </div>
      {!error && isValid && <Checkmark className="mt-4" />}
    </React.Fragment>
  )
}

FileInput.propTypes = {
  bottomText: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fileName: PropTypes.string,
  isValid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

export default FileInput
