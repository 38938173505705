import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const CustomInput = ({ onClick, value }) => (
  <div className="input-group date">
    <div className="input-group-prepend">
      <span className="input-group-text font-bold">
        <i className="fa fa-calendar"></i>
      </span>
    </div>
    <input className="form-control input-lg text-right" value={value} onClick={onClick} />
  </div>
)

CustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
}

const DateInput = props => {
  return <DatePicker {...props} customInput={<CustomInput />} />
}

DateInput.propTypes = {
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
}

export default DateInput
