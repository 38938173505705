import faker from 'faker'

const cardTypeList = ['maestro', 'visa', 'mastercard', 'diners', 'discover', 'amex', 'jcb']

const randomCardType = () => cardTypeList[Math.floor(Math.random() * cardTypeList.length)]

export default [
  {
    card_number: faker.finance.creditCardNumber(),
    card_type: randomCardType(),
  },
  {
    card_number: faker.finance.creditCardNumber(),
    card_type: randomCardType(),
  },
  {
    card_number: faker.finance.creditCardNumber(),
    card_type: randomCardType(),
  },
]
