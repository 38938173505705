import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const registrationSteps = ['Create account', 'Personal information', 'Address details', 'FICA', 'Promotions']

const ProgressBar = ({ title, activeStep }) => {
  const passedSteps = activeStep ? registrationSteps.slice(0, registrationSteps.indexOf(activeStep)) : []

  return (
    <div className="container-1200 flex-align-center">
      <div className={styles.fredoka50}>{title}</div>
      <div className={styles.progressColumnsContainer}>
        {registrationSteps.map(step => (
          <div key={step}>
            <div
              className={`${styles.circle} ${step === activeStep && styles.redBefore} ${
                passedSteps.includes(step) && styles.passedCircle
              }`}>
              {step === activeStep ? (
                <div className={styles.activeCircle} />
              ) : (
                passedSteps.includes(step) && <div className={styles.passedCheckmark} />
              )}
            </div>
            <div>{step}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  activeStep: PropTypes.string,
  title: PropTypes.string,
}

export default ProgressBar
