import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import {
  valueDatePrefs as getValueDatePrefs,
  update as updateSettlementPrefs,
} from 'api/trading/cw/transaction_settlement_prefs'
import renderErrors from 'components/helpers/render_errors'
import DateInput from 'components/helpers/form_fields/date_input'

const ValueDatePrefs = props => {
  const { transactionId, show } = props
  const [errors, setErrors] = useState({})
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())
  const [valueDate, setValueDate] = useState(new Date())
  const location = useLocation()

  const onValueDateChange = value => setValueDate(value)

  useEffect(() => {
    if (transactionId) {
      const data = {
        transaction_settlement_prefs: {
          value_date: {
            day: String(new Date(valueDate).getDate()),
            month: String(new Date(valueDate).getMonth() + 1),
            year: String(new Date(valueDate).getFullYear()),
          },
        },
      }
      updateSettlementPrefs(transactionId, data).catch(error => setErrors(error?.response?.data?.errors))
    }
  }, [valueDate, transactionId])

  useEffect(() => {
    if (transactionId) {
      getValueDatePrefs(transactionId).then(response => {
        const prefs = response.data
        if (prefs.may_change_value_date) {
          const minValueDate = prefs.minimum_value_date
          const maxValueDate = prefs.maximum_value_date
          setMinDate(new Date(minValueDate.year, minValueDate.month - 1, minValueDate.day))
          setMaxDate(new Date(maxValueDate.year, maxValueDate.month - 1, maxValueDate.day))
          setValueDate(new Date(minValueDate.year, minValueDate.month - 1, minValueDate.day))
        }
      })
    }
  }, [transactionId])

  if (!show) {
    return null
  }

  return location.pathname.includes('retail') ? (
    <div className="form-group space-10">
      <div>Select Value Date</div>
      <DateInput
        selected={valueDate}
        onChange={date => onValueDateChange(date)}
        className="form-control"
        minDate={minDate}
        maxDate={maxDate}
      />
      {renderErrors(errors?.value_datetime)}
    </div>
  ) : (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h5 className="font-bold">Value date</h5>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="transaction-content">
            <div className="form-group">
              <div className="col-sm-6">
                <DateInput
                  selected={valueDate}
                  onChange={date => onValueDateChange(date)}
                  className="form-control"
                  minDate={minDate}
                  maxDate={maxDate}
                />
                {renderErrors(errors?.value_datetime)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ValueDatePrefs.propTypes = {
  show: PropTypes.bool.isRequired,
  transactionId: PropTypes.number.isRequired,
}

export default ValueDatePrefs
