export const statusBadgeColors = [
  {
    backgroundColor: '#B5D5F7',
    color: '#2479DE',
    status: 'Open',
  },
  {
    backgroundColor: '#B5D5F7',
    color: '#2479DE',
    status: 'Pending',
  },
  {
    backgroundColor: '#B5D5F7',
    color: '#2479DE',
    status: 'Approved',
  },
  {
    backgroundColor: '#B5D5F7',
    color: '#2479DE',
    status: 'Pending Payment',
  },
  {
    backgroundColor: '#B5D5F7',
    color: '#2479DE',
    status: 'Confirmed',
  },
  {
    backgroundColor: '#B5D5F7',
    color: '#F56313',
    status: 'Partially Settled',
  },
  {
    backgroundColor: '#FBCDB1',
    color: '#F56313',
    status: 'Settled',
  },
  {
    backgroundColor: '#DBBEDD',
    color: '#8A3F93',
    status: 'Released',
  },
  {
    backgroundColor: '#B5D9DF',
    color: '#258D9B',
    status: 'Paid',
  },
  {
    backgroundColor: '#B5D9DF',
    color: '#258D9B',
    status: 'Complete',
  },
  {
    backgroundColor: '#FAB2B3',
    color: '#E70022',
    status: 'Cancelled',
  },
  {
    backgroundColor: '#FAB2B3',
    color: '#E70022',
    status: 'Failed',
  },
  {
    backgroundColor: '#FAB2B3',
    color: '#E70022',
    status: 'Cancellation Requested',
  },
  {
    backgroundColor: '#FAB2B3',
    color: '#E70022',
    status: 'refunded',
  },
]
