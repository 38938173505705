import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextInputMask from '../input_mask'

/**
 * Component for text input
 */
export const TextInput = ({
  className,
  disabled,
  error,
  helpBlock,
  label,
  layout,
  name,
  placeholder,
  onChange,
  required,
  testID,
  type: typeProp,
  value,
  lowerIndent,
}) => {
  const [type, setType] = useState(typeProp)
  const activePath = location.pathname

  /**
   * Gets called when clicked on eye to show/hide password
   */
  const changeType = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  /**
   * Renders eye when input type is password
   */
  const renderEye = () => (
    <i className={`form-control_eye fa ${type === 'password' ? 'fa-eye' : 'fa-eye-slash'}`} onClick={changeType} />
  )

  /**
   * Renders input
   */
  const renderInput = () => (
    <input
      className={`form-control input-sm${error?.error ? ' well-danger' : ''}`}
      data-testid={testID}
      disabled={disabled}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )

  /**
   * Renders InputMask
   */
  const renderInputMask = () => (
    <TextInputMask
      data-testid={testID}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )

  /**
   * Renders TextInput on horizontal layout
   */
  if (layout === 'horizontal') {
    const inputWidth = label ? 7 : 12
    return (
      <div className={`d-flex form-group ${className || ''}`}>
        {label && (
          <div className="col-sm-5">
            <label className={`control-label${required ? ' required' : ''}`} htmlFor={name}>
              {label}
            </label>
          </div>
        )}
        <div className={`col-sm-${inputWidth}`}>
          {type === 'mask' ? renderInputMask() : renderInput()}
          {helpBlock && <div className="help-block">{helpBlock}</div>}
        </div>
      </div>
    )
  }

  /**
   * Renders TextInput on vertical layout
   */
  return (
    <div className={`form-group ${lowerIndent || ''}`} data-testid={`${testID}-container`}>
      {label && (
        <label
          className={`new-color control-label${required ? ' required' : ''} ${
            activePath.includes('/retail/recipients/') ? 'field-label' : ''
          }`}
          htmlFor={name}>
          {label}
        </label>
      )}

      <div className="form-control-container">
        <input
          className={`form-control input-sm ${className || ''}`}
          data-testid={testID}
          type={type}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {typeProp === 'password' && renderEye()}
      </div>
      {helpBlock && <div className="help-block">{helpBlock}</div>}
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  helpBlock: PropTypes.string,
  label: PropTypes.string,
  layout: PropTypes.string,
  lowerIndent: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  testID: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

TextInput.defaultProps = {
  className: '',
  disabled: false,
  layout: 'vertical',
  required: false,
  testID: 'text-input',
  type: 'text',
  value: '',
}

export default TextInput
