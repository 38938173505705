import { isEmpty, toArray } from 'lodash'
import React, { createRef, useCallback, useEffect, useState } from 'react'

import { useOnClickOutside } from 'components/hooks/useOnClickOutside'

import CustomOption from './CustomOption'
import useWindowDimensions from 'components/hooks/useWindowDimensions'

import { BACKSPACE_KEY, DOWN_KEY, SPACE_KEY, UP_KEY } from './'

/**
 * Component for custom Dropdown Menu
 */
const CustomMenu = props => {
  const ref = createRef()

  const { height } = useWindowDimensions()

  const [hovered, setHovered] = useState(-1)
  const [options, setOptions] = useState(props.options ? toArray(props.options) : [])
  const [position, setPosition] = useState('bottom')
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (ref.current?.getBoundingClientRect) {
      const currentPosition = ref.current.getBoundingClientRect().bottom > height ? 'top' : 'bottom'
      if (position !== currentPosition) {
        setPosition(currentPosition)
      }
    }
  }, [height, position, ref])

  useEffect(() => {
    setOptions(prevState => {
      if (!search) {
        return props.options
      }
      const newArray = toArray(props.options).filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      if (newArray) {
        setHovered(-1)
      }
      return newArray
    })
  }, [props.options, search])

  /**
   * Usage of hook when gets clicked outside of Menu
   */
  useOnClickOutside(ref, () => {
    if (props.selectProps.menuIsOpen) {
      props.selectProps.toggleMenu()
      if (hovered !== -1) {
        setHovered(-1)
      }
    }
  })

  const handleUserKeyPress = useCallback(
    event => {
      const { keyCode } = event
      if (props.selectProps.menuIsOpen) {
        if (keyCode === DOWN_KEY && hovered < options.length - 1) {
          setHovered(prevState => prevState + 1)
        } else if (keyCode === UP_KEY && hovered > -1) {
          setHovered(prevState => prevState - 1)
        } else if (keyCode === SPACE_KEY && hovered !== -1) {
          props.selectProps.onChange(options[hovered].value)
        }
      }
    },
    [hovered, options, props.selectProps],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  /**
   * Updates value of search field
   */
  const onTextInputChange = e => {
    setSearch(e.target.value)
  }

  /**
   * Updates value of search field on backspace press
   * as react-select has its own tempering with backspace click, it is disabled in root of select component
   */
  const handleKeyDown = event => {
    if (event.which === BACKSPACE_KEY) {
      setSearch(prevState => prevState.slice(0, prevState.length - 1))
    }
  }

  /**
   * Renders custom menu
   */
  return (
    <div className={`select-menu select-menu--${position}`} ref={ref}>
      {props.selectProps.isSearchable && (
        <div className="m-2">
          <input
            className="w-100 p-2"
            data-testid={`${props.selectProps.testID}-search`}
            name="select-search"
            onChange={onTextInputChange}
            onKeyDown={handleKeyDown}
            value={search}
          />
        </div>
      )}
      {isEmpty(options) ? (
        <div className="select-option">No results found</div>
      ) : (
        options.map((item, index) => (
          <CustomOption
            {...props}
            key={item.value}
            data={item}
            hovered={hovered === index}
            testID={props.selectProps.testID}
            toggleMenu={props.selectProps.toggleMenu}
          />
        ))
      )}
    </div>
  )
}

CustomMenu.propTypes = { ...CustomOption.propTypes }

export default CustomMenu
