import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import formatCurrencyAmount from 'components/utils/format_currency_amount'

const shouldBeBold = ['Account', 'Number', 'Name']

const AmountToPay = ({ prefs, currencyCode, receiptAccount }) => {
  if (
    prefs.transfer_in_settlement_account?.enabled &&
    !isEmpty(receiptAccount) &&
    prefs.transfer_in_settlement_account?.amount_to_pay > 0
  ) {
    return (
      <div>
        <div className="my-1">
          I will pay
          <span className="font-bold mx-1 my-1">
            {currencyCode} {formatCurrencyAmount(prefs.transfer_in_settlement_account?.amount_to_pay)}
          </span>
          to account as follows:
        </div>
        <div className="col-sm-12 no-padding">
          {receiptAccount?.receipt_text?.split(/\n/)?.map(line => (
            <div key={line}>
              {line
                ?.split(/\b/)
                ?.map(word => (shouldBeBold.includes(word) ? <strong key={word}>{word}</strong> : word))}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return null
}

AmountToPay.propTypes = {
  currencyCode: PropTypes.string,
  prefs: PropTypes.shape({
    transfer_in_settlement_account: PropTypes.shape({
      amount_to_pay: PropTypes.string,
      enabled: PropTypes.bool,
    }),
  }),
  receiptAccount: PropTypes.object,
}

export default AmountToPay
