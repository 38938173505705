export default {
  base_currency_amount: '',
  base_currency_code: null,
  counter_currency_amount: '',
  counter_currency_code: null,
  customer_exchange_rate: null,
  initializer: true,
  receipt_amount: null,
  source_of_funds: null,
  transaction_id: null,
}
