import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
/** API */
import { search as searchBankAccountSetting } from 'api/recipients/cw/bank_account_settings'
/* Helpers */
import InputMask from 'components/helpers/form_fields/input_mask'
import { SimplePopover } from 'components/helpers/popover'
import renderErrors from 'components/helpers/render_errors'

const AccountDetails = props => {
  const { errors, beneficiary, onBeneficiaryAccountDetailsChange } = props

  const [bankAccountSettings, setBankAccountSettings] = useState({})

  useEffect(() => {
    if (beneficiary?.address?.country_code) {
      const params = {
        countryCode: beneficiary.address.country_code,
        currencyCode: beneficiary.currency_code,
        paymentMode: beneficiary.renumeration_type_name,
      }
      searchBankAccountSetting(params)
        .then(response => setBankAccountSettings(response?.data))
        .catch(error => toast.error(error?.response?.data?.description))
    }
  }, [beneficiary.currency_code])

  return (
    <React.Fragment>
      {['bic', 'blc', 'account_number'].map(attribute => {
        if (
          (!bankAccountSettings?.[attribute]?.enabled && attribute !== 'account_number') ||
          !bankAccountSettings[attribute]
        ) {
          return null
        }

        return (
          <div key={bankAccountSettings[attribute].label} className="form-group required mb-0 column">
            <div className="col-12 px-0">
              <label className="control-label field-label">
                {bankAccountSettings[attribute].label}
                <SimplePopover id="popover-basic" text={bankAccountSettings[attribute].note}>
                  <i className="ml-2 fa fa-question-circle fa-lg" />
                </SimplePopover>
              </label>
            </div>
            <div className="col-12 px-0">
              <InputMask
                maxLength={bankAccountSettings[attribute].max_length}
                value={beneficiary.account[attribute]}
                onChange={event => onBeneficiaryAccountDetailsChange(attribute, event.target.value)}
              />
              {renderErrors(errors[`account.${attribute}`])}
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

AccountDetails.propTypes = {
  bankAccountSettings: PropTypes.shape({
    account_number: PropTypes.shape({
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    bic: PropTypes.shape({
      enabled: PropTypes.bool,
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    blc: PropTypes.shape({
      enabled: PropTypes.bool,
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    payment_mode: PropTypes.shape({
      local: PropTypes.bool,
    }),
  }),
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      account_number: PropTypes.string,
      bic: PropTypes.string,
      blc: PropTypes.string,
      payment_mode: PropTypes.string,
    }),
    address: PropTypes.shape({
      country_code: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    payment_mode: PropTypes.string,
    payment_reference: PropTypes.string,
    renumeration_type_name: PropTypes.string,
  }),
  errors: PropTypes.shape({
    'account.account_number': PropTypes.any,
    'account.bic': PropTypes.any,
    'account.blc': PropTypes.any,
    'account.payment_mode': PropTypes.any,
    'account.payment_mode_cd': PropTypes.any,
    payment_reference: PropTypes.any,
  }),
  onBeneficiaryAccountDetailsChange: PropTypes.func.isRequired,
}

export default AccountDetails
