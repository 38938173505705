const NO_NETWORK = 'NO_NETWORK'
const NOT_FOUND = 'NOT_FOUND'
const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
const CRITICAL = 'CRITICAL'
const CLEAR_ERRORS = 'CLEAR_ERRORS'

const notFound = payload => ({
  payload,
  type: NOT_FOUND,
})

const internalServerError = payload => ({
  payload,
  type: INTERNAL_SERVER_ERROR,
})

const critical = payload => ({
  payload,
  type: CRITICAL,
})

const noNetwork = payload => ({
  payload,
  type: NO_NETWORK,
})

const clearErrors = {
  type: CLEAR_ERRORS,
}

export {
  notFound,
  noNetwork,
  internalServerError,
  critical,
  clearErrors,
  NO_NETWORK,
  NOT_FOUND,
  INTERNAL_SERVER_ERROR,
  CLEAR_ERRORS,
  CRITICAL,
}
