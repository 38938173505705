import PropTypes from 'prop-types'
import React from 'react'

/**
 * Component for custom ClearIndicator
 */
const CustomClearIndicator = props => (
  <div
    className="select-clear-icon"
    data-testid={`${props.selectProps.testID}-clear-indicator`}
    onClick={() => props.selectProps.onChange([])}>
    &times;
  </div>
)

CustomClearIndicator.propTypes = {
  selectProps: PropTypes.shape({
    onChange: PropTypes.func,
    testID: PropTypes.string,
  }),
}

export default CustomClearIndicator
