import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Switch } from 'react-router'
import MyAccount from 'components/pages/users/account'
import NotFound from 'routes/shared/not_found'
import Route from 'routes/shared/auth_route'

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/:tab`} component={MyAccount} isPrivate />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
