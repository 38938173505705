/* eslint default-case: "off" */
import { initializers, ClientActions } from 'actions'

const initializeCurrentClientMiddleware = _ => next => action => {
  switch (action.type) {
    case initializers.types.INIT_SET_CURRENT_CLIENT:
      return next(ClientActions.setCurrentClientData())
    case initializers.types.INIT_SET_DROPDOWN_COUNTRIES:
      return next(ClientActions.setDropdownCountries())
    case initializers.types.INIT_SET_DROPDOWN_SOF:
      return next(ClientActions.setDropdownSOF())
  }
  return next(action)
}

export default initializeCurrentClientMiddleware
