import * as Yup from 'yup'

/**
 *  Validation schema to pass in as props for the input fields of Contact Information
 *  @see {link} For mobile number validation:  https://www.w3resource.com/javascript/form/phone-no-validation.php
 *  @description For mobile code validation:  Regex matches any string starting with '+' follow up with 3 digits OR a string of 4 digits
 */
const ContactInfoValidation = Yup.object().shape({
  address: Yup.object().shape({
    mobile: Yup.string().matches(/^\d{10}$/, 'Phone number is invalid'),
    mobile_isd_code: Yup.string().matches(/^(\+?\d{1,3}|\d{1,4})$/gm, 'Country code is invalid'),
    postal_address: Yup.object().shape({}),
  }),
  email: Yup.string().email('Invalid email'),
})

export { ContactInfoValidation }
