/* eslint-disable indent */
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { RegistrationContext } from 'context/registration/registrationContext'
import { userLocalStorage } from 'config/local_storage'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
/* API */
import { uploadIdentificationScan } from 'api/accounts/public/registration'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import FileInput from 'components/helpers/form_fields/input_file'
import renderErrors from 'components/helpers/render_errors'
import { ReactComponent as TakePhoto } from 'assets/svg/takePhoto.svg'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { REGISTER_PATH } from 'routes/paths/root'
import { STEP_SIX_PROMOTIONS } from 'routes/paths/public/register'

/**
 * Component for the fifth step of registration process where user should upload scans of his docs
 */
export const StepFive = () => {
  const history = useHistory()
  const [files, setFiles] = useState({ individual_govt_issued_id: null, individual_poa: null })
  const [errors, setErrors] = useState({})
  const [uploading, setUploading] = useState(false)

  const { currentCustomerCategory, registerFlow, tempAuthData } = useContext(RegistrationContext)
  const registrationFlow = isEmpty(registerFlow) ? userLocalStorage.get('registerFlow') : registerFlow
  const sections = registrationFlow.step_4?.identification_form.sections
  const temporaryAuthData = isEmpty(registerFlow) ? userLocalStorage.get('tempAuthData') : tempAuthData
  const customerCategory = isEmpty(registerFlow)
    ? userLocalStorage.get('currentCustomerCategory')
    : currentCustomerCategory

  useEffect(() => {
    if (customerCategory.label === 'Foreign National Four One Seven') {
      setFiles({ ...files, individual_bank_statement: null })
    }
  }, [customerCategory.label])

  const handleFileUpload = e => {
    if (e.target.files.length) {
      setFiles({ ...files, [e.target.name]: e.target.files[0] })

      const name = e.target.files[0].name
      const lastDot = name.lastIndexOf('.')
      const extension = name.substring(lastDot + 1)
      const allowedExtensions = ['JPG', 'JPEG', 'PNG', 'GIF', 'DOC', 'DOCX', 'PDF']
      !allowedExtensions.some(ext => ext === extension.toUpperCase())
        ? setErrors({ ...errors, [e.target.name]: 'The file format is not supported.' })
        : setErrors({ ...errors, [e.target.name]: '' })
    } else {
      setErrors({ ...errors, [e.target.name]: '' })
      setFiles({ ...files, [e.target.name]: null })
    }
  }
  const uploadFile = async (file, referenceId) => {
    return new Promise((resolve, reject) => {
      setUploading(true)
      uploadIdentificationScan(file, temporaryAuthData.account_number, referenceId)
        .then(res => resolve(setUploading(false)))
        .catch(error => {
          setErrors({
            ...errors,
            [referenceId]:
              error?.response?.status === 422
                ? 'The file format is not supported.'
                : error?.message || error?.response?.data?.description || error?.response?.data?.error,
          })

          toast.error(error?.response?.data?.description || error?.message)
          setUploading(false)
          reject(error)
        })
    })
  }

  const submitForm = async () => {
    await Promise.all(Object.keys(files).map(file => !!files[file] && uploadFile(files[file], file))).then(result => {
      if (!uploading && Object.values(errors).every(error => !errors[error])) {
        history.push(`${REGISTER_PATH}/${STEP_SIX_PROMOTIONS}`)
      }
    })
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[2].text} activeStep="FICA" />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <h5 className="d-flex align-items-start new-header mb-4">
                {sections.identification_information.inputs.identification_information.name}
              </h5>

              <p className="popins-16 registration-placeholder">
                As part of our compliance and anti money laundering obligations we are required to view identification
                for all of our client accounts.
              </p>
              <br />
              <h6 className="mb-3 popins-14">You will need to provide: </h6>
              <h6 className="mb-3 popins-16">Photo of your ID Document</h6>
              <ul className="pl-3 popins-16 registration-placeholder">
                <li>Make sure it is not blurry or cut off</li>
                <li>
                  {customerCategory?.isNative
                    ? 'Only SA green ID Book or Smart card allowed'
                    : 'Only passport or temporary resident permit allowed'}
                </li>
              </ul>
              <h6 className="mb-3 popins-16">Photo of yourself holding your ID</h6>
              <ul className="pl-3 popins-16 registration-placeholder">
                <li>
                  <strong>See guidance below</strong>
                </li>
              </ul>
              <h6 className="mb-3 popins-16">You can provide the details via:</h6>
              <ul className="pl-3 popins-16 registration-placeholder">
                <li>By email: info@shopritefinancialservices.co.za</li>
                <li>Whatsapp: 081813 3911</li>
              </ul>
              <hr className="my-4" />
              <div className="form-group">
                {/* TO DO: Temporary fix for demo. To remove regex later */}
                <h6 className="popins-16 body-copy">
                  {sections.govt_issued_document.inputs.individual_govt_issued_id.name.replace(/\(([^)]+)\)/g, '')}
                </h6>
                <label>
                  {customerCategory?.isNative
                    ? 'SA Green bar coded ID book or SA Smart card ID'
                    : 'A copy of your passport or temporary resident permit'}
                </label>
                <div className="d-flex flex-nowrap">
                  <FileInput
                    onChange={handleFileUpload}
                    name="individual_govt_issued_id"
                    fileName={files?.individual_govt_issued_id?.name}
                    isValid={!!files?.individual_govt_issued_id}
                    error={errors?.individual_govt_issued_id === 'The file format is not supported.'}
                    bottomText="JPG, PNG, GIF, DOC, DOCX, PDF only"
                  />
                </div>
                {renderErrors(errors?.individual_govt_issued_id)}
              </div>
              <hr className="my-4" />
              <div className="form-group">
                <h6 className="popins-16 body-copy">Proof of ID: </h6>
                <label htmlFor="proofOfID">Take a photo of you holding your ID/Passport/Permit</label>
                <div className="d-flex flex-nowrap">
                  <FileInput
                    onChange={handleFileUpload}
                    name="individual_poa"
                    fileName={files?.individual_poa?.name}
                    isValid={!!files?.individual_poa}
                    error={errors?.individual_poa === 'The file format is not supported.'}
                    bottomText="JPG, PNG, GIF, DOC, DOCX, PDF only"
                  />
                  <TakePhoto className="float-right" style={{ marginRight: '-20px' }} />
                </div>
                {renderErrors(errors?.individual_poa)}
              </div>
              {customerCategory.label === 'Foreign National Four One Seven' && (
                <React.Fragment>
                  <hr className="my-4" />
                  <div className="form-group">
                    <h6 className="popins-16 body-copyd">Proof of income not older than 3 months</h6>
                    <label htmlFor="proofOfIncome">(Bank statement or Pay slips or Employment letter)</label>
                    <div className="d-flex flex-nowrap">
                      <FileInput
                        onChange={handleFileUpload}
                        name="individual_bank_statement"
                        fileName={files?.individual_bank_statement?.name}
                        isValid={!!files?.individual_bank_statement}
                        error={errors?.individual_bank_statement === 'The file format is not supported.'}
                        bottomText="JPG, PNG, GIF, DOC, DOCX, PDF only"
                      />
                    </div>
                    {renderErrors(errors?.individual_bank_statement)}
                  </div>
                </React.Fragment>
              )}
              <button disabled={uploading} onClick={submitForm} className="ss-btn red w-100 popins-16">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}
