import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
/** API */
import { getCurrentTradingLimits } from 'api/trading/cw/trading_limits'
/** Helpers */
import PaginationTable from 'components/helpers/pagination_table'
/** Utils */
import formatCurrencyAmount from 'components/utils/format_currency_amount'

const renderItem = tradingLimit => (
  <div
    key={tradingLimit.counter + tradingLimit.total_limit}
    columns={1}
    data-testid="trading-limits-item-row"
    className="rows limits">
    <div className="item-container text">
      <div className="placeholder">Name</div>
      <div>{tradingLimit.counter}</div>
    </div>
    <div className="item-container text">
      <div className="placeholder">Limit</div>
      <div>{formatCurrencyAmount(tradingLimit.total_limit)}</div>
    </div>
    <div className="item-container text">
      <div className="placeholder">Remaining</div>
      <div>{formatCurrencyAmount(tradingLimit.remaining_limit)}</div>
    </div>
    {/* <td>{tradingLimit.counter}</td>
    <td>{formatCurrencyAmount(tradingLimit.total_limit)}</td>
    <td>{formatCurrencyAmount(tradingLimit.remaining_limit)}</td> */}
  </div>
)

/** Component to display available/current transfer limits   */
export const Limits = () => {
  const [data, setData] = useState([])
  const { currentTradingLimit } = useSelector(({ trading }) => trading.data)

  const columns = [
    { header: 'Name', type: 'text' },
    { header: 'Limit', type: 'text' },
    { header: 'Remaining', type: 'text' },
  ]

  const getData = async () => {
    const response = isEmpty(currentTradingLimit) ? await getCurrentTradingLimits('ZAR') : currentTradingLimit
    const filteredLimits = Object.keys(response?.limits)
      .map(limit => response?.limits[limit])
      .filter(
        limit => limit.counter === 'Daily Buy' || limit.counter === 'Calendar Month' || limit.counter === 'Yearly',
      )
      .filter(limit => limit.total_limit || limit.remaining_limit)
    setData(filteredLimits)
  }

  return (
    <React.Fragment>
      <PaginationTable
        tableClassName="limits"
        columns={columns}
        data={data}
        getData={getData}
        perPage={5}
        renderItem={renderItem}
        testID="limits"
        title="Limits"
      />
    </React.Fragment>
  )
}

Limits.propTypes = {
  getCurrentTradingLimits: PropTypes.func,
}

export default Limits
