import React, { useState, useEffect } from 'react'
import NewPayment from './new_payment'
import PaymentHistory from './payment_history'

const New = () => {
  const [reload, setReload] = useState(false)
  const [displayPage, setDisplayPage] = useState(1)

  const reloadComponent = () => {
    setReload(true)
  }

  const goNextPage = () => {
    setDisplayPage(2)
  }

  useEffect(() => {
    if (reload) {
      setReload(false)
    }
  }, [reload])

  return (
    <React.Fragment>
      {!reload && <NewPayment displayPage={displayPage} goNextPage={goNextPage} reloadComponent={reloadComponent} />}
      {displayPage === 1 && <PaymentHistory refresh={reload} />}
    </React.Fragment>
  )
}

export default New
