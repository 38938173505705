/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
export const externalCash = {
  enabled: true,
  form_preferences: {
    enabled: true,
    locale: 'helper.external_cash',
    name: 'External Cash',
    required: false,
    sections: {
      payout_location: {
        inputs: {
          state: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'payout.state',
            name: 'Payout State',
            placeholder: 'Select',
            position: null,
            required: false,
          },
          city: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'payout.city',
            name: 'Payout City',
            placeholder: 'Select',
            position: null,
            required: false,
          },
          location: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'payout.location',
            name: 'Payout Location',
            placeholder: 'Select',
            position: null,
            required: false,
          },
        },
      },
      recipient_details: {
        inputs: {
          first_name: {
            display_placeholder_only: false,
            enabled: true,
            name: 'First Name',
            placeholder: 'Enter Beneficiary First Name',
            position: 0,
            required: true,
          },
          last_name: {
            display_placeholder_only: false,
            enabled: true,
            name: 'Last Name',
            placeholder: 'Enter Last Name',
            required: false,
          },
        },
      },
      recipient_identification: {
        inputs: {
          identification_type: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'identification.type',
            name: 'Identification Type',
            placeholder: 'Select',
            position: 0,
            required: false,
          },
          identification_number: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'identification.number',
            name: 'Identification Number',
            placeholder: 'Enter the ID number',
            position: 1,
            required: false,
          },
        },
      },
    },
  },
  note: '',
  renumeration_type: 'external_cash',
  renumeration_type_id: 3,
  value_date: 0,
}

export const mobileWallet = {
  enabled: true,
  form_preferences: {
    enabled: true,
    locale: 'helper.wallet',
    name: 'Wallet',
    required: false,
    sections: {
      mobile_wallet: {
        inputs: {
          mobile_provider: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'mobile_wallet.provider',
            name: 'Mobile Provider',
            placeholder: 'Select',
            position: null,
            required: false,
          },
        },
      },
      recipient_address: {
        inputs: {
          mobile: {
            display_placeholder_only: false,
            enabled: true,
            locale: 'beneficiary.mobile',
            name: 'Mobile',
            placeholder: 'Enter Beneficiary Cellphone Number',
            position: 10,
            required: false,
          },
        },
      },
      recipient_details: {
        inputs: {
          first_name: {
            display_placeholder_only: false,
            enabled: true,
            name: 'First Name',
            placeholder: 'Enter Beneficiary First Name',
            position: 0,
            required: true,
          },
          last_name: {
            display_placeholder_only: false,
            enabled: true,
            name: 'Last Name',
            placeholder: 'Enter Last Name',
            required: false,
          },
        },
      },
    },
  },
  note: '',
  renumeration_type: 'mobile_wallet',
  renumeration_type_id: 4,
  value_date: 0,
}
