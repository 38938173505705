import React from 'react'
import PropTypes from 'prop-types'

export const RadioGroup = ({
  className,
  disabled,
  helpBlock,
  label,
  layout,
  name,
  onChange,
  options,
  required,
  testID,
  type: typeProp,
  value,
}) => {
  const onRadioChange = value => () => {
    onChange(value)
  }

  const renderRadio = () => (
    <div className="d-flex align-items-center">
      {options.map(item => (
        <div key={`${name}-${item.value}`} className="radio radio-inline radio-success">
          <input
            data-testid={`${testID}-${item.value}`}
            type="radio"
            name={name}
            value={item.value}
            id={`${name}-${item.value}`}
            checked={value === item.value}
            onChange={onRadioChange(item.value)}
          />
          <label htmlFor={`${name}-${item.value}`} className="mb-0">
            {item.label}
          </label>
        </div>
      ))}
    </div>
  )

  if (layout === 'horizontal') {
    const groupWidth = label ? 12 : 7
    return (
      <div className={`d-flex form-group ${className || ''}`}>
        {label && (
          <div className="col-sm-5">
            <label className={`control-label${required ? ' required' : ''}`} htmlFor={name}>
              {label}
            </label>
          </div>
        )}
        <div className={`col-sm-${groupWidth} d-flex align-items-center`}>
          {renderRadio()}
          {helpBlock && <div className="help-block">{helpBlock}</div>}
        </div>
      </div>
    )
  }
  return (
    <div className="form-group">
      <label className={`control-label${required ? ' required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div>
        <div className="form-control-container">{renderRadio()}</div>
        {helpBlock && <div className="help-block">{helpBlock}</div>}
      </div>
    </div>
  )
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helpBlock: PropTypes.string,
  label: PropTypes.string.isRequired,
  layout: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  required: PropTypes.bool,
  testID: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

RadioGroup.defaultProps = {
  className: '',
  disabled: false,
  layout: 'vertical',
  options: [],
  required: false,
  testID: '',
  type: 'text',
  value: '',
}

export default RadioGroup
