import React from 'react'
import PropTypes from 'prop-types'

/**
 * Shows a loading screen in form of bars
 * @param {{className: string}} object
 * @returns {Component}
 */
const Skeleton = ({ className = '' }) => (
  <div className={`skeleton ${className}`}>
    <p />
    <p />
  </div>
)

Skeleton.propTypes = {
  className: PropTypes.string,
}

/**
 * Shows a loading screen in form of bars but bigger (Mostly use for card forms components)
 * @param {{ text: string, className: string}} object
 * @returns {Component}
 */
const SkeletonPanel = ({ text = '', className = '' }) => (
  <div className={className}>
    <div className="panel panel-default skeleton">
      <div className="panel-heading">
        <h5 className="font-bold">{text}</h5>
      </div>
      <div className="panel-body">
        <p></p>
        <p></p>
      </div>
    </div>
  </div>
)

SkeletonPanel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}

export { Skeleton, SkeletonPanel }
