import fetchApi from 'components/utils/fetch_api'

const valueDatePrefs = transactionId => {
  return fetchApi(`trading/cw/transaction_settlement_prefs/${transactionId}/value_date_prefs`)
}

const update = (transactionId, data) => {
  return fetchApi(`trading/cw/transaction_settlement_prefs/${transactionId}`, { data, method: 'PUT' })
}

const show = transactionId => {
  return fetchApi(`trading/cw/transaction_settlement_prefs/${transactionId}`)
}

const getPaymentModes = currencyCode => {
  return fetchApi('trading/cw/transaction_settlement_prefs/new', {
    params: { transaction_settlement_prefs: { base_currency_code: currencyCode } },
  }).then(res => res?.data)
}

export { valueDatePrefs, update, show, getPaymentModes }
