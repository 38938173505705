import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import _ from 'lodash'
/* Actions */
/* API */
import { sessionsDelete as boqSessionsDelete } from 'api/boq'
/* Base */
/* Helpers */
import Spinner from 'components/helpers/spinner'
/* Utils */
import WithDataSessionStore from 'components/HOC/with_data_session_store'
import queryUrl from 'components/utils/query_url'
/* Paths */

const FAIL_MESSAGE = 'Failed to logout to IB'

class SsoSignOut extends React.Component {
  state = {
    ssoToken: null,
  }

  formRef = React.createRef()

  async componentDidMount() {
    const {
      removeDataSession,
      history: { location },
    } = this.props
    const springBackReason = queryUrl.extract(location.search).get('reason')
    try {
      if (env.tenant.uid !== 'kiwibank') {
        const result = await boqSessionsDelete({ springBackReason })
        if (_.get(result, 'data.sso_token')) {
          this.setState({
            ssoToken: result.data.sso_token,
          })
        } else {
          throw Error
        }
      }
      removeDataSession()
      this.formRef.current.submit()
    } catch (error) {
      const message = error && error.message
      toast.error(message || FAIL_MESSAGE)
      removeDataSession()
      this.props.history.push('/')
    }
  }

  render() {
    const formAction = env.springBack.url
    const formMethod = env.springBack.method || 'POST'
    const { ssoToken } = this.state
    return (
      <form ref={this.formRef} action={formAction} method={formMethod}>
        {ssoToken ? (
          <React.Fragment>
            <input type="hidden" name="SsoToken" value={ssoToken} />
            <Spinner>Redirecting...</Spinner>
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </form>
    )
  }
}

SsoSignOut.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  /** Allows to store data session after sign in {@link WithDataSessionStore} */
  removeDataSession: PropTypes.func.isRequired,
}

export default WithDataSessionStore(withRouter(SsoSignOut))
