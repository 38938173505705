/* eslint react-hooks/exhaustive-deps: 0 */
import env from '.env'
import React, { useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { toast } from 'react-toastify'
/* Actions */
/* API */
import { sessions as boqSessions } from 'api/boq'
import { sessions as kbSessions } from 'api/kb/users'
/* Base */
/* Helpers */
import Spinner from 'components/helpers/spinner'
/* Utils */
import WithDataSessionStore from 'components/HOC/with_data_session_store'
import queryUrl from 'components/utils/query_url'
/* Paths */
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import { ERROR_FULL_PATH as SSO_ERROR_FULL_PATH } from 'routes/paths/public/sso'

const FAIL_MESSAGE = 'Failed to sign in'

/**
 * Component to allow user to sign in into the app via SSO
 */
const SsoSignIn = props => {
  const url = useLocation()

  useEffect(() => {
    const { history, saveDataSession } = props
    const ssoToken = queryUrl.extract(url.search).get('SsoToken')

    /**
     * It's used to store data session and redirect user to proper page
     * Note: redirection after sign in is handled automatically by routes
     * @see src/routes/shared/auth_route/index.jsx For more information
     */
    const handleSignIn = response => {
      saveDataSession({ ...response, sso: true })
    }

    const printErrorMessage = error => {
      const message = _.get(error, 'message')
      toast.error(message || FAIL_MESSAGE)
    }

    if (!ssoToken) {
      history.push(SIGN_IN_FULL_PATH)
      return
    }

    if (env.tenant.uid === 'kiwibank') {
      kbSessions({ ssoToken })
        .then(response => handleSignIn(response.data))
        .catch(error => {
          printErrorMessage(error)
          history.push(SSO_ERROR_FULL_PATH)
        })
    } else {
      boqSessions({ ssoToken })
        .then(response => handleSignIn(response.data))
        .catch(error => {
          printErrorMessage(error)
          history.push(SIGN_IN_FULL_PATH)
        })
    }
  }, [])

  return <Spinner />
}

SsoSignIn.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  /** Allows to store data session after sign in {@link WithDataSessionStore} */
  saveDataSession: PropTypes.func.isRequired,
}

export default WithDataSessionStore(withRouter(SsoSignIn))
