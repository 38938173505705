const amountToPayFromAvailableFund = prefs => {
  let amountToPayFromDaf = 0
  let amountToPayFromHf = 0
  if (prefs?.from_deposited_funds?.enabled) {
    amountToPayFromDaf = prefs.from_deposited_funds.amount_to_pay
  }
  if (prefs?.from_held_funds?.enabled) {
    amountToPayFromHf = prefs.from_held_funds.amount_to_pay
  }

  return amountToPayFromDaf + amountToPayFromHf
}

export default amountToPayFromAvailableFund
