import env from '.env'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
/* Helpers */
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import Spinner from 'components/helpers/spinner'
import { MobileWalletInfo } from './shared'
import BeneficiarySettings from 'components/pages/retail/transactions/new/new_payment/shared/beneficiary_settings'
import validChecked from 'assets/svg/white_check_mark.svg'
import style from './style.module.scss'
import { transactionMessage } from 'components/helpers/transaction/confirmed_message.js'
import { googleTagManagerEvent } from 'components/utils/google_tag_manager'

const TransactionConfirmed = props => {
  const { show, onHide, beneficiary, beneficiaryTransaction, transactionDetails } = props

  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'
  const beneficiaryType = () => {
    switch (beneficiary.renumeration_type_name) {
      case 'Mobile Wallet':
        return <MobileWalletInfo beneficiary={beneficiary} />
      case 'SWIFT':
        return <BeneficiarySettings beneficiary={beneficiary} />
      case 'Local':
        return <BeneficiarySettings beneficiary={beneficiary} />

      default:
        return <span></span>
    }
  }

  // Google tag manager transfer event
  const userDetails = useSelector(state => state?.user?.data?.userDetails)
  const currentPaymentMode = useSelector(state => state?.trading?.data?.currentPaymentMode?.paymentMode)

  useEffect(() => {
    googleTagManagerEvent({
      beneficiary,
      currentPaymentMode,
      transactionDetails,
      userDetails,
    })
  })

  if (!transactionDetails) {
    return (
      <Modal contentClassName={env.retail.theme} className={containerClass} show={show} onHide={onHide} size={'lg'}>
        <Modal.Header className="px-5 pt-5 border-0">
          <Modal.Title>Transfer Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5 well-dark d-flex justify-content-center">
          <Spinner />
        </Modal.Body>
      </Modal>
    )
  }

  const receiptPaymentModeId = transactionDetails?.receipt_payment_mode_id
  const transactionStatus = transactionDetails?.status_detail?.status
  const paymentMethodLabel = transactionMessage(receiptPaymentModeId, transactionStatus)

  return (
    <Modal
      backdrop="static"
      contentClassName={env.retail.theme}
      className={containerClass + ' bene-modal confirmed-modal'}
      show={show}
      onHide={onHide}
      size={'lg'}>
      <Modal.Body
        className="well-dark d-flex flex-column justify-content-center p-0"
        style={{ backgroundColor: '#fff' }}
        data-testid="modal-transaction-confirmed">
        <div className={style.success}>
          <div className={style.successContent}>
            <img src={validChecked} alt="check" width="16" height="16" />
            Transaction was successful. Details have been sent to your contact information.
          </div>
        </div>
        <div className="col-12 justify-content-center content-body">
          <h2 className="heading confirmation-header">Payment: {transactionDetails?.reference_number}</h2>
          <div className={`${style.modalContent} w-100`}>
            <div className="well well-bordered col-12 px-0 py-0 my-0">
              <div className="form-group">
                <div className="row no-margin">
                  <span className="col-sm-12">
                    You have purchased{' '}
                    <span data-testid="payment-amount" className={style.green}>
                      {formatCurrencyAmount(transactionDetails?.counter_currency_amount)}{' '}
                      {transactionDetails?.counter_currency_code}
                    </span>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="row no-margin">
                  <span className="col-sm-12">
                    The cost of payment is{' '}
                    <span className={style.red}>
                      {formatCurrencyAmount(transactionDetails?.receipt_amount)}{' '}
                      {transactionDetails?.base_currency_code}
                    </span>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="row no-margin">{paymentMethodLabel}</div>
              </div>

              <div className="divider"></div>

              <div className="form-group mb-0">
                <div className="row no-margin">
                  <p className="col-sm-12">
                    Once we receive your payment we will release this payment to{' '}
                    <strong data-testid="recipient">{beneficiary?.address?.name}:</strong>
                  </p>
                  <p className="col-sm-12">{beneficiaryType()}</p>
                  <p className="col-sm-12 py-0 mb-0">
                    The expected receiving date is <strong>{beneficiaryTransaction?.value_date}</strong> providing we
                    have received your {transactionDetails?.base_currency_code}{' '}
                    {formatCurrencyAmount(transactionDetails?.receipt_amount)}
                  </p>
                </div>
              </div>
            </div>
            <div className="divider"></div>
          </div>

          <Modal.Footer className={`px-0 mx-0 py-0 border-0 bg-white justify-content-start ${style.footer}`}>
            <p>
              1. By Confirming the payment you are entering into a contract with Shoprite to sell{' '}
              <strong>{transactionDetails?.base_currency_code}</strong> and buy{' '}
              <strong>{transactionDetails?.counter_currency_code}</strong>. Once we receive your{' '}
              {transactionDetails?.base_currency_code} as cleared funds the {transactionDetails?.counter_currency_code}{' '}
              purchased will be released to the beneficiary.
            </p>
            <p>
              2. Shoprite's commission is the total cost charged by Shoprite once your payment request is confirmed.
              Receiving banks other than Shoprite may impose additional charges.
            </p>
          </Modal.Footer>

          <div className="col-12 d-flex justify-content-start px-0 mt-4">
            <a className="cta-button w-button" onClick={onHide} data-testid="finish-confirmed">
              Done
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => {
  const { payOutLocationOptions } = state.client.data
  return {
    payOutLocationOptions,
  }
}

TransactionConfirmed.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      account_number: PropTypes.string,
      bic: PropTypes.string,
    }),
    address: PropTypes.shape({
      mobile: PropTypes.string,
      mobile_isd_code: PropTypes.string,
      name: PropTypes.string,
    }),
    full_name: PropTypes.string,
    renumeration_type_name: PropTypes.string,
    wallet_provider: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  beneficiaryTransaction: PropTypes.shape({
    value_date: PropTypes.any,
  }),
  cashExternalAddress: PropTypes.object,
  onHide: PropTypes.any,
  payOutLocationOptions: PropTypes.array,
  show: PropTypes.any,
  transactionDetails: PropTypes.shape({
    base_currency_amount: PropTypes.string,
    base_currency_code: PropTypes.string,
    counter_currency_amount: PropTypes.string,
    counter_currency_code: PropTypes.string,
    fee: PropTypes.string,
    funding_source: PropTypes.shape({
      label: PropTypes.string,
    }),
    receipt_amount: PropTypes.string,
    receipt_payment_mode_id: PropTypes.number,
    reference_number: PropTypes.any,
    status_detail: PropTypes.any,
    transaction_id: PropTypes.any,
    value_datetime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }),
}

export default connect(mapStateToProps)(TransactionConfirmed)
