import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik } from 'formik'
/** Helpers */
import renderErrors from 'components/helpers/render_errors'
import FlagImage from 'components/helpers/flag_image'
import { SelectField } from 'components/helpers/formik_fields'
/** Shared */
import './style.scss'
import { userLocalStorage } from 'config/local_storage'

class Beneficiary extends Component {
  state = {
    errors: {},
  }

  currentLocale = () => {
    const { transaction, tradingCurrencies } = this.props
    const currency = tradingCurrencies.sell.find(ccy => ccy.currency_code === transaction.counter_currency_code)
    return currency?.country_code
  }

  balanceLabel = () => {
    const { transaction, customerBankAccount } = this.props
    const amount = transaction.counter_currency_amount
    const balance = customerBankAccount.balance - parseFloat(amount)
    if (balance > 0) {
      return `Remaining Funds ${balance}`
    }
    return 'Insufficient Available Funds'
  }

  shouldDisableBeneficiaryOption = beneficiary => {
    const { customerBankAccount, transaction, oneToOneDealAllowed } = this.props
    if (oneToOneDealAllowed()) {
      if (customerBankAccount.use_as_beneficiary) {
        return String(beneficiary.id) === String(beneficiary.linked_cba_id)
      }
      return false
    }

    return beneficiary.currency_code === transaction.base_currency_code
  }

  beneficiaryType = type => {
    switch (type) {
      case 'Cash External Alt':
        return 'CASH Convenience'
      case 'Cash External':
        return 'CASH'
      case 'Cash Internal':
        return 'CASH'
      case 'Mobile Wallet':
        return 'MOBILE WALLET'
      default:
        return 'BANK'
    }
  }

  beneficiaryOptions = () => {
    const { beneficiaryList, currentCounterCurrency } = this.props
    const filteredBeneficiaries = beneficiaryList.filter(
      beneficiary => beneficiary?.address?.country_code === currentCounterCurrency?.counterCurrencyCountryCode,
    )

    // TODO: temporarily removing Create/edit beneficiary option
    // return [{ label: 'Create New Beneficiary', value: 'newBeneficiary' }].concat(
    return filteredBeneficiaries.map(beneficiary => {
      return {
        filterValue: `${beneficiary?.country_code} ${beneficiary?.full_name} ${beneficiary.renumeration_type_name}`,
        isDisabled: this.shouldDisableBeneficiaryOption(beneficiary),
        label: (
          <div>
            <div className="select-container">
              <div className="select__control select-div">
                <div className="select__value-container">
                  <span className="fullName">{beneficiary.full_name}</span>
                </div>
              </div>
              <div className="select__menu">
                <div className="select__menu-list">
                  <div className="select__option" onClick={() => userLocalStorage.remove('currentRecipient')}>
                    <div className="container">
                      <div className="row">
                        <div>{<FlagImage countryCode={beneficiary?.address?.country_code} />}</div>
                        <div className="nameAndMethod">
                          <span className="fullName">{beneficiary.full_name}</span>
                          <br />
                          <span>
                            {beneficiary.country_code} {this.beneficiaryType(beneficiary.renumeration_type_name)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
        value: beneficiary.id,
      }
    })
    // )
  }

  render() {
    const {
      beneficiary,
      errors,
      isBeneficiaryListLoading,
      setBeneficiaryId,
      setBeneficiaryTransactionReference,
      fetching,
    } = this.props
    const beneficiaryOptions = this.beneficiaryOptions()

    return (
      <div data-testid="beneficiary-information">
        <div>
          <Formik>
            <SelectField
              label="Select existing beneficiary"
              placeholder="Select"
              options={beneficiaryOptions}
              name="counter_currency_code"
              id="beneficiaries-select"
              className="select-input"
              isLoading={isBeneficiaryListLoading || fetching.initialLoad}
              onChange={beneficiaryOption => {
                setBeneficiaryId(beneficiaryOption.value)
              }}
              value={beneficiaryOptions.find(option => option.value === beneficiary.id)}
              lowerIndent="my-0"
              filterOption={({ data }, value) => data.filterValue.toLocaleLowerCase().includes(value)}
            />
          </Formik>
          {renderErrors(errors?.beneficiary_id)}
          {renderErrors(errors?.amount)}
        </div>

        <div className="form-group my-0">
          <label className="control-label field-label">Message for recipient (optional)</label>
          <input
            onBlur={event => setBeneficiaryTransactionReference(event.target.value)}
            className="form-control w-input"
            placeholder="Add message"
          />
          {renderErrors(errors?.reference)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { currencies, currentCounterCurrency } = state.trading.data
  const { tenant } = state.client.data
  return {
    currentCounterCurrency,
    isApplicationBank: tenant?.is_bank,
    tradingCurrencies: currencies,
  }
}

Beneficiary.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      bank_name: PropTypes.string,
    }),
    country_code: PropTypes.string,
    currency_code: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renumeration_type_name: PropTypes.string,
  }),
  beneficiaryList: PropTypes.arrayOf(
    PropTypes.shape({
      country_code: PropTypes.string,
      currency_code: PropTypes.string,
      full_name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      linked_cba_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  currentCounterCurrency: PropTypes.shape({
    counterCurrencyCode: PropTypes.string,
    counterCurrencyCountryCode: PropTypes.string,
  }),
  customerBankAccount: PropTypes.shape({
    balance: PropTypes.number,
    use_as_beneficiary: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    amount: PropTypes.any,
    'beneficary_payout_location.error': PropTypes.any,
    beneficiary_id: PropTypes.any,
    counter_currency_amount: PropTypes.any,
    reference: PropTypes.any,
  }),
  fetching: PropTypes.shape({
    initialLoad: PropTypes.bool,
  }),
  isBeneficiaryListLoading: PropTypes.bool,
  oneToOneDealAllowed: PropTypes.func.isRequired,
  setBeneficiaryId: PropTypes.func.isRequired,
  setBeneficiaryTransactionReference: PropTypes.func.isRequired,
  tradingCurrencies: PropTypes.shape({
    sell: PropTypes.arrayOf(
      PropTypes.shape({
        currency_code: PropTypes.string,
      }),
    ),
  }),
  transaction: PropTypes.shape({
    base_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    base_currency_code: PropTypes.string,
    counter_currency_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    counter_currency_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Beneficiary)
