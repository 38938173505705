/* eslint default-case: "off" */
import { initializers } from 'actions'
import { userLocalStorage } from 'config/local_storage'
import saveDataSession from 'components/utils/save_data_session'

const initializeCurrentUserMiddleware = store => next => action => {
  const userData = userLocalStorage.get('data')
  switch (action.type) {
    case initializers.types.INIT_SET_CURRENT_USER_DATA:
      if (userData) {
        saveDataSession(userData, store)
        return next(action)
      }
      break
  }

  return next(action)
}

export default initializeCurrentUserMiddleware
