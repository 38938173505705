import * as Yup from 'yup'

const passwordSchema = Yup.string()
  .matches(
    /^(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])(?=.*[A-Z])[-!$%^&*()_+|~=`{}[:;<>?,.@#\]0-9a-zA-Z].{7,}$/,
    'Password is invalid',
  )
  .required('Invalid Password')

export { passwordSchema }
