import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { search as searchBankAccountSetting } from 'api/recipients/cw/bank_account_settings'
import useDeepEffect from 'components/hooks/use_deep_effect'

const CustomerBankAccountSettings = props => {
  const { customerBankAccount } = props
  const bankAccountDetail = customerBankAccount?.bank_account_detail || {}
  const [customerBankAccountSettings, setCustomerBankAccountSettings] = useState({})

  useDeepEffect(() => {
    const params = {
      countryCode: customerBankAccount.bank_account_detail?.bank_address?.country_code,
      currencyCode: customerBankAccount.currency_code,
      paymentMode: customerBankAccount.bank_account_detail?.payment_mode,
    }
    searchBankAccountSetting(params).then(response => {
      const bankAccountSettings = response.data
      if (bankAccountSettings.blc?.enabled) {
        bankAccountSettings.bic.enabled = false
      }
      setCustomerBankAccountSettings(bankAccountSettings)
    })
  }, [customerBankAccount])

  if (isEmpty(customerBankAccountSettings)) {
    return null
  }

  return (
    <React.Fragment>
      {!customerBankAccount.use_as_beneficiary && (
        <React.Fragment>
          {customerBankAccountSettings.bic?.enabled && (
            <div className="mt-1">
              <label>{bankAccountDetail.bic_label}:</label>
              <span className="font-bold">{bankAccountDetail.bic}</span>
            </div>
          )}
          {customerBankAccountSettings.blc?.enabled && (
            <div className="mt-1">
              <label>{bankAccountDetail.blc_label}:</label>
              <span className="font-bold">{bankAccountDetail.blc}</span>
            </div>
          )}
        </React.Fragment>
      )}
      <div className="mt-1">
        <label>{bankAccountDetail.account_number_label}:</label>
        <span className="font-bold">{bankAccountDetail.account_number}</span>
      </div>
    </React.Fragment>
  )
}

CustomerBankAccountSettings.propTypes = {
  customerBankAccount: PropTypes.shape({
    bank_account_detail: PropTypes.shape({
      account_number: PropTypes.string,
      account_number_label: PropTypes.string,
      bank_address: PropTypes.object,
      bic: PropTypes.string,
      bic_label: PropTypes.string,
      blc: PropTypes.string,
      blc_label: PropTypes.string,
      payment_mode: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    use_as_beneficiary: PropTypes.bool,
  }),
}

export default CustomerBankAccountSettings
