import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import WithCurrentUser from 'components/HOC/with_current_user'
/* Utils */
import switchAccount from 'components/utils/switch_account'
import AuthorizationRedirectionPath from 'components/utils/authorization_redirection_path'

const accountTypesIcons = {
  association: 'fa-users',
  'co-operative': 'fa-bank',
  company: 'fa-briefcase',
  individual: 'fa-user',
  partnership: 'fa-link',
  trust: 'fa-shield',
}

/**
 * Component wich renders Select-account page right after login conditionaly, depending on linkedAccounts.
 * If Email is also associated with few account types - this component gives the User possibility to choose
 * wich account type the User will be interacting
 */
const SwitchAccount = ({ currentUser: { linkedAccounts = [] } }) => {
  /**
   * @namespace
   * @property {Object} selectedValue - linkedAccount selected by the User
   */
  const [selectedValue, setSelectedValue] = useState(linkedAccounts[0])
  const history = useHistory()
  const formattedLinkedAccount = useMemo(() => {
    const filteredAccounts = {}
    Object.keys(accountTypesIcons).map(
      accountType =>
        (filteredAccounts[accountType] = linkedAccounts.filter(
          account => account.account_type.toLowerCase() === accountType,
        )),
    )
    return filteredAccounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(linkedAccounts)])

  const setSession = async () => {
    await switchAccount(selectedValue.account_number)
    history.push(AuthorizationRedirectionPath())
  }
  /**
   * Gets called when the user selects the linkedAccount
   *
   * @param {Object} event object returned by Form.Check radio type
   */
  const handleAccountClick = account => {
    if (account.account_number !== selectedValue.account_number) {
      setSelectedValue(account)
    }
  }

  useEffect(() => {
    if (!selectedValue && linkedAccounts.length) {
      setSelectedValue(linkedAccounts[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedAccounts.length])

  if (linkedAccounts.length === 0) {
    return null
  }

  return (
    <section className="row">
      <div className="col-12">
        <div className="well page-header">
          <h3>Select Your Account</h3>
          <span>
            Your login is associated with multiple accounts, please select the account you wish to represent to proceed.
            You may change to another account anytime during your session
          </span>
        </div>
      </div>
      <div className="col-12 row">
        {isEmpty(linkedAccounts) ? (
          <Spinner />
        ) : (
          Object.keys(accountTypesIcons).map((accountType, index) => {
            return (
              <div key={accountType} className={linkedAccounts.length > 1 ? 'col-6' : 'col-12'}>
                <div className="col-12 well row">
                  <div className="col-5 text-center text-capitalize">
                    <span className="fa-stack fa-2x">
                      <i className={`${accountTypesIcons[accountType]} fa fa-stack-2x`} />
                    </span>
                    <h3>{accountType}</h3>
                  </div>
                  {formattedLinkedAccount[accountType].map(account => (
                    <div className="col-7" key={account.account_number}>
                      <div className="radio radio-success">
                        <Form.Check
                          data-testid={`checkbox-account-${index}`}
                          type="radio"
                          checked={selectedValue.account_number === account.account_number}
                          onChange={() => handleAccountClick(account)}
                          label={account.account_name}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })
        )}
        <div className="col-12 text-center">
          <Button size={'lg'} className="mt-3" onClick={setSession}>
            Proceed
          </Button>
        </div>
      </div>
    </section>
  )
}

SwitchAccount.propTypes = {
  /** Provides current linkedAccounts */
  currentUser: PropTypes.shape({
    linkedAccounts: PropTypes.array,
  }),
}

export default WithCurrentUser(SwitchAccount)
