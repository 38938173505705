import React, { useState } from 'react'
import PropTypes from 'prop-types'
import renderErrors from 'components/helpers/render_errors'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
/** API */
import { getPayoutPartners, getPayoutPartnersAlt } from 'api/recipients/cw/cash_beneficiaries.js'
/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import TextInput from 'components/helpers/form_fields/text_input'
import Spinner from 'components/helpers/spinner'

function PayoutLocation(props) {
  const {
    payoutDetails,
    payoutFields,
    onPayoutLocationChange,
    onIdentificationChange,
    beneficiary,
    errors,
    isLoading,
    onPayoutPartnerChange,
    onPayoutPartnerAltChange,
  } = props

  const [state, setState] = useState({ partnerOptions: [], value: '' })

  const fieldsObj = {
    city: 'payout_address.address_line_2',
    location: 'payout_address.location_id',
    type: 'id_card.type_id',
  }
  const loadOptions = async () => {
    const params = {
      per_page: 100,
      q: {
        country_code_eq: beneficiary.address.country_code,
        currency_code_eq: beneficiary.currency_code,
      },
    }
    var response
    if (
      beneficiary?.renumeration_type_name === 'cash_external_alt' ||
      beneficiary?.renumeration_type_name === 'Cash External Alt'
    ) {
      response = await getPayoutPartnersAlt(params).catch(error => toast.error(error?.response?.data?.description))
    } else {
      response = await getPayoutPartners(params).catch(error => toast.error(error?.response?.data?.description))
    }
    const partnerOptions = response?.data?.map(partner => ({
      country_code: partner.country_code,
      currency_code: partner.currency_code,
      label: partner.name,
      value: partner.id,
    }))
    setState(prevState => ({ ...prevState, partnerOptions }))
  }

  return (
    <React.Fragment>
      {payoutDetails ? (
        Object.keys(payoutDetails).map((field, index) => {
          if (field === 'identification_number' && payoutDetails[field]?.enabled) {
            return (
              <div key={`${field}-${index}`} className="form-group mb-0">
                <TextInput
                  className="w-input"
                  lowerIndent="mb-0"
                  name={field.name}
                  onChange={e => onIdentificationChange(field, e.target.value)}
                  value={beneficiary.identification?.[field]}
                  label={payoutDetails[field].name}
                  placeholder={payoutDetails[field].placeholder}
                  required={payoutDetails[field].required}
                />
                {renderErrors(errors['id_card.number'])}
              </div>
            )
          }
          if (field === 'cash_payout_partner' && payoutDetails[field]?.enabled) {
            return (
              <div key={`${field}-${index}`} className="form-group mb-0">
                <Formik>
                  <SelectField
                    onFocus={() => !state.partnerOptions.length && loadOptions()}
                    label={payoutDetails[field].name}
                    options={state.partnerOptions}
                    theme="superSwift"
                    name="payout_partner.partner_id"
                    onChange={option => onPayoutPartnerChange(option.value)}
                    value={state.partnerOptions.find(
                      option => option?.value === beneficiary?.payout_partner?.partner_id,
                    )}
                    required={payoutDetails[field].required}
                    placeholder={payoutDetails[field].placeholder}
                    isLoading={!state.partnerOptions.length}
                  />
                </Formik>
                {renderErrors(errors['payout_partner.partner_id'])}
              </div>
            )
          }
          if (field === 'cash_payout_partner_alt' && payoutDetails[field]?.enabled) {
            return (
              <div key={`${field}-${index}`} className="form-group mb-0">
                <Formik>
                  <SelectField
                    onFocus={() => !state.partnerOptions.length && loadOptions()}
                    label={payoutDetails[field].name}
                    options={state.partnerOptions}
                    theme="superSwift"
                    name="payout_partner_alt.partner_id"
                    onChange={option => onPayoutPartnerAltChange(option.value)}
                    value={state.partnerOptions.find(
                      option => option?.value === beneficiary?.payout_partner_alt?.partner_id,
                    )}
                    required={payoutDetails[field].required}
                    placeholder={payoutDetails[field].placeholder}
                    isLoading={!state.partnerOptions.length}
                  />
                </Formik>
                {renderErrors(errors['payout_partner.partner_id'])}
              </div>
            )
          }
          if (payoutDetails[field]?.enabled) {
            return (
              <div key={`${field}-${index}`} className="form-group mb-0">
                <Formik>
                  <SelectField
                    label={payoutDetails[field].name}
                    theme="superSwift"
                    name={`payout_address.${payoutFields[field]}`}
                    options={payoutFields[field]}
                    onChange={countryOption => onPayoutLocationChange(field, countryOption.value)}
                    value={payoutFields[field]?.find(option => option.value === beneficiary.payout_address?.[field])}
                    required={payoutDetails[field].required}
                    placeholder={payoutDetails[field].placeholder}
                    isLoading={isLoading[field]}
                  />
                </Formik>
                {renderErrors(errors[fieldsObj[field.split('_')[1]]])}
              </div>
            )
          }
        })
      ) : (
        <Spinner text={false} />
      )}
    </React.Fragment>
  )
}

PayoutLocation.propTypes = {
  beneficiary: PropTypes.shape({
    address: PropTypes.shape({
      country_code: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    identification: PropTypes.object,
    payout_address: PropTypes.object,
    payout_partner: PropTypes.shape({
      partner_id: PropTypes.number,
    }),
    payout_partner_alt: PropTypes.shape({
      partner_id: PropTypes.number,
    }),
    renumeration_type_name: PropTypes.string,
  }),
  errors: PropTypes.object,
  identificationTypesE4F: PropTypes.array,
  isLoading: PropTypes.object,
  onIdentificationChange: PropTypes.func,
  onPayoutLocationChange: PropTypes.func,
  onPayoutPartnerAltChange: PropTypes.func,
  onPayoutPartnerChange: PropTypes.func,
  payoutDetails: PropTypes.shape({
    city: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.any,
      placeholder: PropTypes.any,
      required: PropTypes.string,
    }),
  }),
  payoutFields: PropTypes.shape({
    payout_state: PropTypes.array,
  }),
}

export default PayoutLocation
