import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
/** API */
import { accepted as transferAccepted } from 'api/trading/cw/customers/transactions'
/** Helpers */
import PaginationTable from 'components/helpers/pagination_table'
/** Utils */
import formatCurrencyAmount from 'components/utils/format_currency_amount'
/** Styles */
import './style.scss'
import ViewTransactionDetails from 'components/pages/retail/history_transfers/view_transaction_details'

const defaultParams = { 'q[s]': 'confirmed_at DESC' }

/** */
export const PaymentHistory = props => {
  const { refresh } = props
  const [data, setData] = useState([])
  const [viewTransaction, setViewTransaction] = useState(false)
  const [transaction, setTransaction] = useState()

  useEffect(() => refresh && getData(defaultParams), [refresh])

  const renderItem = transaction => {
    const newClassName = `${transaction?.status_details?.status_label?.toLowerCase?.() || 'default'}-status`

    return (
      <div
        key={transaction.user_id + Math.random()}
        columns={1}
        data-testid="payment-history-item-row"
        className="rows transactions">
        <div className="item-container text">
          <div className="placeholder">Date</div>
          <div>
            {new Date(transaction.confirmation_details?.confirmed_at?.day_month_date_yy).toLocaleDateString('en-us', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Beneficiary</div>
          <div id="t-bene">{transaction.beneficiary_names}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Currency</div>
          <div id="trans-currency-2">{transaction.counter_currency_code}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Amount</div>
          <div id="trans-status-2">{formatCurrencyAmount(transaction.counter_currency_amount)}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Status</div>
          <div id="trans-status-2">
            <span className={newClassName}>
              {transaction.status_details.status_label || transaction.status_details.status}
            </span>
          </div>
        </div>
        <a
          onClick={() => {
            setViewTransaction(true)
            setTransaction(transaction)
          }}
          className="history-link">
          View history
        </a>
      </div>
    )
  }

  const columns = [
    { header: 'Date', sort_field: 'value_date', type: 'date' },
    { header: 'Beneficiary', sort_field: 'beneficiary_name_matches', type: 'text' },
    { header: 'Currency', sort_field: 'counter_currency_code', type: 'text' },
    { header: 'Amount', sort_field: 'counter_currency_amount', type: 'text' },
    { header: 'Status', sort_field: 'status', type: 'text' },
  ]

  const getData = async params => {
    const response = await transferAccepted(params)
    setData(response?.data)
  }

  return (
    <div className="content-container pt-0">
      <h1 className="page-title white">
        <span className="bar-1">Recent transactions</span>
      </h1>
      <PaginationTable
        columns={columns}
        data={data}
        defaultParams={defaultParams}
        getData={getData}
        perPage={5}
        renderItem={renderItem}
        testID="payment-history"
        title="Recent transactions"
        tableTop={false}
        recentTransfers
        tableName="transactions"
      />
      {viewTransaction && (
        <ViewTransactionDetails
          show={viewTransaction}
          onHide={() => setViewTransaction(!viewTransaction)}
          transaction={transaction}
        />
      )}
    </div>
  )
}

PaymentHistory.propTypes = {
  refresh: PropTypes.bool.isRequired,
}

export default PaymentHistory
