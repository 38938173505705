import React from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router'
import SsoSignOut from 'components/pages/sso/sign_out'
import SsoSignIn from 'components/pages/sso/sign_in'
import SsoError from 'components/pages/sso/error'
import SsoSessionTimeout from 'components/pages/sso/session_timeout'
import { SIGN_IN_PATH, ERROR_PATH, SESSION_TIMEOUT_PATH, SIGN_OUT_PATH } from 'routes/paths/public/sso'
import NotFound from 'routes/shared/not_found'
import Route from 'routes/shared/auth_route'

const Routing = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/${SIGN_IN_PATH}`} component={SsoSignIn} />
    <Route exact path={`${match.path}/${ERROR_PATH}`} component={SsoError} />
    <Route exact path={`${match.path}/${SESSION_TIMEOUT_PATH}`} component={SsoSessionTimeout} />
    <Route exact isPrivate path={`${match.path}/${SIGN_OUT_PATH}`} component={SsoSignOut} />
    <Route component={NotFound} />
  </Switch>
)

Routing.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Routing)
