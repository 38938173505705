import moment from 'moment'
import PropTypes from 'prop-types'
import React, { createRef, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import { useOnClickOutside } from 'components/hooks/useOnClickOutside'

import './searchbox.style.scss'
import { HISTORY_PAYMENTS } from 'routes/paths/private/payments'
import { RECIPENTS_FULL_PATH } from 'routes/paths/private/recipients'

/**
 * Component for searchbox
 */
export const Searchbox = ({
  calendarActiveStartDate = null,
  calendarMaxDate = null,
  calendarMinDate = null,
  className,
  disabled,
  error,
  name,
  onChange,
  onSearch,
  placeholder,
  testID,
  type,
  value,
  clearSearch,
}) => {
  const calendarRef = createRef()
  const [visible, setVisible] = useState(false)
  const activePath = [`${HISTORY_PAYMENTS}`, `${RECIPENTS_FULL_PATH}`].some(path => location.pathname.includes(path))

  /**
   * on search icon press calls onSearch prop
   */
  const onSearchPress = e => {
    e.stopPropagation()
    onSearch(e)
  }

  /**
   * resets current date value
   */
  const onResetDate = e => {
    e.stopPropagation()
    if (value) {
      onChangeDate('')
    }
  }

  /**
   * update value of the field
   */
  const onChangeDate = value => {
    onChange({ target: { value: value ? moment(value).format('YYYY-MM-DD') : value } })
    if (value) {
      onToggleCalendar()
    }
  }

  /**
   * toggles calendar
   */
  const onToggleCalendar = () => {
    if (type === 'date') {
      setVisible(prevState => (type === 'date' ? !prevState : false))
    }
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onSearchPress(e)
    }
  }

  /**
   * hides calendar on outside click
   */
  useOnClickOutside(calendarRef, () => {
    if (visible) {
      setVisible(false)
    }
  })

  /**
   * renders date picker if type is "date"
   */
  const renderDatePicker = () => {
    return (
      <div
        className={`calendar-datepicker ${visible ? 'calendar-datepicker--visible' : ''}`}
        ref={calendarRef}
        data-testid={`${testID}-calendar`}>
        <Calendar
          className="datepicker"
          value={moment(value).isValid() ? new Date(value) : ''}
          onChange={onChangeDate}
          defaultActiveStartDate={calendarActiveStartDate && calendarActiveStartDate}
          maxDate={calendarMaxDate && calendarMaxDate}
          minDate={calendarMinDate && calendarMinDate}
        />
      </div>
    )
  }

  return (
    <div
      className={`${activePath ? 'searchbox w-input' : 'searchbox'}`}
      id={`${[`${RECIPENTS_FULL_PATH}`].some(path => location.pathname.includes(path)) ? 'span-2' : ''}`}>
      <i
        className={`fa ${type === 'date' ? 'fa-calendar' : 'fa-search'} searchbox_search-icon`}
        data-testid={`${testID}-button`}
        onClick={type === 'date' ? () => {} : onSearchPress}
      />
      <input
        className={`form-control input-sm${error?.error ? ' well-danger' : ''} ${className || ''}`}
        autoComplete="off"
        data-testid={testID}
        disabled={disabled}
        name={name}
        type="text"
        onBlur={onToggleCalendar}
        onChange={type === 'date' ? () => {} : onChange}
        onFocus={onToggleCalendar}
        placeholder={placeholder}
        value={value}
        onKeyPress={onKeyPress}
      />
      {type === 'date' && (
        <div className="searchbox_calendar-value" onClick={onToggleCalendar}>
          {value && moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : 'Select date'}
          {value && !disabled && (
            <div className="searchbox_remove-value" onClick={onResetDate}>
              &times;
            </div>
          )}
        </div>
      )}
      {clearSearch && value && (
        <div className="searchbox_remove-value clear" onClick={onResetDate}>
          &times;
        </div>
      )}
      {type === 'date' && renderDatePicker()}
    </div>
  )
}

Searchbox.propTypes = {
  calendarActiveStartDate: PropTypes.instanceOf(Date),
  calendarMaxDate: PropTypes.instanceOf(Date),
  calendarMinDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  clearSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  testID: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

Searchbox.defaultProps = {
  className: '',
  disabled: false,
  name: 'searchbox',
  placeholder: '',
  required: false,
  testID: '',
  type: 'text',
  value: '',
}

export default Searchbox
