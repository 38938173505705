import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import Spinner from 'components/helpers/spinner'

/**
 * HOC(Higher Order Components) that passes down current user data
 */
const withCurrentUser = (Component, CustomLoader = null) => {
  const HOC = class extends React.Component {
    /**
     * @namespace
     * @property {number}  state.currentUser - Current user data retrieve from redux store user reducer data
     */
    state = {
      currentUser: null,
    }

    static getDerivedStateFromProps(props, state) {
      if (!_.isEqual(state.currentUser, props.currentUser)) {
        return {
          currentUser: props.currentUser,
        }
      }
      return state
    }

    componentDidMount() {
      this.setState({
        currentUser: this.props.currentUser,
      })
    }

    componentWillUnmount() {
      this.setState({ currentUser: null })
    }

    render() {
      const { currentUser, ...otherProps } = this.props
      return this.state.currentUser ? (
        <Component {...this.state} {...otherProps} />
      ) : CustomLoader ? (
        <CustomLoader />
      ) : (
        <Spinner />
      )
    }
  }

  HOC.displayName = 'WithCurrentUser'

  HOC.propTypes = {
    currentUser: PropTypes.object.isRequired,
  }

  return connect(mapStateToProps, null)(HOC)
}

const mapStateToProps = state => {
  const { data = {} } = state.user
  return { currentUser: data }
}

export default withCurrentUser
