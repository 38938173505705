import * as Yup from 'yup'
import verifyNationalIdNumber from 'south-africa-national-id-validation'
import { SOUTH_AFRICAN_ID } from 'config/identification_types'

export const personalDetailsConfirmValidation = Yup.object().shape({
  address: Yup.object().shape({
    address_line_1: Yup.string().required('Street address information is missing').nullable(),
    city: Yup.string().required('City information is missing'),
    country_code: Yup.string().required('Country of residence is missing'),
    mobile: Yup.string()
      .nullable()
      .matches(/^\+?([0-9]{2})\)?[-. ]?([0-9]{8})$/, 'Phone number is invalid')
      .required('Mobile number is missing'),
    mobile_isd_code: Yup.string()
      .matches(/^\+?\d+$/g, 'Phone country code is invalid')
      .required('Phone country code is missing'),
    province: Yup.string().required('Province information is missing').nullable(),
    suburb: Yup.string().required('Suburb information is missing').nullable(),
    zip: Yup.mixed().required('Postal code is missing').nullable(),
  }),

  email: Yup.string().email('Email is invalid'),
  id_card: Yup.object().shape({
    expiry_date_year: Yup.string().when('identity_type_id', {
      is: SOUTH_AFRICAN_ID,
      otherwise: Yup.string().required('Expiry date is missing').nullable(),
      then: Yup.string().notRequired(),
    }),
    identity_type_id: Yup.number().required('Identification type is missing').nullable(),
    number: Yup.string().when('identity_type_id', {
      is: SOUTH_AFRICAN_ID,
      otherwise: Yup.string().required('Identification ID is missing').nullable(),
      then: Yup.string()
        .test('ID test', 'ID is invalid', value => verifyNationalIdNumber({ number: value || '' }))
        .required('Identification ID is missing')
        .nullable(),
    }),
  }),
})
