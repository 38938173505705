import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'
/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'
import { SET_CURRENT_RECEIPT_PAYMENT_METHOD } from 'actions/trading'

const FundingOption = ({ errors, fetching, receiptPaymentModeList }) => {
  const { currentReceiptPaymentMethod } = useSelector(({ trading }) => trading?.data) || {}
  const dispatch = useDispatch()
  return (
    <div className="col-xs-12 no-padding">
      <div>
        <label className="calculator-label">Collection method</label>
        <Formik>
          <SelectField
            id="funding_method-select"
            name="funding_method"
            placeholder="Select"
            className="w-select ml-0 w-100"
            isLoading={fetching.options}
            options={receiptPaymentModeList}
            onChange={receiptPaymentModeOption =>
              dispatch({
                payload: receiptPaymentModeOption,
                type: SET_CURRENT_RECEIPT_PAYMENT_METHOD,
              })
            }
            value={receiptPaymentModeList.find(paymentMode => paymentMode?.value === currentReceiptPaymentMethod.value)}
          />
        </Formik>
        {renderErrors(errors?.value_datetime || currentReceiptPaymentMethod?.error)}
      </div>
    </div>
  )
}

FundingOption.propTypes = {
  errors: PropTypes.object,
  fetching: PropTypes.object,
  prefs: PropTypes.shape({
    by_payment_card: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
  receiptPaymentModeList: PropTypes.arrayOf(PropTypes.object),
}

export default FundingOption
