/* eslint-disable indent */
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
import { userLocalStorage } from 'config/local_storage'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
/* API */
import { verifyOTP, resendOTP } from 'api/accounts/public/registration'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
import Spinner from 'components/helpers/spinner'
/* Helpers */
import { TextField } from 'components/helpers/formik_fields'
import checkmark from 'assets/svg/checkmark.svg'
import { ReactComponent as Info } from 'assets/svg/info.svg'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { REGISTER_PATH } from 'routes/paths/root'
import { STEP_FOUR_PERSONAL_INFORMATION } from 'routes/paths/public/register'
/* Styles */
import styles from 'components/pages/users/password/reset/styles.module.scss'

// Step 4 section FewMoreThings
export const StepFourOTPValidation = () => {
  const history = useHistory()
  const [otpState, setOTPState] = useState()
  const { tempAuthData, registerFlow } = useContext(RegistrationContext)
  const registrationFlow = isEmpty(registerFlow) ? userLocalStorage.get('registerFlow') : registerFlow
  const temporaryAuthData = isEmpty(registerFlow) ? userLocalStorage.get('tempAuthData') : tempAuthData

  // eslint-disable-next-line no-unused-vars
  const inputs = registrationFlow?.step_3?.account_information.sections.account_information.inputs

  const onResendOTPClick = async () => {
    setOTPState(true)
    await resendOTP({
      otp: {
        event: 'registration',
        user_id: temporaryAuthData.admin_user_id,
      },
    })
      .then(res => toast.success(res?.data?.description || 'OTP Resent Successfully'))
      .catch(error => toast.error(error?.response?.data?.description || 'Unable to send request'))
    setOTPState(false)
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[3].text} activeStep="Create account" />
        <div className="container-1200 flex-align-center">
          <div className={`${styles.otpContainer} reg-frame`}>
            <div className="p-2 mt-2">
              <span className="d-flex align-items-start new-header mb-4">Enter one time pin (OTP) </span>
              <div className={`${styles.alertSS} ${styles.valid} mb-4`}>
                <img src={checkmark} alt="valid-tick" />
                To confirm your account please enter the one time pin we have sent you by SMS.
              </div>

              <Formik
                initialValues={{
                  otp: '',
                }}
                //             validationSchema={}
                onSubmit={async (values, actions) => {
                  try {
                    const res = await verifyOTP({
                      otp: {
                        event: 'registration',
                        otp: values.otp,
                        user_id: temporaryAuthData.admin_user_id,
                      },
                    })
                    res && history.push(`${REGISTER_PATH}/${STEP_FOUR_PERSONAL_INFORMATION}`)
                  } catch (error) {
                    actions.setSubmitting(false)
                    error.response.status === 422
                      ? actions.setFieldError('otp', error?.response?.data?.description)
                      : toast.error(error?.response?.data?.description || error?.message)
                  }
                }}>
                {formik => (
                  <Form>
                    <TextField label="Enter OTP" name="otp" type="text" placeholder="Enter OTP" />

                    <div className="form-group">
                      {otpState ? (
                        <Spinner />
                      ) : (
                        <input
                          type="button"
                          disabled={formik.isSubmitting}
                          onClick={onResendOTPClick}
                          className={styles.btnFlatSs}
                          value={'Resend OTP'}
                        />
                      )}
                    </div>

                    <div className="form-group mt-4">
                      <button disabled={formik.isSubmitting} type="submit" className="ss-btn dark-teal w-100">
                        {formik.isSubmitting ? <Spinner spinner={false} /> : 'Confirm'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={`mt-0 ${styles.valid} otp-foot`}>
            <Info className={styles.more} />
            One time pin will be delivered by SMS to your mobile phone number registered on your account. If you have
            not received the one time pin please contact us.
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}
