/* eslint-disable indent */
import env from '.env'
import axios from 'axios'
import withApiCatch from './with_api_catch'
import { userLocalStorage } from 'config/local_storage'
import 'config/axios/interceptors'
import 'config/axios/mocks'

const fetchApi = (endpoint, data = {}, critical = false, responseType = null, customURL = '') => {
  const token = userLocalStorage.get('registrationToken')?.token || userLocalStorage.get('tokenInformation')?.token
  const options = customURL
    ? { url: customURL }
    : {
        headers: {
          Accept: data.accept ? data.accept : 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': data.contentType ? data.contentType : 'application/json',
        },
        url: `${env.backend.url}/${env.backend.version}/${endpoint}`,
      }

  if (responseType) {
    options.responseType = responseType
  }

  return withApiCatch(axios(Object.assign(options, data)), critical)
}

export default fetchApi
