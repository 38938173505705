export var Ipay = (function (window, document, undefined) {
  var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
  var eventer = window[eventMethod]
  var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message'
  var redirectPrefix = '/payment/iframeredirect?redirecturl='
  var paymentErrorMessage = 'Payment could not be completed, please contact the site administrator'

  function ipay() {
    var self = this
    ;(self.createPaymentFrame = function (elementSelector, paymentUrl, postData) {
      if (!elementSelector || elementSelector == '' || elementSelector.length == 0) {
        console.log('The createPaymentFrame method parameter elementSelector cannot be empty or null.')
        alert(paymentErrorMessage)
        return
      }

      if (!paymentUrl || paymentUrl == '' || paymentUrl.length == 0) {
        console.log('The createPaymentFrame method parameter paymentUrl cannot be empty or null.')
        alert(paymentErrorMessage)
        return
      }

      if (document.getElementById('paymentFrame')) {
        var iframe = document.getElementById('paymentFrame')
        iframe.parentNode.removeChild(iframe)
      }

      if (typeof postData === 'object') {
        var paymentData = setRedirectPrefix(postData)
        var params = Object.keys(postData)
          .map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(postData[k])
          })
          .join('&')
        var postUrl = paymentUrl + '?viewName=JsInjection&' + params
        var iframe = document.createElement('iframe')
        iframe.setAttribute('id', 'paymentFrame')
        iframe.setAttribute('src', postUrl)
        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('scrolling', 'no')
        iframe.setAttribute('height', '100%')
        iframe.setAttribute('width', '100%')
        iframe.style.overflow = 'hidden'
        iframe.style.height = '100%'
        iframe.style.width = '100%'
        console.log('IPAY', elementSelector)
        elementSelector.appendChild(iframe)
      } else {
        console.log('The createPaymentFrame method expects a JSON object for the postData parameter.')
        alert(paymentErrorMessage)
      }
    }),
      (self.createPaymentModal = function (paymentUrl, postData) {
        if (!paymentUrl || paymentUrl == '' || paymentUrl.length == 0) {
          console.log('The createPaymentModal method parameter paymentUrl cannot be empty or null.')
          alert(paymentErrorMessage)
          return
        }

        if (document.getElementById('paymentFrame')) {
          var iframe = document.getElementById('paymentFrame')
          iframe.parentNode.removeChild(iframe)
        }

        if (typeof postData === 'object') {
          var paymentData = setRedirectPrefix(postData)
          var params = Object.keys(postData)
            .map(function (k) {
              return encodeURIComponent(k) + '=' + encodeURIComponent(postData[k])
            })
            .join('&')
          var postUrl = paymentUrl + '?viewName=JsPopup&' + params
          var iframe = document.createElement('iframe')
          iframe.setAttribute('id', 'paymentFrame')
          iframe.setAttribute('src', postUrl)
          iframe.setAttribute('frameborder', '0')
          iframe.setAttribute('scrolling', 'no')
          iframe.style.position = 'fixed'
          iframe.style.left = '0'
          iframe.style.right = '0'
          iframe.style.bottom = '0'
          iframe.style.top = '0'
          iframe.style.width = '100%'
          iframe.style.height = '175%'
          document.body.appendChild(iframe)
        } else {
          console.log('The createPaymentModal method expects a JSON object for the postData parameter.')
          alert(paymentErrorMessage)
        }
      }),
      (self.cancelFramePayment = function () {
        var paymentFrame = document.getElementById('paymentFrame')
        paymentFrame.contentWindow.postMessage({ event: 'ipayCancelPayment' }, '*')
      })
  }

  function setRedirectPrefix(data) {
    if (data.CancelUrl && data.CancelUrl.length > 0) {
      data.CancelUrl = redirectPrefix + encodeURI(data.CancelUrl)
    }

    if (data.ErrorUrl && data.ErrorUrl.length > 0) {
      data.ErrorUrl = redirectPrefix + encodeURI(data.ErrorUrl)
    }

    if (data.SuccessUrl && data.SuccessUrl.length > 0) {
      data.SuccessUrl = redirectPrefix + encodeURI(data.SuccessUrl)
    }

    return data
  }

  eventer(
    messageEvent,
    function (e) {
      if (!e.data || !e.data.event || (e.data.event != 'ipayMessage' && e.data.event != 'ipayResize')) {
        return
      }

      if (e.data.event == 'ipayMessage') {
        var params = Object.keys(e.data.postData)
          .map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(e.data.postData[k])
          })
          .join('&')
        window.location = e.data.url + (e.data.url.indexOf('?') == -1 ? '?' : '&') + (params || {})
      } else if (e.data.event == 'ipayResize') {
        document.getElementById('paymentFrame').style.height = e.data.height + 'px'
      }
    },
    false,
  )

  return ipay
})(window, document)
