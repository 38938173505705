import React from 'react'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'
import { StepFive } from './step-five'
import { StepFourPersonalInfo } from './step-four/personal-info'
import { StepFourPhysicalAddress } from './step-four/physical-address'
import { StepFourOTPValidation } from './step-four/otp-validation'
import { StepSix } from './step-six'
import StepSixSuccess from './step-six/success'
import NotFound from 'routes/shared/not_found'

export const steps = [
  {
    component: <StepOne />,
    step: 'new',
    text: 'Select your account type',
    to: '/register/new',
  },
  {
    component: <StepTwo />,
    step: 'transfer-limit',
    text: 'Select your transfer limit',
    to: '/register/transfer-limit',
  },
  {
    component: <StepThree />,
    step: 'account-credentials',
    text: 'Set up your profile',
    to: '/register/account-credentials',
  },
  {
    component: <StepFourOTPValidation />,
    step: 'otp-validation',
    text: 'OTP validation',
    to: '/register/otp-validation',
  },
  {
    component: <StepFourPersonalInfo />,
    step: 'personal-information',
    text: 'Set up your profile',
    to: '/register/personal-information',
  },
  {
    component: <StepFourPhysicalAddress />,
    step: 'physical-address',
    text: 'Physical Address',
    to: '/register/physical-address',
  },
  {
    component: <StepFive />,
    step: 'identification-information',
    text: 'Identification information',
    to: '/register/identification-information',
  },
  {
    component: <StepSix />,
    step: 'promotions',
    text: 'Promotions',
    to: '/register/promotions',
  },
  {
    component: <StepSixSuccess />,
    step: 'success',
    text: 'Set up your profile',
    to: '/register/success',
  },
  {
    component: <NotFound />,
    step: '404',
    text: '404',
    to: '/register/404',
  },
]

export const SOUTH_AFRICAN = 'South African Four O One'
export const FOREIGN_NATIONAL_416 = 'Foreign National Four One Six'
export const FOREIGN_NATIONAL_417 = 'Foreign National Four One Seven'

export default {
  FOREIGN_NATIONAL_416,
  FOREIGN_NATIONAL_417,
  SOUTH_AFRICAN,
}
