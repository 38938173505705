import * as Sentry from '@sentry/browser'
import store from 'config/create_store'
import { toast } from 'react-toastify'
import { notFound, internalServerError, critical } from 'actions/server_errors'
import refreshToken from 'components/utils/refresh_token'

// TODO: refresh should retry a request instead of only getting the new token
const withApiCatch = (promise, isCritical = false) => {
  return promise.catch(error => {
    const { response } = error

    if (isCritical) {
      Sentry.captureException(error)
      store.dispatch(critical(error))
      return
    }

    if (!response) {
      return
    }

    toast.error(response.message)

    if (!window.Cypress && response.status === 401) {
      const { code } = response.data
      if (code === 'FTOKEN00001') {
        refreshToken()
        return
      } else {
        throw error
      }
    }

    switch (response.status) {
      case 404:
        store.dispatch(notFound(error))
        break
      case 500:
        Sentry.captureException(error)
        store.dispatch(internalServerError(error))
        break
      case 403:
        toast.error(response.data.description)
        break
      default:
        console.error('Unknown error: ', error)
    }
    throw error
  })
}

export default withApiCatch
