import React from 'react'
import PropTypes from 'prop-types'

const AlertError = props => {
  const { message } = props
  return (
    <div time={Date.now()} color="danger">
      {message}
    </div>
  )
}

AlertError.propTypes = {
  message: PropTypes.string.isRequired,
}

export default AlertError
