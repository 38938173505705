import fetchApi from 'components/utils/fetch_api'

const settings = params => {
  return fetchApi('trading/cw/settings', { params })
}

const settingsSpotTransactionAuthorizations = () => {
  return fetchApi('trading/cw/settings/spot_transaction_authorizations')
}

export { settings, settingsSpotTransactionAuthorizations }
