import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { userLocalStorage } from 'config/local_storage'
import AuthorizationRedirectionPath from 'components/utils/authorization_redirection_path'
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import MainContainer from 'components/base/layouts/main/container'
import ConfirmCustomerDetails from 'components/pages/users/confirm-customer-details'

const AuthRoute = ({
  component: Component,
  Container = MainContainer,
  isPrivate = false,
  redirectIfAuthorized = true,
  isAuthorized,
  isReviewRequired,
  ...rest
}) => {
  if (isPrivate) {
    return (
      <Route
        {...rest}
        render={props => {
          switch (true) {
            case isAuthorized && isReviewRequired && !userLocalStorage.get('isAccountConfirmed'):
              return <ConfirmCustomerDetails {...props} />
            case isAuthorized:
              return (
                <MainContainer>
                  <Component {...props} />
                </MainContainer>
              )
            default:
              return <Redirect to={SIGN_IN_FULL_PATH} />
          }
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (redirectIfAuthorized && isAuthorized) {
          return <Redirect to={`/${AuthorizationRedirectionPath()}`} />
        }
        return <Component {...props} />
      }}
    />
  )
}

const mapStateToProps = state => {
  const { tokenInformation } = state.user.auth
  const { userId } = state.user.data
  const isReviewRequired = state.client?.data?.isReviewRequired
  return {
    isAuthorized: userId && !isEmpty(tokenInformation),
    isReviewRequired,
  }
}

AuthRoute.propTypes = {
  Container: PropTypes.element,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isAuthorized: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isReviewRequired: PropTypes.bool,
  redirectIfAuthorized: PropTypes.bool,
}

export default connect(mapStateToProps, null)(AuthRoute)
