import React from 'react'
import PropTypes from 'prop-types'
import { Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from 'components/pages'
import ErrorBoundary from 'components/base/errors/error_boundary'

const Root = ({ store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      <ErrorBoundary>
        <Route path="/" component={App} />
      </ErrorBoundary>
    </Router>
  </Provider>
)

Root.propTypes = {
  /** Provides several attributes/functions for managing session history {@link Router} */
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}

export default Root
