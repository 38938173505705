import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** Actions */
import { setOutgoingTransfers } from 'actions/trading'
/** Helpers */
import PaginationTable from 'components/helpers/pagination_table'
/* Config */
import { statusBadgeColors } from 'config/badgeColorPallete'
import ViewTransactionDetails from './view_transaction_details'
import RefundModal from './refund_modal'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import { NEW_FULL_PATH } from 'routes/paths/private/transactions'

/** Past Transactions component */
export const HistoryTransfers = ({ data, getData }) => {
  const history = useHistory()
  const [reload, setReload] = useState(false)
  const [viewTransaction, setViewTransaction] = useState('')
  const [refundTransaction, setRefundTransaction] = useState('')

  const renderItem = ({
    beneficiary_names: beneficiaryNames,
    confirmation_details: confirm,
    id,
    status_details: status,
    quote,
  }) => {
    const badgeColor = statusBadgeColors?.find(item => item.status === status.status_label)
    return (
      <div key={id} columns={1} data-testid="past-payments-item-row" className="rows transactions">
        <div className="item-container text">
          <div className="placeholder">Date</div>
          <div>
            {new Date(confirm.confirmed_at?.day_month_date_yy).toLocaleDateString('en-us', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Beneficiary</div>
          <div id="trans-bene">
            {!!beneficiaryNames?.length &&
              beneficiaryNames.map((name, index) => (
                <span key={index}>{`${name}${index < beneficiaryNames.length - 1 ? ', ' : ''}`}</span>
              ))}
          </div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Currency</div>
          <div id="trans-currency">{quote?.counter_currency_code}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Amount</div>
          <div id="trans-currency">{formatCurrencyAmount(quote?.counter_currency_amount)}</div>
        </div>
        <div className="item-container text">
          <div className="placeholder">Status</div>
          <div id="trans-status">
            <div
              style={{
                backgroundColor: badgeColor?.backgroundColor || '#e91f29',
                border: `1px solid ${badgeColor?.color || '#212529'}`,
                borderRadius: '5px',
                color: '#2a2a2a',
                fontSize: '14px',
                padding: '5px 5px',
                textAlign: 'center',
                width: '100px',
              }}
              className="badge-info">
              {status.status_label || status.status}
            </div>
          </div>
        </div>
        <div>
          <div onClick={() => setViewTransaction(id)} className="view-history">
            View details
          </div>
        </div>
        {status.status === 'confirmed' ? (
          <div>
            <button onClick={() => setRefundTransaction(id)} className="refund-btn">
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <div className="empty"></div>
          </div>
        )}
      </div>
    )
  }

  const defaultParams = {
    'q[home_currency_trading_ability_eq]': 'sell',
    'q[qustomer_bank_account_debited_eq]': true,
    'q[s]': 'confirmed_at DESC',
  }
  const columns = [
    { header: 'Date', search_type: 'eq', sort: false, sort_field: 'value_date', type: 'date' },
    { header: 'Beneficiary', search_type: 'eq', sort: false, sort_field: 'beneficiary_names', type: 'text' },
    { header: 'Currency', search_type: 'eq', sort: false, sort_field: 'counter_currency_code', type: 'text' },
    { header: 'Amount', search_type: 'eq', sort: false, sort_field: 'counter_currency_amount', type: 'text' },
    { header: 'Status', search_type: 'eq', sort: false, sort_field: 'status_details.status', type: 'text' },
  ]

  useEffect(() => reload && setReload(false), [reload])

  return (
    <div className="content-container">
      <h1 className="page-title white">
        <span className="bar-1">Transactions</span>
      </h1>
      {!reload && (
        <PaginationTable
          columns={columns}
          data={data}
          defaultParams={defaultParams}
          getData={getData}
          perPage={10}
          renderItem={renderItem}
          tableTop={true}
          isLoadMoreButtonEnabled={true}
          testID="past-transactions"
          title="Transaction History"
          tableName="transactions"
          extraButtonLabel="+ New transaction"
          extraButtonPress={() => history.push(NEW_FULL_PATH)}
        />
      )}
      {!!viewTransaction && (
        <ViewTransactionDetails
          show={!!viewTransaction}
          onHide={() => setViewTransaction('')}
          transaction={data?.find(transaction => transaction?.id === viewTransaction)}
        />
      )}
      {!!refundTransaction && (
        <RefundModal
          setReload={setReload}
          show={!!refundTransaction}
          onHide={() => setRefundTransaction('')}
          transaction={data?.find(transaction => transaction?.id === refundTransaction)}
        />
      )}
    </div>
  )
}

HistoryTransfers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  getData: PropTypes.func,
}

const mapStateToProps = ({ trading }) => ({ data: trading.data.outgoingTransfers })

const mapDispatchToProps = dispatch => ({ ...bindActionCreators({ getData: setOutgoingTransfers }, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTransfers)
