export function transactionMessage(receiptPaymentModeId, transactionStatus) {
  let paymentMethodLabel

  if (receiptPaymentModeId === 9) {
    paymentMethodLabel =
      "Please make payment at a Shoprite Checkers, Usave, House and Home, K'nect or OK Furniture store."
  } else if (receiptPaymentModeId === 3 && transactionStatus !== 'open') {
    paymentMethodLabel = 'Payment made by supplied card'
  } else if ((receiptPaymentModeId === null || receiptPaymentModeId === 10) && transactionStatus !== 'open') {
    paymentMethodLabel = 'Payment made by Ozow'
  } else {
    paymentMethodLabel = 'Payment failed'
  }
  return paymentMethodLabel
}
