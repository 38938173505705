import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

/**
 * Components for screen
 * LoginDetails - user can update his password with new one
 * SecurityQuestions - user can change security questions and answers
 */
import LoginDetails from './login_details'

/**
 * Component for Account Settings
 */
export const AccountSettings = ({ userId, memberID }) => (
  <div className="accounts-card col-xl-9">
    <div className="col ">
      <LoginDetails userId={userId} memberID={memberID} />
    </div>
  </div>
)

AccountSettings.propTypes = {
  /** Provides current memeberID */
  memberID: PropTypes.string,
  /** Provides current userId */
  userId: PropTypes.number,
}

const mapStateToProps = ({ user }) => ({ memberID: user.data.userDetails.external_uid, userId: user.data.userId })

export default connect(mapStateToProps)(withRouter(AccountSettings))
