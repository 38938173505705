import fetchApi from 'components/utils/fetch_api'

const index = () => {
  return fetchApi('accounts/public/countries/registration_enabled')
}

const allCountries = () => {
  return fetchApi('accounts/public/countries/?q[s]=nationality', {
    headers: { Accept: 'application/json' },
    method: 'GET',
  })
}

const preference = countryCode => {
  return fetchApi(`accounts/public/countries/${countryCode}`)
}

export { index, preference, allCountries }
