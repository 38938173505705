import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import renderErrors from 'components/helpers/render_errors'

const FundingMethod = ({ receiptAccountList, receiptAccount, onReceiptAccountChange, errors, prefs }) => {
  if (prefs.transfer_in_settlement_account?.enabled && receiptAccountList.length > 1) {
    const fundingMethodOptions = receiptAccountList.map(receiptAccount => ({
      label: receiptAccount.receipt_account,
      value: receiptAccount.receipt_account_id,
    }))

    return (
      <div>
        <div>Select Funding Method</div>
        <Select
          options={fundingMethodOptions}
          value={fundingMethodOptions.find(option => option.value === receiptAccount.receipt_account_id)}
          onChange={receiptAccountOption => onReceiptAccountChange(receiptAccountOption.value)}
        />
        <div className="col-sm-12 no-padding">
          {renderErrors(errors?.base_currency_account_id)}
          {renderErrors(errors?.receipt_account_id)}
        </div>
      </div>
    )
  }

  return null
}

FundingMethod.propTypes = {
  errors: PropTypes.object,
  onReceiptAccountChange: PropTypes.func.isRequired,
  prefs: PropTypes.shape({
    transfer_in_settlement_account: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
  receiptAccount: PropTypes.object,
  receiptAccountList: PropTypes.arrayOf(PropTypes.object),
}

export default FundingMethod
