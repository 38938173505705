import fetchApi from 'components/utils/fetch_api'

const getCheckoutID = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}/peach_payments`, { method: 'POST' })
}

const updatePeachPayment = ({ transactionId, id, data }) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/peach_payments/${id}`, { data, method: 'PUT' })
}

export { getCheckoutID, updatePeachPayment }
