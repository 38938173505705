/* eslint-disable indent */
/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { RegistrationContext } from 'context/registration/registrationContext'
import { userLocalStorage } from 'config/local_storage'
import { toast } from 'react-toastify'
/* API */
import { createAccountLogin } from 'api/accounts/public/registration'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import { Fields } from './fields'
import Spinner from 'components/helpers/spinner'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { REGISTER_PATH } from 'routes/paths/root'
import { STEP_FOUR_OTP_VALIDATION } from 'routes/paths/public/register'

/**
 * Component for the third step of registration process
 */
const StepThree = () => {
  const history = useHistory()
  const { registerFlow, saveRegData, currentCustomerCategory } = useContext(RegistrationContext)
  const registrationFlow = isEmpty(registerFlow) ? userLocalStorage.get('registerFlow') : registerFlow

  const inputs = registrationFlow?.step_1?.account_credentials.sections.account_credentials.inputs
  const additionalInputs = registrationFlow?.step_2?.customer_information.sections.physical_address.inputs
  const tncInputs = registrationFlow?.step_3?.account_information.sections.account_information.inputs
  const affiliateToken = useSelector(state => state?.registration?.data?.affiliateToken)

  const onFormSubmit = async (
    { customer_address, secret_question_1, secret_question_2, secret_answer_1, secret_answer_2, admin_user, ...values },
    actions,
    initialFields,
  ) => {
    const { mobile, mobile_isd_code } = customer_address
    const registrationObj = {
      customer: {
        admin_user_attributes: {
          ...admin_user,
          ...(secret_question_1 === '' && secret_question_2 === '' && secret_answer_1 === '' && secret_answer_2 === ''
            ? {}
            : {
                secret_answers_attributes: {
                  0: { answer: secret_answer_1, secret_question_id: secret_question_1 },
                  1: { answer: secret_answer_2, secret_question_id: secret_question_2 },
                },
              }),
        },
        customer_address_attributes: { mobile, mobile_isd_code: mobile_isd_code.replace(/\+/g, '') },
        principal_category_id: currentCustomerCategory.value || userLocalStorage.get('currentCustomerCategory').value,
        ...values,
      },
    }

    if (affiliateToken) {
      registrationObj.customer.affiliate_token_attributes = {
        token: affiliateToken,
      }
    }

    try {
      const response = await createAccountLogin(registrationObj)
      actions.setSubmitting(false)
      if (response) {
        saveRegData(response)
        history.push(`${REGISTER_PATH}/${STEP_FOUR_OTP_VALIDATION}`)
      }
    } catch (error) {
      if (error.response.status === 422) {
        actions.setStatus(error?.response?.data?.errors)
      } else {
        toast.error('Failed to create account. Please try again or contact support')
      }
    }
  }
  const renderContent = () => {
    if (!inputs) {
      return <Spinner />
    }
    return (
      <Fields inputs={inputs} additionalInputs={additionalInputs} tncInputs={tncInputs} onFormSubmit={onFormSubmit} />
    )
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[2].text} activeStep="Create account" />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">{renderContent()}</div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}

StepThree.propTypes = {
  /** Provides several attributes/functions for managing session history */
  history: PropTypes.object,
  /** Provides data for the hole registration process, all the steps */
  registerFlow: PropTypes.object,
}

export default StepThree
