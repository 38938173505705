import fetchApi from 'components/utils/fetch_api'

const fees = ({ transactionId, data }) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/fees`, { data, method: 'POST' })
}

const refresh = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}/refresh`, { method: 'PUT' })
}

const confirm = (transactionId, otp) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/confirm`, { data: { otp }, method: 'POST' })
}

const directDebits = ({ transactionId, data }) => {
  return fetchApi(`/trading/cw/transactions/${transactionId}/direct_debits`, { data, method: 'POST' })
}

const approvals = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}/approvals`)
}

const createPayment = ({ transactionId, data }) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/payments`, { data, method: 'POST' })
}

const updatePayment = ({ transactionId, beneficiaryTransactionId, data }) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/payments/${beneficiaryTransactionId}`, {
    data,
    method: 'PUT',
  })
}

const destroyPayment = ({ transactionId, beneficiaryTransactionId }) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/payments/${beneficiaryTransactionId}`, {
    method: 'DELETE',
  })
}

const approve = (transactionId, beneficiaryTransactionId) => {
  return fetchApi(`/trading/cw/transactions/${transactionId}/payments/${beneficiaryTransactionId}/approve`, {
    method: 'PUT',
  })
}

const show = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}`)
}

const notification = (transactionId, data = {}) => {
  return fetchApi(`trading/cw/transactions/${transactionId}/notifications/confirmation`, { data, method: 'POST' })
}

const cancel = transactionId => {
  return fetchApi(`trading/cw/transactions/${transactionId}/cancel`, { method: 'POST' })
}

export {
  approvals,
  approve,
  fees,
  directDebits,
  updatePayment,
  createPayment,
  show,
  refresh,
  confirm,
  destroyPayment,
  notification,
  cancel,
}
