import fetchApi from 'components/utils/fetch_api'

const acceptedDefaultQuery = {
  page: 1,
  per_page: 1,
  q: {
    customer_bank_account_debited_eq: true,
    home_currency_trading_ability_eq: 'buy',
    s: 'quote_time desc',
  },
}

const accepted = (params = acceptedDefaultQuery) => {
  return fetchApi('trading/cw/customers/transactions/accepted', { params })
}

export { accepted }
