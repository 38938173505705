import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { RECIPENTS_FULL_PATH } from 'routes/paths/private/recipients'
/**
 * Component for Tabs
 */
export const Tabs = ({ currentTab, tabs }) => {
  const renderComponent = () => {
    const tab = tabs.find(item => item.tab === currentTab)
    if (tab && tab.component) {
      return tab.component
    }
    return null
  }

  const activePath = useLocation().pathname
  const beneficiaryPath = `${RECIPENTS_FULL_PATH}`

  if (!tabs || !tabs.length) {
    return null
  }
  return (
    <React.Fragment>
      <div
        className={`${
          activePath === beneficiaryPath || activePath.includes('retail/users/') ? 'nav-wrapper pb-0' : 'pb-2'
        }`}>
        <ul
          className={`tabs ${
            activePath === beneficiaryPath || activePath.includes('retail/users/') ? 'sub-nav-wrapper' : ''
          }`}>
          {tabs.map(item => (
            <li
              key={Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5)}
              className={`tab ${item.tab === currentTab ? 'tab--active' : ''} ${
                activePath === beneficiaryPath || activePath.includes('retail/users/') ? 'sub-nav-link' : ''
              }`}>
              <NavLink to={item.to}>{item.text}</NavLink>
            </li>
          ))}
        </ul>
      </div>
      {renderComponent()}
    </React.Fragment>
  )
}

Tabs.propTypes = {
  currentTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string,
      text: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
}

export default Tabs
