import {
  setTokenInformation,
  setCurrentUserData,
  setCurrentUserPermissions,
  setCurrentUserAccountSettings,
  setCurrentUserLinkedAccounts,
  setSSO,
  setUserDetails,
} from 'actions/users'
import { setForcedProfileReview } from 'actions/clients'

import { setSettings, setCurrencies } from 'actions/trading'

const saveDataSession = (data, store) => {
  const customerId = data.customer_id
  const sso = data.sso
  const userId = data.user_id
  const expiresIn = (data.expires_in + 30) * 1000 + Date.now()

  store.dispatch(
    setTokenInformation({
      expiresIn,
      refreshToken: data.refresh_token,
      token: data.token,
    }),
  )
  store.dispatch(setForcedProfileReview(userId))
  store.dispatch(setCurrentUserPermissions(userId))

  if (customerId) {
    store
      .dispatch(setCurrentUserLinkedAccounts(customerId))
      .finally(() => store.dispatch(setCurrentUserData({ customerId, userId })))
  } else {
    store.dispatch(setCurrentUserData({ userId }))
  }

  if (userId) {
    store.dispatch(setUserDetails(userId))
    store.dispatch(setCurrentUserAccountSettings({ userId }))
  }

  if (sso) {
    store.dispatch(setSSO())
  }

  store.dispatch(setSettings())
  store.dispatch(setCurrencies())
}

export default saveDataSession
