import { useState } from 'react'
import { toast } from 'react-toastify'

import { updatePassword } from 'api/accounts/cw/users'

import {
  NEW_PASSWORDS_DOES_NOT_MATCH_CONFIRM_PASSWORD,
  FILL_OUT_ALL_FIELDS_TO_CHANGE_PASSWORD,
  UNEXPECTED_ERROR,
  INVALID_PASSWORD,
} from '../account_settings.constants'

const INITIAL_PASSWORD = { current_password: '', password: '', password_confirmation: '' }

export const useLoginDetails = ({ userId }) => {
  const [isSavingPassword, setIsSavingPassword] = useState(false)
  const [password, setPassword] = useState(INITIAL_PASSWORD)

  /**
   * Gets called when user changes text on password input
   * @param {Object} type object returned by form
   */
  const onPasswordChange = e => {
    const { name, value } = e.target
    setPassword(prevState => ({ ...prevState, [name]: value }))
  }

  /**
   * Get called when user click on update changes for password update
   */
  const onPasswordSubmit = async () => {
    if (!password.current_password || !password.password || !password.password_confirmation) {
      toast.error(FILL_OUT_ALL_FIELDS_TO_CHANGE_PASSWORD)
    } else if (
      !password.password.match(
        /^(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])(?=.*[A-Z])[-!$%^&*()_+|~=`{}[:;<>?,.@#\]0-9a-zA-Z].{7,}$/,
      )
    ) {
      toast.error(INVALID_PASSWORD)
    } else if (password.password !== password.password_confirmation) {
      toast.error(NEW_PASSWORDS_DOES_NOT_MATCH_CONFIRM_PASSWORD)
    } else {
      setIsSavingPassword(true)
      try {
        const response = await updatePassword(userId, password)
        toast.success(response.data.description)
        setPassword(INITIAL_PASSWORD)
      } catch (e) {
        toast.error(e?.response?.data?.description || UNEXPECTED_ERROR)
      }
      setIsSavingPassword(false)
    }
  }

  return {
    isSavingPassword,
    onPasswordChange,
    onPasswordSubmit,
    password,
  }
}
