import React from 'react'
import PropTypes from 'prop-types'
import { RegistrationState } from 'context/registration/RegistrationState'
import { steps } from './register.constants'

/**
 * Component for Registration
 */
const Registration = ({ match }) => {
  const { step } = match.params

  const currentStep = steps.find(item => item.step === step)
  if (currentStep?.component) {
    return <RegistrationState>{currentStep.component}</RegistrationState>
  }
  return null
}

Registration.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }),
}

export default Registration
