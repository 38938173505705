import React from 'react'
import PropTypes from 'prop-types'
/* Helpers */
import TextInput from 'components/helpers/form_fields/text_input'

import { useLoginDetails } from './login_details.hook'

import 'components/base/layouts/main/container/index.scss'

/**
 * Component for Login Details - form for updating user account password
 */
export const LoginDetails = ({ userId }) => {
  const { isSavingPassword, onPasswordChange, onPasswordSubmit, password } = useLoginDetails({ userId })

  /**
   * Renders component Login Details
   */
  return (
    <form className="col-xs-12 form-horizontal">
      <div className="page-header">
        <h3>Login Details</h3>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <TextInput
            label="Current password"
            layout="horizontal"
            name="current_password"
            required
            testID="current-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.current_password}
          />
          <TextInput
            helpBlock="Minimum length is 8 with at least one lower and one upper case letter and one number"
            label="New password"
            layout="horizontal"
            name="password"
            required
            testID="new-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.password}
          />
          <TextInput
            label="Confirm new password"
            layout="horizontal"
            name="password_confirmation"
            required
            testID="confirmation-new-password-input"
            type="password"
            disabled={isSavingPassword}
            onChange={onPasswordChange}
            value={password.password_confirmation}
          />
          <input
            className="btn btn-sm btn-success btn-width-200 space-30"
            data-testid="update-changes-password"
            name="update_password"
            type="button"
            value={isSavingPassword ? 'Saving...' : 'Update Changes'}
            onClick={onPasswordSubmit}
          />
        </div>
      </div>
    </form>
  )
}

LoginDetails.propTypes = {
  /** Provides current userId */
  userId: PropTypes.number,
}

export default LoginDetails
