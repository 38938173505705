import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'

const InputMask = props => {
  const { maxLength, onChange, showToggle = true, testID, value } = props
  const [maskedValue, setMaskedValue] = useState()
  const [inputColor, setInputColor] = useState('transparent')
  const [showMaskedInput, setShowMaskedInput] = useState(true)
  const inputFieldRef = useRef()
  const activePath = location.pathname

  const toggleInputMask = () => {
    setShowMaskedInput(prevValue => {
      return !prevValue
    })
    setInputColor(!showMaskedInput ? 'transparent' : '#717171')
  }

  useEffect(() => {
    if (!value) {
      setMaskedValue('')
      return
    }
    const inputLength = value.length
    const maskedString = value.substring(0, inputLength - 4).replace(/./g, '*')
    const visibleString = value.substring(inputLength - 4)
    setMaskedValue(maskedString + visibleString)
  }, [value])

  return (
    <div className="input-group">
      <span
        className={`mask_span ${!showMaskedInput ? 'd-none' : ''}`}
        onClick={() => inputFieldRef.current?.focus?.()}>
        {maskedValue}
      </span>
      <input
        ref={inputFieldRef}
        data-testid={testID}
        className={`mask_input unselectable form-control ${
          activePath.includes('/retail/recipients/') ? 'w-mask-input' : ''
        }`}
        autoComplete="off"
        style={{ color: inputColor }}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
      />
      {showToggle && (
        <div className="input-group-append">
          <a
            className={`input-group-text btn ${activePath.includes('/retail/recipients/') ? 'w-mask-input' : ''}`}
            data-testid={`${testID}-toggle`}
            onClick={() => toggleInputMask()}>
            <i className={showMaskedInput ? 'fa fa-unlock' : 'fa fa-lock'}></i>
          </a>
        </div>
      )}
    </div>
  )
}

InputMask.propTypes = {
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showToggle: PropTypes.bool,
  testID: PropTypes.string,
  value: PropTypes.string,
}

export default InputMask
