export default {
  account_name: null,
  balance: null,
  bank_account_detail: {
    account_number: null,
    bank_address: {
      country_code: null,
      country_name: null,
    },
    bank_name: null,
    bic: null,
    branch_name: null,
  },
  currency_code: null,
  customer_id: null,
  id: null,
  initializer: true,
  source: null,
  status: null,
}
