import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const refPhone = forwardRef((props, ref) => <input {...props} ref={ref} className="form-control" />)

refPhone.displayName = 'InputComponentForPhone'

const InputPhone = props => <PhoneInput {...props} inputComponent={refPhone} />

InputPhone.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
  value: PropTypes.any,
}

export default InputPhone
