import React from 'react'
import { isEmpty, isPlainObject } from 'lodash'

/**
 *  Function to render an array of string, string or objects key's values
 *  Mostly use to display errors
 * @param {string|array|object} errors
 * @param {Component} WrapperComponent
 */
const renderErrors = (errors, WrapperComponent) => {
  let values = errors
  if (isEmpty(values)) {
    return null
  }
  if (isPlainObject(values)) {
    values = Object.values(errors)
  }

  if (WrapperComponent) {
    return <WrapperComponent>{Array.isArray(values) ? values.join(', ') : values}</WrapperComponent>
  }

  return <span className="text-danger">{Array.isArray(values) ? values.join(', ') : values}</span>
}

export default renderErrors
