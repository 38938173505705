import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import formatCurrencyAmount from 'components/utils/format_currency_amount'
import renderErrors from 'components/helpers/render_errors'
import './styles.scss'

const Skeleton = () => (
  <div className="skeleton">
    <p className="m-0" />
  </div>
)

const ExchangeRate = props => {
  const {
    errors,
    showExchangeRate,
    isOneToOneDealWithAcknowledgement,
    isTransactionUpdating,
    paymentApprovalRequired,
    transaction = {},
    className,
    displayPage,
  } = props

  return (
    <React.Fragment>
      <div className={`w-layout-grid economics-grid ${displayPage === 2 && className ? 'split' : ''}`}>
        <div className={`w-layout-grid economics grid ${displayPage === 2 ? className : ''}`}>
          <div className="label">Total to pay</div>
          <div className="large">
            {isTransactionUpdating ? (
              <Skeleton />
            ) : (
              showExchangeRate &&
              `${formatCurrencyAmount(transaction?.receipt_amount)} ${transaction?.base_currency_code}`
            )}
          </div>
        </div>
        {showExchangeRate && Number(transaction.receipt_amount_diff_at_dm_and_sm) > 0 && (
          <div className={`w-layout-grid economics grid ${displayPage === 2 ? className : ''}`}>
            <div className="label">Total savings</div>
            <div>
              {isTransactionUpdating ? (
                <Skeleton />
              ) : (
                formatCurrencyAmount(transaction?.receipt_amount_diff_at_dm_and_sm)
              )}
            </div>
          </div>
        )}
        <div className={`w-layout-grid economics grid ${displayPage === 2 && className ? 'w-row' : ''}`}>
          <div className="label">Total fees</div>
          <div>
            {isTransactionUpdating ? (
              <Skeleton />
            ) : (
              showExchangeRate && `${formatCurrencyAmount(transaction?.fee)} ${transaction?.base_currency_code}`
            )}
          </div>
        </div>
        <div className={`w-layout-grid economics grid ${displayPage === 2 && className ? 'w-row' : ''}`}>
          <div className="label">{paymentApprovalRequired ? 'Indicative Exchange Rate' : 'Exchange Rate'}</div>
          {isTransactionUpdating ? (
            <Skeleton />
          ) : (
            showExchangeRate && (
              <div>
                <span className={`fx-label ${displayPage === 2 ? className : ''}`}>
                  1 {transaction?.base_currency_code} ={' '}
                </span>
                {transaction?.customer_exchange_rate}{' '}
                <span className={`fx-label ${displayPage === 2 ? className : ''}`}>
                  {transaction?.counter_currency_code}
                </span>
              </div>
            )
          )}
        </div>
        <div className={`w-layout-grid economics grid ${displayPage === 2 ? className : ''}`}>
          <div className="label">Recipient gets</div>
          <div className="large">
            {isTransactionUpdating ? (
              <Skeleton />
            ) : (
              showExchangeRate && `${transaction?.counter_currency_amount} ${transaction?.counter_currency_code}`
            )}
          </div>
        </div>
        {isOneToOneDealWithAcknowledgement && (
          <label>
            Same currency payment selected. To make a payment in a foreign currency select a different recipient
          </label>
        )}
      </div>
      {renderErrors(errors?.dealer_rate)}
      {renderErrors(errors?.['transaction_information.same_currency_deal_type'])}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { currencies } = state.trading.data
  return {
    tradingCurrencies: currencies,
  }
}

ExchangeRate.propTypes = {
  className: PropTypes.string,
  displayPage: PropTypes.number,
  errors: PropTypes.object,
  isOneToOneDealWithAcknowledgement: PropTypes.bool,
  isTransactionUpdating: PropTypes.bool,
  paymentApprovalRequired: PropTypes.bool,
  showExchangeRate: PropTypes.bool,
  transaction: PropTypes.shape({
    base_currency_code: PropTypes.string,
    base_currency_country_code: PropTypes.string,
    counter_currency_code: PropTypes.string,
    customer_exchange_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

export default connect(mapStateToProps, null)(ExchangeRate)
