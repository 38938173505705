import React from 'react'
import PropTypes from 'prop-types'
import renderErrors from 'components/helpers/render_errors'
import TextInput from 'components/helpers/form_fields/text_input'
import _ from 'lodash'

const bankDetailsInputs = [
  {
    beneficiaryAttribute: 'account.bank_name',
    name: 'bank_name',
  },
  {
    beneficiaryAttribute: 'account.bank_account_name',
    name: 'bank_account_name',
  },
  {
    beneficiaryAttribute: 'account.address.city',
    label: recipientBankDetails => `${recipientBankDetails.city.name} (${recipientBankDetails.branch_name.name})`,
    name: 'city',
  },
  {
    beneficiaryAttribute: 'account.branch_name',
    name: 'branch_name',
  },
  {
    beneficiaryAttribute: 'account.branch_code',
    name: 'branch_code',
  },
  {
    beneficiaryAttribute: 'account.address.address_line_1',
    name: 'address_line_1',
  },
  {
    beneficiaryAttribute: 'account.address.province',
    name: 'province',
  },
  {
    beneficiaryAttribute: 'account.address.post_code',
    name: 'post_code',
  },
  {
    beneficiaryAttribute: 'account.bank_code',
    name: 'bank_code',
  },
]

const BankDetails = props => {
  const { beneficiary, onBankDetailsChange, recipientBankDetails, errors } = props

  return (
    <div className="px-0">
      {bankDetailsInputs.map(
        attribute =>
          recipientBankDetails?.[attribute.name]?.enabled && (
            <div key={attribute.name} className="form-group mb-0">
              <TextInput
                className="w-input"
                lowerIndent="mb-0"
                name={attribute.name}
                onChange={event => onBankDetailsChange(attribute.beneficiaryAttribute, event.target.value)}
                value={_.get(beneficiary, attribute.beneficiaryAttribute)}
                label={
                  attribute.label ? attribute.label(recipientBankDetails) : recipientBankDetails[attribute.name].name
                }
                placeholder={recipientBankDetails[attribute.name].placeholder}
                required={recipientBankDetails[attribute.name].required}
              />
              {renderErrors(errors[attribute.beneficiaryAttribute])}
            </div>
          ),
      )}
    </div>
  )
}

BankDetails.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      address: PropTypes.shape({
        address_line_1: PropTypes.string,
        city: PropTypes.string,
        province: PropTypes.string,
      }),
      bank_code: PropTypes.string,
      bank_name: PropTypes.string,
    }),
  }),
  errors: PropTypes.object,
  isRenumerationCash: PropTypes.func.isRequired,
  onBankDetailsChange: PropTypes.func.isRequired,
  recipientBankDetails: PropTypes.shape({
    address_line_1: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    bank_code: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    bank_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    branch_name: PropTypes.shape({
      name: PropTypes.string,
    }),
    city: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    province: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
}

export default BankDetails
