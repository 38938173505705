/**
 * Validation util - field validation util
 * @param {Object} validators, value, field
 * @param {Object | string} value
 * @param {string} field
 */
export const checkValidity = ({ validators, value, field }) => {
  if (!validators || !validators.length) {
    return { error: null, valid: true }
  }
  for (let i = 0; i < validators.length; i++) {
    const error =
      validators[i].check &&
      validators[i].check({ field, message: validators[i].message, param: validators[i].param, value })
    if (error) {
      return { error, valid: false }
    }
  }
  return { error: null, valid: true }
}

/**
 * Validation util -form validation util
 * @param {Object} fields - object with fields to validate
 */
export const validateForm = fields => {
  const newState: any = {}
  let isValid = true
  const values = {}
  if (fields && Object.keys(fields).length) {
    Object.keys(fields).forEach((key: string) => {
      const field = fields[key]
      const validity = checkValidity({ field: key, validators: field.validators, value: field.value })
      values[key] = field.value
      if (!validity.valid) {
        isValid = false
      }
      newState[key] = {
        ...field,
        error: validity.error || null,
        isValid: validity.valid,
        touched: true,
      }
    })
  }
  return { isValid, newState, values }
}
