/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
/** Actions */
import {
  setCurrentUserNotificationSettings,
  setCustomerInformation,
  updateCurrentUserNotificationSettings,
  updateCustomerInfo,
} from 'actions/users'

/* Helpers */
import { CheckboxField, TextField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'

/**
 * Component for Account Promotions
 */
export const Promotions = ({
  customerId,
  customerInformation,
  userId,
  notificationSettings,
  setCurrentUserNotificationSettings,
  setCustomerInformation,
  updateCurrentUserNotificationSettings,
  updateCustomerInfo,
}) => {
  const [serverValidationErrors, setServerValidationErrors] = useState({})

  /**
   * Gets initial user settings data
   */
  useEffect(() => {
    isEmpty(notificationSettings) && setCurrentUserNotificationSettings({ userId })
    isEmpty(customerInformation) && setCustomerInformation(customerId, userId)
  }, [setCurrentUserNotificationSettings, setCustomerInformation, customerId, userId])

  if (isEmpty(notificationSettings) || isEmpty(customerInformation)) {
    return <Spinner />
  }

  const initialFields = {
    money_market_products:
      notificationSettings?.money_market_products?.sms ||
      notificationSettings?.money_market_products?.whatsapp ||
      false,
    sms: notificationSettings?.marketing?.sms || false,
    whatsapp: notificationSettings?.marketing?.whatsapp || false,
    xtra_savings: notificationSettings?.promotions?.sms || notificationSettings?.promotions?.whatsapp || false,
    xtra_savings_card_number: customerInformation?.xtra_savings_card_number || '',
  }

  const onClickHandler = (boolValueForAllCheckboxes, setFieldValue) => {
    Object.keys(initialFields).map(
      field =>
        (field === 'money_market_products' || field === 'xtra_savings') &&
        setFieldValue(field, boolValueForAllCheckboxes),
    )
  }

  return (
    <div className="accounts-card col-xl-9">
      <div className="col pl-0 pr-0">
        <div className="justify-content-between">
          <h2>Promotions</h2>
          <hr />
          <div className="mb-4">I want to receive marketing information about following products and services:</div>
          <Formik
            initialValues={initialFields}
            validationSchema={Yup.object().shape({
              money_market_products: Yup.boolean()
                .test('money_market_products', 'Please select at least one promotion.', function (value) {
                  if (
                    (!!this.resolve(Yup.ref('sms')) || !!this.resolve(Yup.ref('whatsapp'))) &&
                    !this.resolve(Yup.ref('xtra_savings')) &&
                    !this.resolve(Yup.ref('money_market_products'))
                  ) {
                    return false
                  }
                  return true
                })
                .nullable(),

              sms: Yup.boolean()
                .test('sms', 'Please select at least one promotion medium.', function (value) {
                  if (
                    (!!this.resolve(Yup.ref('money_market_products')) || !!this.resolve(Yup.ref('xtra_savings'))) &&
                    !this.resolve(Yup.ref('whatsapp')) &&
                    !this.resolve(Yup.ref('sms'))
                  ) {
                    return false
                  }
                  return true
                })
                .nullable(),

              whatsapp: Yup.boolean()
                .test('whatsapp', 'Please select at least one promotion medium.', function (value) {
                  if (
                    (!!this.resolve(Yup.ref('money_market_products')) || !!this.resolve(Yup.ref('xtra_savings'))) &&
                    !this.resolve(Yup.ref('sms')) &&
                    !this.resolve(Yup.ref('whatsapp'))
                  ) {
                    return false
                  }
                  return true
                })
                .nullable(),

              xtra_savings: Yup.boolean()
                .test('xtra_savings', 'Please select at least one promotion.', function (value) {
                  if (
                    (!!this.resolve(Yup.ref('sms')) || !!this.resolve(Yup.ref('whatsapp'))) &&
                    !this.resolve(Yup.ref('money_market_products')) &&
                    !this.resolve(Yup.ref('xtra_savings'))
                  ) {
                    return false
                  }
                  return true
                })
                .nullable(),
              xtra_savings_card_number: Yup.string().matches(
                /^\d{16}$/,
                'Xtra Savings number must be a 16-digit number',
              ),
            })}
            onSubmit={async (values, actions) => {
              const result = await Promise.allSettled([
                updateCustomerInfo({
                  customerId,
                  data: {
                    customer: { xtra_savings_card_number: values.xtra_savings_card_number },
                  },
                  id: userId,
                }).catch(error => {
                  if (error.response.status === 422) {
                    setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                  } else {
                    toast.error(error?.response?.data?.description)
                  }
                }),
                updateCurrentUserNotificationSettings({
                  data: {
                    notification_preference: {
                      marketing: { sms: values.sms, whatsapp: values.whatsapp },
                      money_market_products: {
                        sms: values.money_market_products && values.sms,
                        whatsapp: values.money_market_products && values.whatsapp,
                      },
                      promotions: {
                        sms: values.xtra_savings && values.sms,
                        whatsapp: values.xtra_savings && values.whatsapp,
                      },
                    },
                  },
                  userId,
                }).catch(error => {
                  if (error.response.status === 422) {
                    toast.error(error?.response?.data?.description)
                    setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                  } else {
                    toast.error(error?.response?.data?.description)
                  }
                }),
              ])
              !isEmpty(serverValidationErrors) && actions.setStatus(serverValidationErrors)
              actions.setSubmitting(false)
              setServerValidationErrors({})
              if (result.every(promise => promise.value)) {
                toast.success('Successfully updated promotions')
              }
            }}>
            {formik => (
              <Form>
                <TextField
                  label="Your Xtra Savings Card number"
                  name="xtra_savings_card_number"
                  type="text"
                  placeholder="e.g. 9021093456780151"
                />

                <div className="mb-4">
                  I want to receive marketing information about following products and services:
                </div>
                <CheckboxField name="money_market_products" top="6px">
                  <p className="checkbox-text">
                    I agree to receive marketing information with regards to other{' '}
                    <strong>Money Market Products</strong>.
                  </p>
                </CheckboxField>
                <CheckboxField name="xtra_savings" top="6px">
                  <p className="checkbox-text">
                    I agree to receive information with regards to <strong>Xtra Savings</strong> and promotions.
                  </p>
                </CheckboxField>

                <div className="mt-4 mb-4">
                  <button
                    type="button"
                    onClick={() => onClickHandler(true, formik.setFieldValue)}
                    className="btn btn-sm btn-flat mb-6 p-0">
                    Check all
                  </button>
                  <span className="mb-6 d-inline-block px-3 align-middle">/</span>
                  <button
                    type="button"
                    onClick={() => onClickHandler(false, formik.setFieldValue)}
                    className="btn btn-sm btn-flat mb-6 p-0 ml-0">
                    Uncheck all
                  </button>
                </div>
                <hr className="mt-4" />
                <div className="mt-4 mb-4">I want to receive the above marketing information via:</div>
                <CheckboxField name="sms" top="0px" message="SMS" />
                <br />
                <CheckboxField name="whatsapp" top="0px" message="Whatsapp" />

                <div className="form-group">
                  <Button
                    className={`btn btn-sm btn-success btn-width-200 space-10 mt-4 w-inline-block-button ${
                      formik.isSubmitting ? 'is-submitting' : 'not-submitting'
                    }`}
                    type="submit"
                    data-testid="update-changes-profile-promotions"
                    disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner spinner={false} /> : 'Update changes'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

Promotions.propTypes = {
  customerId: PropTypes.number,
  customerInformation: PropTypes.object,
  /** Object of identificationInfo from redux */
  identificationInfo: PropTypes.object,
  notificationSettings: PropTypes.object,
  setCurrentUserNotificationSettings: PropTypes.func,
  /** Thunk that sets identificationInfo to redux */
  setCustomerInformation: PropTypes.func,
  setIdentificationInformations: PropTypes.func,
  setRiskAssesmentInformation: PropTypes.func,
  /** Thunk that updates identificationInfo on server */
  updateCurrentUserNotificationSettings: PropTypes.func,
  /** Thunk that updates xtra savings id on server */
  updateCustomerInfo: PropTypes.func,
  user: PropTypes.shape({
    account_number: PropTypes.number,
    address: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
    }),
  }),
  /** Provides current userId */
  userId: PropTypes.number,
}

const mapStateToProps = ({ user }) => ({
  customerId: user.data.customerId,
  customerInformation: user.data.customerInformation,
  notificationSettings: user.data.notificationSettings,
  user: user.data.userDetails,
  userId: user.data.userId,
})

export default connect(mapStateToProps, {
  setCurrentUserNotificationSettings,
  setCustomerInformation,
  updateCurrentUserNotificationSettings,
  updateCustomerInfo,
})(Promotions)
