import React from 'react'
import PropTypes from 'prop-types'

export const CashExternalInfo = ({ cashExternalAddress, locationOptions }) => {
  /* Re-arrange fields for Cash External beneficiary details */
  const address = ['province', 'city', 'location']

  return address.map(field => (
    <div key={field} className="my-2">
      <label style={{ textTransform: 'capitalize' }}>{`Payout ${field}`}:&nbsp;</label>
      <strong>
        {field !== 'location'
          ? cashExternalAddress[field]
          : locationOptions?.find(location => location?.value === cashExternalAddress?.location)?.label}
      </strong>
    </div>
  ))
}

export const MobileWalletInfo = ({ beneficiary }) => {
  return (
    <ul className="list-unstyled">
      <li>
        Mobile Provider: <strong className="text-capitalize">{beneficiary?.wallet_provider?.name}</strong>
      </li>
      <li>
        Mobile Number:{' '}
        <strong>
          {beneficiary?.address?.mobile_isd_code} {beneficiary?.address?.mobile}
        </strong>
      </li>
    </ul>
  )
}

CashExternalInfo.propTypes = {
  cashExternalAddress: PropTypes.object,
  locationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

MobileWalletInfo.propTypes = {
  beneficiary: PropTypes.object,
}
