import env from '.env'
import axios from 'axios'
import { externalCash, mobileWallet } from './data'

const {
  externalCash: mockExternalCash,
  mobileWallet: mockMobileWallet,
} = env.mock?.createBeneficiary?.renumerationOptions
if (!mockExternalCash || !mockMobileWallet) {
  const mockedRenumerationOption = new RegExp(/.*recipients\/cw\/renumeration_preferences.*/g)

  axios.interceptors.response.use(
    function (response) {
      if (mockedRenumerationOption.test(response.config.url)) {
        if (mockExternalCash) {
          response.data[0].renumeration_options[externalCash.renumeration_type.toLowerCase()] = externalCash
        }
        if (mockMobileWallet) {
          response.data[0].renumeration_options[mobileWallet.renumeration_type.toLowerCase()] = mobileWallet
        }
      }
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      if (mockedRenumerationOption.test(error.config.url) && mockExternalCash) {
        error.data[0].renumeration_options[externalCash.renumeration_type.toLowerCase()] = externalCash
      }
      if (mockedRenumerationOption.test(error.config.url) && mockMobileWallet) {
        error.data[0].renumeration_options[mobileWallet.renumeration_type.toLowerCase()] = mobileWallet
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    },
  )
}
