/* eslint-disable camelcase */
import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import { RegistrationContext } from './registrationContext'
import { registrationReducer } from 'reducers/registration'
import { userLocalStorage } from 'config/local_storage'
/* Actions */
import {
  SET_CURRENT_CUSTOMER_CATEGORY,
  SET_REGISTRATION_FLOW,
  SET_TEMPORARY_AUTH_DATA,
  SET_CUSTOMER_CATEGORIES,
  SET_LOADING,
} from 'actions/registration'
/* API */
import fetchApi from 'components/utils/fetch_api'
import {
  getRegistrationFlow,
  createAccountLogin,
  updateUserAddresses,
  getCustomerCategories,
} from 'api/accounts/public/registration'

export const RegistrationState = ({ children }) => {
  const [state, dispatch] = useReducer(registrationReducer, {
    currentCustomerCategory: {},
    customerCategories: [],
    isLoading: false,
    regData: {},
    registerFlow: {},
    tempAuthData: {},
  })

  const setLoading = payload => {
    dispatch({ payload, type: SET_LOADING })
  }

  /** Action that saves current customer category */
  const setCurrentCustomerCategory = label => {
    const customerCategories = isEmpty(state.customerCategories)
      ? userLocalStorage.get('customerCategories')
      : state.customerCategories
    const payload = customerCategories.find(category => {
      return category.label === label
    })
    isEmpty(payload) && toast.error('Customer category is empty, check API response')
    dispatch({ payload, type: SET_CURRENT_CUSTOMER_CATEGORY })
    userLocalStorage.set('currentCustomerCategory', payload)
  }
  /** Thunk that fetches Customer Categories from server */
  const setCustomerCategories = async () => {
    setLoading(true)
    const response = await getCustomerCategories().catch(error => toast.error(error?.response?.data?.description))
    const payload = response.map(category => {
      const categoryParams = category => {
        switch (true) {
          case category.label === 'South African Four O One':
            return { isNative: true, transferLimit: { daily: 5000, monthly: 25000 } }
          case category.label === 'Foreign National Four One Seven':
            return { isNative: false, transferLimit: { daily: 4999, monthly: 25000 } }
          case category.label === 'Foreign National Four One Six':
            return { isNative: false, transferLimit: { daily: 3000, monthly: 10000 } }
          default:
            return null
        }
      }
      return { ...category, ...categoryParams(category) }
    })
    if (response) {
      dispatch({ payload, type: SET_CUSTOMER_CATEGORIES })
      setLoading(false)
      userLocalStorage.set('customerCategories', payload)
    }
  }
  /** Thunk that receives Registration Flow from server */
  const setRegistrationFlow = async (accountTypeId, id, customerCategoryName, countryCode) => {
    setLoading(true)
    const response = await getRegistrationFlow(accountTypeId, id, customerCategoryName, countryCode).catch(error =>
      toast.error(error?.response?.data?.description),
    )
    if (response) {
      dispatch({ payload: response, type: SET_REGISTRATION_FLOW })
      userLocalStorage.set('registerFlow', response)
      setLoading(false)
    }
  }
  /** Function that fetches dropdown options */
  const DropdownFetcher = (inputs, setDropdowns, customOptions = []) => {
    useEffect(() => {
      Object.keys(inputs)
        .filter(field =>
          isEmpty(customOptions) ? inputs[field].type === 'select' : customOptions.find(option => option === field),
        )
        .map(field => setDropdownOptions(inputs[field].data_url?.split('api/v1/')[1], field))
    }, [])

    const setDropdownOptions = async (url, field) => {
      const response = await fetchApi(`${url}`, { method: 'GET' }).then(res => res?.data)
      setDropdowns(prevState => ({ ...prevState, [field]: response }))
    }
  }
  /** Action that saves registration data received during registration process */
  const saveRegData = payload => {
    dispatch({ payload, type: SET_TEMPORARY_AUTH_DATA })
    userLocalStorage.set('tempAuthData', payload)
    userLocalStorage.set('registrationToken', payload.registration_token)
  }
  /** Function for destructuring the date */
  const getDate = string =>
    (([birth_date_year, birth_date_month, birth_date_day]) => ({
      birth_date_day,
      birth_date_month,
      birth_date_year,
    }))(string.split('-'))

  /** Thunk that POSTs registration data to server */
  const postRegData = async (values, setStatus) => {
    const regData = isEmpty(state.regData) ? userLocalStorage.get('regData') : state.regData
    const dateOfBirth = values.admin_user.birth_date_year ? getDate(values.admin_user.birth_date_year) : getDate('--')
    const registrationObj = {
      ...regData,
      customer: {
        ...regData.customer,
        admin_user_attributes: {
          ...regData.customer.admin_user_attributes,
          personal_info_attributes: values.admin_user.personal_info,
          ...dateOfBirth,
        },
        customer_address_attributes: {
          ...regData.customer.customer_address_attributes,
          ...values.customer_address,
          country_code: 'ZA',
        },
        principal_category_id:
          state.currentCustomerCategory.value || userLocalStorage.get('currentCustomerCategory').value,
      },
    }
    const response = await createAccountLogin(registrationObj).catch(async error => {
      error?.response?.status === 422
        ? setStatus(error.response.data.errors)
        : toast.error(error?.response?.data?.description)
    })
    if (response) {
      dispatch({ payload: response, type: SET_TEMPORARY_AUTH_DATA })
      saveRegData(registrationObj)
      userLocalStorage.set('tempAuthData', response)
      userLocalStorage.set('registrationToken', response.registration_token)
    }
    return response
  }

  const reCreateBirthDayObject = () => {
    const regData = isEmpty(state.regData) ? userLocalStorage.get('regData') : state.regData
    const { birth_date_day, birth_date_month, birth_date_year } = regData.customer.admin_user_attributes
    return {
      user: { birth_date_day, birth_date_month, birth_date_year },
    }
  }

  /** Thunk that updates user registration info on server */
  const updateRegData = async (payload, setStatus) => {
    const tempAuthData = isEmpty(state.tempAuthData) ? userLocalStorage.get('tempAuthData') : state.tempAuthData

    const isNative = state.currentCustomerCategory?.isNative || userLocalStorage.get('currentCustomerCategory').isNative

    const response = await updateUserAddresses(payload, tempAuthData.admin_user_id, tempAuthData.admin_user_id).catch(
      error => {
        if (!isNative || error?.response?.status !== 422) {
          throw error
        } else {
          setStatus(error?.response?.data?.errors)
        }
      },
    )
    return response
  }
  return (
    <RegistrationContext.Provider
      value={{
        DropdownFetcher,
        currentCustomerCategory: state.currentCustomerCategory,
        customerCategories: state.customerCategories,
        getDate,
        isLoading: state.isLoading,
        postRegData,
        reCreateBirthDayObject,
        regData: state.regData,
        registerFlow: state.registerFlow,
        saveRegData,
        setCurrentCustomerCategory,
        setCustomerCategories,
        setRegistrationFlow,
        tempAuthData: state.tempAuthData,
        updateRegData,
      }}>
      {children}
    </RegistrationContext.Provider>
  )
}
RegistrationState.propTypes = {
  /** Components witch will receive access to Registration context state */
  children: PropTypes.element,
}
