export default [
  'JPY',
  'BYR',
  'CLP',
  'DJF',
  'GNF',
  'ISK',
  'PYG',
  'RWF',
  'UGX',
  'UYI',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
  'ISK',
  'KRW',
]
