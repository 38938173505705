import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Form from 'components/helpers/form_fields'
import Spinner from 'components/helpers/spinner'
import { RECIPIENTS_PATH, USERS_PATH } from 'routes/paths/root'
import { HISTORY_PAYMENTS } from 'routes/paths/private/payments'
import './index.scss'
import { NavLink } from 'react-router-dom'

/**
 * Component for payments table
 */
class PaginationTable extends Component {
  constructor(props) {
    super(props)
    const filterOptions = {}
    props.columns.map(item => {
      if (item.sort_field) {
        filterOptions[item.sort_field] = { ...item, label: item.header, value: item.sort_field }
      }
    })
    this.state = {
      filterOptions,
      filteredData: false,
      loading: true,
      page: 1,
      searchType: 'text',
      selected: '',
      sortField: '',
      sortOrder: '',
    }
  }

  componentDidMount() {
    this.getTableData()
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  /**
   * generates params for network call
   */
  generateParams = ({ page, values }) => {
    let filteredData = false
    const { filterOptions, sortField, sortOrder } = this.state
    const { defaultParams, perPage } = this.props
    let params = { ...defaultParams, page, per_page: perPage }
    if (values && values.filter && values.search) {
      filteredData = true
      params[`q[${values.filter}_${filterOptions[values.filter].search_type || '_eq'}]`] = values.search
    }
    if (sortField) {
      params = { ...params, 'q[s]': `${sortField} ${sortOrder}` }
    }
    return { filteredData, params }
  }

  /**
   * getting data for table
   */
  getTableData = async ({ page = 1, values } = {}) => {
    const searchDisabled = this.form?.fields?.search.disabled
    this.toggleFieldsDisable(true)
    const { filteredData, params } = this.generateParams({ page, values })
    this.setState({ loading: true })
    await this.props.getData(params)
    if (!this._unmounted) {
      this.setState({ filteredData, loading: false }, () => {
        this.toggleFieldsDisable(false, searchDisabled)
      })
    }
  }

  /**
   * Gives an option to disable filter fields
   */
  toggleFieldsDisable = (filterDisabled, searchDisabled) => {
    this.form?.setValues({
      filter: { disabled: filterDisabled },
      search: { disabled: searchDisabled || filterDisabled },
    })
  }

  /**
   * on press on "Load more" button
   */
  onLoadMore = () => {
    if (!this.state.loading) {
      this.setState(
        prevState => ({ ...prevState, page: prevState.page + 1 }),
        () => {
          this.getTableData({ page: this.state.page })
        },
      )
    }
  }

  /**
   * press on search icon
   */
  onSearch = () => {
    const { values } = this.form.getValues()
    this.setState({ page: 1 }, () => {
      this.getTableData({ page: 1, values })
    })
  }

  /**
   * enable/disable search fields on filter change, changes type of search field
   */
  onSelectChange = ({ value }) => {
    if (typeof value !== 'string' && this.state.filteredData) {
      this.setState({ page: 1 }, () => {
        this.getTableData({ page: 1, values: {} })
      })
    }
    this.form.setValues({
      search: {
        disabled: !value.length,
        type: typeof value === 'string' ? this.state.filterOptions[value].type : 'text',
        value: '',
      },
    })
  }

  /**
   * on header sort press
   */
  onSort = field => () => {
    if (field) {
      this.setState(
        prevState => {
          if (prevState.sortField !== field) {
            return { sortField: field, sortOrder: 'ASC' }
          } else {
            let { sortOrder } = prevState
            if (!sortOrder) {
              sortOrder = 'ASC'
            } else if (sortOrder === 'ASC') {
              sortOrder = 'DESC'
            } else {
              sortOrder = ''
            }
            return { sortField: sortOrder ? field : '', sortOrder }
          }
        },
        () => {
          this.getTableData()
        },
      )
    }
  }

  /**
   * check if all of the data has been loaded
   */
  isAllLoaded = () => {
    const { page } = this.state
    const { data, perPage } = this.props
    let all = true
    if (page * perPage <= data?.length) {
      all = false
    }
    return all
  }

  /**
   * generates className by selected sort for header
   */
  generateSortClassName = item => {
    const { sortOrder, sortField } = this.state
    let className = ''
    if (item.sort) {
      className = 'fa-sort'
      if (item.sort_field === sortField) {
        if (sortOrder === 'DESC') {
          className = 'fa-sort-down'
        } else if (sortOrder === 'ASC') {
          className = 'fa-sort-up'
        }
      }
    }
    return className
  }

  /**
   * renders loader row
   */
  renderLoader = () => (
    <tr className="disable-hover" data-testid={`${this.props.testID}-row-loading`}>
      <td colSpan={this.props.columns.length}>
        <Spinner />
      </td>
    </tr>
  )

  /**
   * renders content
   */
  renderContent = () => {
    const { loading } = this.state
    const { columns, data, renderItem, testID } = this.props
    if (loading && !data?.length) {
      return this.renderLoader()
    }
    if (data?.length) {
      return (
        <React.Fragment>
          {data?.map(item => renderItem(item, testID))}
          {loading && this.renderLoader()}
        </React.Fragment>
      )
    }
    return (
      <tr data-testid={`${testID}-row-no-data`}>
        <td colSpan={columns.length}>
          <div className="d-flex justify-content-center align-items-center">No data found</div>
        </td>
      </tr>
    )
  }

  /**
   * render component
   */
  render() {
    const { loading, filterOptions, selected } = this.state
    const activePath = [USERS_PATH, HISTORY_PAYMENTS, RECIPIENTS_PATH].some(path => location.pathname.includes(path))

    const {
      columns,
      currencySelection,
      data,
      extraButtonLabel,
      extraButtonPress,
      testID,
      title,
      extraComponents,
      tableClassName,
      tableName,
      tableTop,
      onHide,
      showFilterField = true,
      recentTransfers,
      isLoadMoreButtonEnabled = false,
    } = this.props
    let classWidth = 'width-250'
    if (extraButtonLabel && extraButtonPress) {
      classWidth = 'width-200'
    }
    const initialFields = {
      filter: {
        className: `${activePath ? 'render-input' : classWidth} mb-0`,
        classNameContainer: `${classWidth}`,
        disabled: false,
        field: 'filter',
        multiple: false,
        onChange: this.onSelectChange,
        options: filterOptions,
        placeholder: 'Select...',
        type: 'select',
        value: selected,
      },
      search: {
        className: `${classWidth} mb-0`,
        disabled: true,
        field: 'search',
        label: 'Search',
        onSearch: this.onSearch,
        placeholder: `${activePath ? 'Search' : 'Enter query'}`,
        required: true,
        type: 'text',
      },
    }
    const isAllLoaded = this.isAllLoaded()
    return (
      <React.Fragment>
        <div className={`pagination-table ${tableName === 'transactions' ? 'mt-5' : ''}`}>
          <div>
            {tableClassName === 'history' && (
              <div className="close-modal history" onClick={onHide}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
            )}
            {tableTop && (
              <div
                className={`d-flex flex-column flex-lg-row justify-content-between pagination-table-border ${
                  activePath ? 'pagination-table-top' : ''
                }`}>
                <h2>{title}</h2>
                <Form
                  identificator={`${testID}-form`}
                  initialFields={initialFields}
                  fieldContainer={this.fieldContainer}>
                  {form => {
                    this.form = form
                    const { renderSearchbox, renderSelect } = form
                    return (
                      <div className={`${activePath ? 'table-filters' : 'd-flex ml-n3 mb-3 mt-3 mr-3'}`}>
                        {extraComponents || (
                          <React.Fragment>
                            {' '}
                            {showFilterField && !isEmpty(filterOptions) && renderSelect({ field: 'filter' })}
                            {!isEmpty(filterOptions) && renderSearchbox({ field: 'search' })}
                            {tableName === 'transactions' && extraButtonLabel && extraButtonPress && (
                              <div className="cta-button-block new-tran">
                                <button
                                  className="cta-button secondary"
                                  onClick={extraButtonPress}
                                  data-testid={`${testID}-header-button`}>
                                  {extraButtonLabel}
                                </button>
                              </div>
                            )}
                            {currencySelection && (
                              <button
                                className={`btn btn-sm btn-muted ml-3 ${classWidth}`}
                                data-testid={`${testID}-currency-selection`}
                                type="button">
                                <i className="icon-currency text-muted"></i>
                                <i className="text-muted">Currency Selection : All</i>
                              </button>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    )
                  }}
                </Form>
              </div>
            )}
            <div className={`grid-rows ${tableClassName || ''}`}>
              <div
                className={`${tableName === 'transactions' ? 'transactions-header' : 'rows header'} ${
                  tableClassName || ''
                }`}>
                {columns.map((item, index) => {
                  const { header, sort_field: sortField } = item
                  return (
                    <div
                      className="item-container"
                      key={`${header}-${index}`}
                      data-testid={`${testID}${sortField ? '-sort' : ''}`}>
                      <div>{header}</div>
                    </div>
                  )
                })}
              </div>
              {this.renderContent()}
            </div>
            {extraButtonLabel && extraButtonPress && tableClassName === 'recipients' && (
              <div className="cta-button-block">
                <button
                  className="cta-button secondary"
                  onClick={extraButtonPress}
                  data-testid={`${testID}-header-button`}>
                  {extraButtonLabel}
                </button>
              </div>
            )}
            {recentTransfers && (
              <div className="cta-button-block">
                <NavLink className="cta-button secondary" to="/retail/history-transfers">
                  View all transactions
                </NavLink>
              </div>
            )}
          </div>
          {!isEmpty(data) && isLoadMoreButtonEnabled && (
            <div className="btn-below">
              {!activePath && (
                <div className="ml-3 width-200">{`Displayed ${isAllLoaded ? 'all ' : ''}${data.length} entries`}</div>
              )}
              {!isAllLoaded && !loading && (
                <button
                  className="w-inline-block-button-load-more"
                  data-testid={`${testID}-load-more`}
                  onClick={this.onLoadMore}>
                  Load More
                </button>
              )}
              <div className="width-200" />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

PaginationTable.propTypes = {
  /** table columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      sort_field: PropTypes.string,
    }),
  ),
  /** flag to enable button for currency filter */
  currencySelection: PropTypes.bool,
  /** table data */
  data: PropTypes.arrayOf(PropTypes.object),
  /** default params that needs to be passed to the network call - can be empty object */
  defaultParams: PropTypes.object,
  /** extra top right button label */
  extraButtonLabel: PropTypes.string,
  /** extra top right call to action */
  extraButtonPress: PropTypes.func,

  /** Custom fields (Array of components) */
  extraComponents: PropTypes.array,
  /** for getting data for the table */
  getData: PropTypes.func.isRequired,

  isLoadMoreButtonEnabled: PropTypes.bool,

  /** Hide modal */
  onHide: PropTypes.func,

  /** number that should be shown in the table */
  perPage: PropTypes.number,

  recentTransfers: PropTypes.bool,

  /** renders item row */
  renderItem: PropTypes.func,
  showFilterField: PropTypes.bool,
  /** Table class name */
  tableClassName: PropTypes.string,

  /** Table class name */
  tableName: PropTypes.string,

  /** Table top */
  tableTop: PropTypes.bool,

  /** component testID */
  testID: PropTypes.string,

  /** component title */
  title: PropTypes.string.isRequired,
}

PaginationTable.defaultProps = {
  currencySelection: false,
  defaultParams: {},
  perPage: 10,
  testID: 'payments-table',
}

export default PaginationTable
