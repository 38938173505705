/* eslint-disable indent */
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, set, cloneDeep } from 'lodash'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
/** API */
import { create as sendBeneficiaryCreateEmail } from 'api/recipients/cw/beneficiary_notifications'
import { bankAccountsIndex as intermediaryBankAccountsIndex } from 'api/recipients/cw/intermediary_routing'
import { index as recipientSettings } from 'api/recipients/cw/settings'
import { preference as publicCountryPreference } from 'api/accounts/public/countries'
import { index as countryIndex, preference as recipientCountryPreference } from 'api/recipients/cw/countries'
import { index as renumerationPreferencesIndex } from 'api/recipients/cw/renumeration_preferences'
import { show as beneficiaryShow } from 'api/recipients/cw/beneficiaries'
import {
  identificationTypesE4F,
  getPayoutStates,
  getPayoutCities,
  getPayoutLocations,
  getRelationshipTypes,
} from 'api/dropdowns'
/* Base */
import { Button } from 'react-bootstrap'
import Spinner from 'components/helpers/spinner'
/* Helpers */
import FlagImage from 'components/helpers/flag_image'
import beneficiaryInitializer from 'data/initializers/beneficiary'
import OtpModal from 'components/pages/shared/modals/otp'
import BeneficiaryNameEmail from './modules/beneficiary_name_email'
import SecondPage from './pages/second'
import ThirdPage from './pages/third'
import './styles.scss'

class recipientForm extends Component {
  beneficiaryNameEmailFormRef = createRef(null)
  beneficiaryDetailsFormRef = createRef(null)
  state = {
    beneficiary: isEmpty(this.props.currentBeneficiary) ? beneficiaryInitializer : this.props.currentBeneficiary,
    cashPrefs: null,
    countriesList: [],
    currenciesCache: [],
    currentCurrenciesList: [],
    displayDeliveryMethods: false,
    displayPage: isEmpty(this.props.currentBeneficiary) ? 1 : 2,

    errors: {},
    fetchingBeneficiaryData: false,
    formLabelPreferences: undefined,
    identificationTypesE4F: [],
    isLoading: {
      formSubmit: false,
      renumerationPreference: false,
    },
    localPayoutProvider: {},
    localPayoutProviders: [],
    localTransfer: null,
    mobileProviders: [],
    mobileWallet: {},
    modal: {
      otp: false,
    },
    otp: '',
    paymentMethods: [],
    payoutDetails: {},
    payoutFields: {},
    priorityTransfer: null,
    recipientAddress: {},
    recipientBankDetails: {},
    recipientDetails: {},
    recipientEmail: {
      email: {
        display_placeholder_only: false,
        enabled: true,
        locale: 'beneficiary.beneficiary_email_address',
        name: 'Beneficiary Email Address',
        placeholder: 'Enter email Address',
        position: 9,
        required: false,
      },
    },
    recipientNames: {
      first_name: {
        display_placeholder_only: false,
        enabled: true,
        name: 'First Names',
        placeholder: 'Enter beneficiary first Name',
        position: 0,
        required: true,
      },
      last_name: {
        display_placeholder_only: false,
        enabled: true,
        name: 'Last Name',
        placeholder: 'Enter beneficiary last Name',
        required: true,
      },
    },
    recipientSettings: null,
    relationshipTypes: [],
    renumerationPreference: {},
    renumerationPreferences: [],
    swiftPayoutProvider: {},
    swiftPayoutProviders: [],
  }

  componentDidMount() {
    const isNewBeneficiary = this.state.beneficiary?.initializer

    countryIndex({ q: { allow_beneficiaries_eq: true } }).then(response =>
      this.setState({
        countriesList: response?.data.map(countryOption => ({
          countryCode: countryOption.country_code,
          currencyCode: countryOption.currency_code,
          label: (
            <React.Fragment>
              <FlagImage countryCode={countryOption.country_code} />
              {countryOption.country_name}
            </React.Fragment>
          ),
          value: countryOption.country_name,
        })),
      }),
    )

    identificationTypesE4F().then(res =>
      this.setState(prevState => ({
        ...prevState,
        payoutFields: {
          ...prevState.payoutFields,
          identification_type: res?.data,
        },
      })),
    )
    getRelationshipTypes().then(res => this.setState({ relationshipTypes: res?.data }))
    !isNewBeneficiary && this.setState({ fetchingBeneficiaryData: true }, this.fetchBeneficiary)
  }

  componentDidUpdate(prevProps, prevState) {
    const { beneficiary } = this.state

    if (prevState.beneficiary.currency_code !== beneficiary.currency_code && beneficiary.currency_code) {
      this.setRenumerationPreferences()
      this.setBeneficiaryMobileIsdCode()
      this.settingsIndex()
    }

    if (prevState.beneficiary.renumeration_type_name !== beneficiary.renumeration_type_name) {
      this.updateRenumerationDetails()
    }
  }

  toggleModal = name =>
    this.setState(state => ({
      modal: {
        ...this.state.modal,
        [name]: !state.modal[name],
      },
    }))

  isRenumerationCash = () => this.state.beneficiary?.renumeration_type_name?.toLowerCase?.() === 'cash'

  isRenumerationSwift = () => this.state.beneficiary?.renumeration_type_name?.toLowerCase?.() === 'swift'

  isRenumerationLocal = () => this.state.beneficiary?.renumeration_type_name?.toLowerCase?.() === 'local'

  isRenumerationExternal = () =>
    this.state.beneficiary?.renumeration_type_name?.toLowerCase?.().replace(/ /g, '_') === 'cash_external'

  isRenumerationExternalAlt = () =>
    this.state.beneficiary?.renumeration_type_name?.toLowerCase?.().replace(/ /g, '_') === 'cash_external_alt'

  isRenumerationMobile = () =>
    this.state.beneficiary?.renumeration_type_name?.toLowerCase?.() === 'mobile_wallet' ||
    this.state.beneficiary?.renumeration_type_name?.toLowerCase?.() === 'mobile wallet'

  isBankTransference = () => !isEmpty(this.state.recipientBankDetails)

  showThirdPage = () => this.isRenumerationSwift() || this.isRenumerationLocal()

  onMobileWalletChange = (beneficiaryAttribute, value) =>
    this.setState(state => ({
      ...state,
      beneficiary: set(cloneDeep(state.beneficiary), `mobile_wallet.${beneficiaryAttribute}`, value),
    }))

  onLocalPayoutProviderChange = (beneficiaryAttribute, value) =>
    this.setState(state => ({
      ...state,
      beneficiary: set(cloneDeep(state.beneficiary), `local_provider.${beneficiaryAttribute}`, value),
    }))

  onSwiftPayoutProviderChange = (beneficiaryAttribute, value) =>
    this.setState(state => ({
      ...state,
      beneficiary: set(cloneDeep(state.beneficiary), `swift_provider.${beneficiaryAttribute}`, value),
    }))

  onPayoutLocationChange = (beneficiaryAttribute, value) => {
    switch (beneficiaryAttribute) {
      case 'payout_city':
        return this.setPayoutLocations(value)

      case 'payout_state':
        return this.setPayoutCities(value)

      default:
        break
    }
    this.setState(state => ({
      ...state,
      beneficiary: set(cloneDeep(state.beneficiary), `payout_address.${beneficiaryAttribute}`, value),
    }))
  }

  onIdentificationChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(cloneDeep(beneficiary), `identification.${beneficiaryAttribute}`, value),
      }
    })
  }

  onBeneficiaryOrderingCustomerChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(cloneDeep(beneficiary), `ordering_customer.${beneficiaryAttribute}`, value),
      }
    })
  }

  onBeneficiaryAddressChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(cloneDeep(beneficiary), `address.${beneficiaryAttribute}`, value),
      }
    })
  }

  onBeneficiaryShortNameChange = shortName =>
    this.setState({
      beneficiary: {
        ...this.state.beneficiary,
        short_name: shortName,
      },
    })

  onIntermediaryBankAttributeChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(cloneDeep(beneficiary), `intermediary_routing_bank_account.${beneficiaryAttribute}`, value),
      }
    })
  }

  onBankDetailsChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(beneficiary, beneficiaryAttribute, value),
      }
    })
  }

  onBeneficiaryAccountDetailsChange = (beneficiaryAttribute, value) => {
    this.setState(state => {
      const { beneficiary } = state
      return {
        beneficiary: set(cloneDeep(beneficiary), `account.${beneficiaryAttribute.trim()}`, value),
      }
    })
  }

  onBeneficiaryIsDefaultChange = () =>
    this.setState(state => ({
      beneficiary: {
        ...state.beneficiary,
        is_default: !state.beneficiary.is_default,
      },
    }))

  goNextPage = () => this.setState({ displayPage: this.state.displayPage + 1 })

  onRenumerationTypeNameChange = preference => {
    const {
      address: { country_code: countryCode },
      currency_code: currencyCode,
    } = this.state.beneficiary
    preference.renumeration_type === 'cash_external' && this.setPayoutStates(countryCode, currencyCode, 3)
    preference.renumeration_type === 'cash_external_alt' && this.setPayoutStates(countryCode, currencyCode, 7)
    this.setState(state => ({
      beneficiary: {
        ...state.beneficiary,
        renumeration_type_name: preference?.renumeration_type,
      },
    }))
  }

  onPayoutPartnerChange = partnerId =>
    this.setState(state => ({ beneficiary: { ...state.beneficiary, payout_partner: { partner_id: partnerId } } }))

  onPayoutPartnerAltChange = partnerId =>
    this.setState(state => ({ beneficiary: { ...state.beneficiary, payout_partner_alt: { partner_id: partnerId } } }))

  onCurrencyChange = currencyCode =>
    this.setState(state => ({ beneficiary: { ...state.beneficiary, currency_code: currencyCode } }))

  setPayoutStates = (country, currency, payoutMethodValue) =>
    getPayoutStates({
      destination_country_code: country,
      destination_currency_code: currency,
      payment_method_value: payoutMethodValue,
    }).then(res => {
      this.setState(prevState => ({
        ...prevState,
        beneficiary: {
          ...prevState.beneficiary,
          payout: {
            ...prevState.beneficiary.payout,
            payout_state: '',
          },
        },

        payoutFields: {
          ...prevState.payoutFields,
          payout_state: res?.data,
        },
      }))
    })

  setPayoutCities = state => {
    this.setState(state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        payout_city: true,
        payout_location: true,
      },
    }))
    getPayoutCities({
      country_state: state,
      destination_country_code: this.state.beneficiary.address.country_code,
      destination_currency_code: this.state.beneficiary.currency_code,
      payment_method_value: 3,
    })
      .then(res => {
        this.setState(prevState => ({
          ...prevState,
          beneficiary: {
            ...prevState.beneficiary,
            payout: {
              ...prevState.beneficiary.payout,
              payout_city: '',
              payout_location: '',
            },
          },
          payoutFields: {
            ...prevState.payoutFields,
            payout_city: res?.data,
            payout_location: [],
          },
        }))
      })
      .finally(() =>
        this.setState(state => ({
          ...state,
          isLoading: {
            ...state.isLoading,
            payout_city: false,
            payout_location: false,
          },
        })),
      )
  }

  setPayoutLocations = city => {
    this.setState(state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        payout_location: true,
      },
    }))
    getPayoutLocations({
      city_name: city,
      destination_country_code: this.state.beneficiary.address.country_code,
      destination_currency_code: this.state.beneficiary.currency_code,
      payment_method_value: 3,
    })
      .then(res => {
        this.setState(prevState => ({
          ...prevState,
          beneficiary: {
            ...prevState.beneficiary,
            payout: {
              ...prevState.beneficiary.payout,
              payout_location: '',
            },
          },

          payoutFields: {
            ...prevState.payoutFields,
            payout_location: res?.data,
          },
        }))
      })
      .finally(() =>
        this.setState(state => ({
          ...state,
          isLoading: {
            ...state.isLoading,
            payout_location: false,
          },
        })),
      )
  }

  onRelationshipTypeChange = relationshipType => {
    this.setState(state => ({
      beneficiary: {
        ...state.beneficiary,
        relation_with_customer: relationshipType,
      },
    }))
  }

  onCountryChange = (countryCode, setFieldValue) => {
    const setDefaultCurrency = () => {
      const { currentCurrenciesList } = this.state
      const currencyCode =
        currentCurrenciesList.length === 1
          ? currentCurrenciesList[0].value
          : currentCurrenciesList.find(option => String(option.countryCode) === String(countryCode))?.value
      this.setState(
        state => ({
          beneficiary: {
            ...state.beneficiary,
            currency_code: currencyCode || currentCurrenciesList[0].value,
          },
        }),
        () => setFieldValue('currency_code', currencyCode),
      )
    }
    let { currenciesCache, beneficiary } = this.state

    beneficiary = cloneDeep(
      isEmpty(this.props.currentBeneficiary) ? beneficiaryInitializer : this.props.currentBeneficiary,
    )
    beneficiary.address.country_code = countryCode
    beneficiary.address.first_name = this.state.beneficiary.address.first_name
    beneficiary.address.last_name = this.state.beneficiary.address.last_name
    beneficiary.intermediary_routing_bank_account.address.country_code = countryCode

    if (currenciesCache[countryCode]) {
      this.setState(
        {
          beneficiary,
          currentCurrenciesList: currenciesCache[countryCode],
          displayDeliveryMethods: false,
          displayPaymentTypes: false,
        },
        setDefaultCurrency,
      )
      return
    }

    this.setState({
      beneficiary,
      currentCurrenciesList: [],
      displayDeliveryMethods: false,
      displayPaymentTypes: false,
    })

    recipientCountryPreference(countryCode).then(response => {
      const currencyOption = response?.data?.currencies.map(ccy => ({
        countryCode: ccy.country_code,
        label: (
          <React.Fragment>
            <FlagImage countryCode={countryCode} />
            {ccy.currency_name} - {ccy.currency_code}
          </React.Fragment>
        ),
        value: ccy.currency_code,
      }))
      this.setState(
        {
          currenciesCache: {
            ...currenciesCache,
            [countryCode]: currencyOption,
          },
          currentCurrenciesList: currencyOption,
        },
        setDefaultCurrency,
      )
    })
  }

  submitBeneficiaryForm = () => {
    const isNewBeneficiary = isEmpty(this.props.currentBeneficiary)
    const { displayPage, beneficiary } = this.state
    const valuesRefObj = {
      1: this.beneficiaryNameEmailFormRef.current,
      2: this.beneficiaryDetailsFormRef.current,
    }

    if (displayPage === 1 || (displayPage === 2 && this.showThirdPage())) {
      valuesRefObj?.[displayPage]?.handleSubmit()
      valuesRefObj?.[displayPage]?.isValid &&
        this.setState(
          state => ({
            beneficiary: {
              ...state.beneficiary,
              ...valuesRefObj?.[displayPage]?.values,
              address: { ...state.beneficiary.address, ...valuesRefObj?.[displayPage]?.values.address },
            },
          }),
          () => {
            // prevent "locking" the form of "pages" other than the first
            if (displayPage !== 1) {
              valuesRefObj?.[displayPage]?.setSubmitting(false)
            }
            this.goNextPage()
          },
        )
      return
    }

    if (displayPage === 2 && !this.showThirdPage()) {
      valuesRefObj?.[2]?.handleSubmit()
      if (!valuesRefObj?.[2].isValid) {
        return
      }
    }
    valuesRefObj?.[2]?.setSubmitting(false)

    const beneficiaryTypeObj = {
      'cash external': 'Cash',
      'cash external alt': 'Cash',
      cash_external: 'Cash',
      cash_external_alt: 'Cash',
      local: 'Local',
      'mobile wallet': 'Wallet',
      mobile_wallet: 'Wallet',
      swift: 'Swift',
    }

    const data = {
      ...beneficiary,
      account_attributes: {
        ...beneficiary.account,
        address_attributes: {
          ...beneficiary.account?.address,
          country_code: beneficiary?.address?.country_code,
        },
        bic: beneficiary?.account?.bic || beneficiary?.account?.bank_code,
        blc: beneficiary?.account?.blc || beneficiary?.account?.bank_code,
        payment_mode: beneficiary?.renumeration_type_name,
      },
      address_attributes: beneficiary.address,
      id_card_attributes: {
        number: beneficiary?.identification?.identification_number,
        type_id: beneficiary?.payout_address?.identification_type,
      },
      intermediary_routing_bank_account_attributes: {
        ...beneficiary.intermediary_routing_bank_account,
        address_attributes: {
          ...beneficiary?.intermediary_routing_bank_account?.address,
          city: 'No city', // This attribute is required on API but not used on this project
        },
        bank_name: 'No bank name provided', // This attribute is required on API but not used on this project
        bic: beneficiary?.account?.bic, // This attribute is required on API but not used on this project
      },
      local_provider_attributes: {
        name: beneficiary.local_provider?.local_payout_provider,
      },
      ordering_customer_attributes: beneficiary?.ordering_customer,
      payout_address_attributes: {
        city: beneficiary?.payout_address?.payout_city,
        location: beneficiary?.payout_address?.payout_location,
        province: beneficiary?.payout_address?.payout_state,
      },
      payout_partner_alt_attributes: { partner_id: beneficiary?.payout_partner_alt?.partner_id },
      payout_partner_attributes: { partner_id: beneficiary?.payout_partner?.partner_id },
      provider_attributes: {
        name: beneficiary.mobile_wallet?.wallet_provider,
      },
      relation_with_customer: beneficiary?.relation_with_customer,
      renumeration_type_name: beneficiary?.renumeration_type_name,
      short_name: beneficiary?.address?.short_name,
      swift_provider_attributes: {
        name: beneficiary.swift_provider?.swift_payout_provider,
      },
    }

    this.setState({ isLoading: { ...this.state.isLoading, formSubmit: true } }, async () => {
      const apiCallName = `${isNewBeneficiary ? 'create' : 'update'}${
        beneficiaryTypeObj[beneficiary.renumeration_type_name.toLowerCase()] || ''
      }`
      try {
        const module = await import('api/recipients/cw/beneficiaries')
        const response = await module?.[apiCallName](data, beneficiary.id)
        response && this.handleSubmitSuccess(response?.data)
      } catch (e) {
        this.handleErrors(e)
      } finally {
        this.setState({ isLoading: { ...this.state.isLoading, formSubmit: false } })
      }
    })
  }

  handleSubmitSuccess(beneficiary) {
    beneficiary.status === 'Approved'
      ? sendBeneficiaryCreateEmail(beneficiary?.id)
          .then(() => toast.success('Beneficiary is successfully created'))
          .catch(() => toast.error('Failed to send confirmation email'))
      : toast.success(
          `Beneficiary is successfully created but can not be used in deal due status as ${beneficiary.status}`,
        )
    this.props.history.push('/retail/recipients/')
  }

  handleErrors(errors) {
    if (!errors) {
      return
    }

    errors?.otp && this.setState({ modal: { ...this.state.modal, otp: true } })

    const ibdRequired = this.isRenumerationSwift() && this.state.recipientSettings?.intermediary_routing
    if (ibdRequired && !this.state.showInterBankDetails) {
      errors.ibdRequired = ['Intermediary bank details required']
    }

    if (errors?.response?.data?.errors?.base) {
      // this is the structure returned by the create or update recipient
      // when the underlying provider has a specific error.
      // we cannot map this to a field so just display up top with toast
      toast.error(errors?.response?.data?.errors?.base?.join(','))
    } else if (errors?.renumeration_type_name) {
      toast.error(errors?.renumeration_type_name)
    }

    const errorMessages = errors?.response?.data?.errors
    this.setState({ errors: errorMessages })
  }

  addIntermediaryBankDetails = () => {
    const { beneficiary } = this.state
    const { initializer: isNewBeneficiary } = beneficiary || false
    if (!isNewBeneficiary && beneficiary.intermediary_routing_bank_account) {
      const ibd = beneficiary.intermediary_routing_bank_account
      if (ibd.standard_intermediary_routing_account) {
        this.setState({
          disableInterBankDetails: true,
          displayStdIbdDetails: true,
        })
      }
    }
    this.setState({ showInterBankDetails: true })
    this.intermediaryRoutingBankAccountsIndex()
  }

  removeIntermediaryBankDetails = () => {
    const { beneficiary } = this.state
    const { initializer: isNewBeneficiary } = beneficiary || false
    this.setState(state => ({
      beneficiary: {
        ...state.beneficiary,
        intermediary_routing_bank_account: beneficiaryInitializer.intermediary_routing_bank_account,
      },
      showInterBankDetails: false,
    }))
    if (!(isNewBeneficiary && beneficiary.intermediary_routing_bank_account.standard_intermediary_routing_account)) {
      this.setState(state => {
        const { beneficiary } = state
        beneficiary.intermediary_routing_bank_account._destroy = true
        return {
          beneficiary,
        }
      })
    }
  }

  intermediaryRoutingBankAccountsIndex = () => {
    const { beneficiary } = this.state
    const params = {
      q: {
        bank_country_code_eq: beneficiary.address.country_code,
        currency_code_eq: beneficiary.currency_code,
      },
    }
    intermediaryBankAccountsIndex(params).then(response => {
      const interBankDetails = response.data
      if (!isEmpty(interBankDetails)) {
        const ibd = beneficiary.intermediary_routing_bank_account
        ibd.bank_name = interBankDetails[0].bank_name
        ibd.blc = interBankDetails[0].blc
        ibd.bic = interBankDetails[0].bic
        ibd.account_number = interBankDetails[0].account_number
        if (interBankDetails[0].address) {
          ibd.address.city = interBankDetails[0].address.city
          ibd.address.address_line_1 = interBankDetails[0].address.address_line_1
          ibd.address.country_code = interBankDetails[0].address.country_code
        }
        this.setState(state => ({
          beneficiary: {
            ...state.beneficiary,
            intermediary_routing_bank_account: ibd,
          },
          disableInterBankDetails: true,
          displayStdIbdDetails: true,
        }))
      } else {
        this.setState({
          disableInterBankDetails: false,
          displayStdIbdDetails: false,
        })
      }
    })
  }

  replaceStdDetails = () => {
    const { beneficiary } = this.state
    beneficiary.intermediary_routing_bank_account = cloneDeep(beneficiaryInitializer.intermediary_routing_bank_account)
    beneficiary.intermediary_routing_bank_account.address.country_code = beneficiary.address.country_code
    this.setState({
      beneficiary,
      disableInterBankDetails: false,
      displayStdIbdDetails: false,
    })
  }

  setRenumerationPreferences = () => {
    const { beneficiary } = this.state
    const { initializer: isNewBeneficiary } = beneficiary || false
    const countryCode = this.isRenumerationCash()
      ? beneficiary?.account?.address?.country_code
      : beneficiary?.address?.country_code
    if (beneficiary?.currency_code && countryCode) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          renumerationPreference: true,
        },
      })
      const params = {
        q: {
          country_code_eq: countryCode,
          currency_code_eq: beneficiary.currency_code,
        },
      }
      renumerationPreferencesIndex(params).then(response => {
        const renumerationPreferences = response?.data?.[0]?.renumeration_options
        this.setState({ renumerationPreferences }, () =>
          isNewBeneficiary ? this.updateRenumerationBasedOnPreferences() : this.updateRenumerationDetails(),
        )
      })
    }
  }

  updateRenumerationDetails = () => {
    const { renumerationPreferences, beneficiary } = this.state

    const renumerationPreference =
      renumerationPreferences[beneficiary.renumeration_type_name?.toLowerCase?.().replace(/ /g, '_')]
    // Due to the API returns renumeration_type_name of Mobile wallet with a name different from the key

    const {
      local_payout_provider_details: localPayoutProvider,
      payout_location_details: payoutDetails,
      recipient_address: recipientAddress,
      recipient_bank_details: recipientBankDetails,
      recipient_details: recipientDetails,
      swift_payout_provider_details: swiftPayoutProvider,
      wallet_payment_details: mobileWallet,
    } = renumerationPreference?.form_preferences?.sections || {}

    this.setState({
      fetchingBeneficiaryData: false,
      isLoading: { ...this.state.isLoading, renumerationPreference: false },
      localPayoutProvider: localPayoutProvider?.inputs,
      mobileWallet: mobileWallet?.inputs,
      payoutDetails: payoutDetails?.inputs,
      recipientAddress: recipientAddress?.inputs,
      recipientBankDetails: recipientBankDetails?.inputs,
      recipientDetails: recipientDetails?.inputs,
      swiftPayoutProvider: swiftPayoutProvider?.inputs,
    })
  }

  updateRenumerationBasedOnPreferences = () => {
    const { renumerationPreferences } = this.state
    const { swift: priorityTransfer, local: localTransfer, cash: cashPrefs } = renumerationPreferences || {}

    const displayPaymentTypes = priorityTransfer && cashPrefs
    const displayDeliveryMethods = priorityTransfer && localTransfer
    const paymentMethods = Object.keys(renumerationPreferences).map(item => ({
      label: renumerationPreferences[item].form_preferences.name,
      value: renumerationPreferences[item],
    }))

    this.setState({
      cashPrefs,
      displayDeliveryMethods,
      displayPaymentTypes,
      localTransfer,
      paymentMethods,
      priorityTransfer,
    })
  }

  setBeneficiaryMobileIsdCode = () => {
    const { beneficiary } = this.state
    const countryCode = beneficiary.address.country_code
    publicCountryPreference(countryCode).then(response => {
      this.setState(state => {
        const { beneficiary } = state
        beneficiary.address.mobile_isd_code = `+${response.data.telephone_routing?.country_code}`
        return {
          beneficiary,
        }
      })
    })
  }

  settingsIndex = () => {
    const { beneficiary } = this.state
    recipientSettings(beneficiary.currency_code).then(response => this.setState({ recipientSettings: response.data }))
  }

  fetchBeneficiary = () =>
    beneficiaryShow(this.state.beneficiary.id).then(response =>
      this.setState({ beneficiary: response?.data }, this.setRenumerationPreferences),
    )

  render() {
    const { displayPage, modal, errors, isLoading } = this.state
    const isNewBeneficiary = this.state.beneficiary?.initializer

    return (
      <div className="content-container shadows">
        <div className="centered d-flex flex-column">
          <h1 className="page-title white align-self-center">
            <span className="bar-1 ml-0">{isNewBeneficiary ? 'New' : 'Edit'} beneficiary </span>
          </h1>
          <div className="beneficiary-card">
            <fieldset disabled={this.state.isLoading.formSubmit}>
              <React.Fragment>
                {isNewBeneficiary && (
                  <BeneficiaryNameEmail
                    {...this.props}
                    {...this.state}
                    ref={this.beneficiaryNameEmailFormRef}
                    isRenumerationCash={this.isRenumerationCash}
                    isRenumerationSwift={this.isRenumerationSwift}
                    isRenumerationLocal={this.isRenumerationLocal}
                    isBankTransference={this.isBankTransference}
                    onBeneficiaryAddressChange={this.onBeneficiaryAddressChange}
                    onCountryChange={this.onCountryChange}
                    onCurrencyChange={this.onCurrencyChange}
                    onRenumerationTypeNameChange={this.onRenumerationTypeNameChange}
                  />
                )}

                {(displayPage === 2 || displayPage === 3) && (
                  <SecondPage
                    {...this.props}
                    {...this.state}
                    ref={this.beneficiaryDetailsFormRef}
                    isNewBeneficiary={isEmpty(this.props.currentBeneficiary)}
                    onBeneficiaryAddressChange={this.onBeneficiaryAddressChange}
                    onBeneficiaryIsDefaultChange={this.onBeneficiaryIsDefaultChange}
                    onBeneficiaryOrderingCustomerChange={this.onBeneficiaryOrderingCustomerChange}
                    onPaymentModeChange={this.onPaymentModeChange}
                    onPayoutLocationChange={this.onPayoutLocationChange}
                    onIdentificationChange={this.onIdentificationChange}
                    isRenumerationSwift={this.isRenumerationSwift}
                    isRenumerationLocal={this.isRenumerationLocal}
                    isRenumerationCash={this.isRenumerationCash}
                    isRenumerationExternal={this.isRenumerationExternal}
                    isRenumerationExternalAlt={this.isRenumerationExternalAlt}
                    isRenumerationMobile={this.isRenumerationMobile}
                    isBankTransference={this.isBankTransference}
                    addIntermediaryBankDetails={this.addIntermediaryBankDetails}
                    onBankDetailsChange={this.onBankDetailsChange}
                    onBeneficiaryAccountDetailsChange={this.onBeneficiaryAccountDetailsChange}
                    onBeneficiaryShortNameChange={this.onBeneficiaryShortNameChange}
                    onIntermediaryBankAttributeChange={this.onIntermediaryBankAttributeChange}
                    onMobileWalletChange={this.onMobileWalletChange}
                    onLocalPayoutProviderChange={this.onLocalPayoutProviderChange}
                    onSwiftPayoutProviderChange={this.onSwiftPayoutProviderChange}
                    replaceStdDetails={this.replaceStdDetails}
                    removeIntermediaryBankDetails={this.removeIntermediaryBankDetails}
                    onRelationshipTypeChange={this.onRelationshipTypeChange}
                    onPayoutPartnerChange={this.onPayoutPartnerChange}
                    onPayoutPartnerAltChange={this.onPayoutPartnerAltChange}
                  />
                )}

                {displayPage === 3 && this.showThirdPage() && (
                  <ThirdPage
                    {...this.props}
                    {...this.state}
                    addIntermediaryBankDetails={this.addIntermediaryBankDetails}
                    onBankDetailsChange={this.onBankDetailsChange}
                    isRenumerationSwift={this.isRenumerationSwift}
                    onBeneficiaryAccountDetailsChange={this.onBeneficiaryAccountDetailsChange}
                    onBeneficiaryShortNameChange={this.onBeneficiaryShortNameChange}
                    onIntermediaryBankAttributeChange={this.onIntermediaryBankAttributeChange}
                    replaceStdDetails={this.replaceStdDetails}
                    removeIntermediaryBankDetails={this.removeIntermediaryBankDetails}
                  />
                )}

                <div className="mt-4" data-testid="bene-complete-form">
                  <Button
                    disabled={false}
                    onClick={this.submitBeneficiaryForm}
                    variant="primary"
                    size="sm"
                    className="cta-button w-100"
                    type="submit">
                    {isLoading.formSubmit ? (
                      <Spinner spinner={false} />
                    ) : displayPage === 1 || displayPage === 2 ? (
                      'Continue'
                    ) : (
                      'Complete'
                    )}
                  </Button>
                </div>
              </React.Fragment>
            </fieldset>
          </div>
        </div>
        <OtpModal
          show={modal.otp}
          onHide={() => this.toggleModal('otp')}
          errors={errors?.otp}
          onSubmit={otp => this.setState({ otp })}
        />
      </div>
    )
  }
}

recipientForm.propTypes = {
  currentBeneficiary: PropTypes.shape({
    account: PropTypes.shape({
      address: PropTypes.shape({
        country_code: PropTypes.string,
      }),
    }),
    currency_code: PropTypes.string,
  }),

  history: PropTypes.object.isRequired,
  /** Provides several attributes to get the current url {@link withRouter} */
  location: PropTypes.object,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  show: PropTypes.bool,
}

export default connect(
  ({ trading }) => ({ currentBeneficiary: trading?.data?.currentBeneficiary || {} }),
  null,
)(withRouter(recipientForm))
