import React, { useState, useEffect, useCallback, useRef } from 'react'
import env from '.env'
import { useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
/* API */
import { show as showTransactionDetails } from 'api/trading/cw/transactions'
import { updatePeachPayment } from 'api/trading/cw/peach_payment'
import { getOzowStatus } from 'api/trading/cw/ozow'
/* Helpers */
import validChecked from 'assets/svg/white_check_mark.svg'
import Cancel from 'assets/svg/whitecross.svg'
import style from '../new_payment/modals/style.module.scss'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
/* Paths */
import { NEW_FULL_PATH } from 'routes/paths/private/transactions'
import TransactionProcessing from 'components/pages/shared/modals/transaction_processing'
/* Config */
import { MobileWalletInfo } from 'components/pages/retail/transactions/new/new_payment/modals/shared/index'
import BeneficiarySettings from 'components/pages/retail/transactions/new/new_payment/shared/beneficiary_settings'
import { setCurrentTradingLimit } from 'actions/trading'
import { transactionMessage } from 'components/helpers/transaction/confirmed_message.js'
import { googleTagManagerEvent } from 'components/utils/google_tag_manager'

const DebitTransactionStatus = props => {
  const [transactionDetails, setTransactionDetails] = useState()
  const [status, setStatus] = useState('')
  const [show, setShow] = useState(false)

  const transactionID = props.match.params.transaction_id
  const urlParams = new URLSearchParams(props.location.search)
  const checkoutID = urlParams.get('id')

  const history = useHistory()

  // Google tag manager transfer event
  const userDetails = useSelector(state => state?.user?.data?.userDetails)
  const currentPaymentMode = useSelector(state => state?.trading?.data?.currentPaymentMode?.paymentMode)

  const fetchPeachStatus = useCallback(
    async () =>
      await updatePeachPayment({
        data: { checkout_response: {} },
        id: checkoutID,
        transactionId: transactionID,
      })
        .then(res => {
          const receiptIsSettled = res?.data?.receipt_is_settled
          setStatus(receiptIsSettled ? 'accepted' : 'declined')

          if (receiptIsSettled) {
            // fire google tag manager transfer event
            googleTagManagerEvent({
              beneficiary: props.beneficiaryDetails,
              currentPaymentMode,
              transactionDetails,
              userDetails,
            })
          }
        })
        .catch(error => {
          toast.error(error?.errors?.description || error?.description)
          setStatus('declined')
        })
        .finally(() => {
          setShow(false)
          props.setCurrentTradingLimit(env.tradingLimitCurrency || 'ZAR')
        }),
    [transactionDetails],
  )

  const timeout = delay => {
    return new Promise(resolve => setTimeout(resolve, delay))
  }

  const fetchOzowStatus = useCallback(async () => {
    try {
      for (let retries = 0; retries < 16; ++retries) {
        const res = await getOzowStatus(transactionID)
        if (res.status === 200 && res.data.receipt_is_settled) {
          setStatus('accepted')
          setShow(false)
          props.setCurrentTradingLimit(env.tradingLimitCurrency || 'ZAR')

          // fire google tag manager transfer event
          googleTagManagerEvent({
            beneficiary: props.beneficiaryDetails,
            currentPaymentMode,
            transactionDetails,
            userDetails,
          })
          return
        }
        if (res.status !== 200 && res.status !== 204) {
          throw new Error(`HTTP error ${res.status}`)
        }
        await timeout(20000)
      }
      setStatus('declined')
      setShow(false)
    } catch (error) {
      toast.error(error?.errors?.description || error?.description)
      setStatus('declined')
      setShow(false)
    }
  }, [transactionDetails])

  const { beneficiaryDetails } = props

  const beneficiaryType = () => {
    switch (beneficiaryDetails.renumeration_type_name) {
      case 'Mobile Wallet':
        return <MobileWalletInfo beneficiary={beneficiaryDetails} />
      case 'SWIFT':
        return <BeneficiarySettings beneficiary={beneficiaryDetails} />
      case 'Local':
        return <BeneficiarySettings beneficiary={beneficiaryDetails} />
      default:
        return <span></span>
    }
  }
  const sentRef = useRef(false)
  useEffect(() => {
    setShow(true)
    if (sentRef.current) { return }
    checkoutID ? fetchPeachStatus() : fetchOzowStatus()
    sentRef.current = true
    isEmpty(transactionDetails) &&
      showTransactionDetails(transactionID)
        .then(res => setTransactionDetails(res?.data))
        .catch(() => toast.error('Error Fetching transaction'))
  }, [fetchPeachStatus, fetchOzowStatus])

  const receiptPaymentModeId = transactionDetails?.receipt_payment_mode_id
  const transactionStatus = transactionDetails?.status_detail?.status

  const paymentMethodLabel = transactionMessage(receiptPaymentModeId, transactionStatus)

  return (
    <div className="content-container py-0">
      <div className="mt-3">
        <TransactionProcessing show={show} />
      </div>
      <div className="mt-4">
        {status === 'accepted' && (
          <div id="quote-result">
            <div className={style.success}>
              <div className={style.successContent}>
                <img src={validChecked} alt="check" width="16" height="16" />
                Transaction was successful. Details have been sent to your contact information.
              </div>
            </div>
            <div className="col-12 px-4 justify-content-center content-body">
              <h2 className="heading confirmation-header">Payment: {transactionDetails?.reference_number}</h2>
              <div className={`${style.modalContent} w-100`}>
                <div className="well well-bordered col-12 p-0 my-0">
                  <div className="form-group">
                    <div className="row no-margin">
                      <span className="col-sm-12 p-0">
                        You have purchased{' '}
                        <span data-testid="payment-amount" className={style.green}>
                          <strong>
                            {formatCurrencyAmount(transactionDetails?.counter_currency_amount)}{' '}
                            {transactionDetails?.counter_currency_code}
                          </strong>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row no-margin">
                      <span className="col-sm-12 p-0">
                        The cost of payment is{' '}
                        <span className={style.red}>
                          <strong>
                            {formatCurrencyAmount(transactionDetails?.receipt_amount)}{' '}
                            {transactionDetails?.base_currency_code}
                          </strong>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row no-margin">{paymentMethodLabel}</div>
                  </div>

                  <div className="divider"></div>

                  <div className="form-group mb-0">
                    <div className="row no-margin">
                      <p className="col-sm-12 p-0">
                        We will release this payment to{' '}
                        <strong data-testid="recipient">{transactionDetails?.beneficiary_names[0]}</strong>
                      </p>
                      <p className="col-sm-12 p-0">{beneficiaryType()}</p>
                      <p className="col-sm-12 p-0 mb-0">
                        The expected receiving date is <strong>{transactionDetails?.value_date}</strong> providing we
                        have received your {transactionDetails?.base_currency_code}{' '}
                        {formatCurrencyAmount(transactionDetails?.receipt_amount)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </div>

              <div className={`mx-0 p-0 border-0 bg-white justify-content-start ${style.footer}`}>
                <p>
                  1. By Confirming the payment you are entering into a contract with Shoprite to sell{' '}
                  <strong>{transactionDetails?.base_currency_code}</strong> and buy{' '}
                  <strong>{transactionDetails?.counter_currency_code}</strong>. Once we receive your{' '}
                  {transactionDetails?.base_currency_code} as cleared funds the{' '}
                  {transactionDetails?.counter_currency_code} purchased will be released to the beneficiary.
                </p>
                <p>
                  2. Shoprite's commission is the total cost charged by Shoprite once your payment request is confirmed.
                  Receiving banks other than Shoprite may impose additional charges.
                </p>
              </div>

              <div className="col-12 d-flex justify-content-start px-0 mt-4">
                <a
                  className="cta-button w-button"
                  onClick={() => history.push(NEW_FULL_PATH)}
                  data-testid="finish-confirmed">
                  Done
                </a>
              </div>
            </div>
          </div>
        )}
        {status === 'declined' && (
          <div id="quote-result">
            <div className={style.failed}>
              <div className={style.successContent} role="alert">
                <img src={Cancel} alt="check" width="16" height="16" />
                Transaction was unsuccessful. Details have been sent to your contact information.
              </div>
            </div>
            <div className={`${style.failedQuote}`}>
              <h2 className="heading confirmation-header">Payment: {transactionDetails?.reference_number}</h2>
              <div className={`${style.grid}`}>
                <p>Funding Account:</p>
                <p className="font-bold">{checkoutID ? 'Debit/Credit Card' : 'Instant EFT'}</p>
              </div>
              <div className={`${style.grid}`}>
                <p>Recipient:</p>
                <p className="font-bold">{transactionDetails?.beneficiary_names[0]}</p>
              </div>
              <div className={`${style.grid}`}>
                <p>Amount I want to send:</p>
                <p className={`${style.red}`}>
                  <strong>
                    {transactionDetails?.base_currency_code}{' '}
                    {formatCurrencyAmount(transactionDetails?.base_currency_amount)}
                  </strong>
                </p>
              </div>
              <div className={`${style.grid}`}>
                <p>Amount I want recipient to get:</p>
                <p className={`${style.red}`}>
                  <strong>
                    {transactionDetails?.counter_currency_code}{' '}
                    {formatCurrencyAmount(transactionDetails?.counter_currency_amount)}
                  </strong>
                </p>
              </div>
              <div className={`${style.grid}`}>
                <p>Exchange rate:</p>
                <p className={`${style.red}`}>
                  <strong>{transactionDetails?.quote?.customer_exchange_rate}</strong>
                </p>
              </div>
              <div className="mt-5">
                <div className="justify-content-start cta-button-block">
                  <a className="cta-button w-button" onClick={() => history.push(NEW_FULL_PATH)}>
                    Go Back
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { currentBeneficiary, currentReceiptAccounts } = state.trading.data
  const { payOutLocationOptions } = state.client.data
  return {
    beneficiaryDetails: currentBeneficiary,
    currentReceiptAccounts,
    payOutLocationOptions,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ setCurrentTradingLimit }, dispatch),
})

DebitTransactionStatus.propTypes = {
  beneficiaryDetails: PropTypes.object,
  currentReceiptAccounts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      transaction_id: PropTypes.number,
    }),
  }),
  payOutLocationOptions: PropTypes.array,
  search: PropTypes.string,
  setCurrentTradingLimit: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DebitTransactionStatus)
