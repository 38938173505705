import PropTypes from 'prop-types'

import { tabs } from './account.constants'

/**
 * Component for My Account
 */
export const MyAccount = ({ match }) => {
  const { tab } = match.params

  const currentTab = tabs.find(item => item.tab === tab)
  if (currentTab && currentTab.component) {
    return currentTab.component
  }
  return null
}

MyAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
}

export default MyAccount
