import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Spinner from 'components/helpers/spinner'
import { Modal } from 'react-bootstrap'
import './styles.scss'

const TransactionProcessing = props => {
  const { show, triggeredFromPaymentPage } = props
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'

  return (
    <Modal contentClassName={env.retail.theme} className={containerClass + ' bene-modal transaction'} show={show}>
      <Modal.Body className="content" data-testid="modal-transaction-in-process">
        <div className="processing">
          {triggeredFromPaymentPage ? (
            <React.Fragment>
              <h4 className="text-center mb-4">Your Request being processed...</h4>
              <h6 className="text-center"> Please don't click on back or refresh button. </h6>
              <Spinner className="my-4" text={false} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2 className="heading panel-header">Transaction is being processed...</h2>
              <p className="modal-p">
                It may take up to 2 minutes to process your transaction. Please don't click on back or refresh button.
                It may cancel your transaction.
              </p>
              <Spinner className="my-4" text={false} />
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

TransactionProcessing.propTypes = {
  show: PropTypes.bool.isRequired,
  triggeredFromPaymentPage: PropTypes.bool,
}

export default TransactionProcessing
