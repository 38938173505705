import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { userLocalStorage } from 'config/local_storage'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
/* API */
import { updatePasswordWithToken, sendResetPasswordInstructions } from 'api/accounts/cw/users'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import AuthHeader from 'components/base/layouts/auth_header'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import validChecked from 'assets/svg/valid.svg'
import { ReactComponent as MoreInfo } from 'assets/svg/more_info.svg'
/* Paths */
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
/* Styles */
import styles from '../reset/styles.module.scss'

class OtpValidation extends Component {
  /**
   * @namespace
   * @property {string} state.otp - One time pin sent to user.
   * @property {boolean} state.isFetching  - Indicates the component loading status
   */
  state = {
    isFetching: false,
    otp: '',
  }

  submitForm = async event => {
    this.setState({ isFetching: true })
    event.preventDefault()
    const userId = userLocalStorage.get('restorationLogin')

    try {
      const res = await updatePasswordWithToken({
        reset_password_token: this.state.otp,
        user_id: userId,
        ...userLocalStorage.get('newCredentials'),
      })
      if (res) {
        toast.success(res?.data?.message || res?.data?.description)
        userLocalStorage.set('newCredentials', null)
        userLocalStorage.set('restorationLogin', null)
        this.props.history.push(SIGN_IN_FULL_PATH)
      }
    } catch (error) {
      toast.error(error?.response?.data?.description || error?.message)
    }
    this.setState({ isFetching: false })
  }

  handleResetPassword = async () => {
    this.setState({ isFetching: true })
    try {
      const response = await sendResetPasswordInstructions(userLocalStorage.get('restorationLogin'))
      response && toast.success(response?.data?.message || response?.data?.description)
    } catch (error) {
      toast.error(error?.response?.data?.description || error?.message)
    }
    this.setState({ isFetching: false })
  }

  render() {
    return (
      <RegistrationHeader classContainer="col-8">
        <AuthHeader title="OTP validation">
          <div className="row justify-content-center">
            <div className={`alert alert-success ${styles.valid}`}>
              <img src={validChecked} alt="valid-tick" />
              To confirm your action you will need to provide a one time pin sent to you.
            </div>
            <form className="w-100" onSubmit={this.submitForm}>
              <div className="form-group">
                <span className="d-flex align-items-start new-color mb-2 popins-14">Enter one time pin (OTP)</span>
                <div className="d-flex">
                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    onChange={e => this.setState({ otp: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group mt-4">
                  <button type="submit" disabled={this.state.isFetching} className="ss-btn red w-100 popins-16 mt-3">
                    {this.state.isFetching ? <Spinner spinner={false} /> : 'Confirm'}
                  </button>
                </div>
                <div className="form-group text-center">
                  <a onClick={this.handleResetPassword} className="btn btn-flat">
                    {this.state.isFetching ? <Spinner /> : 'Resend OTP'}
                  </a>
                </div>
              </div>
            </form>
            <div className={`alert alert-info mt-4 ${styles.valid}`}>
              <MoreInfo className={`${styles.more}`} />
              One time pin will be delivered by SMS to your mobile phone number registered on your account. If you have
              not received the one time pin please contact us.
            </div>
          </div>
        </AuthHeader>
      </RegistrationHeader>
    )
  }
}

OtpValidation.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
}

export default withRouter(OtpValidation)
