import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ApprovedRecipients from './recipients.approved'
import ViewHistory from 'components/pages/shared/modals/view_history/view_history'
import './styles.scss'

const Recipients = () => {
  const [recipientId, setRecipientId] = useState(null)

  const onViewHistory = (recipientId = '') => setRecipientId(recipientId)

  const [reload, setReload] = useState(false)

  useEffect(() => reload && setReload(false), [reload])

  return (
    <div className="content-container">
      <h1 className="page-title white">
        <span className="bar-1">Beneficiaries</span>
      </h1>
      <ApprovedRecipients onViewHistory={onViewHistory} />

      {/** Modals **/}
      {!!recipientId && (
        <ViewHistory show={!!recipientId} onHide={() => setRecipientId('')} recipientId={recipientId} />
      )}
    </div>
  )
}

Recipients.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
}

export default Recipients
