import fetchApi from 'components/utils/fetch_api'

const linkedAccounts = customerId => {
  return Promise.resolve({ data: [] }) // fetchApi(`accounts/cw/customers/${customerId}/linked_accounts`)
}

const getAccountInfoStatus = customerId => {
  return fetchApi(`accounts/cw/customers/${customerId}/risk_assesment_informations/account_info_status`).then(
    res => res?.data,
  )
}

const getCustomerInformation = (customerId, id) => {
  return fetchApi(`accounts/cw/customers/${customerId}/individual_accounts/${id}`)
}

const updateCustomerInformation = (customerId, id, data) => {
  return fetchApi(`accounts/cw/customers/${customerId}/individual_accounts/${id}`, {
    data,
    method: 'PUT',
  })
}

const riskAssesmentInformations = customerId => {
  return fetchApi(`accounts/cw/customers/${customerId}/risk_assesment_informations`)
}

const updateRiskAssesmentInformation = (customerId, id, data) => {
  return fetchApi(`accounts/cw/customers/${customerId}/risk_assesment_informations/${id}`, {
    data,
    method: 'PUT',
  })
}

const getIdentificationInformations = (customerId, id) => {
  return fetchApi(`accounts/cw/customers/${customerId}/id_informations/${id}`)
}

const updateIdentificationInformation = (customerId, data) => {
  return fetchApi(`accounts/cw/customers/${customerId}/id_informations/`, {
    data,
    method: 'POST',
  })
}

const getAccountDocuments = (customerId, id) => {
  return fetchApi(`accounts/cw/customers/${customerId}/documents/${id}`)
}

const createCustomerNotes = (customerId, note) => {
  return fetchApi(`accounts/cw/customers/${customerId}/notes`, { data: { note: { note } }, method: 'POST' })
}

const turnOffReviewCustomerDetails = customerId => {
  return fetchApi(`accounts/cw/customers/${customerId}/risk_assesment_informations/turn_off_update_account_info`, {
    method: 'POST',
  })
}

const getBankProductsNames = customerId =>
  fetchApi(`accounts/cw/customers/${customerId}/risk_assesment_informations/bank_product_names`, {
    method: 'GET',
  }).then(res => res?.data)

export {
  linkedAccounts,
  riskAssesmentInformations,
  getIdentificationInformations,
  updateIdentificationInformation,
  getAccountDocuments,
  updateRiskAssesmentInformation,
  createCustomerNotes,
  getAccountInfoStatus,
  getCustomerInformation,
  turnOffReviewCustomerDetails,
  getBankProductsNames,
  updateCustomerInformation,
}
