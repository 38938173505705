import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { cancel as refundPayment } from 'api/trading/cw/transactions'
import Spinner from 'components/helpers/spinner'
import { toast } from 'react-toastify'
import './index.scss'

const RefundModal = ({ show, onHide, transaction, setReload }) => {
  const [{ loading, resMessage }, setResState] = useState({ loading: false, resMessage: '' })

  const confirmRefundPayment = () => {
    setResState(state => ({ ...state, loading: true }))
    refundPayment(transaction.id)
      .then(response => {
        setResState(state => ({ ...state, loading: false, resMessage: response?.data?.description }))
        if (response?.data?.status === 'cancelled') {
          toast.success('Successfully Cancelled')
          setReload(true)
          return onHide()
        }
      })
      .catch(() => {
        setResState(state => ({ ...state, loading: false }))
        toast.error('Refund failed')
        onHide()
      })
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body className="p-4 font-pop">
        <button onClick={onHide} className="border-0 checkLocal size-bg">
          ×
        </button>
        <div className="refund-header">Cancel Transaction</div>
        {loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!resMessage && (
              <div className="refund-text-line">This will cancel the transaction. This action cannot be undone.</div>
            )}
            <div className="refund-text-line border-bottom">
              {resMessage || 'If you wish to proceed, click Confirm'}
            </div>
            <div className="refund-btns">
              {!resMessage && (
                <button className="refund-btn" onClick={confirmRefundPayment}>
                  Confirm
                </button>
              )}
              <button className="refund-btn" onClick={onHide}>
                {resMessage ? 'Ok' : 'Cancel'}
              </button>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  )
}

RefundModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  setReload: PropTypes.func,
  show: PropTypes.bool,
  transaction: PropTypes.shape({
    base_currency_amount: PropTypes.string,
    base_currency_code: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default RefundModal
