import env from '.env'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import './styles.scss'
/* Actions */
import { setCurrentTradingLimit } from 'actions/trading'
/* Base */
import Spinner from 'components/helpers/spinner'

const tradinglimitCurrency = env.tradingLimitCurrency || 'ZAR'

const RemainingTransferLimit = ({ displayPage }) => {
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'
  const dispatch = useDispatch()
  const { userId } = useSelector(({ user }) => user.data)
  const { currentTradingLimit } = useSelector(({ trading }) => trading.data)

  useEffect(() => {
    if (isEmpty(currentTradingLimit)) {
      dispatch(setCurrentTradingLimit(tradinglimitCurrency))
    }
  }, [userId])

  return (
    <div className={`alertInfo  ${displayPage === 2 ? 'hidden' : ''}`}>
      {isEmpty(currentTradingLimit) ? (
        <Spinner />
      ) : (
        <div className={`${containerClass} transfer-limit-text`}>
          {`You can send up to ${tradinglimitCurrency} ${currentTradingLimit?.limits?.daily_buy?.remaining_limit} today and up to ${tradinglimitCurrency}
            ${currentTradingLimit?.limits?.calendar_month?.remaining_limit} this month.`}
        </div>
      )}
    </div>
  )
}

RemainingTransferLimit.propTypes = {
  currentTradingLimit: PropTypes.object,
  displayPage: PropTypes.number,
}

export default RemainingTransferLimit
