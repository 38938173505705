/* eslint-disable indent */
import React from 'react'
import env from '.env'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { userLocalStorage } from 'config/local_storage'
/* API */
import { signIn } from 'api/accounts/cw/users'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import FlagImage from 'components/helpers/flag_image'
import TextInput from 'components/helpers/form_fields/text_input'
/* Utils */
import WithDataSessionStore from 'components/HOC/with_data_session_store'
/* Paths */
import { PASSWORD_RESET_FULL_PATH, SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import { HOW_IT_WORKS, NEW_FULL_PATH as REGISTER_NEW_FULL_PATH } from 'routes/paths/public/register'
import { ReactComponent as Success } from 'assets/svg/valid.svg'

const authStrategyLabels = {
  email: 'Email',
  mobile_phone_or_member_id: 'Mobile number or Member ID',
}

/**
 * Component to allow user to sign in into the app via token
 */
class SignIn extends React.Component {
  /**
   * @namespace
   * @property {string} state.login - Login typed by the user
   * @property {string} state.password  - Password typed by the user
   * @property {string} state.errorMessage - Message displayed when an error hoccurs
   * @property {boolean} state.isFetching  - Indicates the component loading status
   * @property {boolean} state.open  - Indicates the navbar status
   */
  state = {
    authStrategy: window.Cypress ? 'email' : env.authenticationStrategy || 'email',
    country: 'ZA',
    errorMessage: '',
    isFetching: false,
    login: '',
    password: '',
    showCountryName: false,
    showCountryPhoneCodeSelector: false,
  }

  componentWillUnmount() {
    userLocalStorage.get('isRedirectedFromRegistration') && userLocalStorage.set('isRedirectedFromRegistration', false)
  }

  /**
   * Gets called when the user submit the form
   *
   * @param {Object} event object returned by form
   */
  submitForm = event => {
    event.preventDefault()
    /* if any of the environmental strategy is allowing user to SignIn then we
     * sending his credentials to BE
     */
    if (this.isLoginValid()) {
      this.setState({ isFetching: true })
      signIn(this.createAuthObj(), this.state.password)
        .then(res => this.props.saveDataSession(res.data))
        .catch(error => {
          const status = error.request ? error.request.status : null
          const errorMessage = !status
            ? 'Could not connect to the server now. Please try again later.'
            : error.response.data.errors
          this.setState({ errorMessage })
        })
        .finally(() => this.setState({ isFetching: false }))
    }
  }

  createAuthObj() {
    const { authStrategy, country, showCountryPhoneCodeSelector, login } = this.state
    if (authStrategy === 'email') {
      return { email: login }
    }
    if (authStrategy === 'mobile_phone_or_member_id') {
      if (showCountryPhoneCodeSelector) {
        const isdCode = getCountryCallingCode(country)
        return { isd_code: isdCode, mobile_number: login }
      }
      return { external_uid: login }
    }
  }

  isLoginValid = () => {
    const { showCountryPhoneCodeSelector, login, authStrategy } = this.state
    const emailPattern = /\S+@\S+\.\S+/
    const regexMemberId = /^[a-zA-z]{3}[0-9]{8}$/g
    const payload = () => {
      switch (true) {
        case authStrategy === 'email' && !emailPattern.test(login):
          return 'Please type a valid email'
        case authStrategy === 'mobile_phone_or_member_id' &&
          showCountryPhoneCodeSelector &&
          (login.length < 9 || login.length > 10):
          return 'Phone number is invalid'
        case authStrategy === 'mobile_phone_or_member_id' &&
          !showCountryPhoneCodeSelector &&
          !regexMemberId.test(login):
          return 'Member ID is invalid'
        default:
          return ''
      }
    }
    const errorMessage = payload()
    this.setState({ errorMessage })

    return !errorMessage.length
  }

  /**
   * Validate the input for special characters and Show country codes selector if user starts typing a number
   */
  onChangeHandler = value => {
    const regexSpecialCharacters = /[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,'#]/
    const regexNumbers = /^[0-9\b]+$/
    this.setState({
      error: regexSpecialCharacters.test(value) ? 'Special characters are not allowed' : '',
      login: value,
      showCountryPhoneCodeSelector: !!regexNumbers.test(value),
    })
  }

  renderCountryPhoneCodeSelector() {
    const { showCountryName } = this.state
    return (
      <React.Fragment>
        <FlagImage countryCode={this.state.country} />
        <select
          className="width-100 ss-phone"
          value={this.state.country}
          onMouseLeave={() => this.setState({ showCountryName: false })}
          onMouseUp={() => this.setState({ showCountryName: true })}
          onFocus={() => this.setState({ showCountryName: true })}
          onBlur={() => this.setState({ showCountryName: false })}
          onChange={e => this.setState({ country: e.target.value, showCountryName: false })}>
          {getCountries().map(country => (
            <option key={country} value={country}>
              +{getCountryCallingCode(country)} {showCountryName && en[country]}
            </option>
          ))}
        </select>
      </React.Fragment>
    )
  }

  renderNav = () => (
    <React.Fragment>
      <ul>
        <li id="ss-margin-left">
          <a href={HOW_IT_WORKS} onClick={() => this.setState({ open: false })}>
            How it works
          </a>
        </li>
        <li>
          <Link to={SIGN_IN_FULL_PATH} onClick={() => this.setState({ open: false })}>
            Log in
          </Link>
        </li>
      </ul>
      <button type="button" className="ss-btn red" onClick={() => this.props.history.push(REGISTER_NEW_FULL_PATH)}>
        Register
      </button>
    </React.Fragment>
  )
  /**
   * It's used to store data session and redirect user to proper page
   * Note: redirection after sign in is handled automatically by routes
   * @see src/routes/shared/auth_route/index.jsx For more information
   *
   * @param {Object} response data returnted by the server
   */

  render() {
    const { errorMessage, showCountryPhoneCodeSelector, password, isFetching, authStrategy } = this.state
    return (
      <React.Fragment>
        <RegistrationHeader>
          <div className="fixed-bg inner-shadows justify-content-center d-flex">
            <div className="flex-column">
              {userLocalStorage.get('isRedirectedFromRegistration') && (
                <div className="alert-frame d-flex mb-4">
                  <Success className="mr-3 pb-5 w-25" />
                  <span className="font-bold popins-14">
                    Your account registration was successful. You may proceed to book and pay for your transfer while we
                    activate your account.
                  </span>
                </div>
              )}
              <div>
                <div className="reg-frame">
                  <span className="d-flex align-items-start new-header my-2" id="margin-up">
                    Welcome back
                  </span>
                  <span className="d-flex align-items-start new-header-2 mb-5">Log in to your account</span>
                  <form onSubmit={this.submitForm}>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <div className="form-group">
                      <span className="d-flex align-items-start new-color mb-2 popins-14">
                        {authStrategyLabels[authStrategy]}
                      </span>
                      <div className="d-flex">
                        {showCountryPhoneCodeSelector && this.renderCountryPhoneCodeSelector()}
                        <input
                          type="text"
                          name="login"
                          placeholder="User ID"
                          className="form-control new-radius"
                          onChange={e => {
                            this.onChangeHandler(e.target.value)
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <TextInput
                        label="Your password"
                        className="justify-content-start new-radius"
                        placeholder="Password"
                        name="password"
                        type="password"
                        disabled={isFetching}
                        onChange={e => this.setState({ password: e.target.value })}
                        value={password}
                      />
                    </div>
                    <div className="reset-pwd">
                      <Link to={PASSWORD_RESET_FULL_PATH}>Forgot Password?</Link>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="ss-btn dark-teal w-100">
                        {isFetching ? <Spinner spinner={false} /> : 'Log in'}
                      </button>
                    </div>
                  </form>
                  <div className="d-flex new-acct">
                    Not registered yet? &nbsp;
                    <Link to={REGISTER_NEW_FULL_PATH}>Register now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RegistrationHeader>
      </React.Fragment>
    )
  }
}

SignIn.propTypes = {
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,

  location: PropTypes.object,
  /** Allows to store data session after sign in {@link WithDataSessionStore} */
  saveDataSession: PropTypes.func.isRequired,
}

export default WithDataSessionStore(withRouter(SignIn))
