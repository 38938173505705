import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import formatCurrencyAmount from 'components/utils/format_currency_amount'
import renderErrors from 'components/helpers/render_errors'
import CustomerBankAccountSettings from 'components/pages/retail/transactions/new/new_payment/shared/customer_bank_account_settings'

const AmountDirectDebited = ({ customerBankAccount, currencyCode, errors, prefs }) => {
  const amountToPayFromCba = prefs.from_customer_bank_account?.amount_to_pay || 0

  if (!prefs?.from_customer_bank_account?.enabled || isEmpty(customerBankAccount)) {
    return null
  }

  return (
    <React.Fragment>
      {amountToPayFromCba > 0 && (
        <div className="form-group">
          Amount
          <span className="font-bold mx-1">
            {currencyCode} {formatCurrencyAmount(amountToPayFromCba)}
          </span>
          will be direct debited from my bank account
        </div>
      )}
      <div>
        {!customerBankAccount?.use_as_beneficiary && (
          <React.Fragment>
            <div className="form-group">
              <span>Bank Name: </span>
              <span className="font-bold">
                {customerBankAccount?.bank_account_detail?.bank_name || customerBankAccount?.account_name}
              </span>
            </div>
            <CustomerBankAccountSettings customerBankAccount={customerBankAccount} />
            <div className="form-group">
              <span>Branch Name: </span>
              <span className="font-bold">{customerBankAccount?.bank_account_detail?.branch_name}</span>
            </div>
          </React.Fragment>
        )}
        <div className="form-group">
          <span>{customerBankAccount.bank_account_detail?.account_number_label}: </span>
          <span className="font-bold">{customerBankAccount.bank_account_detail?.account_number}</span>
        </div>
        {renderErrors(errors?.amount)}
      </div>
    </React.Fragment>
  )
}

AmountDirectDebited.propTypes = {
  amountToPayFromCba: PropTypes.number,
  currencyCode: PropTypes.string,
  customerBankAccount: PropTypes.shape({
    account_name: PropTypes.string,
    bank_account_detail: PropTypes.shape({
      account_number: PropTypes.string,
      account_number_label: PropTypes.string,
      bank_name: PropTypes.string,
      branch_name: PropTypes.string,
    }),
    use_as_beneficiary: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.object,
  prefs: PropTypes.shape({
    from_customer_bank_account: PropTypes.shape({
      amount_to_pay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      enabled: PropTypes.bool,
    }),
  }),
}

export default AmountDirectDebited
