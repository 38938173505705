import fetchApi from 'components/utils/fetch_api'

const index = params => {
  return fetchApi('recipients/cw/countries', { params })
}

const preference = (countryCode, params = { page: 1, per_page: 180 }) => {
  return fetchApi(`recipients/cw/countries/${countryCode}`, { params })
}

export { index, preference }
