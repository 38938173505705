import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

export default function Checkbox({ bold, testId, children, ...props }) {
  const activePath = location.pathname
  const beneficiaryPath = activePath.includes('/retail/recipients/')
  const sendMoneyPath = activePath.includes('/retail/transactions/')
  return (
    <label
      className={
        styles.container +
        ` ${props.className || ''} ${beneficiaryPath || sendMoneyPath ? styles.checkboxButtonField : ''}`
      }>
      <div className={`${activePath.includes('/retail/users/') ? 'd-flex' : ''}`}>
        <input {...props} type="checkbox" data-testid={testId} />
        <span
          data-testid={`${testId}-checkmark`}
          style={{ top: props.top || null }}
          className={`${styles.checkmark} ${beneficiaryPath || sendMoneyPath ? styles.newCheckmark : ''} `}
        />
        {props.message && <span className={bold ? 'font-bold' : null}>{props.message}</span>}
        {children}
      </div>
    </label>
  )
}

Checkbox.propTypes = {
  /** Should message be bold? */
  bold: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,

  onChange: PropTypes.func,

  testId: PropTypes.string,
  /** Distance from top */
  top: PropTypes.string,
  value: PropTypes.any,
}
