import { useCallback } from 'react'
import { useField as useFieldFormik, useFormikContext } from 'formik'
import get from 'lodash/get'

export function useField({ name, ...props }) {
  const { status, setStatus, isSubmitting } = useFormikContext()
  const [{ onBlur: onBlurFormik, ...field }, meta, { setValue, setError }] = useFieldFormik({ name, ...props })
  const apiError = get(status, name)

  const onBlurMemo = useCallback(
    e => {
      setStatus({ ...status, [name]: null })
      onBlurFormik(e)
    },
    [status, name, setStatus, onBlurFormik],
  )

  return [
    { onBlur: onBlurMemo, ...field },
    { ...meta, apiError },
    { isSubmitting, setError, setStatus, setValue },
  ]
}
