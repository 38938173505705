import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { userLocalStorage } from 'config/local_storage'

/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import AuthHeader from 'components/base/layouts/auth_header'

/* Helpers */
import { PasswordField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'

/* Validations */
import { NewPasswordValidation } from 'validations'

/* API */
import { updatePasswordWithToken } from 'api/accounts/cw/users'

/* Paths */
import { SIGN_IN_FULL_PATH, OTP_VALIDATION_PATH } from 'routes/paths/public/users'

const PasswordNew = ({ history, location }) => {
  const resetToken = new URLSearchParams(location.search).get('reset_password_token')
  const userId = userLocalStorage.get('restorationLogin')

  return (
    <RegistrationHeader classContainer="col-8">
      <AuthHeader title="Set new password">
        <div className="justify-content-between">
          <h6 className="popins-16 dim-grey mb-4">Enter your new password</h6>
          <Formik
            initialValues={{
              password: '',
              password_confirmation: '',
            }}
            validationSchema={NewPasswordValidation}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              if (resetToken) {
                try {
                  const res = await updatePasswordWithToken({
                    reset_password_token: resetToken,
                    user_id: userId,
                    ...values,
                  })
                  if (res) {
                    toast.success(res?.data?.message)
                    history.push(SIGN_IN_FULL_PATH)
                  }
                } catch (error) {
                  toast.error(error?.response?.data?.description || error?.message)
                  setStatus(error?.response?.data?.errors)
                }
              } else {
                userLocalStorage.set('newCredentials', values)
                history.push(OTP_VALIDATION_PATH)
              }
              setSubmitting(false)
            }}>
            {formik => (
              <form onSubmit={formik.handleSubmit}>
                <PasswordField name="password" label="New password" />
                <div style={{ fontSize: '10px' }} className="my-2 ml-1">
                  Password must be a minimum of 8 characters long, include at least one capital letter, one lowercase
                  letter, one number, and one special character
                </div>
                <PasswordField name="password_confirmation" label="Re-enter new password" />
                <button type="submit" className="ss-btn red w-100 popins-16 mt-3">
                  {formik.isSubmitting ? <Spinner spinner={false} /> : 'Confirm'}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </AuthHeader>
    </RegistrationHeader>
  )
}

PasswordNew.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default PasswordNew
