import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
/** API */
import { search as searchBankAccountSetting } from 'api/recipients/cw/bank_account_settings'
/** Helpers */
import { SimplePopover } from 'components/helpers/popover'
import renderErrors from 'components/helpers/render_errors'
import InputMask from 'components/helpers/form_fields/input_mask'
import TextInput from 'components/helpers/form_fields/text_input'
import SelectCustom from 'components/helpers/form_fields/select'

const IntermediaryBankDetails = props => {
  const {
    beneficiary,
    countriesList,
    disableInterBankDetails,
    displayStdIbdDetails,
    replaceStdDetails,
    errors,
    isRenumerationSwift,
    onIntermediaryBankAttributeChange,
    recipientSettings,
    removeIntermediaryBankDetails,
    addIntermediaryBankDetails,
    showInterBankDetails,
  } = props

  const [intermediaryBankAccountSettings, setIntermediaryBankAccountSettings] = useState({})

  useEffect(() => {
    if (!isEmpty(beneficiary) && isRenumerationSwift()) {
      const params = {
        countryCode: beneficiary?.intermediary_routing_bank_account?.address?.country_code,
        currencyCode: beneficiary.currency_code,
        paymentMode: 'swift',
      }
      searchBankAccountSetting(params)
        .then(response => setIntermediaryBankAccountSettings(response?.data))
        .catch(error => toast.error(error?.response?.data?.description))
    }
  }, [beneficiary.currency_code])

  if (!(isRenumerationSwift() && recipientSettings?.intermediary_routing?.enabled)) {
    return null
  }

  return (
    <React.Fragment>
      <div className="form-group row mb-4">
        <div className="col-12">
          <div className="well-text p-2">
            <label className="text-right-label font-bold">Intermediary bank details</label>
            {showInterBankDetails ? (
              <a className="ml-3 cursor-pointer text-danger" onClick={removeIntermediaryBankDetails}>
                I don't have intermediary bank details
              </a>
            ) : (
              <a className="ml-3 cursor-pointer text-success" onClick={addIntermediaryBankDetails}>
                Add intermediary bank details
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="px-4">
        {showInterBankDetails ? (
          <fieldset className="mt-4" disabled={disableInterBankDetails}>
            <div>
              {displayStdIbdDetails && (
                <div className="well well-warning font-bold space-20">
                  Our standard intermediary bank details have been populated below.
                  <a className="ml-2 cursor-pointer text-success" onClick={replaceStdDetails}>
                    Replace the standard details
                  </a>
                </div>
              )}
              <div>
                <div className="form-group row">
                  <div className="col-12">
                    <TextInput
                      onChange={event => onIntermediaryBankAttributeChange('bank_name', event.target.value)}
                      value={beneficiary.intermediary_routing_bank_account.bank_name}
                      label="Bank Name"
                    />
                    {renderErrors(errors['intermediary_routing_bank_account.bank_name'])}
                  </div>
                </div>
                <div>
                  <div className="form-group row">
                    <div className="col-12">
                      <TextInput
                        onChange={event =>
                          onIntermediaryBankAttributeChange('address.address_line_1', event.target.value)
                        }
                        value={beneficiary.intermediary_routing_bank_account.address.address_line_1}
                        label="Address"
                      />
                      {renderErrors(errors['intermediary_routing_bank_account.address.address_line_1'])}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12">
                      <TextInput
                        onChange={event => onIntermediaryBankAttributeChange('address.city', event.target.value)}
                        value={beneficiary.intermediary_routing_bank_account.address.city}
                        label="City"
                      />
                      {renderErrors(errors['intermediary_routing_bank_account.address.city'])}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12">
                      <SelectCustom
                        label="Country"
                        disabled={disableInterBankDetails}
                        placeholder="Select"
                        theme="tdb-theme-select"
                        options={countriesList}
                        onChange={countryOption =>
                          onIntermediaryBankAttributeChange('address.country_code', countryOption.value)
                        }
                        value={countriesList.find(
                          option =>
                            option.value === beneficiary.intermediary_routing_bank_account?.address?.country_code,
                        )}
                        required
                        useDefaultMenu
                        useDefaultValueContainer
                      />
                      {renderErrors(errors['intermediary_routing_bank_account.address.country_code'])}
                    </div>
                  </div>
                </div>
                {!isEmpty(intermediaryBankAccountSettings) &&
                  ['bic', 'blc', 'account_number'].map(attribute => {
                    if (!intermediaryBankAccountSettings?.[attribute]?.enabled && attribute !== 'account_number') {
                      return null
                    }

                    return (
                      <div key={intermediaryBankAccountSettings[attribute].label} className="form-group required row">
                        <div className="col-sm-6">
                          <label className="control-label">
                            {intermediaryBankAccountSettings[attribute].label}
                            <SimplePopover id="popover-basic" text={intermediaryBankAccountSettings[attribute].note}>
                              <i className="ml-2 fa fa-question-circle fa-lg" />
                            </SimplePopover>
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <InputMask
                            maxLength={intermediaryBankAccountSettings[attribute].max_length}
                            value={beneficiary.intermediary_routing_bank_account[attribute]}
                            onChange={event => onIntermediaryBankAttributeChange(attribute, event.target.value)}
                          />
                          {renderErrors(errors[`intermediary_routing_bank_account.${attribute}`])}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </fieldset>
        ) : (
          Object.keys(errors).some(key => key.includes('intermediary_routing_bank_account')) && (
            <div className="text-danger text-center mb-4">Intermediary bank details is required</div>
          )
        )}
      </div>
    </React.Fragment>
  )
}

IntermediaryBankDetails.propTypes = {
  addIntermediaryBankDetails: PropTypes.func.isRequired,
  beneficiary: PropTypes.shape({
    currency_code: PropTypes.string,
    intermediary_routing_bank_account: PropTypes.shape({
      _destroy: PropTypes.any,
      address: PropTypes.shape({
        address_line_1: PropTypes.string,
        city: PropTypes.string,
        country_code: PropTypes.string,
      }),
      bank_name: PropTypes.string,
    }),
  }),
  countriesList: PropTypes.arrayOf(PropTypes.object),
  disableInterBankDetails: PropTypes.bool,
  displayStdIbdDetails: PropTypes.bool,
  errors: PropTypes.shape({
    'intermediary_routing_bank_account.address.address_line_1': PropTypes.any,
    'intermediary_routing_bank_account.address.city': PropTypes.any,
    'intermediary_routing_bank_account.address.country_code': PropTypes.any,
    'intermediary_routing_bank_account.bank_name': PropTypes.any,
  }),
  intermediaryBankAccountSettings: PropTypes.shape({
    account_number: PropTypes.shape({
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    bic: PropTypes.shape({
      enabled: PropTypes.bool,
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    blc: PropTypes.shape({
      enabled: PropTypes.bool,
      label: PropTypes.string,
      max_length: PropTypes.number,
      note: PropTypes.string,
    }),
    payment_mode: PropTypes.shape({
      local: PropTypes.bool,
    }),
  }),
  isRenumerationSwift: PropTypes.func.isRequired,
  onIntermediaryBankAttributeChange: PropTypes.func.isRequired,
  recipientSettings: PropTypes.shape({
    intermediary_routing: PropTypes.shape({
      enabled: PropTypes.bool,
    }),
  }),
  removeIntermediaryBankDetails: PropTypes.func.isRequired,
  replaceStdDetails: PropTypes.func.isRequired,
  showInterBankDetails: PropTypes.bool,
}

export default IntermediaryBankDetails
