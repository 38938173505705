import fetchApi from 'components/utils/fetch_api'

const create = data => {
  return fetchApi('trading/cw/transaction_quotes', { data, method: 'POST' })
}

const update = ({ transactionId, data }) => {
  return fetchApi(`trading/cw/transaction_quotes/${transactionId}`, { data, method: 'PUT' })
}

const refresh = transactionId => {
  return fetchApi(`trading/cw/transaction_quotes/${transactionId}/refresh`, { method: 'PUT' })
}

export { create, update, refresh }
