import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Loader } from 'assets/images/loader.svg'

class Spinner extends Component {
  /**
   * @namespace
   * @property {boolean} state.showLoader - Disables loader when timeout is set
   */
  state = {
    showLoader: true,
  }

  componentDidMount() {
    const { timeout } = this.props
    if (timeout) {
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            showLoader: false,
          })
        }
      }, timeout)
    }
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { showLoader } = this.state
    const { text = true, spinner = true, className = '', children } = this.props
    return showLoader ? (
      <div className={`text-center${className ? ` ${className}` : ''}`}>
        {text && (children || <span>Please Wait...</span>)}
        {spinner && <Loader />}
      </div>
    ) : null
  }
}

Spinner.propTypes = {
  /** Components passed down to Spinner */
  children: PropTypes.element,
  /** Classes to be passed to loader wrapper */
  className: PropTypes.string,
  /** Allows to render loader animation */
  spinner: PropTypes.bool,
  /** Allows to render loader text */
  text: PropTypes.bool,
  /** Amount of time (in miliseconds) that the loader should be visible */
  timeout: PropTypes.number,
}

export default Spinner
