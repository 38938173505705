import { clientApplication } from 'api/accounts/public/settings'
import { allCountries } from 'api/accounts/public/countries'
import { sourceOfFunds } from 'api/dropdowns'
import { getAccountInfoStatus } from 'api/accounts/cw/customers'
import { clientApplicationLocalStorage } from 'config/local_storage'
const SET_CURRENT_CLIENT_DATA = 'SET_CURRENT_CLIENT_DATA'
const SET_DROPDOWN_COUNTRIES = 'SET_DROPDOWN_COUNTRIES'
const SET_DROPDOWN_SOF = 'SET_DROPDOWN_SOF'
const SET_FORCED_PROFILE_REVIEW = 'SET_FORCED_PROFILE_REVIEW'
const SET_PAYOUT_LOCATION_OPTIONS = 'SET_PAYOUT_LOCATION_OPTIONS'

const setCurrentClientData = () => dispatch => {
  // Show cached data while loading new data
  dispatch({
    payload: clientApplicationLocalStorage.get('data'),
    type: SET_CURRENT_CLIENT_DATA,
  })

  return clientApplication().then(res => {
    const payload = res?.data
    window.Localize.initialize({
      key: payload?.tenant?.localize_js_api_key,
      rememberLanguage: true,
    })
    dispatch({
      payload: payload,
      type: SET_CURRENT_CLIENT_DATA,
    })
  })
}

const setDropdownCountries = () => dispatch => {
  return allCountries().then(res => {
    const payload = res?.data
    dispatch({
      payload,
      type: SET_DROPDOWN_COUNTRIES,
    })
  })
}

/**
 * Fetch list of available source of funds
 */
const setDropdownSOF = () => dispatch => {
  return sourceOfFunds().then(res => {
    const payload = res?.data
    dispatch({
      payload,
      type: SET_DROPDOWN_SOF,
    })
  })
}

const setForcedProfileReview = userId => dispatch =>
  getAccountInfoStatus(userId).then(response => {
    const payload = response?.profile_info_review_required
    dispatch({
      payload,
      type: SET_FORCED_PROFILE_REVIEW,
    })
  })

const eraseForcedProfileReviewProp = isReviewRequired => dispatch =>
  dispatch({ payload: isReviewRequired, type: SET_FORCED_PROFILE_REVIEW })

const setPayOutLocationOptions = options => dispatch =>
  dispatch({ payload: options, type: SET_PAYOUT_LOCATION_OPTIONS })

export {
  SET_CURRENT_CLIENT_DATA,
  SET_DROPDOWN_COUNTRIES,
  SET_DROPDOWN_SOF,
  SET_FORCED_PROFILE_REVIEW,
  SET_PAYOUT_LOCATION_OPTIONS,
  setPayOutLocationOptions,
  setCurrentClientData,
  setDropdownCountries,
  setDropdownSOF,
  setForcedProfileReview,
  eraseForcedProfileReviewProp,
}
