/* Redux */
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import reducers from 'reducers'
import { initializers } from 'actions'
/* Middlewares */
import initializeCurrentUserMiddleware from 'middlewares/initialize_current_user'
import initializeCurrentClientMiddleware from 'middlewares/initialize_current_client'
const loggerMiddleware = createLogger()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = [initializeCurrentUserMiddleware, initializeCurrentClientMiddleware, thunkMiddleware]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware)
}

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)))

if (process.env.NODE_ENV !== 'test') {
  initializers.actions.forEach(action => {
    store.dispatch(action)
  })
}

export default store
