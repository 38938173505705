import fetchApi from 'components/utils/fetch_api'

const getRegistrationFlow = (accountTypeId, id, customerCategoryName = '', countryCode = 'ZA') => {
  return fetchApi(`accounts/public/account_types/${accountTypeId}/forms/${id}`, {
    params: { country_code: countryCode, customer_category_name: customerCategoryName },
  }).then(res => res?.data)
}

const createAccountLogin = data => {
  return fetchApi('accounts/public/individual_accounts', { data, method: 'POST' }).then(res => res?.data)
}
const updateUserAddresses = (data, userId, id) => {
  return fetchApi(`accounts/cw/users/${userId}/addresses/${id}`, { data, method: 'PUT' }).then(res => res?.data)
}
// referenceId acceptable values: ["individual_govt_issued_id", "individual_poa", "individual_bank_statement"]"
const uploadIdentificationScan = (scanFile, customerId, referenceId) => {
  const formData = new FormData()
  formData.append('document[file]', scanFile)
  formData.append('document[reference_id]', referenceId)
  return fetchApi(`/accounts/cw/customers/${customerId}/individual_documents`, {
    contentType: 'multipart/form-data',
    data: formData,
    method: 'POST',
  }).then(res => res?.data)
}

const getCustomerCategories = () => {
  return fetchApi('dropdowns/public/account_information/customer_category').then(res => res?.data)
}

const notifyOnNewAccountCreation = id => {
  return fetchApi(`notifications/am/customers/${id}/new_account`, { method: 'POST' }).then(res => res?.data)
}

const getBankProductsPromotions = () => {
  return fetchApi('dropdowns/public/account_information/bank_products').then(res => res?.data)
}

const verifyOTP = data => {
  return fetchApi('/otp/cw/am_otps/verify', { data, method: 'POST' }).then(res => res?.data)
}

const resendOTP = data => {
  return fetchApi('/otp/cw/am_otps', { data, method: 'POST' }).then(res => res?.data)
}

export {
  getRegistrationFlow,
  createAccountLogin,
  updateUserAddresses,
  uploadIdentificationScan,
  getCustomerCategories,
  notifyOnNewAccountCreation,
  getBankProductsPromotions,
  verifyOTP,
  resendOTP,
}
