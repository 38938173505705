import faker from 'faker'

export default [
  {
    account_name: faker.finance.accountName(),
    balance: faker.finance.amount(),
    bank_account_detail: {
      account_number: faker.finance.routingNumber(),
      bank_address: {
        country_code: 'US',
        country_name: faker.address.country(),
      },
      bank_name: faker.company.companyName(),
      bic: faker.finance.bic(),
      branch_name: null,
    },
    currency_code: 'ZAR',
    customer_id: 1,
    id: 1,
    source: '',
    status: 'active',
  },
]
