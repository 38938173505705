import env from '.env'
import React from 'react'
import PropTypes from 'prop-types'
import MainHeader from 'components/base/layouts/main/header'

import './index.scss'

const MainContainer = props => {
  return (
    <div className="retail">
      <div className={env.retail.theme}>
        <MainHeader />

        <section className="pt-0 background shadows">{props.children}</section>
      </div>
    </div>
  )
}

MainContainer.propTypes = {
  children: PropTypes.element.isRequired,
}

export default MainContainer
