import { isEmpty, isEqual, toArray } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import CurrencyInput from './currency'
import TextInput from './text_input'
import TextInputPhone from './text_input_phone'
import RadioGroup from './radio_group'
import Searchbox from './searchbox'
import Select from './select'
import PhoneInputWithFlag from './input_phone'
import { checkValidity, validateForm } from 'components/utils/form_validation/validation'

/**
 *
 * @param {Object} initialFields - fields object with all objects that need to be rendered
 * gives option to render form elements and access to form data
 */
export const useForm = ({ identificator = 'form', initialFields = {}, layout = 'horizontal', fieldContainer }) => {
  const [{ fields, fieldsProp }, setState] = useState({ fields: initialFields, fieldsProp: initialFields })

  /**
   * updates fields and bind value to field
   * - on initialization
   * - in case of initialFields change - if some async data was fetched
   * - in case of initial values change - if some async data was fetched
   */
  const updateFields = useCallback(() => {
    setState(prevState => {
      return { fields: initialFields, fieldsProp: initialFields }
    })
  }, [initialFields, setState])

  /**
   * updates fields if initial fields changed/fetched - in case of async data fetch
   * updates fields if initial state changed/fetched - in case of async data fetch
   */
  useEffect(() => {
    if (!isEmpty(initialFields) && !isEqual(fieldsProp, initialFields)) {
      updateFields()
    }
  }, [fields, fieldsProp, initialFields, updateFields])

  /**
   * Provides form values
   */
  const getValues = () => {
    const values = {}
    Object.keys(fields).forEach(key => {
      values[key] = fields[key].value
    })
    return { values }
  }

  /**
   * Validates form
   * updates form with new data
   * Provides form values and check if form isValid
   */
  const getValuesWithValidation = () => {
    const { isValid, newState, values } = validateForm(fields)
    setState(prevState => ({ ...prevState, fields: { ...prevState.fields, ...newState } }))
    return { isValid, values }
  }

  /**
   * Provides option to update form data from component
   */
  const setValues = fieldsToUpdate => {
    setState(prevState => {
      const newFields = { ...prevState.fields }
      Object.keys(fieldsToUpdate).forEach(key => {
        newFields[key] = {
          ...newFields[key],
          ...fieldsToUpdate[key],
        }
      })
      return { ...prevState, fields: newFields }
    })
  }

  /**
   * Provides option to update form field value
   * @param {string} field
   * @param {any} field
   */
  const setValue = ({ field, value }) => {
    onChangeValue(field)(value)
  }

  /**
   * updates field value
   * @param {string} field
   * @param {event} event
   */
  const onChange = field => event => {
    onChangeValue(field)(event.target.value)
  }

  /**
   * updates value on dropdown change
   * @param {string} field
   * @param {boolean} multiple
   * @param {Object | string} value
   */
  const onSelect = (field, multiple) => async value => {
    if (typeof value === 'string' || (typeof value === 'object' && value.length === 0)) {
      let newValue = fields[field].value
      await setState(prevState => {
        let valid = {}
        newValue = value
        if (multiple) {
          newValue = [...(prevState.fields[field].value || [])]
          if (newValue.includes(value)) {
            newValue = newValue.filter(item => item !== value)
          } else {
            newValue.push(value)
          }
          if (typeof value === 'object' && value.length === 0) {
            newValue = []
          }
        }
        if (fields[field].error) {
          valid = checkValidity({ field, validators: fields[field].validators, value: newValue })
        }
        return {
          ...prevState,
          fields: { ...prevState.fields, [field]: { ...prevState.fields[field], ...valid, value: newValue } },
        }
      })
      setTimeout(() => {
        if (fields[field].onChange) {
          fields[field].onChange({ field, value: newValue })
        }
      })
    }
  }

  /**
   * updates field of type phone on change
   * @param {string} field
   * @param {Object | string} event
   */
  const onChangePhoneValue = field => subField => async event => {
    let newValue = fields[field].value
    setState(prevState => {
      let valid = {}
      newValue = { ...(prevState.fields[field].value || {}) }
      newValue[subField] = event.target.value
      if (fields[field].error) {
        valid = checkValidity({ field, validators: fields[field].validators, value: newValue })
      }
      return {
        ...prevState,
        fields: { ...prevState.fields, [field]: { ...prevState.fields[field], ...valid, value: newValue } },
      }
    })
    setTimeout(() => {
      if (fields[field].onChange) {
        fields[field].onChange({ field, value: newValue })
      }
    })
  }

  /**
   * updates field value on change
   * @param {string} field
   * @param {Object | string} event
   */
  const onChangeValue = field => value => {
    setState(prevState => {
      let valid = {}
      if (fields[field].error) {
        valid = checkValidity({ field, validators: fields[field].validators, value })
      }
      return {
        ...prevState,
        fields: { ...prevState.fields, [field]: { ...prevState.fields[field], ...valid, value } },
      }
    })
    setTimeout(() => {
      if (fields[field].onChange) {
        fields[field].onChange({ field, value })
      }
    })
  }

  /**
   * Renders Select
   */
  const renderSelect = ({ field }) => {
    const { className, disabled, label, multiple, options, placeholder, required, searchable, type, name } = fields[
      field
    ]
    let value = fields[field].value
    if (!multiple && !isEmpty(options) && !!value) {
      value = options[fields[field].value]
    }
    return (
      <Select
        key={field}
        className={`${className || 'mb-3'}`}
        clearable
        disabled={disabled}
        error={fields[field].error}
        multiple={multiple}
        layout={layout}
        label={label || name}
        name={field}
        testID={`${identificator}-${field}-select`}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onSelect(field, multiple)}
        options={toArray(options)}
        searchable={searchable}
        value={value}
      />
    )
  }

  /**
   * Renders TextInput
   */
  const renderTextInput = ({ field }) => {
    const { className, label, placeholder, required, type, name } = fields[field]
    return (
      <TextInput
        key={field}
        className={`${className || 'mb-3'}`}
        error={fields[field].error}
        layout={layout}
        label={label || name}
        name={field}
        testID={`${identificator}-${field}-text-input`}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onChange(field)}
        value={fields[field].value}
      />
    )
  }

  /**
   * Renders PhoneInputWithFlag
   */
  const renderPhoneInputWithFlag = ({ field }) => {
    const { className, label, placeholder, required } = fields[field]
    return (
      <div className="d-flex align-items-center justify-content-between form-group">
        <div className="col-sm-5">
          <label className={`control-label${required ? ' required' : ''}`} htmlFor={field}>
            {label}
          </label>
        </div>
        <div className="col-sm-7">
          <PhoneInputWithFlag
            key={field}
            international
            className={`${className}`}
            error={fields[field].error}
            layout={layout}
            label={label}
            name={field}
            testID={`${identificator}-${field}-phone-input-with-flag`}
            defaultCountry="ZA"
            placeholder={placeholder}
            required={required}
            onChange={value => onChangeValue(field)(value)}
            value={fields[field].value}
          />
        </div>
      </div>
    )
  }

  const renderCurrencyInput = ({ field }) => {
    const { disabled, placeholder, locale } = fields[field]
    return (
      <CurrencyInput
        name={field}
        locale={locale}
        value={fields[field].value}
        onChange={onChange(field)}
        disabled={disabled}
        className="form-control input-lg text-right"
        placeholder={placeholder}
      />
    )
  }
  /**
   * Renders TextInputPhone
   */
  const renderTextInputPhone = ({ field }) => {
    const { className, keys, label, mask, placeholder, required, type } = fields[field]
    let value = fields[field].value
    if (!value) {
      value = { [keys[0]]: '', [keys[1]]: '' }
    }
    return (
      <TextInputPhone
        key={field}
        keys={keys}
        className={`${className || 'mb-3'}`}
        error={fields[field].error}
        layout={layout}
        label={label}
        mask={mask}
        name={field}
        testID={`${identificator}-${field}-text-input-phone`}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onChangePhoneValue(field)}
        value={value}
      />
    )
  }

  /**
   * Renders RadioGroup
   */
  const renderRadioGroup = ({ field }) => {
    const { className, label, options, placeholder, required, type } = fields[field]
    return (
      <RadioGroup
        key={field}
        className={`${className || 'mb-3'}`}
        error={fields[field].error}
        layout={layout}
        label={label}
        name={field}
        testID={`${identificator}-${field}-radio-group`}
        type={type}
        options={options}
        placeholder={placeholder}
        required={required}
        onChange={onChangeValue(field)}
        value={fields[field].value}
      />
    )
  }

  /**
   * Renders Searchbox
   */
  const renderSearchbox = ({ field }) => {
    const { className, disabled, label, onSearch, placeholder, type } = fields[field]
    return (
      <Searchbox
        key={field}
        className={`${className || 'mb-3'}`}
        disabled={disabled}
        error={fields[field].error}
        label={label}
        name={field}
        testID={`${identificator}-${field}-searchbox`}
        type={type}
        placeholder={placeholder}
        onChange={onChange(field)}
        onSearch={onSearch}
        value={fields[field].value}
      />
    )
  }

  const renderField = ({ field }) => {
    switch (fields[field].type) {
      case 'radio':
        return renderRadioGroup({ field })
      case 'select':
        return renderSelect({ field })
      case 'text':
        return renderTextInput({ field })
      default:
        return null
    }
  }

  const renderForm = fieldNames => {
    const fieldset = fieldNames || Object.keys(fieldsProp)
    return fieldset.map(field =>
      fieldContainer ? fieldContainer(renderField({ field }), field) : renderField({ field }),
    )
  }

  /**
   * Exposes useForm methods
   */
  return {
    fields,
    getValues,
    getValuesWithValidation,
    renderCurrencyInput,
    renderForm,
    renderPhoneInputWithFlag,
    renderRadioGroup,
    renderSearchbox,
    renderSelect,
    renderTextInput,
    renderTextInputPhone,
    setValue,
    setValues,
  }
}
