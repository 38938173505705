import fetchApi from 'components/utils/fetch_api'

const search = ({ currencyCode, countryCode, paymentMode }) => {
  return fetchApi('recipients/cw/bank_account_settings/search', {
    params: {
      q: {
        country_code_eq: countryCode,
        currency_code_eq: currencyCode,
        payment_mode_eq: paymentMode,
      },
    },
  })
}

export { search }
