import PropTypes from 'prop-types'
import React from 'react'

/**
 * Component for custom DropdownValueContainer
 */
const CustomValueContainer = ({ getValue, isMulti, selectProps, testID, toggleMenu }) => {
  const values = getValue()
  let text = selectProps.placeholder || ''
  let hasValue = false
  if (isMulti) {
    if (values && values.length) {
      hasValue = true
      text = `${values.length} option${values.length > 1 ? 's' : ''} selected`
    } else {
      text = 'No options selected'
    }
  } else if (values[0]) {
    hasValue = true
    text = values[0].label
  }
  return (
    <div
      className="select_value-container"
      onClick={toggleMenu}
      data-testid={`${testID}-value-${hasValue ? text : ''}`}>
      {text}
    </div>
  )
}

CustomValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  testID: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
}

export default CustomValueContainer
