import env from '.env'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import renderErrors from 'components/helpers/render_errors'

const OtpModal = props => {
  const [otp, setOtp] = useState()
  const { onSubmit, onHide, show, errors } = props
  const containerClass = useLocation().pathname.includes('retail') ? 'retail' : 'wholesale'

  return (
    <Modal contentClassName={env.retail.theme} className={containerClass} show={show} onHide={onHide}>
      <Modal.Body className="px-5 pb-5">
        <form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            onSubmit(otp)
          }}>
          <div className="row">
            <div className="col-sm-3 col-sm-offset-1">
              <label className="control-label">OTP</label>
            </div>
            <div className="col-sm-9">
              <input value={otp} type="otp" className="form-control" onChange={event => setOtp(event.target.value)} />
              {renderErrors(errors)}
            </div>
          </div>
          <div className="pull-right">
            <button className="btn btn-lg btn-primary space-30">Submit</button>
            <button onClick={onHide} className="btn btn-lg btn-default cancel">
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

OtpModal.propTypes = {
  errors: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default OtpModal
